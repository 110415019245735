import styled from "styled-components";

export const AvailableWidgetsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.neutralBorder};
  height: 100%;
`;

export const AvailableWidgetsHeadingWrapper = styled.div`
  display: flex;
  padding: 20px 20px 12px 20px;
  align-items: center;
  gap: 8px;
`;

export const AvailableWidgetsHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const AvailableWidgetsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.primaryBackgroundActive};
  color: ${({ theme }) => theme.primaryText};
  font-size: 12px;
  font-weight: 500;
`;

export const WidgetsItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 8px;
  padding-bottom: 16px;
  padding-inline: 20px;
  max-width: 100%;
`;

export const WidgetItem = styled.div<{
  $selected?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 16px;
  width: auto;
  height: 263px;
  color: ${({ theme }) => theme.primaryText};
`;
