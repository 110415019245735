import React, { SetStateAction } from "react";
import {
  DropDownWrapper,
  SectionItem,
  SectionItemsWrapper,
  SectionName,
  SectionWrapper,
} from "./styles";
import { LOCAL_STORAGE_KEYS } from "../../constants";

interface SortDropDownInterface {
  sortBy: any;
  orderBy: any;
  setSortBy: SetStateAction<any>;
  handleSortChange: SetStateAction<any>;
  setOrderBy: SetStateAction<any>;
}

export const SortDropDown = ({
  setSortBy,
  sortBy,
  handleSortChange,
  setOrderBy,
  orderBy,
}: SortDropDownInterface) => {
  return (
    <DropDownWrapper>
      <SectionWrapper>
        <SectionName>SORT BY</SectionName>
        <SectionItemsWrapper>
          <SectionItem
            $active={sortBy === "name"}
            onClick={() => {
              setSortBy("name");
              handleSortChange("name");
              localStorage.setItem(LOCAL_STORAGE_KEYS.sort, "name");
            }}
          >
            Alphabetical
          </SectionItem>
          <SectionItem
            $active={sortBy === "createdAt"}
            onClick={() => {
              setSortBy("createdAt");
              handleSortChange("createdAt");
              localStorage.setItem(LOCAL_STORAGE_KEYS.sort, "createdAt");
            }}
          >
            Date created
          </SectionItem>
        </SectionItemsWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SectionName>ORDER</SectionName>
        <SectionItemsWrapper>
          <SectionItem
            $active={orderBy === "ASC"}
            onClick={() => {
              setOrderBy("ASC");
              localStorage.setItem(LOCAL_STORAGE_KEYS.order, "ASC");
            }}
          >
            Oldest first
          </SectionItem>
          <SectionItem
            $active={orderBy === "DESC"}
            onClick={() => {
              setOrderBy("DESC");
              localStorage.setItem(LOCAL_STORAGE_KEYS.order, "DESC");
            }}
          >
            Newest first
          </SectionItem>
        </SectionItemsWrapper>
      </SectionWrapper>
    </DropDownWrapper>
  );
};
