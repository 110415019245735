import { getIconSrc } from "../../../../../../../helpers/getIconType";
import Badge from "../../../../../../Badge";
import {
  PreviewButton,
  Slash,
} from "../../../../../../DatasetModalListItem/style";
import RadioButton from "../../../../../../Inputs/CustomRadio/RadioButton";
import { Dot } from "react-bootstrap-icons";

import {
  Data,
  DimensionsList,
  Header,
  MeasuresCount,
  PreviewWrapper,
  SubHeader,
  TableDataRow,
  TableDataRowContainer,
} from "./style";

type TRProps = {
  id: string;
  header: string;
  subHeader: string;
  measures?: number;
  selected?: boolean;
  selectHandler: any;
  fields?: any[];
  goToPreview?: () => void;
};

export const TRElement = ({
  id,
  header,
  subHeader,
  measures,
  selected = false,
  selectHandler,
  fields,
  goToPreview,
}: TRProps) => {
  const subHeaderText = (subHeader: string, selected?: boolean) => {
    const maxLength = measures ? 64 : 68;

    if (selected) {
      return subHeader;
    }

    return subHeader.length > maxLength
      ? subHeader.slice(0, maxLength) + "..."
      : subHeader;
  };

  return (
    <TableDataRowContainer onClick={(e) => selectHandler(e, id)}>
      <TableDataRow>
        <RadioButton
          name={"selectPopulation"}
          checked={selected}
          onChange={(e) => selectHandler(e, id)}
          value={header}
        ></RadioButton>

        <Data>
          <Header>{header}</Header>
          <SubHeader>{subHeaderText(subHeader, selected)}</SubHeader>
        </Data>
        {!!measures && <MeasuresCount>{measures}</MeasuresCount>}
      </TableDataRow>
      <DimensionsList>
        {fields &&
          fields?.length > 0 &&
          selected &&
          fields.map((field, index: number) => (
            <Badge
              key={index}
              name={field.label}
              onClick={() => { }}
              keyName={field.key}
              type={field.type}
              subtype={field.subtype}
              icon={getIconSrc({ type: field.type, subtype: field.subtype })}
              isDraggable={false}
              selectedItems={[]}
            />
          ))}
        {fields && fields?.length > 0 && selected && goToPreview && (
          <PreviewWrapper>
            <Dot></Dot>
            <PreviewButton onClick={goToPreview}>
              <Slash />
              Preview
              <Slash />
            </PreviewButton>
          </PreviewWrapper>
        )}
      </DimensionsList>
    </TableDataRowContainer>
  );
};
