import React, { ReactNode } from "react";

import { CheckboxName, CloseButton, CustomBadge, VerticalLine } from "./styles";
import { useDrag } from "react-dnd";

interface BadgeProps {
  name: string;
  onClick: () => void;
  selectedItems?: string[];
  icon?: ReactNode | any;
  errorDetected?: boolean;
  type?: string;
  keyName?: string;
  subtype?: string;
  isDraggable?: boolean;
  onCancel?: () => void;
  cancelButton?: boolean;
  customSelected?: boolean;
}

const Badge: React.FC<BadgeProps> = ({
  name,
  onClick,
  selectedItems,
  icon,
  errorDetected,
  type,
  keyName,
  subtype,
  isDraggable,
  cancelButton,
  customSelected,
  onCancel = () => {},
}: BadgeProps) => {
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: type || "DATASET",
      item: { name, type, icon, selectedItems, subtype, key: keyName },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [name, type, icon, selectedItems, subtype, keyName]
  );

  const selected =
    selectedItems?.includes(name!) || selectedItems?.includes(keyName!);

  const Property =
    isDraggable && !selected
      ? { ref: dragRef }
      : customSelected
      ? { ref: dragRef }
      : {};

  return (
    <CustomBadge
      {...Property}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={onClick}
      $selected={selected}
      $errorDetected={errorDetected}
      $customSelected={customSelected}
    >
      <img src={icon} alt="dimension-icon" />
      <VerticalLine $full={customSelected && selected} />
      <CheckboxName>{name}</CheckboxName>
      {cancelButton && <CloseButton onClick={onCancel} />}
    </CustomBadge>
  );
};

export default Badge;
