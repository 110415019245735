import React, { useState } from "react";

import {
  CheckBoxWrapper,
  DatasetsTable,
  Details,
  Dimensions,
  HeadingWrapper,
  More,
  Name,
  UploadedOn,
  UploadedOnSort,
} from "./styles";
import { ThreeDots } from "react-bootstrap-icons";

import { DatasetRow } from "../DatasetRow";
import { NewDatasetDTO } from "../../models/Datasets";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";

export const DatasetsList = ({ items }: any) => {
  const [checkAll, setCheckAll] = useState<boolean>(false);
  return (
    <DatasetsTable>
      <HeadingWrapper>
        <CheckBoxWrapper>
          <CheckBox
            checked={checkAll}
            onChange={(e) => setCheckAll(e.target.checked)}
          />
        </CheckBoxWrapper>
        <Name>
          Name <ThreeDots />
        </Name>
        <Dimensions>
          Dimensions <ThreeDots />
        </Dimensions>
        <UploadedOn>
          Uploaded on <UploadedOnSort />
        </UploadedOn>
        <Details>
          <More></More>
        </Details>
      </HeadingWrapper>
      {items?.map((dataset: NewDatasetDTO) => (
        <DatasetRow
          id={dataset.id}
          name={dataset.name}
          createdAt={dataset.createdAt}
          fields={dataset.fields}
          filePath={dataset.filePath}
          updatedAt={dataset.updatedAt}
          selectAll={checkAll}
          key={dataset.id}
        />
      ))}
    </DatasetsTable>
  );
};
