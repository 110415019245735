import styled from "styled-components";

export const ChildrenWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 384px;
  margin-top: 32px;
`;
export const HeaderWrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
`;
export const Title = styled.h1`
  position: absolute;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
`
export const SettingsWrapper = styled.div<{ $hover?: boolean }>`
  display: ${({ $hover }) => ($hover ? "flex" : "none")};
  position: absolute;
  left: 50%;
  top: 14px;
  /* transform: translateX(-50%); */
  gap: 2px;
  align-items: center;
  transition: 300ms all linear;
`;
export const CloseWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  top: 0;
`;
export const BorderedSettingsBlock = styled.div<{
  $background?: string;
  $gap?: string;
}>`
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
  background: ${({ theme, $background }) =>
    $background ? theme[$background] : theme.neutralBackgroundBase};
  display: flex;
  padding: 2px 3px;
  gap: ${({ $gap }) => $gap || "4px"};
  align-items: center;
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  margin: 0 6.5px;
  border-radius: 100%;
  background: ${({ theme }) => theme.neutralTextWeak};
`;

export const SectionWrapper = styled.div<{
  $storytelling?: boolean;
  $isEditMode?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  /* gap: 24px 32px; */
  border-radius: 8px;
`;

export const ContentWrapper = styled.div<{ $flexOrientation?: string }>`
`;

export const BlockLoader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InformativeInsideWrapper = styled.div<{
  $addMargin?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;
