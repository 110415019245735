import { createSlice } from "@reduxjs/toolkit";
import { ActiveModalPayload, ModalsTypes } from "../../models";

export interface Modals {
  activeModal: ModalsTypes[];
  backOptions: ModalsTypes[];
  createOptions: boolean;
  projectId?: string;
  firstItem: number;
}

const initialState: Modals = {
  activeModal: [],
  backOptions: [],
  createOptions: false,
  projectId: undefined,
  firstItem: 0,
};

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setActiveModal: (state, { payload }: { payload: ActiveModalPayload }) => ({
      ...state,
      activeModal: [...state.activeModal, payload.id],
      backOptions: payload.back
        ? [...new Set([...state.backOptions, payload.id])]
        : state.backOptions,
      createOptions: payload.createOptions ? payload.createOptions : false,
      firstItem: payload.firstItem ? payload.firstItem : 0,
      projectId: payload.projectId ? payload.projectId : undefined,
    }),
    resetActiveModal: (state) => {
      return {
        ...state,
        activeModal: [],
        backOptions: [],
        projectId: undefined,
      };
    },
    closeActiveModal: (state, { payload }: { payload?: { back: boolean } }) => {
      const updatedActiveModals = [...state.activeModal];
      updatedActiveModals.pop();
      return {
        ...state,
        activeModal: updatedActiveModals,
        backOptions: payload?.back ? updatedActiveModals : state.backOptions,
        createOptions: false,
        firstItem: 0,
        projectId: undefined,
      };
    },
  },
});

export const { setActiveModal, resetActiveModal, closeActiveModal } =
  modalSlice.actions;
export default modalSlice.reducer;
