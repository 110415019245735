export function getAdditionalKeys<T extends Record<string, unknown>>(
  data: T[],
  xAxe: string,
  yAxe: string
): string[] {
  const requiredKeys = new Set([xAxe, yAxe]);

  const additionalKeys = new Set<string>();

  for (const record of data) {
    const recordKeys = Object.keys(record);

    for (const key of recordKeys) {
      if (!requiredKeys.has(key)) {
        additionalKeys.add(key);
      }
    }
  }

  return Array.from(additionalKeys)
}
