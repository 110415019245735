export const getBarPosition = (barWidth: number, idx: number, keys: number) => {
  const currentIdx = idx + 1;
  const halfKeys = keys / 2;
  
  if (keys % 2) {
    const centerIdx = Math.ceil(halfKeys);
    
    const step = barWidth+1;

    if (currentIdx < centerIdx) {
      return -step * currentIdx;
    } else if (currentIdx === centerIdx) {
      return 0;
    } else {
      return step * (currentIdx - centerIdx);
    }

  } else {

    const centerIdx = halfKeys + 0.5;
    const step = barWidth + 1;

    if (currentIdx < centerIdx) {
      if (currentIdx + 0.5 === centerIdx) {
        return -(step / 2);
      } else {
        return -((step / 2) + (currentIdx * step));
      }
    } else {
      if (currentIdx - 0.5 === centerIdx) {
        return step / 2;
      } else {
        return (step / 2) + (currentIdx * step);
      }
    }

  }
};