import { formatDistanceToNow } from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PublicPageButton, PublicPageButtonContentWrapper, PublishedBadge } from "../styles";

import { Button } from "../../../Button";
import { PageSettingsDTO } from "../../../../models/Pages";
import { getCurrentProjectData, getCurrentProjectId } from "../../../../store/selectors/projects";
import { requestPublishProject } from "../../../../store/slices/projects";
import { requestAllPages, requestUpdatePage } from "../../../../store/slices/projectPages";
import { getIsPublicMode } from "../../../../store/selectors/main";

interface Props {
  item: PageSettingsDTO
}

export const PublicLink = ({ item }: Props) => {
  const [isHover, setIsHover] = useState(false)
  const dispatch = useDispatch();
  const currentProjectId = useSelector(getCurrentProjectId);
  const { isPublic } = useSelector(getCurrentProjectData);
  const isPublicMode = useSelector(getIsPublicMode);
  const id = useSelector(getCurrentProjectId);

  const handleMouseEnter = () => {
    if (isPublicMode) { return; }
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    if (isPublicMode) { return; }
    setIsHover(false)
  }

  const publishUnpublishProjectAndPage = (page: PageSettingsDTO) => {
    if (isPublicMode) { return; }
    if (!isPublic) {
      dispatch(requestPublishProject(id));
    }
    dispatch(
      requestUpdatePage({
        ...page,
        isPublic: !page.isPublic,
        disableSaveCurrentPage: true,
        callbacks: {
          onSuccess: () => {
            dispatch(requestAllPages({ projectId: currentProjectId }));
          },
        },
      })
    );
  };

  return (
    <PublicPageButton onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} $published={Boolean(item.isPublic)}>
      <PublicPageButtonContentWrapper>
        <span>{item.name}</span>
        <span>
          {formatDistanceToNow(new Date(String(item.updatedAt)))}
        </span>
      </PublicPageButtonContentWrapper>
      {!isPublicMode && isHover
        ? (
          <Button
            size="xs"
            variant={item.isPublic ? "danger" : "secondary"}
            onClick={() => {
              publishUnpublishProjectAndPage(item);
            }}
            name={item.isPublic ? "Unpublish" : "Publish"}
          />
        )
        : ''
      }

      {!isPublicMode && item.isPublic && !isHover ? (
        <PublishedBadge>Published</PublishedBadge>
      ) : (
        ""
      )}
    </PublicPageButton>
  )
}