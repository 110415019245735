import styled from "styled-components";

export const ContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 20px 0 20px;
`;
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;
export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
`;
export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
