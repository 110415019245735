import { styled } from "styled-components";

//TH
export const TableHeaderElement = styled.div<{ $size?: string }>`
  display: flex;
  padding: 4px 8px;
  justify-content: space-between;
  gap: 8px;
  width: ${({ $size }) => $size || "100%"};
  border-right: 1px solid ${({ theme }) => theme.neutralBorder};
  &:last-child {
    border: none;
  }
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.neutralTextWeak};
  line-height: 14px;
  font-weight: 400;
  font-size: 11px;
`;

//TR
export const TableDataRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  transition: 0.3s;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 24px);
    height: 1px;
    bottom: 0;
    right: 0;
    background: ${({ theme }) => theme.neutralBorder};
  }

  &:hover {
    &::before {
      background: ${({ theme }) => theme.neutralBorderMedium};
    }
  }

  &:active {
    &::before {
      background: ${({ theme }) => theme.primaryActionHover};
    }
  }
`;

export const TableDataRow = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 100%;
`;

export const Header = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralText};
`;

export const SubHeader = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const MeasuresCount = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  padding: 3px 10px;
  border-radius: 3px;
  display: flex;
  justify-self: flex-end;
  background-color: ${({ theme }) => theme.primaryBackgroundActive};
`;

export const DimensionsList = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding-left: 20px;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  align-items: center;
`;
