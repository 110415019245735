import React, { useMemo } from "react";
import Compact from "@uiw/react-color-compact";
import { colorBlocks, colorTexts, compactColors } from "./colors";
import {
  ColorItem,
  CustomAlphaPicker,
  GradientsDelimtator,
  GradientsItem,
  GradientsItemBlock,
  GradientsItems,
  GradientsTitle,
  GradientsWrapper,
  GradientsWrapperText,
  HexInput,
  HexInputColor,
  HexTitle,
  HexWrapper,
  StyledSketchPickerWrapper,
} from "./styles";

interface ColorPickerProps {
  callback: (color: any) => void;
  callbackText: (color: any) => void;
  item: any;
}

const BorderRadiusColor: { [key: string]: string } = {
  "#FEFFFE": "6px 0px 0px 0px",
  "#000000": "0px 6px 0px 0px",
  "#CBF0FF": "0px 0px 0px 6px",
  "#DEEED4": "0px 0px 6px 0px",
};
export const ColorPicker = ({
  callback,
  callbackText,
  item,
}: ColorPickerProps) => {
  const colorWithBorder = useMemo(() => Object.keys(BorderRadiusColor), []);
  return (
    <StyledSketchPickerWrapper $backgroundColor={item.backgroundColor}>
      <Compact
        color={item.backgroundColor}
        style={{
          width: 256,
          marginBottom: 15,
          padding: 0,
          backgroundColor: "transparent",
        }}
        onChange={(color: any) => {
          callback(color.hex);
        }}
        colors={compactColors}
        rectRender={(props: any) => {
          return (
            <div
              style={{
                backgroundColor: props.color,
                height: 20,
                width: 20,
                gap: 1,
                border: props.checked
                  ? "1px solid #FFFFFF"
                  : `1px solid ${
                      props.color === "#FEFFFE" ? "#DFE1E4" : props.color
                    }`,
                borderRadius: props.checked
                  ? "10px"
                  : colorWithBorder.includes(props.color)
                  ? (BorderRadiusColor[props.color] as string)
                  : "",
              }}
              onClick={props.onClick}
            />
          );
        }}
      />

      <HexWrapper>
        <HexTitle>HEX</HexTitle>
        <HexInput
          value={item.backgroundColor}
          onChange={(e) => {
            callback(e.target.value);
          }}
        />
        <HexInputColor $color={item.backgroundColor} />
      </HexWrapper>

      <CustomAlphaPicker
        color={item.backgroundColor}
        width="251"
        onChange={(color) => {
          if (color.rgb) {
            callback(
              `rgba(${color?.rgb?.r || 0},${color?.rgb?.g || 0},${
                color?.rgb?.b || 0
              },${color?.rgb?.a || 0})`
            );
          }
        }}
      />

      <GradientsWrapper>
        <GradientsTitle>GRADIENTS</GradientsTitle>
        <GradientsItems>
          {colorBlocks.map((el) => {
            return (
              <GradientsItemBlock
                key={el.id}
                onClick={() => {
                  callback(el.gradient);
                }}
              >
                <GradientsItem
                  $gradient={el.gradient}
                  $selectedItem={item.backgroundColor === el.gradient}
                />
              </GradientsItemBlock>
            );
          })}
        </GradientsItems>
      </GradientsWrapper>
      <GradientsDelimtator />
      <GradientsWrapperText>
        <GradientsTitle>Text color</GradientsTitle>
        <GradientsItems>
          {colorTexts.map((el) => {
            return (
              <GradientsItemBlock
                key={el.id}
                onClick={() => {
                  callbackText(el.color);
                }}
              >
                <ColorItem
                  $color={el.color}
                  $selectedItem={item.textColor === el.color}
                />
              </GradientsItemBlock>
            );
          })}
        </GradientsItems>
      </GradientsWrapperText>
    </StyledSketchPickerWrapper>
  );
};
