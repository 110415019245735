import React, { useMemo } from "react";
import { SkeletonText } from "./styles";

export const TextLoader = ({
  width,
  height,
  opacity,
}: {
  width?: string;
  height?: string;
  opacity?: number;
}) => {
  const getRandomWidth = () => {
    const widths = ["70%", "80%", "90%", "60%", "75%", "85%"];
    return widths[Math.floor(Math.random() * widths.length)];
  };
  const randomWidth = useMemo(() => {
    if (width) {
      return width;
    }
    return getRandomWidth();
  }, [width]);

  return (
    <SkeletonText $width={randomWidth} $height={height} $opacity={opacity} />
  );
};
