import L from "leaflet";
import UsCounties from "./GeoJson/us-counties.json";
import UsStates from "./GeoJson/us-states.json";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import { mapPopup } from "./popup";
import { DataItem } from "../../../../models/Widgets";
import { ColorRangeI } from "../../../../models/Pages";

export const mapLayers = ({
  geoJSONLayersRef,
  mapRef,
  mapType,
  colorRanges,
  data,
  showTooltip,
  widgetTooltip,
  widgetPreview,
  metricField,
  timePeriodField,
}: {
  geoJSONLayersRef: any;
  mapRef: any;
  mapType: any;
  colorRanges: ColorRangeI[];
  data?: DataItem[];
  showTooltip?: boolean;
  widgetTooltip?: boolean;
  widgetPreview?: boolean;
  metricField: string;
  timePeriodField: string;
}) => {
  // Clear existing layers
  geoJSONLayersRef.current.forEach((layer: any) => {
    mapRef.current.removeLayer(layer);
  });
  geoJSONLayersRef.current = [];

  // Get unique states from data
  const statesInData = new Set(data?.map(item => item.state));

  const getCountyData = (countyName: string, stateName: string) => {
    return data?.find((c) => c.county === countyName && c.state === stateName);
  };

  const getColorForValue = (countyValue: number) => {
    if (countyValue === 0) {
      return colorRanges[0].color;
    }

    const lastItem = colorRanges[colorRanges.length - 1].start;
    if (countyValue > lastItem) {
      return colorRanges[colorRanges.length - 1].color;
    }

    const localColor = colorRanges.find((c: ColorRangeI, i: number) => {
      const nextItem =
        i === colorRanges.length - 1 ? colorRanges.length - 1 : i + 1;
      return (
        countyValue >= c.start &&
        countyValue < colorRanges[nextItem].start
      );
    });

    return localColor ? localColor.color : colorRanges[0].color;
  };

  const highlightFeature = (feature: any, e: any) => {
    const layer = e.target;
    const [countyName, stateName] = feature.properties.COUNTY_STATE_NAME?.split(",").map((s: string) => s.trim()) || [];
    const county = getCountyData(countyName, stateName);
    
    if (county) {
      layer.setStyle({
        weight: 2,
        color: "black",
        dashArray: "",
      });

      if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
      }
    }
  };

  const resetHighlight = (e: any) => {
    geoJSONLayer.resetStyle(e.target);
  };

  const onEachFeature = (feature: any, layer: any) => {
    const [countyName, stateName] = feature.properties.COUNTY_STATE_NAME?.split(",").map((s: string) => s.trim()) || [];
    const county = getCountyData(countyName, stateName);

    if (!widgetPreview && county) {
      mapPopup({
        properties: {
          countyStateCode: feature.properties.COUNTY_STATE_CODE,
          members: county?.[metricField],
          year: county?.[timePeriodField],
        },
        layer,
      });
    }

    layer.on({
      mouseover: (e: any) => widgetPreview ? null : highlightFeature(feature, e),
      mouseout: resetHighlight,
    });
  };

  // Filter to only show counties that exist in our data
  const filterFeature = (feature: any) => {
    const [countyName, stateName] = feature.properties.COUNTY_STATE_NAME?.split(",").map((s: string) => s.trim()) || [];
    return statesInData.has(stateName) && getCountyData(countyName, stateName) !== undefined;
  };

  const geoJSONLayer = L.geoJSON(
    mapType === "counties" ? UsCounties : UsStates,
    {
      filter: filterFeature,
      style: function (feature: any) {
        const [countyName, stateName] = feature.properties.COUNTY_STATE_NAME?.split(",").map((s: string) => s.trim()) || [];
        
        const county = getCountyData(countyName, stateName);
        const countyValue = parseInt(county?.[metricField] || "0");
        const color = getColorForValue(countyValue);

        return {
          color: "white",
          stroke: "white",
          strokeWidth: "0px",
          fill: true,
          fillColor: hexToRGBA(color, 1),
          weight: 1,
          fillOpacity: 1,
        };
      },
      onEachFeature: showTooltip || widgetTooltip ? onEachFeature : () => {},
    }
  );

  geoJSONLayer.addTo(mapRef.current);
  geoJSONLayersRef.current.push(geoJSONLayer);
};