import React, { useState, useEffect, useRef } from "react";
import { toPng } from "html-to-image";
import * as s from "./styled";
import { getPageSettings } from "../../../store/selectors/projects";
import { useSelector } from "react-redux";

interface MiniatureNavigationProps {
  containerRef: React.RefObject<HTMLElement>;
  loading: boolean;
  scrollOffset?: number;
}

const IMAGE_OPTIONS = {
  width: 200,
  height: 258,
  style: {
    transform: "scale(0.2)",
    transformOrigin: "top left",
    width: "1000px",
    height: "1290px",
  },
  quality: 1,
  pixelRatio: 0.8,
  backgroundColor: "#FFFFFF",
  cacheBust: true,
  fontEmbedCSS:
    '@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");',
};

const MiniatureNavigation: React.FC<MiniatureNavigationProps> = ({
  containerRef,
  loading,
  scrollOffset = 137,
}) => {
  const currentPage = useSelector(getPageSettings);
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [selectedPage, setSelectedPage] = useState<number | null>(null);
  const elementRefs = useRef<(Element | null)[]>([]);
  const navigationRef = useRef<HTMLDivElement>(null);
  const isScrollingRef = useRef(false);
  const isMouseOverRef = useRef(false);

  useEffect(() => {
    if (loading) return;

    const generateThumbnails = async () => {
      if (containerRef.current) {
        const firstPage = containerRef.current.querySelector("#first-page");
        const tableOfContent =
          containerRef.current.querySelector("#table-of-content");
        const content = containerRef.current.querySelector("#content");
        const lastPage = containerRef.current.querySelector("#last-page");

        const newThumbnails: string[] = [];
        elementRefs.current = [];

        const generateThumbnail = async (element: Element | null) => {
          if (element) {
            try {
              const dataUrl = await toPng(
                element as HTMLElement,
                IMAGE_OPTIONS
              );
              newThumbnails.push(dataUrl);
              elementRefs.current.push(element);
            } catch (error) {
              newThumbnails.push("");
              elementRefs.current.push(null);
            }
          }
        };

        if (firstPage && currentPage.showHeader)
          await generateThumbnail(firstPage);

        if (tableOfContent && currentPage.tableOfContents) {
          const tableOfContentSections = Array.from(
            tableOfContent.children || []
          );
          for (const section of tableOfContentSections) {
            await generateThumbnail(section);
          }
        }

        if (content) {
          const contentSections = Array.from(content.children || []);
          for (const section of contentSections) {
            await generateThumbnail(section);
          }
        }

        if (lastPage && currentPage.showHeader)
          await generateThumbnail(lastPage);

        setThumbnails(newThumbnails);
      }
    };

    generateThumbnails();

    const handleScroll = () => {
      if (isScrollingRef.current || isMouseOverRef.current) return;

      if (containerRef.current && navigationRef.current) {
        const containerHeight = containerRef.current.scrollHeight;
        const navigationHeight = navigationRef.current.scrollHeight;
        const scrollPercentage =
          window.scrollY / (containerHeight - window.innerHeight);
        const scrollPosition =
          scrollPercentage *
          (navigationHeight - navigationRef.current.clientHeight);
        navigationRef.current.scrollTop = scrollPosition;

        // Update selected page
        const viewportMiddle = window.innerHeight / 2;
        let closestElement = null;
        let closestDistance = Infinity;
        elementRefs.current.forEach((el, index) => {
          if (el) {
            const rect = el.getBoundingClientRect();
            const distance = Math.abs(
              rect.top + rect.height / 2 - viewportMiddle
            );
            if (distance < closestDistance) {
              closestDistance = distance;
              closestElement = index;
            }
          }
        });
        if (closestElement !== null) {
          setSelectedPage(closestElement);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, loading, currentPage]);

  const handleThumbnailClick = (index: number) => {
    const element = elementRefs.current[index];
    setSelectedPage(index);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - scrollOffset;

      isScrollingRef.current = true;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      // Add a delay before allowing the miniature scroll to follow the main scroll
      setTimeout(() => {
        isScrollingRef.current = false;
      }, 1000);
    }
  };

  return (
    <s.NavigationContainer ref={navigationRef}>
      {!thumbnails.length ? (
        <>
          {Array.from({ length: 6 }).map((_, index) => (
            <s.SkeletonContainer key={index}>
              <s.Skeleton />
              <s.PageNumber>{index + 1}.</s.PageNumber>
            </s.SkeletonContainer>
          ))}
        </>
      ) : (
        thumbnails.map((thumbnail, index) => (
          <s.ThumbnailContainer key={index}>
            <s.Thumbnail
              $isSelected={selectedPage === index}
              onClick={() => handleThumbnailClick(index)}
            >
              <s.ThumbnailImage src={thumbnail} alt={`Page ${index + 1}`} />
            </s.Thumbnail>
            <s.PageNumber>{index + 1}.</s.PageNumber>
          </s.ThumbnailContainer>
        ))
      )}
    </s.NavigationContainer>
  );
};

export default MiniatureNavigation;
