import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getAiSuggestions } from "../../store/selectors/widgets";
import { CreateMapWidgetDto } from "../../models/Widgets";

import { ColorRangeI } from "../../models/Pages";
import RangeInput from "../Inputs/CustomRange/RangeInput";
import {
  ColorsWrapper,
  HideButton,
  LayerHeading,
  LayersWrapper,
  LayerWrapper,
  LegendAccentHeading,
  LegendDetailsDescription,
  LegendDetailsHeading,
  LegendDetailsWrapper,
  LegendWrapper,
  LegendWrapperHeader,
  MapLegendColor,
  MapLegendColorsWrapper,
  MapLegendDescription,
  MapLegendDescriptionWrapper,
  MapLegendWrapper,
  RangeWrapper,
} from "./styles";

export const MapLegend = ({
  colorRanges,
  average,
  leftSlider,
  rightSlider,
  setLeftSlider = () => {},
  setRightSlider = () => {},
  isCard,
  timeline,
}: {
  colorRanges: ColorRangeI[];
  average: number;
  leftSlider: number;
  rightSlider: number;
  setLeftSlider: any;
  setRightSlider: any;
  timeline: boolean;
  isCard: boolean;
}) => {
  const [mapChartData, setMapChartData] = useState<CreateMapWidgetDto>();
  const aiSuggestions = useSelector(getAiSuggestions);

  useEffect(() => {
    if (aiSuggestions) {
      setMapChartData(
        aiSuggestions?.find(
          (el: CreateMapWidgetDto) => el.chartType === "mapChart"
        )
      );
    }
  }, [aiSuggestions, mapChartData]);

  const formatValue = (value: number): string => {
    if (isNaN(value) || !isFinite(value)) return "0";

    const abbreviations = ["B", "M", "K"];
    const units = abbreviations.map((_, i) =>
      Math.pow(1000, abbreviations.length - i)
    );

    for (let i = 0; i < abbreviations.length; i++) {
      if (Math.abs(value) >= units[i]) {
        const formattedValue = (value / units[i]).toFixed(1);
        const cleanValue = formattedValue.endsWith(".0")
          ? formattedValue.slice(0, -2)
          : formattedValue;
        return `${cleanValue} ${abbreviations[i]}`;
      }
    }

    return Math.round(value).toString();
  };

  const renderValue = () => {
    if (average) {
      const areZecimale = average % 1 !== 0;

      if (areZecimale) {
        return average?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return average?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return average;
  };
  const [showLegend, setShowLegend] = useState<boolean>(true);

  return (
    <LayerWrapper $isCard={isCard} $timeline={timeline}>
      <LegendWrapper>
        <LegendWrapperHeader>Legend</LegendWrapperHeader>
        <HideButton
          $show={showLegend}
          onClick={() => setShowLegend((prev) => !prev)}
        />
      </LegendWrapper>
      {showLegend && (
        <LayersWrapper>
          <LayerHeading>Members per county</LayerHeading>
          <LegendDetailsWrapper>
            <LegendDetailsHeading>
              <LegendAccentHeading>{renderValue()}</LegendAccentHeading> ppl
            </LegendDetailsHeading>
            <LegendDetailsDescription>
              Avg., per county
            </LegendDetailsDescription>
            <MapLegendWrapper>
              <MapLegendColorsWrapper>
                <RangeWrapper>
                  <RangeInput
                    leftSlider={leftSlider}
                    rightSlider={rightSlider}
                    setLeftSlider={setLeftSlider}
                    setRightSlider={setRightSlider}
                  />
                </RangeWrapper>

                <ColorsWrapper>
                  {colorRanges?.map((range, index) => (
                    <MapLegendColor
                      $color={range.color}
                      key={index}
                    ></MapLegendColor>
                  ))}
                </ColorsWrapper>
              </MapLegendColorsWrapper>
              <MapLegendDescriptionWrapper>
                {colorRanges?.map((range, index) => (
                  <MapLegendDescription key={index}>
                    {range.start &&
                    range.start !== Infinity &&
                    range.start !== -Infinity &&
                    !Number.isNaN(range.start)
                      ? formatValue(range.start)
                      : 0}
                  </MapLegendDescription>
                ))}
              </MapLegendDescriptionWrapper>
            </MapLegendWrapper>
          </LegendDetailsWrapper>
        </LayersWrapper>
      )}
    </LayerWrapper>
  );
};
