import { Sizes } from "./Sizes.interface";

export const LGSize: Sizes = {
  label: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  value: {
    fontSize: '32px',
    lineHeight: '36px',
  },
  subValues: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  margin: '5px'
}