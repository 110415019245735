import { Field } from 'formik';
import styled, { css } from 'styled-components';

export const FormWrapper = styled.div`
  padding: 0 20px 16px 20px;
`;

export const FormGroup = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const inputStyle = css`
  height: 36px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.primaryBorderColor};
  outline: none;
  padding: 0 14px;

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`;

export const InputField = styled(Field) <{ $hasValue?: boolean; $required?: boolean }>`
  ${inputStyle};
  position: relative;
  background-color: ${({ theme, $hasValue }) =>
    $hasValue ? theme.neutralBackgroundBase : theme.neutralBackground};
  ${({ $required }) => ($required ? 'padding-left: 20px' : "")};
  width: 100%;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;