import React from "react";
import {
  MapSettingLayerContent,
  MapSettingsControl,
  MapSettingsControlName,
  MapSettingsDatasets,
  MapSettingsDatasetsHeading,
  MapSettingsDatasetsWrapper,
  MapSettingsLayerDirection,
  MapSettingsLayerHeader,
  MapSettingsLayersWrapper,
  MapSettingsLeftSide,
  MapSettingsRightSide,
  SwitchDataset,
} from "../../styles";
import { Button } from "../../../Button";
import { ProjectDTO } from "../../../../models/Projects";
import { DatasetsItem } from "../../../DatasetsItem";
import { Select } from "../../../Inputs/CustomSelect/Select";
import { selectOptions } from "../../data/mock";
import { WidgetItem } from "../../../../models/Widgets";
import RadioButton from "../../../Inputs/CustomRadio/RadioButton";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";
import {
  MapSettings,
  MapSettingsControls,
  MapSettingsSelectLayout,
  MapSettingsSelectLayoutHeading,
  MapSettingsSelectLayoutOptions,
} from "./styles";

type Props = {
  widgetData: WidgetItem;
  setWidgetData: React.Dispatch<React.SetStateAction<WidgetItem>>;
  projectData: ProjectDTO;
  selectedDimensions: string[];
  fullScreenLayout: boolean;
};

const MapConfig = ({
  widgetData,
  setWidgetData,
  projectData,
  selectedDimensions,
  fullScreenLayout,
}: Props) => {
  return (
    <>
      <MapSettingsLeftSide>
        <MapSettingsDatasetsWrapper>
          <MapSettingsDatasetsHeading>
            Datasets
            <Button
              onClick={() => {}}
              variant="primary"
              size="xs"
              name="Switch dataset"
              icon={<SwitchDataset />}
            />
          </MapSettingsDatasetsHeading>
          <MapSettingsDatasets>
            {projectData.datasets.map((dataset) => (
              <DatasetsItem
                dataset={dataset}
                key={dataset.filePath}
                selectedDimensions={selectedDimensions}
                isDraggable
                disablePreview
              />
            ))}
          </MapSettingsDatasets>
        </MapSettingsDatasetsWrapper>
        <MapSettingsLayersWrapper>
          <MapSettingsLayerHeader>Chart Type</MapSettingsLayerHeader>
          <MapSettingLayerContent>
            <Select
              withOutClose
              options={selectOptions}
              value={widgetData.chartType}
            />

            {!!["barChart", "lollipopChart"].includes(widgetData.chartType) && (
              <MapSettingsLayerDirection>
                <RadioButton
                  name="orientation"
                  onChange={(e) =>
                    setWidgetData({
                      ...widgetData,
                      orientation: e.target.value,
                    })
                  }
                  label={"Vertical bars"}
                  value={"vertical"}
                  checked={widgetData.orientation === "vertical"}
                />
                <RadioButton
                  name="orientation"
                  onChange={(e) =>
                    setWidgetData({
                      ...widgetData,
                      orientation: e.target.value,
                    })
                  }
                  label={"Horizontal bars"}
                  value={"horizontal"}
                  checked={widgetData.orientation === "horizontal"}
                />
              </MapSettingsLayerDirection>
            )}
          </MapSettingLayerContent>
        </MapSettingsLayersWrapper>
      </MapSettingsLeftSide>

      <MapSettingsRightSide>
        <MapSettingsSelectLayout>
          <MapSettingsSelectLayoutHeading>
            Layout
          </MapSettingsSelectLayoutHeading>
          <MapSettingsSelectLayoutOptions>
            <RadioButton
              name={"mapLayout"}
              checked={widgetData.layout === "card"}
              onChange={(e) =>
                setWidgetData((prev) => ({
                  ...prev,
                  layout: e.target.value,
                }))
              }
              label={"Card"}
              value={"card"}
            />
            <RadioButton
              name={"mapLayout"}
              checked={widgetData.layout === "minimalist"}
              onChange={(e) =>
                setWidgetData((prev) => ({
                  ...prev,
                  layout: e.target.value,
                }))
              }
              label={"Minimalist"}
              value={"minimalist"}
            />

            <RadioButton
              name={"mapLayout"}
              disabled={!fullScreenLayout}
              checked={widgetData.layout === "fullScreen"}
              onChange={(e) => {
                setWidgetData((prev) => ({
                  ...prev,
                  layout: e.target.value,
                }));
              }}
              label={"Full screen"}
              value={"fullScreen"}
            />
          </MapSettingsSelectLayoutOptions>
        </MapSettingsSelectLayout>
        <MapSettings>
          <MapSettingsControls>
            <MapSettingsControlName>Terrain background</MapSettingsControlName>
            <MapSettingsControl>
              <SwitchComponent
                label={widgetData.terrain ? "Show" : "Don't show"}
                checked={widgetData.terrain}
                onChange={(e) =>
                  setWidgetData((prev) => ({
                    ...prev,
                    terrain: e.target.checked,
                  }))
                }
              />
            </MapSettingsControl>
          </MapSettingsControls>
          <MapSettingsControls>
            <MapSettingsControlName>Search</MapSettingsControlName>
            <MapSettingsControl>
              <SwitchComponent
                label={widgetData.search ? "Show" : "Don't show"}
                checked={widgetData.search}
                onChange={(e) =>
                  setWidgetData((prev) => ({
                    ...prev,
                    search: e.target.checked,
                  }))
                }
              />
            </MapSettingsControl>
          </MapSettingsControls>
          <MapSettingsControls>
            <MapSettingsControlName>Timeline</MapSettingsControlName>
            <MapSettingsControl>
              <SwitchComponent
                label={widgetData.timeline ? "Show" : "Don't show"}
                checked={widgetData.timeline}
                onChange={(e) =>
                  setWidgetData((prev) => ({
                    ...prev,
                    timeline: e.target.checked,
                  }))
                }
              />
            </MapSettingsControl>
          </MapSettingsControls>
        </MapSettings>
      </MapSettingsRightSide>
    </>
  );
};

export default MapConfig;
