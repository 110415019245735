import React, { Dispatch, SetStateAction, useState, useEffect } from "react";

import {
  ImportedFilesContainer,
  DatasetWrapper,
  UploadedItemsHeader,
  FileName,
} from "./styles";
import { DatasetModalListItem } from "../DatasetModalListItem";
import { useSelector } from "react-redux";
import { ProgressBar } from "../ProgressBar";
import {
  getFiles,
  getFileUploadProgress,
  getUploadedFilesCount,
} from "../../store/selectors/files";
import { FileLibraryItem } from "../../models/Files";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";

type DatasetFilesType = {
  setFileOverviewOpen: Dispatch<SetStateAction<boolean>>;
  setDimensionsSelected: Dispatch<SetStateAction<any[]>>;
};

type ICheckBoxStatus = "checked" | "indeterminate" | "none";
interface IFilesWithSelectedProp extends FileLibraryItem {
  checkboxState: ICheckBoxStatus;
}

export const UploadedFileItem = ({
  setFileOverviewOpen,
  setDimensionsSelected,
}: DatasetFilesType) => {
  const files = useSelector(getFiles) as FileLibraryItem[];

  const [filesWithSelectedProp, setFilesWithSelectedProp] = useState<
    IFilesWithSelectedProp[]
  >(() =>
    files.map((item: FileLibraryItem) => ({ ...item, checkboxState: "none" }))
  );

  useEffect(() => {
    setFilesWithSelectedProp(
      files.map((item: FileLibraryItem) => ({ ...item, checkboxState: "none" }))
    );
  }, [files]);

  const fileUploadProgress = useSelector(getFileUploadProgress);
  const uploadedFilesCount = useSelector(getUploadedFilesCount);

  const Progress = (count: number, progress: number, order: number): number => {
    const step = 100 / count;

    const currentStep = Math.ceil(progress / step);

    const controlProgres = (progressNumber: number): number => {
      if (progressNumber > step) {
        return controlProgres(progressNumber - step);
      } else {
        return progressNumber;
      }
    };

    if (order === currentStep) {
      return Math.ceil(controlProgres(progress) * count);
    } else if (progress >= step && order < currentStep) {
      return 100;
    } else {
      return 0;
    }
  };

  const [selectAllStatus, setSelectAllStatus] =
    useState<ICheckBoxStatus>("none");

  useEffect(() => {
    const count = filesWithSelectedProp.filter(
      (item) => item.checkboxState !== "none"
    ).length;
    const checked = filesWithSelectedProp.filter(
      (item) => item.checkboxState === "checked"
    ).length;

    setSelectAllStatus(
      checked === filesWithSelectedProp.length && filesWithSelectedProp.length
        ? "checked"
        : count
        ? "indeterminate"
        : "none"
    );
  }, [filesWithSelectedProp]);

  return (
    <DatasetWrapper>
      <ImportedFilesContainer $noGap>
        {fileUploadProgress > 0 &&
          Array(uploadedFilesCount)
            .fill("")
            .map((item, index) => (
              <ProgressBar
                key={index + "upload files"}
                index={index + 1}
                progress={Progress(
                  uploadedFilesCount,
                  fileUploadProgress,
                  index + 1
                )}
              />
            ))}

        {filesWithSelectedProp.length ? (
          <UploadedItemsHeader>
            <CheckBox
              onChange={() => {
                const newStatus =
                  selectAllStatus === "checked" ? "none" : "checked";
                setSelectAllStatus(newStatus);
                setFilesWithSelectedProp((prevFiles) =>
                  prevFiles.map((item) => ({
                    ...item,
                    checkboxState: newStatus === "none" ? "none" : "checked",
                  }))
                );
              }}
              status={selectAllStatus}
            />
            <FileName>File name</FileName>
          </UploadedItemsHeader>
        ) : (
          ""
        )}

        {filesWithSelectedProp.map((item, idx) => (
          <DatasetModalListItem
            file={item}
            key={idx}
            setFileOverviewOpen={setFileOverviewOpen}
            selectAllProp={selectAllStatus}
            setDimensionsSelected={setDimensionsSelected}
            isChecked={item.checkboxState}
            onChange={(value: "checked" | "indeterminate" | "none") => {
              setFilesWithSelectedProp((prev) =>
                prev.map((item, index) =>
                  index === idx ? { ...item, checkboxState: value } : item
                )
              );
            }}
          />
        ))}
      </ImportedFilesContainer>
    </DatasetWrapper>
  );
};
