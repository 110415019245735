import { useState } from "react";

export const useSortByCreatedDate = (initialSortOrder = "desc") => {
  const [sortByCreatedDate, setSortByCreatedDate] = useState(initialSortOrder);

  const handleSortByCreatedDate = () => {
    if (sortByCreatedDate === "asc") {
      setSortByCreatedDate("desc");
    } else {
      setSortByCreatedDate("asc");
    }
  };

  const sortItemsByCreatedDate = (data: any) => {
    if (sortByCreatedDate === "asc") {
      return [...data].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    } else if (sortByCreatedDate === "desc") {
      return [...data].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    }
    return data;
  };

  return { sortByCreatedDate, handleSortByCreatedDate, sortItemsByCreatedDate };
};
