import { styled } from "styled-components";
import UserBg from "../../../../../../assets/userBg.jpg";
import { Dot, CheckCircleFill, CheckCircle } from "react-bootstrap-icons";

export const MessageContent = styled.div`
  padding: 10px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.neutralTextWeak};
  width: 290px;
`;

export const CommnetsHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Author = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.neutralText};
`;

export const DotIcon = styled(Dot)`
  display: flex;
  align-items: baseline;
  zoom: 1.5;
`;

export const CheckCircleFillIcon = styled(CheckCircleFill)`
  fill: ${({ theme }) => theme.primaryText};
  display: none;
  cursor: pointer;
`;

export const CheckCircleIcon = styled(CheckCircle)`
  fill: ${({ theme }) => theme.primaryText};
  display: none;
  cursor: pointer;
`;

export const OptionButton = styled.button`
  all: unset;
  display: none; // Hidden by default
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryText};
  margin-right: 5px;
`;

export const ActionsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Time = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-left: 5px;
  &::before {
    content: "•";
    margin-right: 5px;
  }
`;

export const Avatar = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
  background: url(${UserBg}) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export const MessageText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.neutralTextWeak};
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-bottom: 4px;
`;

export const Replies = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText};
`
export const CommentItem = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  &:hover {
    ${ActionsContainer} {
      ${DotIcon} {
        display: none;
      }
      ${OptionButton} {
        display: flex;
      }
      ${CheckCircleFillIcon} {
        display: block;
      }
      ${CheckCircleIcon} {
        display: block;
      }
    }
  }

`;