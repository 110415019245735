import React from "react";
import { ProfileSideBarWrapper, TermsOfUse } from "./styles";
import { AboutSection } from "./AboutSection";
import { ContactsSection } from "./ContactsSection";
import { SecuritySection } from "./SecuritySection";
import { NotificationsSection } from "./NotificationsSection";

export const ProfileSideBar = () => {
  return (
    <ProfileSideBarWrapper>
      <AboutSection />
      <ContactsSection />
      <SecuritySection />
      <NotificationsSection />
      <TermsOfUse>Privacy Policy and Terms of use</TermsOfUse>
    </ProfileSideBarWrapper>
  );
};
