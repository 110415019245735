import styled from "styled-components";

import { ReactComponent as MainLogo } from "../../assets/logoWithoutPaddings.svg";
import { ReactComponent as DashboardImage } from "../../assets/dashboard.svg";

export const MobileViewWrapper = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  height: 100vh;
  background-color: ${({ theme }) => theme.neutralBackgroundMedium};
`;

export const LogoWrapper = styled(MainLogo)`
  flex: none;
`;

export const MobileViewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const MobileViewImage = styled(DashboardImage)`
  width: 130px;
  height: 130px;
`;

export const MobileViewDataWrapper = styled.div`
  text-align: center;
`;

export const MobileViewTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralTextWeak};
  padding-top: 24px;
  padding-bottom: 8px;
`;

export const MobileViewDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
