import { styled } from "styled-components";

export const Rect = styled.rect<{
  $tooltip?: boolean;
}>`
  padding-top: 5px;
  ${({ $tooltip }) =>
    $tooltip
      ? `
    &:hover {
      stroke: black ;
      stroke-width: 1.5px ;
    }
  `
      : ``}
`;
