import styled, { css } from "styled-components";
import { ReactComponent as Xls } from "../../assets/xls.svg";
import { ExclamationCircleFill, Trash } from "react-bootstrap-icons";

interface ContainerProps {
  $datasetLibraryModalOpened?: boolean;
  $datasetOverviewModalOpened?: boolean;
  $previewOpen?: boolean;
  $selected?: boolean;
}

export const TrashIcon = styled(Trash)`
  cursor: pointer;
  display: block;
  align-self: center;
  fill: #992a4b;
`;

const uploadDatasetContainer = css<ContainerProps>`
  border-top: 1px solid lightgray !important;
  border-right: ${({ $datasetOverviewModalOpened, theme }) =>
    $datasetOverviewModalOpened && `2px solid ${theme.primaryBorder}`};
  background-color: ${({ $selected, $datasetOverviewModalOpened, theme }) =>
    $selected
      ? theme.primaryBackgroundPublish
      : $datasetOverviewModalOpened
      ? theme.neutralBackgroundActive
      : "unset"};
`;

const datasetLibraryContainer = css<ContainerProps>`
  border-top: 1px solid lightgray;
  border-right: ${({ $datasetOverviewModalOpened, theme }) =>
    $datasetOverviewModalOpened && `2px solid ${theme.primaryBorder}`};
  background-color: ${({ $selected, $datasetOverviewModalOpened, theme }) =>
    $selected
      ? theme.primaryBackgroundPublish
      : $datasetOverviewModalOpened
      ? theme.neutralBackgroundActive
      : "unset"};

  &:first-child {
    border-top: none;
  }
`;

const previewOpen = css`
  background-color: ${({ theme }) => theme.neutralBorder};
`;

export const FileIcon = styled(Xls)<{ $itemsSelected?: boolean }>`
  align-self: center;
  cursor: pointer;
  display: ${({ $itemsSelected }) => ($itemsSelected ? "none" : "block")};
`;

export const DatasetRowContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 13px 20px;
  border-right: 2px solid transparent;
  transition: 0.3s;

  &:active {
    background: ${({ theme }) => theme.neutralBorder};
    border-color: ${({ theme }) => theme.primaryBackgroundStrong};
  }

  ${({ $previewOpen }) => $previewOpen && previewOpen}
  ${({ $datasetLibraryModalOpened }) =>
    $datasetLibraryModalOpened
      ? datasetLibraryContainer
      : uploadDatasetContainer}
`;

export const DataSetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  gap: 4px;
`;

export const DatasetHeadingWrapper = styled.div`
  display: flex;
  font-size: 14px;
  height: 20px;
  font-weight: 600;
  gap: 8px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const DatasetHeading = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 500;
  max-width: 190px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DateSpan = styled.div`
  color: ${({ theme }) => theme.neutralText};
`;

export const HoursSpan = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const DatasetMeta = styled.div`
  gap: 4px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
`;

export const DatasetDimensionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-left: 28px;
`;

export const DimensionsCount = styled.div`
  background-color: ${({ theme }) => theme.neutralBackground};
  border-radius: 5px;
  padding: 4px 6px;
  font-size: 12px;
  color: ${({ theme }) => theme.neutralText};
  cursor: pointer;
`;

export const DimensionsSpan = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-left: 28px;
`;

export const PreviewButton = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  align-items: center;
  align-self: center;
  cursor: pointer;
`;
export const Slash = styled.div`
  height: 1px;
  width: 20px;
  background: ${({ theme }) => theme.primaryText};
  transform: rotate(-75deg);
`;

export const ErrorMessageWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  padding-top: 5px;
  font-size: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.alertText};
  padding-left: 28px;
`;

export const ErrorIcon = styled(ExclamationCircleFill)`
  width: 12px;
  height: 12px;
  fill: #c53660;
`;
