import { useEffect, useState } from "react";
import {
  StyledImagePickerWrapper,
  Image,
  BackgroundImage,
  UploadInput,
  UploadLabel,
  GenerateLabel,
} from "./styles";
import { useSelector } from "react-redux";
import { urltoFile } from "../Storytelling/components/StoryTellingSection/components/TextImage/utils";
import { requestPageBanner } from "../../store/slices/widgets";
import { getCurrentProjectId, getPageId } from "../../store/selectors/projects";
import { useDispatch } from "react-redux";
import { Loader } from "../Loader";

interface ImagePickerProps {
  callback: (file: any) => void;
  image: string;
  isSection?: boolean;
}

export const ImagePicker = ({ image, callback, isSection }: ImagePickerProps) => {
  const [localImage, setImage] = useState(image);
  const [loading, setLoading] = useState(false);
  const currentPageId = useSelector(getPageId);
  const currentProjectId = useSelector(getCurrentProjectId);

  const dispatch = useDispatch();

  useEffect(() => {
    setImage(image)
  }, [image])

  const handleCapture = ({ target }: any) => {
    const file = target.files[0];
    setImage(file);
    callback(file);
  };

  const handleAutoGenerate = () => {
    setLoading(true);
    dispatch(
      requestPageBanner({
        pageId: currentPageId,
        projectId: currentProjectId,
        callbacks: {
          onSuccess: (resp) => {
            urltoFile(
              `data:image/png;base64,${resp?.banner_image}`,
              "a.png"
            ).then(function (file: any) {
              setTimeout(() => {
                setLoading(false);
              }, 1700);
              return callback(file);
            });
          },
        },
      })
    );
  };

  return (
    <StyledImagePickerWrapper $isSection={isSection}>
      <BackgroundImage $isSection={isSection}>
        {
          loading ? <Loader></Loader> : localImage ? (
            <Image
              src={
                typeof localImage === "string"
                  ? localImage
                  : URL.createObjectURL(localImage as any)
              }
            />
          ) : null
        }
      </BackgroundImage>
      {!isSection && (
        <GenerateLabel onClick={handleAutoGenerate}>Auto generate</GenerateLabel>
      )}
      <UploadInput
        type="file"
        name="file"
        id="file"
        accept="image/*"
        onChange={handleCapture}
      />
      <UploadLabel htmlFor="file">Browse image</UploadLabel>
    </StyledImagePickerWrapper>
  );
};
