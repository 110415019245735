import { useEffect, useRef } from "react";

const A_INCLUDES_B_FLAG = 20;

export const useClickOutside = <P>(callback: (p: P) => void, param: P) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const mouseClickHandler = (event: MouseEvent) => {
      const element = event.target as Node;
      const compareResult = itemRef.current && itemRef.current.compareDocumentPosition(element);
      const isOuterClick =
        element && compareResult && event.target && compareResult !== A_INCLUDES_B_FLAG;
      if (isOuterClick) {
        callback(param);
      }
    };

    window.addEventListener("mouseup", mouseClickHandler);

    return () => {
      window.removeEventListener("mouseup", mouseClickHandler);
    };
  }, [callback, param]);
  return [itemRef];
};
