import { Sizes } from "./Sizes.interface";

export const SMSize: Sizes = {
  label: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  value: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  subValues: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  margin: '1px'
}