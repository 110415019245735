import { styled } from "styled-components";
import FirstImage from "../../../../assets/report-first-page.png";

export const PageContainer = styled.div`
  width: 100%;
  height: 1295px;
  display: flex;
  padding: 24px 32px;
  border-radius: var(--layout-card-l-storytelling-radius, 8px);
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  flex-direction: column;
  background-color: #fff;
  background-image: url(${FirstImage});
  background-size: cover;
  .pdf-view {
    border: none;
    border-radius: 0;
  }
`;

export const TextSection = styled.div`
  text-align: left;
  z-index: 1;
  margin-top: 750px;
`;

export const MainTitle = styled.h1`
  color: ${({ theme }) => theme.neutralText};
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  margin-bottom: 24px;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.neutralText};
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 24px;
  line-height: 32px;
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const FooterLogo = styled.div`
  margin-bottom: 16px;
`;

export const FooterText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
`;
