import React from "react";

import { WidgetItem } from "../../../models/Widgets";
import { GroupedLineChart } from "./GroupedLineChart";
import { LineChart } from "./SingleLineChart";

interface WrapperLineInterface {
  storytelling?: boolean;
  recommended?: boolean;
  selected?: boolean;
  showLegend?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
  isRight?: boolean;
}

export const WrapperLineChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  isRight,
}: WrapperLineInterface) => {
  return currentWidget?.groupBy && currentWidget?.groupBy?.length > 0 ? (
    <GroupedLineChart
      recommended={recommended}
      selected={selected}
      storytelling={storytelling}
      showLegend={showLegend}
      currentWidget={currentWidget}
      hideName={hideName}
      isRight={isRight}
    />
  ) : (
    <LineChart
      recommended={recommended}
      selected={selected}
      storytelling={storytelling}
      showLegend={showLegend}
      currentWidget={currentWidget!}
      hideName={hideName}
      // isLeft={isLeft}
    />
  );
};
