import styled, { css } from "styled-components";
import {
  backDropFadeIn,
  backDropFadeOut,
  commonSeparatorStyle,
  fadeInRight,
  fadeOutRight,
} from "../../styles";
import confirmationImage from "../../assets/changeDashType.png";
import { ChevronLeft, XLg } from "react-bootstrap-icons";

const fileOverviewStyles = css`
  left: 16px;
  right: 16px;
  max-height: 98vh;
`;
const projectSettingsStyles = css`
  width: 482px;
  height: max-content;
  max-height: 96vh;
`;

const recommendedWidgetsStyles = css`
  max-width: 803px;
  right: 16px;
  height: calc(100vh - 220px) !important;
  width: 100%;
`;

const modalWrapperDefault = css`
  max-width: 480px;
  width: 100%;
`;

export const ModalWrapper = styled.div<{
  $isClosing?: boolean;
  $fileOverviewOpen?: boolean;
  $projectSettingsOpen?: boolean;
  $recommendedWidgetsStyles?: boolean;
  $centred?: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  height: ${({ $centred }) => $centred ? "max-content" : "96vh"};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  box-shadow: 0 0 50px 10px rgba(99, 99, 99, 0.2);
  border-radius: 6px;
  opacity: ${({ $isClosing }) => ($isClosing ? 1 : 0)};
  ${({ $fileOverviewOpen, $projectSettingsOpen, $recommendedWidgetsStyles }) =>
    $fileOverviewOpen
      ? fileOverviewStyles
      : $projectSettingsOpen
        ? projectSettingsStyles
        : $recommendedWidgetsStyles
          ? recommendedWidgetsStyles
          : modalWrapperDefault}
  animation: 0.4s
  ${({ $isClosing }) => ($isClosing ? fadeOutRight : fadeInRight)} ease
  forwards;
`;

export const OverviewContent = styled.div<{ $isLoading?: boolean }>`
  display: flex;
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

export const ModalOverlay = styled.div<{
  $isClosing?: boolean;
  $noBlur?: boolean;
  $centred?: boolean;
}>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${({ $centred }) => ($centred ? `center` : `end`)};
  align-items: center;
  z-index: 1001;
  background: ${({ theme }) => theme.neutralBackgroundActive};
  backdrop-filter: ${({ $noBlur }) => ($noBlur ? "" : "blur(1px)")};
  animation: ${({ $isClosing }) =>
    $isClosing ? backDropFadeOut : backDropFadeIn}
    0.3s ease-in-out;
  opacity: ${({ $isClosing }) => ($isClosing ? 0 : 1)};
  pointer-events: ${({ $isClosing }) => ($isClosing ? "none" : "auto")};
`;

export const ModalHeadingWrapper = styled.div<{
  $fileOverviewOpen?: boolean;
  $disableSeparator?: boolean;
}>`
  position: relative;
  padding: 26px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;

  ${({ $disableSeparator }) =>
    $disableSeparator === false &&
    `&:before {
      bottom: 0;
      ${commonSeparatorStyle};
    }
    `};
`;

export const ModalCreateProjectHeading = styled.div``;

export const ModalCreateProjectHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ModalHeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalHeading = styled.h2`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.neutralText};
`;
export const ModalSubHeading = styled.h2`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display:flex;
  align-items: center;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-right: 10px;
`;

export const Separator = styled.div`
  width: 1px;
  height: 11px;
  background: ${({ theme }) => theme.neutralBorder};
  transform: rotateZ(10deg);
  margin: 0 5px;
`

export const CloseIcon = styled(XLg)`
  cursor: pointer;
  filter: invert(56%) sepia(23%) saturate(181%) hue-rotate(179deg)
    brightness(89%) contrast(86%);
  margin-left: 16px;
  z-index: 100;
`;

export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CloseIconWrap = styled.div`
  margin-left: auto;
`;

export const CreateProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalContent = styled.div<{ $fileOverviewOpen?: boolean }>`
  display: block;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  width: 100%;
  height: max-content;
  ${({ $fileOverviewOpen }) =>
    $fileOverviewOpen &&
    `
    border-right: 1px solid #D3DBE3;
    max-width: 480px;
    flex: 2;
    `};
`;

export const ConfirmationBlock = styled.div`
padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const ConfirmationHead = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  max-width: 352px;
  text-align: center;
  margin-bottom: 8px;
  color: ${({theme}) => theme.neutralText};
`

export const ConfirmationSubHead = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 352px;
  text-align: center;
  color: ${({theme}) => theme.neutralTextWeak};

`

export const ConfirmationImg = styled.img.attrs({src: confirmationImage})`
  margin-bottom: 16px;
`

export const Steps = styled.div`
  display: flex;
  gap: 16px;
`;

export const ModalFooterWrapper = styled.div<{
  $fileOverviewOpen?: boolean;
  $flexEnd?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: ${({ $flexEnd }) =>
    $flexEnd ? "flex-end" : "space-between"};
  align-items: center;
  padding: 12px 15px;
  max-width: ${({ $fileOverviewOpen }) => $fileOverviewOpen && "480px"};
  box-shadow: ${({ theme }) => theme.boxShadowColor};

  &:after {
    top: 0;
    ${commonSeparatorStyle};
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

export const BackIcon = styled(ChevronLeft)`
  cursor: pointer;
`;

export const ModalFooterButtons = styled.div`
  position: relative;
  display: flex;
  gap: 16px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
