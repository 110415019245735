import React, { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirstPage } from "./components/FirstPage";
import { LastPage } from "./components/LastPage";
import { ReportContent } from "./components/ReportContent";
import { downloadPDF } from "../../helpers/generatePDF";
import { resetPDFGenerationTrigger, setPDFGenerationProgress } from "../../store/slices/pdfSlice";
import { documentName, pdfGenerationTrigger, pdfGenerationProgress } from "../../store/selectors/reportPDF";
import * as s from "./styled";
import { getPageSettings } from "../../store/selectors/projects";
import MiniatureNavigation from "./Miniature";
import { useSyncedState } from "../../hooks/useSyncedState";

interface Props {
  loading: boolean;
}

export const ReportMain: FC<Props> = ({ loading }) => {
  const name = useSelector(documentName);
  const pdfTrigger = useSelector(pdfGenerationTrigger);
  const progress = useSelector(pdfGenerationProgress);
  const currentPage = useSelector(getPageSettings);
  const containerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isHidden, setIsHidden] = useSyncedState("isHiddenCommentIds", false);

  const dispatch = useDispatch();

  const updateLoader = useCallback((progress: number) => {
    dispatch(setPDFGenerationProgress(progress))
  }, [dispatch])

  const handleGeneratePDF = useCallback(() => {
    if (containerRef.current) {
      downloadPDF(containerRef, name, updateLoader);
    }
  }, [name, updateLoader]);

  useEffect(() => {
    if (progress > 5) {
      setIsHidden(true);
    }
    if (progress === 100) {
      setIsHidden(false);
    }
  }, [progress, setIsHidden])

  useEffect(() => {
    if (pdfTrigger) {
      handleGeneratePDF();
      dispatch(resetPDFGenerationTrigger());
    }
  }, [pdfTrigger, dispatch, handleGeneratePDF]);

  return (
    <s.Container>
      <s.Wrapper>
        <s.ProgressBarContainer>
          <s.ProgressBar width={progress} />
        </s.ProgressBarContainer>
        <s.PageContainer ref={containerRef} id="page-container">
          {currentPage.showHeader && <FirstPage /> }
          <ReportContent />
          {currentPage.showHeader && <LastPage /> }
        </s.PageContainer>
      </s.Wrapper>
      <MiniatureNavigation containerRef={containerRef} loading={loading}/>
    </s.Container>
  );
};