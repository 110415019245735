import { Sizes } from "./Sizes.interface";

export const XLSize: Sizes = {
  label: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  value: {
    fontSize: '48px',
    lineHeight: '56px',
  },
  subValues: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  margin: '5px'
}
