import React from "react";
import { SelectedContainer, IconContainer } from "./styles";
import { useSelector } from "react-redux";
import { getCurrentWidget } from "../../../store/selectors/projects";
import { Plus } from "react-bootstrap-icons";

export const SelectBage = ({ selected }: { selected: boolean }) => {
  const currentWidget = useSelector(getCurrentWidget);
  const bageTitle = currentWidget?.id ? "Add" : "Add";

  return (
    <SelectedContainer $selected={selected}>
      {selected ? `${bageTitle}ed` : bageTitle}
      {!selected ? (
        <IconContainer>
          <Plus width={16} height={16} />
        </IconContainer>
      ) : null}
    </SelectedContainer>
  );
};
