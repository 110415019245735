export const getPosition = (idx: number, keys: number) => {
  const currentIdx = idx + 1;
  const width = 4;
  const spacing = 6;
  const halfKeys = keys / 2;

  if (keys % 2) {
    const centerIdx = Math.ceil(halfKeys);

    if (currentIdx < centerIdx) {
      return -(spacing + width) * currentIdx;
    } else if (currentIdx === centerIdx) {
      return 0;
    } else {
      return (spacing + width) * (currentIdx - centerIdx);
    }
  } else {
    const centerIdx = halfKeys + 0.5;

    if (currentIdx < centerIdx) {
      if (currentIdx + 0.5 === centerIdx) {
        return -(width + spacing) / 2;
      } else {
        return -(width + spacing) * currentIdx;
      }
    } else {
      if (currentIdx - 0.5 === centerIdx) {
        return (width + spacing) / 2;
      } else {
        return (width + spacing) * currentIdx;
      }
    }
  }
};
