import { useState, useEffect } from "react";

export function useMobileDetect() {
  const [mobileStatus, setMobileStatus] = useState(false);

  useEffect(() => {
    const isMobileDevice =
      /Android|CriOS|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    setMobileStatus(
      isMobileDevice || window.matchMedia("(max-width: 767px)").matches
    );
  }, []);

  return mobileStatus;
}
