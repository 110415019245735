import { Sizes } from "./Sizes.interface";

export const MDSize: Sizes = {
  label: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  value: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  subValues: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  margin: '4px'
}