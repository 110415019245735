import { Grid, Col, Row } from "react-flexbox-grid";
import {
  InfoText,
  MainSettingsBadge,
  MainSettingsBlockWrapper,
  MainSettingsColorBlock,
  MainSettingsColorSelectWrap,
  MainSettingsColorTitle,
  MainSettingsColorWrapper,
  MainSettingsColumnBlockWrapper,
  MainSettingsLeftBlockWrapper,
  MainSettingsLine,
  MainSettingsLineSecond,
  MainSettingsPalleteColorRow,
  MainSettingsPalleteColorsTable,
  MainSettingsPalleteColorsWrapper,
  MainSettingsPalleteColorTitle,
  MainSettingsRightBlockWrapper,
  MainSettingsTextSubTitle,
  MainSettingsTextTitle,
  MainSettingsTextWrapper,
  MainSettingsWrapper,
  SettingsTitle,
  SideBarDetailsContainerWrapper,
  SideBarDetailsTermsOfUse,
  SideBarDetailsTextSubTitle,
  SideBarDetailsTextTitle,
  SideBarDetailsTextWrapper,
  SideBarDetailsWrapper,
  SquareColor,
  SquaresWrapper,
} from "./styles";
import { FormProfileGroup } from "../../components/Modals/UpdateProfile/FormGroup";
import { Formik } from "formik";
import SwitchComponent from "../../components/Inputs/CustomSwitch/Switch";
import { Button } from "../../components/Button";
import {
  DarkModePreview,
  LightModePreview,
  LineExample,
  PalletePreview,
  ShortcutExample,
  SiteLogoExample,
  InfoCircle,
} from "../../assets";
import { ColorSelect } from "../../components/Inputs/CustomColorSelect";

const formInitialValues: Record<string, any> = {
  siteName: "",
  siteAddress: "",
};

export const SettingsPage = () => {
  return (
    <>
      <SettingsTitle>Settings</SettingsTitle>
      <Grid fluid style={{ padding: 0, paddingBottom: 10 }}>
        <Row>
          <Col style={{ paddingRight: "16px" }} lg={3}>
            <Formik
              initialValues={formInitialValues}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values: any) => {}}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                return (
                  <SideBarDetailsWrapper>
                    <SideBarDetailsContainerWrapper>
                      <SideBarDetailsTextWrapper>
                        <SideBarDetailsTextTitle>
                          Site Name
                        </SideBarDetailsTextTitle>
                        <SideBarDetailsTextSubTitle>
                          The name of this site that will display on the
                          dashboard and in the page title.
                        </SideBarDetailsTextSubTitle>
                      </SideBarDetailsTextWrapper>
                      <FormProfileGroup
                        id="siteName"
                        type="text"
                        name="siteName"
                        errors={errors.siteName as string}
                        placeholder="Site name"
                        values={values.siteName}
                        handleChange={handleChange}
                        touched={touched.siteName as boolean}
                        handleBlur={handleBlur}
                        disableMargin
                      />
                    </SideBarDetailsContainerWrapper>
                    <SideBarDetailsContainerWrapper>
                      <SideBarDetailsTextWrapper>
                        <SideBarDetailsTextTitle>
                          Site Address
                        </SideBarDetailsTextTitle>
                        <SideBarDetailsTextSubTitle>
                          Customize the site address - you can even use your own
                          domain.
                        </SideBarDetailsTextSubTitle>
                      </SideBarDetailsTextWrapper>
                      <FormProfileGroup
                        id="siteAddress"
                        type="text"
                        name="siteAddress"
                        errors={errors.siteAddress as string}
                        placeholder="Site address"
                        values={values.siteAddress}
                        handleChange={handleChange}
                        touched={touched.siteAddress as boolean}
                        handleBlur={handleBlur}
                        disableMargin
                      />
                    </SideBarDetailsContainerWrapper>
                    <SideBarDetailsContainerWrapper>
                      <SideBarDetailsTextWrapper>
                        <SideBarDetailsTextTitle>
                          Real Time Notifications
                        </SideBarDetailsTextTitle>
                        <SideBarDetailsTextSubTitle>
                          As users complete actions, other users will be
                          notified instantly with a badge on the Notifications
                          icon at the top right of the screen.
                        </SideBarDetailsTextSubTitle>
                      </SideBarDetailsTextWrapper>
                      <SwitchComponent
                        name={"realTimeNotifications"}
                        checked={!!values.realTimeNotifications?.length}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors.realTimeNotifications as string}
                        touched={!!touched.realTimeNotifications}
                        label={
                          !!values.realTimeNotifications?.length
                            ? "Enabled"
                            : "Disabled"
                        }
                        labelSide={"left"}
                      />
                    </SideBarDetailsContainerWrapper>
                    <SideBarDetailsContainerWrapper>
                      <SideBarDetailsTextWrapper>
                        <SideBarDetailsTextTitle>
                          Can publish dashboards/reports
                        </SideBarDetailsTextTitle>
                        <SideBarDetailsTextSubTitle>
                          Let users publish projects that can be given to people
                          who are not on your account.
                        </SideBarDetailsTextSubTitle>
                      </SideBarDetailsTextWrapper>
                      <SwitchComponent
                        name={"canPublish"}
                        checked={!!values.canPublish?.length}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors.canPublish as string}
                        touched={!!touched.canPublish}
                        label={
                          !!values.canPublish?.length ? "Enabled" : "Disabled"
                        }
                        labelSide={"left"}
                      />
                    </SideBarDetailsContainerWrapper>
                    <SideBarDetailsContainerWrapper>
                      <SideBarDetailsTextWrapper>
                        <SideBarDetailsTextTitle>
                          Allow 'Remember Me'
                        </SideBarDetailsTextTitle>
                        <SideBarDetailsTextSubTitle>
                          If set to 'Enabled', we will show the option 'Remember
                          Me' on the login screen.
                        </SideBarDetailsTextSubTitle>
                      </SideBarDetailsTextWrapper>
                      <SwitchComponent
                        name={"allowRememberMe"}
                        checked={!!values.allowRememberMe?.length}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors.allowRememberMe as string}
                        touched={!!touched.allowRememberMe}
                        label={
                          !!values.allowRememberMe?.length
                            ? "Enabled"
                            : "Disabled"
                        }
                        labelSide={"left"}
                      />
                    </SideBarDetailsContainerWrapper>
                    <SideBarDetailsTermsOfUse>
                      Privacy Policy and Terms of use
                    </SideBarDetailsTermsOfUse>
                  </SideBarDetailsWrapper>
                );
              }}
            </Formik>
          </Col>
          <Col style={{ paddingLeft: "16px" }} lg={9}>
            <MainSettingsWrapper>
              <MainSettingsBlockWrapper>
                <MainSettingsLeftBlockWrapper>
                  <MainSettingsTextWrapper>
                    <MainSettingsTextTitle>
                      Site Logo
                      <MainSettingsBadge>New</MainSettingsBadge>
                    </MainSettingsTextTitle>
                    <MainSettingsTextSubTitle>
                      The site logo appears on the sign in screen and email
                      notifications.
                    </MainSettingsTextSubTitle>
                  </MainSettingsTextWrapper>
                  <Button
                    onClick={() => {}}
                    size="medium"
                    variant="primary"
                    name="Update Logo"
                  ></Button>
                </MainSettingsLeftBlockWrapper>
                <MainSettingsRightBlockWrapper>
                  <SiteLogoExample />
                </MainSettingsRightBlockWrapper>
                <MainSettingsLine>
                  <LineExample />
                </MainSettingsLine>
              </MainSettingsBlockWrapper>
              <MainSettingsBlockWrapper>
                <MainSettingsLeftBlockWrapper>
                  <MainSettingsTextWrapper>
                    <MainSettingsTextTitle>
                      Shortcut Icon
                      <MainSettingsBadge>New</MainSettingsBadge>
                    </MainSettingsTextTitle>
                    <MainSettingsTextSubTitle>
                      The shortcut icon, also known as the favicon, appears in
                      some web browsers on the address bar, bookmarks menu and
                      tabs.
                    </MainSettingsTextSubTitle>
                  </MainSettingsTextWrapper>
                  <Button
                    onClick={() => {}}
                    size="medium"
                    variant="primary"
                    name="Update Icon"
                  ></Button>
                </MainSettingsLeftBlockWrapper>
                <MainSettingsRightBlockWrapper>
                  <ShortcutExample />
                </MainSettingsRightBlockWrapper>
                <MainSettingsLineSecond>
                  <LineExample />
                </MainSettingsLineSecond>
              </MainSettingsBlockWrapper>
              <MainSettingsBlockWrapper>
                <MainSettingsLeftBlockWrapper>
                  <MainSettingsTextWrapper>
                    <MainSettingsTextTitle>Theme colours</MainSettingsTextTitle>
                    <MainSettingsTextSubTitle>
                      Choose your brand colours to customise the platform.
                    </MainSettingsTextSubTitle>
                  </MainSettingsTextWrapper>
                  <MainSettingsColorWrapper>
                    <MainSettingsColorBlock>
                      <MainSettingsColorTitle>
                        Main colours
                      </MainSettingsColorTitle>
                      <MainSettingsColorSelectWrap>
                        <ColorSelect
                          value="#00143F"
                          options={[
                            "#00143F",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Primary Steel Gray`,
                            value: el,
                          }))}
                        />
                        <ColorSelect
                          value="#5448FF"
                          options={[
                            "#5448FF",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Accent Purple Heart`,
                            value: el,
                          }))}
                        />
                        <InfoText>
                          <InfoCircle fill="#493DDD" />
                          It is not recommended to use as primary, colours close
                          to semantic (green, yellow, orange, red)
                        </InfoText>
                      </MainSettingsColorSelectWrap>
                    </MainSettingsColorBlock>
                  </MainSettingsColorWrapper>
                  <MainSettingsColorWrapper>
                    <MainSettingsColorBlock>
                      <MainSettingsColorTitle>
                        Semantic colours
                      </MainSettingsColorTitle>
                      <MainSettingsColorSelectWrap>
                        <ColorSelect
                          value="#5448FF"
                          options={[
                            "#5448FF",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Info Purple Heart`,
                            value: el,
                          }))}
                        />
                        <ColorSelect
                          value="#2B9E77"
                          options={[
                            "#2B9E77",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Success Jungle Green`,
                            value: el,
                          }))}
                        />
                        <ColorSelect
                          value="#E8C26D"
                          options={[
                            "#E8C26D",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Warning Amber`,
                            value: el,
                          }))}
                        />
                        <ColorSelect
                          value="#C53660"
                          options={[
                            "#C53660",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Critical Cerise Red`,
                            value: el,
                          }))}
                        />
                      </MainSettingsColorSelectWrap>
                    </MainSettingsColorBlock>
                  </MainSettingsColorWrapper>
                  <Button
                    name={"Reset to default"}
                    onClick={() => {}}
                    size="medium"
                    variant="disabled"
                  />
                </MainSettingsLeftBlockWrapper>
                <MainSettingsRightBlockWrapper>
                  <LightModePreview />
                  <DarkModePreview />
                </MainSettingsRightBlockWrapper>
              </MainSettingsBlockWrapper>
              <MainSettingsColumnBlockWrapper>
                <MainSettingsLeftBlockWrapper>
                  <MainSettingsTextWrapper>
                    <MainSettingsTextTitle>Theme colours</MainSettingsTextTitle>
                    <MainSettingsTextSubTitle>
                      Choose your brand colours to customise the platform.
                    </MainSettingsTextSubTitle>
                  </MainSettingsTextWrapper>
                  <MainSettingsColorWrapper>
                    <MainSettingsColorBlock>
                      <MainSettingsColorTitle>
                        Main colours
                      </MainSettingsColorTitle>
                      <MainSettingsColorSelectWrap>
                        <ColorSelect
                          value="#473DD9"
                          options={[
                            "#473DD9",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Colour 1`,
                            value: el,
                          }))}
                        />
                        <ColorSelect
                          value="#36C4A5"
                          options={[
                            "#473DD9",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Colour 2`,
                            value: el,
                          }))}
                        />
                        <ColorSelect
                          value="#FF8C47"
                          options={[
                            "#473DD9",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Colour 3`,
                            value: el,
                          }))}
                        />
                        <ColorSelect
                          value="#FF4781"
                          options={[
                            "#473DD9",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Colour 4`,
                            value: el,
                          }))}
                        />
                        <ColorSelect
                          value="#47B0FF"
                          options={[
                            "#473DD9",
                            "#938CFF",
                            "#36C4A5",
                            "#ADF4E5",
                            "#BDBCC8",
                            "#56546D",
                            "#FF8C47",
                            "#FFCEB0",
                            "#FF4781",
                            "#FFB0C9",
                            "#47B0FF",
                            "#B0DDFF",
                          ].map((el: any, i: number) => ({
                            label: `Colour 5`,
                            value: el,
                          }))}
                        />
                      </MainSettingsColorSelectWrap>
                      <Button
                        name={"Reset to default"}
                        onClick={() => {}}
                        size="medium"
                        variant="disabled"
                      />
                    </MainSettingsColorBlock>
                  </MainSettingsColorWrapper>
                </MainSettingsLeftBlockWrapper>
                <MainSettingsBlockWrapper>
                  <MainSettingsLeftBlockWrapper $disablePadding>
                    <MainSettingsPalleteColorsWrapper>
                      <MainSettingsColorTitle>
                        Qualitative (Categorical) palettes
                      </MainSettingsColorTitle>
                      <MainSettingsPalleteColorsTable>
                        <MainSettingsPalleteColorRow>
                          <MainSettingsPalleteColorTitle>
                            12 - Colour group
                          </MainSettingsPalleteColorTitle>
                          <SquaresWrapper>
                            {[
                              "#473DD9",
                              "#938CFF",
                              "#36C4A5",
                              "#ADF4E5",
                              "#BDBCC8",
                              "#56546D",
                              "#FF8C47",
                              "#FFCEB0",
                              "#FF4781",
                              "#FFB0C9",
                              "#47B0FF",
                              "#B0DDFF",
                            ].map((color) => {
                              return <SquareColor $color={color} />;
                            })}
                          </SquaresWrapper>
                        </MainSettingsPalleteColorRow>
                        <MainSettingsPalleteColorRow>
                          <MainSettingsPalleteColorTitle>
                            4 - Colour group
                          </MainSettingsPalleteColorTitle>
                          <SquaresWrapper>
                            {["#473DD9", "#36C4A5", "#56546D", "#FF8C47"].map(
                              (color) => {
                                return <SquareColor $color={color} />;
                              }
                            )}
                          </SquaresWrapper>
                        </MainSettingsPalleteColorRow>
                        <MainSettingsPalleteColorRow>
                          <MainSettingsPalleteColorTitle>
                            2 - Colour group (Option 1)
                          </MainSettingsPalleteColorTitle>
                          <SquaresWrapper>
                            {["#473DD9", "#36C4A5"].map((color) => {
                              return <SquareColor $color={color} />;
                            })}
                          </SquaresWrapper>
                        </MainSettingsPalleteColorRow>
                        <MainSettingsPalleteColorRow>
                          <MainSettingsPalleteColorTitle>
                            2 - Colour group (Option 2)
                          </MainSettingsPalleteColorTitle>
                          <SquaresWrapper>
                            {["#938CFF", "#FF8C47"].map((color) => {
                              return <SquareColor $color={color} />;
                            })}
                          </SquaresWrapper>
                        </MainSettingsPalleteColorRow>
                      </MainSettingsPalleteColorsTable>
                      <MainSettingsColorTitle>
                        Qualitative (Categorical) palettes
                      </MainSettingsColorTitle>
                      <MainSettingsPalleteColorsTable>
                        <MainSettingsPalleteColorRow>
                          <MainSettingsPalleteColorTitle>
                            Option 1
                          </MainSettingsPalleteColorTitle>
                          <SquaresWrapper>
                            {[
                              "#D6D3FF",
                              "#B5B0FF",
                              "#938CFF",
                              "#7369FF",
                              "#5448FF",
                              "#473DD9",
                              "#3C33B5",
                            ].map((color) => {
                              return <SquareColor $color={color} />;
                            })}
                          </SquaresWrapper>
                        </MainSettingsPalleteColorRow>
                        <MainSettingsPalleteColorRow>
                          <MainSettingsPalleteColorTitle>
                            Option 2
                          </MainSettingsPalleteColorTitle>
                          <SquaresWrapper>
                            {[
                              "#D1F9F0",
                              "#ADF4E5",
                              "#87EFD9",
                              "#62EBCD",
                              "#40E6C2",
                              "#36C4A5",
                              "#2DA38A",
                            ].map((color) => {
                              return <SquareColor $color={color} />;
                            })}
                          </SquaresWrapper>
                        </MainSettingsPalleteColorRow>
                        <MainSettingsPalleteColorRow>
                          <MainSettingsPalleteColorTitle>
                            Option 3
                          </MainSettingsPalleteColorTitle>
                          <SquaresWrapper>
                            {[
                              "#938CFF",
                              "#89A4F7",
                              "#88B9F6",
                              "#8CCBF1",
                              "#98DAEB",
                              "#A8E7E7",
                              "#ADF4E5",
                            ].map((color) => {
                              return <SquareColor $color={color} />;
                            })}
                          </SquaresWrapper>
                        </MainSettingsPalleteColorRow>
                      </MainSettingsPalleteColorsTable>
                      <MainSettingsColorTitle>
                        Qualitative (Categorical) palettes
                      </MainSettingsColorTitle>
                      <MainSettingsPalleteColorsTable>
                        <MainSettingsPalleteColorRow>
                          <MainSettingsPalleteColorTitle>
                            Option 1
                          </MainSettingsPalleteColorTitle>
                          <SquaresWrapper>
                            {[
                              "#5448FF",
                              "#7369FF",
                              "#938CFF",
                              "#B5B0FF",
                              "#DADAE1",
                              "#ADF4E5",
                              "#87EFD9",
                              "#62EBCD",
                              "#40E6C2",
                            ].map((color) => {
                              return <SquareColor $color={color} />;
                            })}
                          </SquaresWrapper>
                        </MainSettingsPalleteColorRow>
                        <MainSettingsPalleteColorRow>
                          <MainSettingsPalleteColorTitle>
                            Option 2
                          </MainSettingsPalleteColorTitle>
                          <SquaresWrapper>
                            {[
                              "#47B0FF",
                              "#68BEFF",
                              "#8BCDFF",
                              "#B0DDFF",
                              "#DADAE1",
                              "#FFCEB0",
                              "#FFB78B",
                              "#FFA168",
                              "#FF8C47",
                            ].map((color) => {
                              return <SquareColor $color={color} />;
                            })}
                          </SquaresWrapper>
                        </MainSettingsPalleteColorRow>
                      </MainSettingsPalleteColorsTable>
                    </MainSettingsPalleteColorsWrapper>
                  </MainSettingsLeftBlockWrapper>
                  <MainSettingsRightBlockWrapper>
                    <PalletePreview />
                  </MainSettingsRightBlockWrapper>
                </MainSettingsBlockWrapper>
              </MainSettingsColumnBlockWrapper>
            </MainSettingsWrapper>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
