import React from "react";

import {
  LogoWrapper,
  MobileViewContentWrapper,
  MobileViewDataWrapper,
  MobileViewDescription,
  MobileViewImage,
  MobileViewTitle,
  MobileViewWrapper,
} from "./styles";

export const MobileView = () => {
  return (
    <MobileViewWrapper>
      <LogoWrapper />
      <MobileViewContentWrapper>
        <MobileViewImage />
        <MobileViewDataWrapper>
          <MobileViewTitle>
            Oops! This page is only available on desktop version.{" "}
          </MobileViewTitle>
          <MobileViewDescription>
            For the best experience, switch to a desktop device or visit us on
            your computer to access this feature. We appreciate your
            understanding.
          </MobileViewDescription>
        </MobileViewDataWrapper>
      </MobileViewContentWrapper>
    </MobileViewWrapper>
  );
};
