import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { ServerResponse } from "../../models";
import { deleteFile, downloadFile, uploadFile } from "../../api/files";
import {
  FileDeleteDto,
  FileDownloadDto,
  FileUploadDto,
} from "../../models/Files";
import { setFileData, setUploadFile, setUploadProgress } from "../slices/files";
import { store } from "../index";

export function* handleUploadFile(action: PayloadAction<FileUploadDto>) {
  const onUploadProgress = (progressEvent: ProgressEvent) => {
    const uploadPercentage = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total!
    );
    store.dispatch(setUploadProgress(uploadPercentage));
  };

  const { response }: ServerResponse = yield call(
    uploadFile,
    action.payload,
    onUploadProgress
  );
  if (response?.status === 201) {
    yield put(setFileData(response.data.items));
    yield put(setUploadProgress(0));
    yield put(setUploadFile([]));
  }
}

export function* handleUploadSimpleFile(action: PayloadAction<FileUploadDto>) {
  const onUploadProgress = (progressEvent: ProgressEvent) => {
    const uploadPercentage = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total!
    );
    store.dispatch(setUploadProgress(uploadPercentage));
  };

  const { response, error }: ServerResponse = yield call(
    uploadFile,
    action.payload,
    onUploadProgress
  );
  if (response?.status === 201) {
    action.payload.callbacks?.onSuccess(response.data.items);
  }
  if (error && action.payload.callbacks?.onError) {
    action.payload.callbacks?.onError();
  }
}

export function* handleDownloadFile(action: PayloadAction<FileDownloadDto>) {
  const { response }: ServerResponse = yield call(
    downloadFile,
    action.payload.path
  );
  if (response?.status === 200) {
    action.payload.callbacks?.onSuccess(response.data);
  }
}

export function* handleDeleteFile(action: PayloadAction<FileDeleteDto>) {
  const { response }: ServerResponse = yield call(
    deleteFile,
    action.payload.path
  );
  if (response?.status === 200) {
    action.payload.callbacks?.onSuccess(response.data);
  }
}

export function* watchFileActions() {
  yield takeLatest("files/requestUploadFile", handleUploadFile);
  yield takeLatest("files/requestUploadSimpleFile", handleUploadSimpleFile);
  yield takeLatest("files/requestDownloadFile", handleDownloadFile);
  yield takeLatest("files/requestDeleteFile", handleDeleteFile);
}
