import React from "react";

import { VerticalLollipopChart } from "./Vertical";
import { HorizontalLollipopChart } from "./Horizontal";
import { WidgetItem } from "../../../models/Widgets";

interface LollipopInterface {
  storytelling?: boolean;
  recommended?: boolean;
  selected?: boolean;
  currentWidget: WidgetItem;
  showLegend?: boolean;
  hideName?: boolean;
  isRight?: boolean;
}

export const LollipopChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend,
  selected = false,
  hideName = false,
  isRight,
}: LollipopInterface) => {
  return currentWidget?.orientation === "horizontal" ? (
    <HorizontalLollipopChart
      currentWidget={currentWidget}
      storytelling={storytelling}
      recommended={recommended}
      selected={selected}
      showLegend={showLegend}
      hideName={hideName}
      isRight={isRight}
    />
  ) : (
    <VerticalLollipopChart
      currentWidget={currentWidget}
      storytelling={storytelling}
      recommended={recommended}
      selected={selected}
      showLegend={showLegend}
      hideName={hideName}
      isRight={isRight}
    />
  );
};
