import { XLg } from "react-bootstrap-icons";
import { styled } from "styled-components";

export const CommentHeadWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  justify-content: flex-end;
`;

export const ActionSection = styled.div`
  display: flex;
  align-items: center;
  justify-self: start;
`;

const encodedChevron = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='18' viewBox='0 0 6 18' fill='none'%3E%3Cpath d='M1 1L5 9L1 17' stroke='%23003E5F' stroke-linecap='round'/%3E%3C/svg%3E")`;
const encodedDiagonalLine = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='18' viewBox='0 0 6 18' fill='none'%3E%3Cpath d='M5 1L1 17' stroke='%23155676' stroke-linecap='round'/%3E%3C/svg%3E")`;


export const ActionItem = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 4px;
  cursor: pointer;
  span {
    color: ${({ theme }) => theme.primaryText}
  }

  &::before, &::after {
    content: ${encodedDiagonalLine};
    transition: all 0.3s ease;
    margin: 2px 4px 0 4px;
    align-self: center;
  }

  &:hover::before, &:hover::after {
    content: ${encodedChevron}; /* Unicode for left arrow */
  }

`;

export const CloseButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  span {
    color: ${({ theme }) => theme.primaryText}
  }
`;

export const Close = styled(XLg)`
  path {
    fill: ${({ theme }) => theme.neutralText};
  }
`;