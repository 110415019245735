import { FC, useState } from 'react';
import * as s from "./styles"
import TextImage from "../StoryTellingSection/components/TextImage/TextImage";
import { ReactComponent as Logo } from "../../../../assets/logo-32px.svg"
import { useSelector } from 'react-redux';
import { getPageBanner, getPageId } from '../../../../store/selectors/projects';
import { IPageHeader } from '../../../../store/slices/pageContentGenerator';
import { defaultHeader } from '../../../AddStorytellingSection';
import { useDebounceCallback } from 'usehooks-ts';


export const FirstPage: FC = () => {
  const header = useSelector(getPageBanner);
  const currentPageId = useSelector(getPageId);
  
  const [contentItem, setContentItem] = useState<IPageHeader | undefined>(
    header || defaultHeader
  );
  const debounced = useDebounceCallback(setContentItem, 500);
 
  if (!header) return null;
  return (
    <s.PageContainer id="first-page">
      <s.TextSection>
      {currentPageId ? (
        <TextImage
          currentPageId={currentPageId!}
          contentItem={contentItem}
          setContentItem={debounced}
        />
      ) : null}
      </s.TextSection>

      {/* Footer Section with Logo */}
      <s.FooterSection>
        <s.FooterLogo>
        <Logo />
        </s.FooterLogo>

        <s.FooterText>Health Information Management Division</s.FooterText>
      </s.FooterSection>
    </s.PageContainer>
  );
};