import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ProjectDTO,
  ProjectsResponseListDto,
  UpdateProjectDTO,
} from "../../models/Projects";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import { QueryGetProjects } from "../../api/projects";
import { RequestCallbacks } from "../../models";

const initialState: ProjectsResponseListDto = {
  items: [],
  count: 0,
  isLoading: false,
};

const projectsSlice = createSlice({
  name: "allProjects",
  initialState,
  reducers: {
    requestAllProjects: (
      state: ProjectsResponseListDto,
      action: PayloadAction<QueryGetProjects>
    ) => {},

    setIsLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },

    setProjectsSearch: (
      state: ProjectsResponseListDto,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        search: action.payload,
      };
    },

    setAllProjects: (
      state: ProjectsResponseListDto,
      action: PayloadAction<ProjectsResponseListDto>
    ) => {
      return {
        ...state,
        items: action.payload.items,
        count: action.payload.count,
      };
    },

    requestCreateProject: (
      state: ProjectsResponseListDto,
      action: PayloadAction<ProjectDTO>
    ) => {},

    requestDeleteProject: (
      state: ProjectsResponseListDto,
      action: PayloadAction<{ id: string; navigate: NavigateFunction }>
    ) => {},

    handleDeleteProject: (
      state: ProjectsResponseListDto,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        items: state.items?.filter((item) => item.id !== action.payload),
      };
    },

    requestPublishProject: (
      state: ProjectsResponseListDto,
      action: PayloadAction<string>
    ) => {},

    requestProjectById: (
      state: ProjectsResponseListDto,
      action: PayloadAction<string>
    ) => {},

    requestPublicProjectById: (
      state: ProjectsResponseListDto,
      action: PayloadAction<string>
    ) => {
      return state;
    },
    requestCloneProjectById: (
      state: ProjectsResponseListDto,
      action: PayloadAction<{
        id: string;
        callbacks?: RequestCallbacks<any>;
      }>
    ) => {
      return state;
    },

    requestPublicProjectWidgetsByPageId: (
      state: ProjectsResponseListDto,
      action: PayloadAction<{
        id: string;
        pageId: string;
      }>
    ) => {},

    requestUpdateProjectById: (
      state: ProjectsResponseListDto,
      action: PayloadAction<UpdateProjectDTO>
    ) => {},

    requestRemoveProjectById: (
      state: ProjectsResponseListDto,
      action: PayloadAction<string>
    ) => {},
  },
});

export const {
  requestAllProjects,
  setIsLoading,
  setAllProjects,
  requestCreateProject,
  requestDeleteProject,
  handleDeleteProject,
  requestPublishProject,
  requestProjectById,
  requestPublicProjectById,
  requestPublicProjectWidgetsByPageId,
  requestUpdateProjectById,
  requestRemoveProjectById,
  setProjectsSearch,
  requestCloneProjectById,
} = projectsSlice.actions;

export default projectsSlice.reducer;
