import { useCallback, useContext, useState } from 'react';
import { MapControlsContext } from '../components/Comparison/components/ChartContainer/components/MapControls/MapControls';

interface UseSharedMapControlsProps {
  isSharedControls: boolean;
  id: string;
}

interface SharedMapControlsResult {
  showControls: boolean;
  handleMapInteraction: () => void;
  currentPeriod: string | undefined;
  handleSetPeriod: (period: string) => void;
  currentLeftSlider: number;
  currentRightSlider: number;
  handleSetLeftSlider: (value: number) => void;
  handleSetRightSlider: (value: number) => void;
}

export const useSharedMapControls = ({ 
  isSharedControls, 
  id 
}: UseSharedMapControlsProps): SharedMapControlsResult => {
  const mapControls = useContext(MapControlsContext);
  
  // Local state for independent controls
  const [localPeriod, setLocalPeriod] = useState<string>();
  const [localLeftSlider, setLocalLeftSlider] = useState(0);
  const [localRightSlider, setLocalRightSlider] = useState(6);

  const handleMapInteraction = useCallback(() => {
    if (isSharedControls) {
      mapControls.setActiveMapId(id);
    }
  }, [isSharedControls, mapControls, id]);

  const handleSetPeriod = useCallback((newPeriod: string) => {
    if (isSharedControls) {
      mapControls.setPeriod(newPeriod);
    } else {
      setLocalPeriod(newPeriod);
    }
  }, [isSharedControls, mapControls]);

  const handleSetLeftSlider = useCallback((value: number) => {
    if (isSharedControls) {
      mapControls.setLeftSlider(value);
    } else {
      setLocalLeftSlider(value);
    }
  }, [isSharedControls, mapControls]);

  const handleSetRightSlider = useCallback((value: number) => {
    if (isSharedControls) {
      mapControls.setRightSlider(value);
    } else {
      setLocalRightSlider(value);
    }
  }, [isSharedControls, mapControls]);

  const showControls = !isSharedControls || 
    mapControls.activeMapId === id || 
    !mapControls.activeMapId;

  return {
    showControls,
    handleMapInteraction,
    currentPeriod: isSharedControls ? mapControls.period : localPeriod,
    handleSetPeriod,
    currentLeftSlider: isSharedControls ? mapControls.leftSlider : localLeftSlider,
    currentRightSlider: isSharedControls ? mapControls.rightSlider : localRightSlider,
    handleSetLeftSlider,
    handleSetRightSlider
  };
};