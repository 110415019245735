import { IFormatting, WidgetItem } from "../../../../models/Widgets";

export const RadarChartGroupedData = (
  currentWidgetData?: WidgetItem,
  chartSuggestion?: any
) => {
  const groupBy = currentWidgetData?.groupBy?.[0];
  const xAxe = currentWidgetData?.xAxe?.[0] ?? chartSuggestion?.xAxe?.[0];
  const yAxe = currentWidgetData?.yAxe?.[0] ?? chartSuggestion?.yAxe?.[0];

  if (!groupBy) {
    return {
      default: currentWidgetData?.data?.map((l) => ({
        x: xAxe ? l[xAxe] : l.year,
        y: yAxe ? l[yAxe] : l.value,
      })),
    };
  }

  return currentWidgetData?.data?.reduce((acc, obj) => {
    const group = obj[groupBy];
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push({
      x: xAxe ? obj[xAxe] : obj.year,
      y: yAxe ? obj[yAxe] : obj.value,
    });
    return acc;
  }, {});
};

export const getRadarChartDefaultColors = (data: any): IFormatting[] => {
  const groupBy = data.groupBy?.[0];

  if (!groupBy) {
    return [{ key: "default", color: "1" }];
  }

  const keys = data.uniqueValues[groupBy] || [];

  return keys.map((item: string, index: number) => ({
    key: item,
    color: String(index + 1),
  }));
};
