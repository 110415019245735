import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";

import { CREATE_PROJECT, GET_ALL_PROJECTS } from "./constants";
import { UpdateProjectDTO } from "../models/Projects";
import { generateQueryString } from "../helpers/queryGenerator";
import { RequestCallbacks } from "../models";

export interface QueryGetProjects {
  name?: string;
  skip?: number;
  limit?: number;
  orderBy?: string;
  orderDirection?: string;
  includeDatasets?: boolean;
  includePages?: boolean;
  callbacks?: RequestCallbacks<any>;
}

export const getAllProjects = async (query: QueryGetProjects) => {
  const queryParams = generateQueryString<QueryGetProjects>({
    ...query,
    limit: query?.limit || 10,
  });

  return axios
    .get(`${GET_ALL_PROJECTS}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const createProject = (projectData: {
  name: string;
  description: string;
  datasets?: any[];
}) =>
  axios
    .post(CREATE_PROJECT, { ...projectData, suggestionsCount: 0 })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const removeProject = (projectId: string) =>
  axios
    .delete(`/projects/${projectId}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getProjectById = (projectId: string) =>
  axios
    .get(`/projects/${projectId}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const updateProjectById = (data: UpdateProjectDTO) =>
  axios
    .patch(`/projects/${data.id}`, data)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const deleteProjectById = (projectId: string) =>
  axios
    .delete(`/projects/${projectId}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const publishProjectById = (projectId: string) => {
  return axios
    .post(`/projects/${projectId}/publish`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const cloneProjectById = (projectId: string) => {
  return axios
    .post(`/projects/${projectId}/clone`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};
