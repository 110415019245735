import { put, call, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ServerResponse } from "../../models";
import {
  CommentQueryParams,
  GroupedCommentQueryParams,
  UpdateCommentsRequest,
  CreateCommentRequest,
} from "../../types/comments";
import {
  fetchComments,
  addComment,
  readComments,
  hideComments,
  fetchGroupedComments,
  resolveComments,
  editComment,
} from "../../api/comments";
import {
  setComments,
  addCommentSuccess,
  readCommentsSuccess,
  hideCommentsSuccess,
  setGroupedComments,
  resolveCommentsSuccess,
  editCommentSuccess,
} from "../slices/commnets";

export function* handleFetchComments(
  action: PayloadAction<CommentQueryParams>
) {
  const { response }: ServerResponse = yield call(
    fetchComments,
    action.payload
  );

  if (response?.status === 200) {
    yield put(setComments(response.data));
  } else {
    toast.error("Failed to fetch comments");
  }
}

export function* handleAddComment(action: PayloadAction<CreateCommentRequest>) {
  const { response }: ServerResponse = yield call(addComment, action.payload);

  if (response?.status === 200) {
    yield put(addCommentSuccess(response.data));
  } else {
    toast.error("Failed to add comment");
  }
}

// Add new edit comment handler
export function* handleEditComment(
  action: PayloadAction<{ id: string; content: string }>
) {
  const { response }: ServerResponse = yield call(editComment, action.payload);

  if (response?.status === 200) {
    yield put(editCommentSuccess(response.data));
  } else {
    toast.error("Failed to update comment");
  }
}

export function* handleReadComments(
  action: PayloadAction<UpdateCommentsRequest>
) {
  const { response }: ServerResponse = yield call(readComments, action.payload);

  if (response?.status === 200) {
    yield put(readCommentsSuccess(action.payload));
  } else {
    toast.error("Failed to mark comments as read");
  }
}

export function* handleHideComments(
  action: PayloadAction<UpdateCommentsRequest>
) {
  const { response }: ServerResponse = yield call(hideComments, action.payload);

  if (response?.status === 200) {
    yield put(hideCommentsSuccess(action.payload));
  } else {
    toast.error("Failed to update comments visibility");
  }
}

export function* handleFetchGroupedComments(
  action: PayloadAction<GroupedCommentQueryParams>
) {
  const { response }: ServerResponse = yield call(
    fetchGroupedComments,
    action.payload
  );

  if (response?.status === 200) {
    yield put(setGroupedComments(response.data));
  } else {
    toast.error("Failed to fetch grouped comments");
  }
}

export function* handleResolveComments(
  action: PayloadAction<{ ids: string[]; isResolved: boolean }>
) {
  const { response }: ServerResponse = yield call(
    resolveComments,
    action.payload
  );

  if (response?.status === 200) {
    yield put(resolveCommentsSuccess(action.payload));
  } else {
    toast.error("Failed to resolve comments");
  }
}

export function* watchCommentActions() {
  yield takeLatest("comments/requestFetchComments", handleFetchComments);
  yield takeLatest("comments/requestAddComment", handleAddComment);
  yield takeLatest("comments/requestEditComment", handleEditComment); // Add new watcher
  yield takeLatest("comments/requestReadComments", handleReadComments);
  yield takeLatest("comments/requestHideComments", handleHideComments);
  yield takeLatest(
    "comments/requestFetchGroupedComments",
    handleFetchGroupedComments
  );
  yield takeLatest("comments/requestResolveComments", handleResolveComments);
}
