import { put, call, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { registerUser, signInUser } from "../../api/auth";
import { setRegistrationSuccess, setSignInSuccess } from "../slices/auth";
import { toast } from "react-toastify";
import { ServerResponse } from "../../models";
import {
  CredentialsRequestDto,
  RegisterDto,
} from "../../models/Authentication";

export function* handleRegistration(action: PayloadAction<RegisterDto>) {
  const { teamName, navigate, ...payload } = action.payload;
  const { response }: ServerResponse = yield call(registerUser, {
    teamName: teamName ? teamName : "Not Set",
    ...payload,
  });

  if (response?.status === 201) {
    yield put(setRegistrationSuccess(response.data));
    action.payload.navigate("/");
  }
}

export function* handleSignIn(action: PayloadAction<CredentialsRequestDto>) {
  const { response }: ServerResponse = yield call(signInUser, action.payload);
  if (response?.status === 200) {
    yield put(setSignInSuccess(response.data));
    action.payload.navigate("/");
    toast.success("Sign in successful");
  }
}

export function* watchAuthActions() {
  yield takeLatest("auth/requestSignIn", handleSignIn);
  yield takeLatest("auth/requestRegistration", handleRegistration);
}
