import React, { Dispatch, SetStateAction, useState } from "react";
import * as s from "./styles";
import { getMeasure, getPopulations } from "../utils";

type FormContentProps = {
  selectedOption: string | null;
  setSelectedOption: Dispatch<SetStateAction<string | null>>;
  selectedChip: string | null;
  setSelectedChip: Dispatch<SetStateAction<string | null>>;
};

export const FormContent: React.FC<FormContentProps> = ({
  selectedOption,
  setSelectedOption,
  selectedChip,
  setSelectedChip,
}) => {
  const [pressedOption, setPressedOption] = useState<string | null>(null);

  return (
    <s.FormWrapper>
      <s.FormGroup>
        <s.Label>Population</s.Label>
      </s.FormGroup>
      <s.ChipContainer>
        {getPopulations().map((population) => (
          <s.Chip
            key={population.bucket}
            $selected={selectedChip === population.bucket}
            onClick={() => {
              setSelectedChip(population.bucket);
              setSelectedOption(null);
            }}
          >
            {population.bucket} <span>{population.count}</span>
          </s.Chip>
        ))}
      </s.ChipContainer>
      <s.OptionsContainer>
        {getMeasure(selectedChip).map((option) => (
          <s.Option
            key={option.id}
            $selected={selectedOption === option.id}
            onClick={() => setSelectedOption(option.id)}
            onMouseDown={() => setPressedOption(option.id)}
            onMouseUp={() => setPressedOption(null)}
            onMouseLeave={() => setPressedOption(null)}
          >
            <s.RadioButtonWrapper>
              {selectedOption === option.id ? (
                <s.ActiveRadioButton />
              ) : pressedOption === option.id ? (
                <s.PressRadioButton />
              ) : (
                <>
                  <s.HoveredRadioButton />
                  <s.InactiveRadioButton />
                </>
              )}
            </s.RadioButtonWrapper>
            <s.Label htmlFor={option.id}>
              {option.id}
              <p className="font-normal text-sm text-gray-500">
                {option.measureDescriptionDesktop}
              </p>
            </s.Label>
          </s.Option>
        ))}
      </s.OptionsContainer>
    </s.FormWrapper>
  );
};
