import { ComparisonContent } from "./components/ComparisonContent";
import * as s from "./styles";
import { useDispatch } from "react-redux";
import { setSelectedMeasure } from "../../store/slices/comparison";
import { getMeasure } from "../Modals/CreateComparison/utils";
import { useSelector } from "react-redux";

import { getCurrentProject, getPageSettings } from "../../store/selectors/projects";
import { Select } from "../Inputs/CustomSelect/Select";
import { ISelect } from "../Inputs/CustomSelect/types";


export const ComparisonMain = () => {
  const projectData = useSelector(getCurrentProject)
  const currentPageSettings = useSelector(getPageSettings);

  const [measure, population] = currentPageSettings.comparisonMeasure?.split("=") ?? ["default", "value"];

  const dispatch = useDispatch();

  const measureList = getMeasure(population);

  const handleSelectMeasure = (select: ISelect) => {
    dispatch(setSelectedMeasure(select.value))
  };

  return (
    <s.Container>
      <s.SelectContainer>
        <div className="select-measure">
          <Select
            label={projectData.projectData.population}
            withOutClose
            options={[{ value: projectData.projectData.measure || "", option: projectData.projectData.measure || '' }]}
            value={projectData.projectData.measure || ""}
            disabled
          />
        </div>
        <div className="select-measure">
          <Select
            label={population}
            withOutClose
            options={measureList.map((measure) => ({
              value: measure.id,
              option: measure.id,
            }))}
            value={measure}
            onSelect={handleSelectMeasure}
          />
        </div>
      </s.SelectContainer>
      <ComparisonContent />
    </s.Container>
  );
};
