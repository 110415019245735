import React from "react";
import { NavBar } from "./components/NavBar";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { Container } from "./styles";
import styled, { ThemeProvider } from "styled-components";
import { Themes } from "./constants";
import { getTheme } from "./store/selectors/main";
import ModalsWrapper from "./components/Modals";
import { useMobileDetect } from "./hooks/useMobileStatus";
import { MobileView } from "./components/MobileView";

interface LayoutProps {
  children: React.ReactNode;
}
const LayoutWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const Layout = ({ children }: LayoutProps) => {
  const themeSelector = useSelector(getTheme);
  const isMobile = useMobileDetect();

  return (
    <ThemeProvider theme={Themes[themeSelector]}>
      {isMobile ? (
        <MobileView />
      ) : (
        <LayoutWrapper>
          <NavBar />
          <Container>{children}</Container>
          <ModalsWrapper />
          <ToastContainer
            position="bottom-left"
            autoClose={2500}
            pauseOnFocusLoss={false}
            hideProgressBar
            theme="colored"
          />
        </LayoutWrapper>
      )}
    </ThemeProvider>
  );
};

export default Layout;
