import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle, CheckLg } from "react-bootstrap-icons";

import { CloseIcon, Heading } from "./styles";
import { ProjectType } from "./ProjectType";
import { Interactivity } from "./Interactivity";
import {
  ConfirmationBlock,
  ConfirmationHead,
  ConfirmationImg,
  ConfirmationSubHead,
  ModalContent,
  ModalFooterWrapper,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { SelectTemplate } from "./SelectTemplate";
import { Button } from "../../Button";

import { CustomInput } from "../../CustomInput";
import {
  requestAllPages,
  requestAllPublicPages,
  requestCreatePage,
  requestPageById,
  requestPageWidgets,
  requestUpdatePage,
  resetDraftSettings,
  resetPageSettings,
  setDraftPageSettings,
  setPageSettings,
  setPageWidgets,
} from "../../../store/slices/projectPages";
import { resetActiveModal } from "../../../store/slices/modals";
import { PageSettingsDTO } from "../../../models/Pages";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getCurrentProjectId,
  getDraftPageSettings,
  getPageSettings,
} from "../../../store/selectors/projects";
import {
  getActiveModal,
  getModalCreateOptions,
} from "../../../store/selectors/modals";
import { adjustArray } from "../../../helpers/ajustArray";
import { LAYOUTS } from "../../ProjectPageLayouts/config";
import { findFirstCommonElement } from "../../../helpers/firstCommonElement";
import { requestUpdateWidgets } from "../../../store/slices/widgets";
import { UpdateWidgetDTO } from "../../../models/Widgets";
import { defaultHeader } from "../../AddStorytellingSection";
import { Loader } from "../../Loader";
import { useNavigate } from "react-router-dom";
import {
  getIsPageTypeSelect,
  getIsPublicMode,
} from "../../../store/selectors/main";
import { extractBlockIds, LayoutI } from "../../../helpers/extractBlockIds";

interface ProjectSettingsModalProps {
  closeModal: () => void;
}

const defaultApparitionConfig = {
  textAlignment: "center",
  background: "",
  border: "",
  descriptionDisabled: false,
  insightsDisabled: false,
};
const defaultNewWidget = {
  apparitionConfig: defaultApparitionConfig,
  description: "Add here description",
  insights: "Add here insights",
};

export const ProjectSettingsModal = ({
  closeModal,
}: ProjectSettingsModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPublicRoute = useSelector(getIsPublicMode);
  const pageSettings = useSelector(getPageSettings);
  const pageDraftSettings = useSelector(getDraftPageSettings);
  const activeModal = useSelector(getActiveModal);
  const createNewProject = useSelector(getModalCreateOptions);
  const currentProjectId = useSelector(getCurrentProjectId);
  const widgets = useSelector(getCurrentPageWidgets);
  const isPageTypeSelect = useSelector(getIsPageTypeSelect);
  const modalOpen = activeModal.includes("projectSettingsModal");
  const settings = createNewProject ? pageDraftSettings : pageSettings;
  const [dashType] = useState<string>(settings.dashType);
  const aiKpi = useSelector(getCurrentPageKpi);

  const isComparison = dashType === "comparison";

  const resetPage = () => {
    dispatch(requestPageById({ id: settings.id! }));
    dispatch(requestPageWidgets({ pageId: settings.id!, includeData: true }));
  };

  const handleOnClose = () => {
    setIsClosing(true);
    // setIsLoading(false);
    pageSettings && dispatch(resetPageSettings());
    pageDraftSettings && dispatch(resetDraftSettings());
    if (settings.id) {
      resetPage();
    }
    setTimeout(() => {
      closeModal();
    }, 400);
  };

  const noLayout = settings.dashType === "dashboard" && !settings.templateId;

  const handleSettingsUpdate = (newSettings: PageSettingsDTO) => {
    const newPage = { ...newSettings, header: defaultHeader };
    dispatch(
      createNewProject
        ? setDraftPageSettings(newPage)
        : setPageSettings(newPage)
    );
    if (
      newSettings.templateId &&
      newSettings.templateId !== settings.templateId &&
      !createNewProject
    ) {
      const layout = LAYOUTS.find((l) => l.id === newSettings.templateId);
      let blocks: any = extractBlockIds(layout?.arranging as LayoutI);
      let restWidgets = [...widgets?.items];
      let mapedWidgets = [...widgets?.items];
      if (blocks.length > restWidgets?.length) {
        const existKpis = aiKpi?.count > 0 ? 1 : 0;
        blocks = adjustArray(blocks, restWidgets?.length + existKpis);
      }
      let chartTypes = restWidgets.map((r: any) => r.chartType);

      for (let i = 0; i < blocks?.length; i++) {
        const block = blocks[i];
        if (block.blockId === 1 && aiKpi.count > 0) {
          continue;
        }
        const chartType = findFirstCommonElement(chartTypes, block.widgets);
        const index = chartTypes.findIndex((c: string) => c === chartType);
        if (index !== -1) {
          chartTypes.splice(index, 1);
        }
        const chart = restWidgets?.find(
          (widget: any) => widget.chartType === chartType
        );
        if (chart) {
          const indexSuggestion = restWidgets?.findIndex(
            (widget: any) => widget.chartType === chartType
          );
          if (indexSuggestion !== -1) {
            restWidgets?.splice(indexSuggestion, 1);
          }
          mapedWidgets = mapedWidgets?.map((w) => {
            if (w.id === chart.id) {
              return { ...w, blockId: block.blockId?.toString() };
            }
            return w;
          });
        }
      }
      mapedWidgets.sort((a, b) => parseInt(a.blockId!) - parseInt(b.blockId!));
      dispatch(setPageWidgets({ count: widgets.count, items: mapedWidgets }));
    }
  };

  const handleEditPage = (
    widgetDescriptions?: {
      story: string;
      widget_id: string;
    }[]
  ) => {
    const { ...requestUpdatePageSettings } = pageSettings;
    let barChartRequestData: UpdateWidgetDTO[] = [];
    let lineChartRequestData: UpdateWidgetDTO[] = [];
    let lollipopChartRequestData: UpdateWidgetDTO[] = [];
    let sankeyChartRequestData: UpdateWidgetDTO[] = [];
    let mapChartRequestData: UpdateWidgetDTO[] = [];
    let areaChartRequestData: UpdateWidgetDTO[] = [];
    let matrixChartRequestData: UpdateWidgetDTO[] = [];
    let scatterplotChartRequestData: UpdateWidgetDTO[] = [];
    let radarChartRequestData: UpdateWidgetDTO[] = [];
    let punchcardChartRequestData: UpdateWidgetDTO[] = [];
    let polarAreaChartRequestData: UpdateWidgetDTO[] = [];
    let bubbleChartRequestData: UpdateWidgetDTO[] = [];

    for (let chart of widgets.items) {
      const descriptionData = widgetDescriptions?.find(
        (w) => w.widget_id === chart.id
      );
      let descriptionChartData = {
        ...defaultNewWidget,
        description: chart?.description || defaultNewWidget.description,
        insights: chart?.insights || defaultNewWidget.insights,
      };
      if (descriptionData) {
        descriptionChartData = {
          ...descriptionChartData,
          description:
            descriptionData.story || descriptionChartData.description,
        };
      }

      switch (chart?.chartType) {
        case "mapChart":
          mapChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "areaChart":
          areaChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "lineChart":
          lineChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "barChart":
          barChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "lollipopChart":
          lollipopChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "sankey":
          sankeyChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "matrix":
          matrixChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "polarAreaChart":
          polarAreaChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "punchcardChart":
          punchcardChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "radar":
          radarChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "scatterplot":
          scatterplotChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        case "bubbleChart":
          bubbleChartRequestData.push({
            id: chart.id,
            blockId: chart?.blockId!,
            ...descriptionChartData,
          });
          break;
        default:
      }
    }
    const existCharts =
      barChartRequestData?.length +
      lineChartRequestData?.length +
      lollipopChartRequestData?.length +
      sankeyChartRequestData?.length +
      areaChartRequestData?.length +
      matrixChartRequestData?.length +
      radarChartRequestData?.length +
      scatterplotChartRequestData?.length +
      polarAreaChartRequestData?.length +
      punchcardChartRequestData?.length +
      bubbleChartRequestData?.length;

    if (existCharts > 0) {
      dispatch(setPageWidgets({ items: [], count: 0 }));
      dispatch(
        requestUpdateWidgets({
          barChart: barChartRequestData,
          lineChart: lineChartRequestData,
          lollipopChart: lollipopChartRequestData,
          sankeyChart: sankeyChartRequestData,
          areaChart: areaChartRequestData,
          matrixChart: matrixChartRequestData,
          mapChart: mapChartRequestData,
          scatterplotChart: scatterplotChartRequestData,
          polarAreaChart: polarAreaChartRequestData,
          punchcardChart: punchcardChartRequestData,
          radarChart: radarChartRequestData,
          bubbleChart: bubbleChartRequestData,
          pageId: requestUpdatePageSettings.id!,
        })
      );
    }

    dispatch(setPageSettings(requestUpdatePageSettings));
    let header = requestUpdatePageSettings.header;
    if (!header) {
      header = defaultHeader;
    }
    dispatch(
      requestUpdatePage({
        ...requestUpdatePageSettings,
        name: requestUpdatePageSettings.name,
        header,
        callbacks: {
          onSuccess: () => {
            if (isPublicRoute) {
              dispatch(requestAllPublicPages({ projectId: currentProjectId! }));
            } else {
              dispatch(requestAllPages({ projectId: currentProjectId }));
            }
          },
        },
      })
    );
    handleOnClose();
  };

  const createPage = () => {
    if (createNewProject) {
      const { ...requestCreatePageSettings } = pageDraftSettings;
      const newPage = {
        ...requestCreatePageSettings,
        header: defaultHeader,
        projectId: currentProjectId,
        interactivity: ["tooltip"],
        navigate,
      };
      dispatch(requestCreatePage(newPage));
      dispatch(resetActiveModal());
    } else if (!createNewProject && pageSettings.id) {
      setIsLoading(true);
      handleEditPage();
    }
  };

  const handleChangePage = () => {
    createPage();
  };

  const handleCreatePage = () => {
    if (dashType === "storytelling" && settings.dashType !== "storytelling") {
      setConfirmation(true);
    } else {
      createPage();
    }
  };

  return (
    <ModalOverlay
      onClick={handleOnClose}
      $isClosing={isClosing}
      $centred
      $noBlur
    >
      <ModalWrapper
        onClick={(e) => e.stopPropagation()}
        $projectSettingsOpen={modalOpen}
        $isClosing={isClosing}
      >
        {isLoading && <Loader fullScreen />}
        <ModalHeadingWrapper>
          <Heading>
            {createNewProject
              ? `Create new ${dashType || "dashboard"}`
              : !confirmation && (
                  <>
                    <span>{dashType || "dashboard"}</span>
                    Configuration
                  </>
                )}
          </Heading>
          <CloseIcon
            onClick={() => {
              handleOnClose();
            }}
          />
        </ModalHeadingWrapper>
        <OverviewContent>
          {!confirmation ? (
            <ModalContent>
              <CustomInput
                name="Page name"
                type="text"
                value={settings.name}
                heading={`Name the ${dashType || "dashboard"}`}
                onChange={(e) => handleSettingsUpdate({ ...settings, name: e })}
              />
              {!isComparison && !isPageTypeSelect && (
                <ProjectType
                  settings={settings}
                  onUpdateSettings={handleSettingsUpdate}
                />
              )}
              {settings.dashType === "dashboard" && (
                <SelectTemplate
                  settings={settings}
                  onUpdateSettings={handleSettingsUpdate}
                />
              )}
              <Interactivity />
            </ModalContent>
          ) : (
            <ConfirmationBlock>
              <ConfirmationImg />
              <ConfirmationHead>
                Are you sure you want to change the view?
              </ConfirmationHead>
              <ConfirmationSubHead>
                You won’t be able to see any of the{" "}
                <b>
                  text <br /> and image{" "}
                </b>
                content related to this Storytelling.
              </ConfirmationSubHead>
            </ConfirmationBlock>
          )}
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            onClick={() => {
              if (settings.id) {
                resetPage();
              }

              if (confirmation) {
                setConfirmation(false);
              } else {
                setIsClosing(true);
              }
            }}
            variant="neutral"
            size="medium"
            name={confirmation ? "Cancel" : "Close"}
          />
          {!confirmation ? (
            <Button
              onClick={() => handleCreatePage()}
              icon={<CheckCircle />}
              variant={
                !!!settings.name?.trim().length ||
                !(!!settings.dashType || noLayout)
                  ? "disabled"
                  : "secondary"
              }
              size="medium"
              disabled={
                !!!settings.name?.trim().length ||
                !(!!settings.dashType || noLayout)
              }
              name={createNewProject ? "Confirm" : "Save changes"}
            />
          ) : (
            <Button
              onClick={() => handleChangePage()}
              icon={<CheckLg />}
              variant={"secondary"}
              size="medium"
              name={"Change View"}
            />
          )}
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
