import React from "react";
import { useDrop } from "react-dnd";
import {
  AcceptedTypes,
  AddIcon,
  AxeIcon,
  Container,
  DisplayIcon,
  DropArea,
  EmptySelectText,
  Lable,
  SelectHeading,
  SubLable,
} from "./style";
import { DnDBadge, IAcceptedTypes } from "./types";
import Badge from "../../Badge";
import { getIconSrc } from "../../../helpers/getIconType";

type Props = {
  onDrop: (item: DnDBadge) => void;
  onCancel?: (name: string) => void;
  selectedBadges?: string[];
  allBadges: DnDBadge[];
  lable: string;
  subLable: string;
  axe?: "X" | "Y";
  icon: "axe" | "add" | "display";
  acceptTypes: IAcceptedTypes[];
};

export const SelectDimensions: React.FC<Props> = ({
  onDrop,
  selectedBadges,
  axe,
  lable,
  subLable,
  acceptTypes,
  icon,
  allBadges,
  onCancel = () => {},
}: Props) => {
  const [{ isOver }, drop] = useDrop({
    accept: acceptTypes,
    drop: (item: DnDBadge) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const activeBadges = allBadges.filter((item) =>
    selectedBadges?.includes(item.key)
  );

  return (
    <Container>
      <SelectHeading>
        <AxeIcon $Axe={axe} />
        <AddIcon $Show={!axe && icon === "add"} />
        <DisplayIcon $Show={!axe && icon === "display"} />
        <Lable>{lable}</Lable>
        <SubLable>{subLable}</SubLable>
        <AcceptedTypes>
          {acceptTypes.map((item) => (
            <img
              src={getIconSrc({ type: item, subtype: "", state: "simple" })}
              alt=""
            />
          ))}
        </AcceptedTypes>
      </SelectHeading>
      <DropArea
        ref={drop}
        $isOver={isOver}
        $isEmpty={!Boolean(activeBadges?.length)}
      >
        {activeBadges && activeBadges?.length ? (
          activeBadges.map((item, idx) => (
            <Badge
              key={idx}
              name={item.name}
              type={item.type}
              keyName={item.key}
              subtype={item.subtype}
              selectedItems={[item.name]}
              icon={getIconSrc({
                type: item.type,
                subtype: item.subtype,
              })}
              onCancel={() => onCancel(item.key)}
              onClick={() => {}}
              customSelected
              cancelButton
              isDraggable
            />
          ))
        ) : (
          <EmptySelectText>Drop parameters here</EmptySelectText>
        )}
      </DropArea>
    </Container>
  );
};
