import React from "react";
import styled from "styled-components";

const getCircleColor = ({
  $isZero,
  $isActive,
  $isRead,
}: {
  $isZero: boolean;
  $isActive: boolean;
  $isRead: boolean;
}) => {
  if ($isRead) {
    return $isActive ? "#5F687729" : "#5F687714";
  }
  if ($isZero) {
    return $isActive ? "#003E5F" : "#2D6E8D";
  }
  return $isActive ? "#7C001B" : "#C53660";
};

const SVGWrapper = styled.svg<{ 
  $isZero: boolean; 
  $isActive: boolean;
  $isRead: boolean;
}>`
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
  &:hover {
    circle {
      fill: ${(props) => 
        props.$isRead 
          ? "#5F68771F"
          : props.$isZero 
            ? "#155676" 
            : "#A81945"};
    }
  }
  &:active {
    circle {
      fill: ${(props) => 
        props.$isRead 
          ? "#5F687729"
          : props.$isZero 
            ? "#003E5F" 
            : "#7C001B"};
    }
  }
  cursor: pointer;
  circle {
    fill: ${getCircleColor};
  }
`;

const formatNumber = (num: number): string => {
  if (num < 1000) {
    return new Intl.NumberFormat().format(num);
  }
  
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(num);
};

const getFontSize = (num: number): number => {
  if (num >= 1000) return 12;
  return 14;
};

interface TeardropIconProps {
  number?: number;
  className?: string;
  isActive?: boolean;
  isRead?: boolean;
}

export const CommentsIcon: React.FC<TeardropIconProps> = ({
  number = 0,
  className,
  isActive = false,
  isRead = false,
}) => {
  const formattedNumber = formatNumber(number);
  const fontSize = getFontSize(number);

  return (
    <SVGWrapper
      className={className}
      width="74"
      height="82"
      viewBox="0 0 74 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      $isZero={number === 0}
      $isActive={isActive}
      $isRead={isRead}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.9212 39.9998H16V23.9998L16.0001 24C16.0002 15.1634 23.1636 8 32.0001 8C40.8367 8 48.0001 15.1634 48.0001 24C48.0001 32.8366 40.8367 40 32.0001 40C31.9738 40 31.9475 39.9999 31.9212 39.9998Z"
        fill="white"
        stroke="#DFE1E4"
      />
      <circle cx="32" cy="24" r="12" />
      {number === 0 ? (
        <g transform="translate(24, 16)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 2C8.27614 2 8.5 2.22386 8.5 2.5V7.5H13.5C13.7761 7.5 14 7.72386 14 8C14 8.27614 13.7761 8.5 13.5 8.5H8.5V13.5C8.5 13.7761 8.27614 14 7.5 13.5V8.5H2.5C2.22386 8.5 2 8.27614 2 8C2 7.72386 2.22386 7.5 2.5 7.5H7.5V2.5C7.5 2.22386 7.72386 2 8 2Z"
            fill="white"
          />
        </g>
      ) : (
        <text
          x="32"
          y="23.5"
          fontSize={fontSize}
          fontWeight="normal"
          fill={isRead ? "black" : "white"}
          textAnchor="middle"
          alignmentBaseline="central"
          dominantBaseline="middle"
          style={{ userSelect: 'none' }}
        >
          {formattedNumber}
        </text>
      )}
    </SVGWrapper>
  );
};

export default CommentsIcon;