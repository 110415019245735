import React, { useEffect, useMemo, useState } from "react";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarSectionWrapper,
  ProfileSideBarHeading,
} from "./styles";
import { Briefcase, People, Person, Compass } from "react-bootstrap-icons";
import { AccountDto } from "../../models/Authentication";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { getUser } from "../../store/selectors/main";

const aboutSection = (fullName: string) => [
  {
    icon: <Person />,
    type: "Full Name",
    data: fullName,
  },
  {
    icon: <Briefcase />,
    type: "Job Position",
    data: "Business Analyst",
  },
  {
    icon: <People />,
    type: "Organization",
  },
  {
    icon: <Compass />,
    type: "Location",
  },
];

export const AboutSection = () => {
  const user = useSelector(getUser);
  const [account, setAccount] = useState<AccountDto | undefined>();

  useEffect(() => {
    if (user?.accessToken) {
      const account: AccountDto = jwtDecode(user?.accessToken);
      setAccount(account);
    }
  }, [user]);

  const fullName = useMemo(() => {
    const name = [account?.firstName, account?.lastName]?.join(" ")?.trim();
    if (name?.length) {
      return name;
    }
    if (account?.name) {
      return account?.name;
    }
    return "Not set";
  }, [account?.firstName, account?.lastName, account?.name]);

  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>About</ProfileSideBarHeading>
      <ProfileSideBarSectionWrapper>
        {aboutSection(fullName).map((section, idx) => (
          <ProfileSideBarAboutCardWrapper key={idx}>
            <ProfileSideBarAboutCardIcon>
              {section.icon}
            </ProfileSideBarAboutCardIcon>
            <ProfileSideBarAboutCardDetailsWrapper>
              <ProfileSideBarAboutCardTitle>
                {section.type}
              </ProfileSideBarAboutCardTitle>
              <ProfileSideBarAboutCardDescription $noData={!section.data}>
                {section.data ? section.data : "No data"}
              </ProfileSideBarAboutCardDescription>
            </ProfileSideBarAboutCardDetailsWrapper>
          </ProfileSideBarAboutCardWrapper>
        ))}
      </ProfileSideBarSectionWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
