import { styled } from "styled-components";

export const Legend = styled.div`
  position: relative;
  background: transparent;
  z-index: 100;
  padding:6px 2px;
`;

export const RangeInput = styled.input.attrs((props) => ({
  type: "range",
}))<{ $active: boolean }>`
  position: absolute;
  background: transparent;
  height: 0;
  width: 100%;
  -webkit-appearance :none;
  -moz-appearance :none;
  appearance :none;
  background: none;
  pointer-events: none;
  /* Firefox */
  &::-moz-range-track {
    width: 100%; 
    height: 0; 
    background: red;
    position: relative;
  }

  &::-moz-range-thumb {
    pointer-events: auto;
    height: 8px;
    width: 8px;
    border: 2px solid white;
    border-radius: 50%;
    background-color: black;
    cursor: pointer;
    position: relative;
    transition: 0.2s;

    ${({$active}) => $active && `
          background-color: white;
          border: 2px solid black;
    `}
    &:hover{
      background-color: white;
      border: 2px solid black;
    }
    &:active{
      background-color: white;
      border: 2px solid black;
    }
  }

  /* WebKit (Chrome, Safari) */
  &::-webkit-slider-runnable-track {
    width: 100%; 
    height: 0px; 
    cursor: pointer;
    background: transparent;
    position: relative;
    z-index: 1;
  }

  &::-webkit-slider-thumb{
    pointer-events: auto;

    position: relative;
    top:-6px;
    height: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: black;
    box-shadow: 0 0 0 2px inset white;
    -webkit-appearance: none;
    appearance: none;
    ${({$active}) => $active && `
          background-color: white;
          border: 2px solid black;
    `}
    &:hover{
      background-color: white;
      border: 2px solid black;
    }
    &:active{
      background-color: white;
      border: 2px solid black;
    }
  }

  /* Microsoft Edge and Internet Explorer */
  &::-ms-track {
    width: 100%;
    height: 5px; 
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-thumb {
    pointer-events: auto;
    height: 8px; 
    width: 8px; 
    border: 2px solid white;
    border-radius: 50%;
    background-color: black;
    cursor: pointer;

    ${({$active}) => $active && `
          background-color: white;
          border: 2px solid black;
    `}
    &:hover{
      background-color: white;
      border: 2px solid black;
    }
    &:active{
      background-color: white;
      border: 2px solid black;
    }
  }

  &::-ms-fill-lower {
    background: transparent;
  }

  &::-ms-fill-upper {
    background: transparent;
  }
`;

export const RangeLine = styled.input.attrs((props) => ({
  type: 'range',
}))`
  position: absolute;
  background: transparent;
  height: 0;
  width: 100%;
  -webkit-appearance :none;
  -moz-appearance :none;
  appearance :none;
  background: none;
  cursor: pointer;
  
  /* Firefox */
  &::-moz-range-thumb {
    height: 80px;
    width: 8px;
    border: 2px dashed transparent;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
    position: relative;

    background-image: linear-gradient(
    to top,
    rgba(128, 128, 128, 0) 0%,
    rgba(128, 128, 128, 0) 5%,
    rgba(128, 128, 128, 1) 5%,
    rgba(128, 128, 128, 1) 10%,
    rgba(128, 128, 128, 0) 10%,
    rgba(128, 128, 128, 0) 15%,
    rgba(128, 128, 128, 1) 15%,
    rgba(128, 128, 128, 1) 20%,
    rgba(128, 128, 128, 0) 20%,
    rgba(128, 128, 128, 0) 25%,
    rgba(128, 128, 128, 1) 25%,
    rgba(128, 128, 128, 1) 30%,
    rgba(128, 128, 128, 0) 30%,
    rgba(128, 128, 128, 0) 35%,
    rgba(128, 128, 128, 1) 35%,
    rgba(128, 128, 128, 0.9) 40%,
    rgba(128, 128, 128, 0) 40%,
    rgba(128, 128, 128, 0) 45%,
    rgba(128, 128, 128, 0.9) 45%,
    rgba(128, 128, 128, 0.7) 50%,
    rgba(128, 128, 128, 0) 50%,
    rgba(128, 128, 128, 0) 55%,
    rgba(128, 128, 128, 0.7) 55%,
    rgba(128, 128, 128, 0.5) 60%,
    rgba(128, 128, 128, 0) 60%,
    rgba(128, 128, 128, 0) 65%,
    rgba(128, 128, 128, 0.5) 65%,
    rgba(128, 128, 128, 0.3) 70%,
    rgba(128, 128, 128, 0) 70%,
    rgba(128, 128, 128, 0) 75%,
    rgba(128, 128, 128, 0.3) 75%,
    rgba(128, 128, 128, 0.1) 80%,
    rgba(128, 128, 128, 0) 80%,
    rgba(128, 128, 128, 0) 85%,
    rgba(128, 128, 128, 0.1) 85%,
    rgba(128, 128, 128, 0.05) 90%,
    rgba(128, 128, 128, 0) 90%,
    rgba(128, 128, 128, 0) 95%,
    rgba(128, 128, 128, 0.05) 95%,
    rgba(128, 128, 128, 0.01) 100%
);


    background-size: 2px 100px;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  /* WebKit (Chrome, Safari) */
  &::-webkit-slider-runnable-track {
    width: 100%; 
    height: 0px; 
    cursor: pointer;
    background: transparent;
    position: relative;
    z-index: 1;
  }

  &::-webkit-slider-thumb {
  appearance: none; /* Removes the default WebKit appearance */
  height: 80px;
  width: 8px;
  border: 2px dashed transparent;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  background-image: linear-gradient(
    to top,
    rgba(128, 128, 128, 0) 0%,
    rgba(128, 128, 128, 0) 5%,
    rgba(128, 128, 128, 1) 5%,
    rgba(128, 128, 128, 1) 10%,
    rgba(128, 128, 128, 0) 10%,
    rgba(128, 128, 128, 0) 15%,
    rgba(128, 128, 128, 1) 15%,
    rgba(128, 128, 128, 1) 20%,
    rgba(128, 128, 128, 0) 20%,
    rgba(128, 128, 128, 0) 25%,
    rgba(128, 128, 128, 1) 25%,
    rgba(128, 128, 128, 1) 30%,
    rgba(128, 128, 128, 0) 30%,
    rgba(128, 128, 128, 0) 35%,
    rgba(128, 128, 128, 1) 35%,
    rgba(128, 128, 128, 0.9) 40%,
    rgba(128, 128, 128, 0) 40%,
    rgba(128, 128, 128, 0) 45%,
    rgba(128, 128, 128, 0.9) 45%,
    rgba(128, 128, 128, 0.7) 50%,
    rgba(128, 128, 128, 0) 50%,
    rgba(128, 128, 128, 0) 55%,
    rgba(128, 128, 128, 0.7) 55%,
    rgba(128, 128, 128, 0.5) 60%,
    rgba(128, 128, 128, 0) 60%,
    rgba(128, 128, 128, 0) 65%,
    rgba(128, 128, 128, 0.5) 65%,
    rgba(128, 128, 128, 0.3) 70%,
    rgba(128, 128, 128, 0) 70%,
    rgba(128, 128, 128, 0) 75%,
    rgba(128, 128, 128, 0.3) 75%,
    rgba(128, 128, 128, 0.1) 80%,
    rgba(128, 128, 128, 0) 80%,
    rgba(128, 128, 128, 0) 85%,
    rgba(128, 128, 128, 0.1) 85%,
    rgba(128, 128, 128, 0.05) 90%,
    rgba(128, 128, 128, 0) 90%,
    rgba(128, 128, 128, 0) 95%,
    rgba(128, 128, 128, 0.05) 95%,
    rgba(128, 128, 128, 0.01) 100%
  );

  background-size: 2px 100px;
  background-position: center bottom;
  background-repeat: no-repeat;

}


  /* Microsoft Edge and Internet Explorer */
  &::-ms-track {
    width: 100%;
    height: 5px; 
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-thumb {
    height: 8px; 
    width: 8px; 
    border: 2px solid white;
    border-radius: 50%;
    background-color: black;
    cursor: pointer;
  }

  &::-ms-fill-lower {
    background: transparent;
  }

  &::-ms-fill-upper {
    background: transparent;
  }
`;