import React, { useRef, useState } from "react";
import {
  ContentWrapper,
  OptionsMenuItem,
  OptionsMenuItemsWrapper,
  OptionsMenuWrapper,
  OptionsWrapper,
} from "./styles";
import { SectionMenuArrowButton } from "../../../../../styles";
import { useOutsideClick } from "../../../../../../../hooks/useOutsideClick";
import { ImagePicker } from "../../../../../../ImagePicker";

interface StorytellingOptionsMenuInterface {
  fileUrl: string;
  setFile: any;
}
export const StorytellingImagePicker = ({
  fileUrl,
  setFile,
}: StorytellingOptionsMenuInterface) => {
  const [activeTab, setActiveTab] = useState<"image">( "image");
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setOptionsMenuOpen(false);
  });

  return (
    <>
      <div ref={ref}>
        <SectionMenuArrowButton
          $active={optionsMenuOpen}
          $isSection={true}
          onClick={() => {
            setOptionsMenuOpen(!optionsMenuOpen);
          }}
        />
        {optionsMenuOpen && (
          <OptionsWrapper $isSection={true}>
            {true && (
              <OptionsMenuWrapper>
                <OptionsMenuItemsWrapper>
                  <OptionsMenuItem
                    onClick={() => setActiveTab("image")}
                    $activeItem={activeTab === "image"}
                    $width={45}
                  >
                    Image
                  </OptionsMenuItem>
                </OptionsMenuItemsWrapper>
              </OptionsMenuWrapper>
            )}
            <ContentWrapper $isSection={true}>
              {activeTab === "image" && (
                <ImagePicker
                  image={fileUrl!}
                  isSection={true}
                  callback={(newImage) => {
                    setFile(newImage);
                  }}
                />
              )}
            </ContentWrapper>
          </OptionsWrapper>
        )}
      </div>
    </>
  );
};
