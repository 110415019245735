import { createSelector } from "reselect";
import { StoreInterface } from "../../models";

const selectCommentsSlice = (state: StoreInterface) => state.comments;

export const getAllComments = createSelector(
  selectCommentsSlice,
  (commentsSlice) => commentsSlice?.comments
);

export const getSelectedWidgetId = createSelector(
  selectCommentsSlice,
  (commentsSlice) => commentsSlice?.selectedWidgetId
);

export const getCustomeCommentsCursor = createSelector(
  selectCommentsSlice,
  (commentsSlice) => commentsSlice?.isCustomCommentsCursor
);

export const getOpenComments = createSelector(
  selectCommentsSlice,
  (commentsSlice) => commentsSlice?.isOpenComments
);

export const getComments = createSelector(
  selectCommentsSlice,
  (commentsSlice) => commentsSlice?.comments
);

export const getGroupedComments = createSelector(
  selectCommentsSlice,
  (commentsSlice) => commentsSlice?.groupedComments
);

export const getTotalCount = createSelector(
  selectCommentsSlice,
  (commentsSlice) => commentsSlice?.totalCount
);

export const getLoading = createSelector(
  selectCommentsSlice,
  (commentsSlice) => commentsSlice?.loading
);

export const getError = createSelector(
  selectCommentsSlice,
  (commentsSlice) => commentsSlice?.error
);

export const countUnreadComments = createSelector(
  selectCommentsSlice,
  (commentsSlice) => 
  commentsSlice.comments.filter(comment => !comment.isRead).length
)
