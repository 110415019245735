export const lightTheme = {
  // Neutral
  neutralBackgroundBase: "#FFFFFF",
  neutralBackgroundMedium: "#EFF0F1",
  neutralBackgroundExtraDark: "#141C2C",
  neutralBackground: "rgba(95, 104, 119, 0.08)",
  neutralBackgroundHover: "rgba(95, 104, 119, 0.12)",
  neutralBackgroundActive: "rgba(95, 104, 119, 0.16)",

  neutralText: "#000005",
  neutralTextWeak: "#5F6877",
  neutralTextDisabled: "#9FA4AD",

  neutralBorder: "#DFE1E4",
  neutralBorderMedium: "#9FA4AD",

  neutralScrim: "rgba(0, 0, 5, 0.1)",

  // Primary
  primaryAction: "#00143F",
  primaryActionHover: "#000631",

  primaryBackground: "#2D6E8D1F",
  primaryBackgroundMedium: "#2D6E8D33",
  primaryBackgroundActive: "#2D6E8D3D",
  primaryBackgroundStrong: "#2D6E8D",
  primaryBackgroundStrongHover: "#155676",
  primaryBackgroundStrongActive: "#003E5F",

  primaryText: "#155676",
  primaryTextHover: "#003E5F",
  primaryTextStrong: "#FFFFFF",
  primaryTextStrongHover: "#81A8BB",
  primaryTextStrongActive: "#578BA4",
  secondaryTextUser: "#0b101a",

  primaryBorder: "#2D6E8D",

  // Alert
  alertBackground: "#C536601F",
  alertBackgroundMedium: "#C5366033",
  alertBackgroundActive: "#C536603D",
  alertBackgroundStrong: "#C53660",
  alertBackgroundStrongHover: "#C53660",
  alertBackgroundStrongActive: "#7C001B",

  alertText: "#8F1A3D",
  alertTextHover: "#7C001B",

  alertBorder: "#C53660",

  // Warning
  warningBackground: "#B35F001F",
  warningBackgroundMedium: "#B35F0033",
  warningBackgroundActive: "#B35F003D",
  warningBackgroundStrong: "#B35F00",
  warningBackgroundStrongHover: "#913D00",
  warningBackgroundStrongActive: "#802C00",

  warningText: "#913D00",
  warningTextHover: "#802C00",

  warningBorder: "#B35F00",

  // Success
  successBackground: "#1386601F",
  successBackgroundMedium: "#13866033",
  successBackgroundActive: "#1386603D",
  successBackgroundStrong: "#138660",
  successBackgroundStrongHover: "#00623E",
  successBackgroundStrongActive: "#004A27",

  successText: "#00623E",
  successTextHover: "#004A27",

  successBorder: "#138660",

  //PREVENT ERRORS TEMPORARY VALUES
  primaryBackgroundPublish: "rgba(84, 72, 255, 0.06)",
  primaryBackgroundHover: "rgba(84, 72, 255, 0.16)",
  secondaryBackground: "rgba(0, 20, 63, 1)",
  stratosBackground: "rgba(0, 6, 49, 1)",
  mediumBorder: "rgba(147, 155, 167, 1)",
  colorGray500: "rgb(223, 225, 228)",
  primaryButton: "#2D6E8D1F",
  secondaryButton: "#00143F",
  secondaryButtonHover: "#000631",
  secondaryButtonActive: "#000631",
  neutralButton: "rgba(95, 104, 119, 0.08)",
  neutralButtonColored: "rgba(230, 233, 238)",
  neutralButtonColoredHover: "rgba(226, 230, 234)",
  secondaryText: "rgba(255, 255, 255, 1)",
  disabledText: "rgba(147, 155, 167, 1)",
  neutralWeak: "rgba(95, 104, 119, 1)",
  primaryTextHoverStrong: "rgba(55, 46, 165, 1)",
  malibuText: "rgba(131, 145, 255, 1)",
  boxShadowColor: "rgba(99, 99, 99, 0.2) 0 0 3px 0",
  primaryBorderColor: "rgba(211, 219, 227, 1)",
};
