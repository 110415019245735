import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PdfState {
  documentName: string;
  pdfGenerationTrigger: boolean;
  pdfGenerationProgress: number;
}

const initialState: PdfState = {
  documentName: 'Report 1',
  pdfGenerationTrigger: false,
  pdfGenerationProgress: 0,
};

const pdfSlice = createSlice({
  name: 'pdfSlice',
  initialState,
  reducers: {
    updateDocumentName: (state, action: PayloadAction<string>) => {
      state.documentName = action.payload;
    },
    triggerPDFGeneration: (state) => {
      state.pdfGenerationTrigger = true;
    },
    resetPDFGenerationTrigger: (state) => {
      state.pdfGenerationTrigger = false;
    },
    setPDFGenerationProgress: (state, action: PayloadAction<number>) => {
      state.pdfGenerationProgress = action.payload;
    }
  },
});

export const { updateDocumentName, triggerPDFGeneration, resetPDFGenerationTrigger, setPDFGenerationProgress } = pdfSlice.actions;
export default pdfSlice.reducer;
