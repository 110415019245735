import styled from "styled-components";

export const CheckboxWrapper = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckboxLabel = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralText};
  margin-left: 8px;
`;

export const KpiSettingItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

export const KpiSettingItemName = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputField = styled.input`
  width: 100%;
  border-style: none;
  padding: 5px;
  border-radius: 10px;
  outline: none;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  position: relative;
  background: none;
  cursor: pointer;
  border: ${({ theme }) => `1px solid ${theme.neutralBorder}`};
  margin-left: 8px;
  min-width: 220px;

  &:focus {
    color: ${({ theme }) => theme.neutralText};
  }
`;

export const EditButton = styled.button`
  margin-left: 4px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Container = styled.div`
  display: block;
  position: relative;
  padding-left: 17px;
  width: 17px;
  height: 17px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  height: 17px;
  width: 17px;
`;

export const Checkmark = styled.span`
  position: absolute;
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 4px;
  background-color: #f3f3f4;
  border: 1px solid #d3dbe3;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const Checkbox = styled.div<{
  $error?: boolean;
  $indeterminate?: boolean;
}>`
  width: 17px;
  height: 17px;

  ${({ $error }) =>
    $error
      ? `
      ${Input} ~ ${Checkmark} {
        background: #C536601F;
        border: none;
      }
    `
      : ""}

  ${Input}:disabled ~ ${Checkmark} {
    border: none;
  }

  ${Input}:checked ~ ${Checkmark} {
    background-color: #00143f;
    border: none;
    &::after {
      display: block;
    }
  }

  ${Input}:checked:hover ~ ${Checkmark} {
    background-color: #000631;
    border: none;
    &::after {
      border-color: #8391ff;
    }
  }

  ${Input}:checked:active ~ ${Checkmark} {
    &::after {
      border-color: #6562ff;
    }
  }

  ${Input}:not(:checked):not(:disabled):hover ~ ${Checkmark}:after {
    ${({ $indeterminate }) =>
      $indeterminate
        ? `
        display: block;
        border-color: #8391FF;
        right: 27%;
        top: 44%;
        width: 6px;
      `
        : `
        display: block;
        border-color: #8391FF;
        right: 5px;
        top:2px;
        width: 4px;
        height: 7px;
      `}
  }

  ${Input}:not(:checked):not(:disabled):active ~ ${Checkmark}:after {
    border-color: #6562ff;
  }

  ${Checkmark}:after {
    ${({ $indeterminate }) =>
      $indeterminate
        ? `
      content: "";
      right: 26%;
      top: 45%;
      width: 7px;
      border-radius: 25px;
      border: solid white;
      border-width: 0 1px 2px 0;
    `
        : `
      content: "";
      right: 6px;
      top: 3px;
      width: 4px;
      height: 7px;
      border: solid white;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(38deg);
      -ms-transform: rotate(38deg);
      transform: rotate(38deg);
    `}
  }
`;
