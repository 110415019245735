import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Comment,
  CommentQueryParams,
  CreateCommentRequest,
  GroupedComment,
  GroupedCommentQueryParams,
  UpdateCommentsRequest,
} from "../../types/comments";

// Add interface for edit comment request
interface EditCommentRequest {
  id: string;
  content: string;
}

export interface CommentsState {
  selectedWidgetId: string | null;
  isCustomCommentsCursor: boolean;
  isOpenComments: boolean;
  comments: Comment[];
  groupedComments: GroupedComment[];

  totalCount: number;
  loading: boolean;
  error: string | null;
}

const initialState: CommentsState = {
  comments: [],
  groupedComments: [],
  totalCount: 0,
  loading: false,
  error: null,
  selectedWidgetId: null,
  isCustomCommentsCursor: false,
  isOpenComments: false,
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    requestFetchComments: (
      state,
      action: PayloadAction<CommentQueryParams>
    ) => {
      state.loading = true;
      state.error = null;
    },
    setComments: (
      state,
      action: PayloadAction<{ items: Comment[]; count: number }>
    ) => {
      state.comments = action.payload?.items ?? [];
      state.totalCount = action.payload?.count ?? 0;
      state.loading = false;
      state.error = null;
    },
    requestAddComment: (state, action: PayloadAction<CreateCommentRequest>) => {
      state.loading = true;
      state.error = null;
    },
    addCommentSuccess: (state, action: PayloadAction<Comment>) => {
      const index = state.groupedComments.findIndex(
        (item) => item.widgetId === action.payload.widgetId
      );
      if (index >= 0) {
        state.groupedComments[index].comments.push(action.payload);
      } else {
        state.groupedComments.push({
          widgetId: action.payload.widgetId,
          id: action.payload.id,
          comments: [action.payload],
        });
      }
      state.loading = false;
      state.error = null;
      state.totalCount += 1;
    },
    requestEditComment: (state, action: PayloadAction<EditCommentRequest>) => {
      state.loading = true;
      state.error = null;
    },
    editCommentSuccess: (state, action: PayloadAction<Comment>) => {
      state.comments = state.comments.map((comment) => ({
        ...comment,
        content:
          comment.id === action.payload.id
            ? action.payload.content
            : comment.content,
      }));

      state.groupedComments = state.groupedComments.map((group) => ({
        ...group,
        comments: group.comments.map((comment) => ({
          ...comment,
          content:
            comment.id === action.payload.id
              ? action.payload.content
              : comment.content,
        })),
      }));

      state.loading = false;
      state.error = null;
    },
    requestReadComments: (
      state,
      action: PayloadAction<UpdateCommentsRequest>
    ) => {
      state.loading = true;
      state.error = null;
    },
    readCommentsSuccess: (
      state,
      action: PayloadAction<UpdateCommentsRequest>
    ) => {
      const { ids, isRead } = action.payload;
      if (ids?.length) {
        state.comments = state.comments.map((comment) =>
          ids.includes(comment.id)
            ? { ...comment, isRead: isRead ?? false }
            : comment
        );
        state.groupedComments = state.groupedComments.map((group) => ({
          ...group,
          comments: group.comments.map((comment) =>
            ids.includes(comment.id)
              ? { ...comment, isRead: isRead ?? false }
              : comment
          ),
        }));
      } else {
        state.comments = state.comments.map((comment) => ({
          ...comment,
          isRead: isRead ?? false,
        }));
        state.groupedComments = state.groupedComments.map((group) => ({
          ...group,
          comments: group.comments.map((comment) => ({
            ...comment,
            isRead: isRead ?? false,
          })),
        }));
      }
      state.loading = false;
      state.error = null;
    },
    requestHideComments: (
      state,
      action: PayloadAction<UpdateCommentsRequest>
    ) => {
      state.loading = true;
      state.error = null;
    },
    hideCommentsSuccess: (
      state,
      action: PayloadAction<UpdateCommentsRequest>
    ) => {
      const { ids, isHidden } = action.payload;
      state.comments = state.comments.map((comment) =>
        ids?.includes(comment.id) ? { ...comment, isHidden } : comment
      );
      state.groupedComments = state.groupedComments.map((group) => ({
        ...group,
        comments: group.comments.map((comment) =>
          ids?.includes(comment.id) ? { ...comment, isHidden } : comment
        ),
      }));
      state.loading = false;
      state.error = null;
    },
    requestFetchGroupedComments: (
      state,
      action: PayloadAction<GroupedCommentQueryParams>
    ) => {
      state.loading = true;
      state.error = null;
    },
    setGroupedComments: (state, action: PayloadAction<GroupedComment[]>) => {
      state.groupedComments = action.payload;
      state.loading = false;
      state.error = null;
    },
    requestResolveComments: (
      state,
      action: PayloadAction<{
        ids?: string[];
        projectId?: string;
        isResolved: boolean;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    resolveCommentsSuccess: (
      state,
      action: PayloadAction<{ ids: string[]; isResolved: boolean }>
    ) => {
      const { ids, isResolved } = action.payload;
      state.comments = state.comments.map((comment) =>
        ids.includes(comment.id)
          ? { ...comment, isResolved: isResolved ?? true }
          : comment
      );
      state.groupedComments = state.groupedComments.map((group) => ({
        ...group,
        comments: group.comments.map((comment) =>
          ids.includes(comment.id)
            ? { ...comment, isResolved: isResolved ?? true }
            : comment
        ),
      }));
      state.loading = false;
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetComments: () => initialState,
    setSelectWidgetId: (state, action: PayloadAction<string>) => {
      state.selectedWidgetId = action.payload;
    },
    clearSelectCommentsId: (state) => {
      state.selectedWidgetId = null;
    },
    toggleCommentsCursor: (state, action: PayloadAction<boolean>) => {
      state.isCustomCommentsCursor = action.payload;
    },
    setOpenComments: (state) => {
      state.isOpenComments = !state.isOpenComments;
    },
  },
});

export const {
  setSelectWidgetId,
  clearSelectCommentsId,
  toggleCommentsCursor,
  setOpenComments,
  requestFetchComments,
  setComments,
  requestAddComment,
  addCommentSuccess,
  requestEditComment, // Export new edit actions
  editCommentSuccess,
  requestReadComments,
  readCommentsSuccess,
  requestHideComments,
  hideCommentsSuccess,
  requestFetchGroupedComments,
  setGroupedComments,
  requestResolveComments,
  resolveCommentsSuccess,
  setError,
  resetComments,
} = commentsSlice.actions;

export default commentsSlice.reducer;
