import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Check, ChevronLeft } from "react-bootstrap-icons";

import {
  ModalFooterWrapper,
  ModalContent,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
  CloseIcon,
  ModalFooterButtons,
  Steps,
  CreateProjectWrapper,
  ModalHeadingRow,
  ModalSubHeading,
  Separator,
} from "../styles";

import { Button } from "../../Button";
import {
  requestCreateProject,
  requestUpdateProjectById,
} from "../../../store/slices/projects";
import { resetActiveModal } from "../../../store/slices/modals";

import {
  removeAllSelectedFiles,
  resetNewProjectSelectedFile,
  setNewProjectDetails,
  setNewProjectSelectedFiles,
} from "../../../store/slices/datasets";
import {
  getDraftProjectDetails,
  getNewProjectSelectedDatasets,
  getDatasetContents,
} from "../../../store/selectors/datasets";
import { getModalProjectId } from "../../../store/selectors/modals";
import { getCurrentProjectData } from "../../../store/selectors/projects";
import { FileLibraryItem } from "../../../models/Files";
import StepDot from "../../Inputs/CustomStepDot/StepDot";
import { Search } from "../../SearchBar";
import { DatasetsItemsWrapper } from "./styles";
import { DatasetsItem } from "../../DatasetsItem";
import { AddDatasetsContainer } from "../../AddDatasetsContainer";
import { ProjectDetails } from "../../NewProject/ProjectDetails";
import FirstStep from "./CreateProjectSteps/FirstStep/FirstStep";
import SecondStep from "./CreateProjectSteps/SecondStep/SecondStep";
import ThirdStep from "./CreateProjectSteps/ThirdStep/ThirdStep";
import { setCurrentProjectData } from "../../../store/slices/projectPages";
import PreviewStep from "./CreateProjectSteps/PreviewStep/PreviewStep";
import { useDebounceCallback } from "usehooks-ts";

interface ModalProps {
  onClose: () => void;
}

export const CreateProject = ({ onClose }: ModalProps) => {
  const datasetContents = useSelector(getDatasetContents);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const newProjectDetails = useSelector(getDraftProjectDetails);
  const newProjectSelectedDatasets = useSelector(getNewProjectSelectedDatasets);
  const projectId = useSelector(getModalProjectId);
  const curent = useSelector(getCurrentProjectData);
  const setName = (name: string) => {
    dispatch(
      setNewProjectDetails({ name, description: newProjectDetails?.description })
    );
  };

  const setDescription = (description: string) => {
    dispatch(
      setNewProjectDetails({ name: newProjectDetails?.name, description })
    );
  };

  const [activeStep, setActiveStep] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();
  const debounced = useDebounceCallback(setSearch, 500);

  const goToPreview = () => {
    setSearch(undefined);
    setActiveStep(4);
  };

  const handleCreate = () => {
    setSearch(undefined);
    if (projectId) {
      dispatch(
        requestUpdateProjectById({
          id: projectId,
          name: newProjectDetails?.name,
          description: newProjectDetails?.description,
        })
      );
      dispatch(
        setCurrentProjectData({
          ...curent,
          name: newProjectDetails.name,
          description: newProjectDetails.description,
        })
      );
      dispatch(resetActiveModal());
      dispatch(resetNewProjectSelectedFile());
      handleOnClose();
    }
    if (activeStep < 3) {
      setActiveStep((prev) => prev + 1);
    } else {
      const newProjectData = {
        name: newProjectDetails.name,
        description: newProjectDetails.description,
        datasets: [
          {
            id: process.env.REACT_APP_DATA_SET_ID!,
            filePath: datasetContents.filePath,
            name: datasetContents.name,
            fields: datasetContents.fields,
          },
        ],
        pages: [],
        population: populationsSelected,
        measure: measureSelected,
      };
      dispatch(requestCreateProject({ ...newProjectData, navigate }));
      dispatch(resetActiveModal());
      dispatch(resetNewProjectSelectedFile());
      handleOnClose();
    }
  };

  // const handleRemove = () => {
  //   if (projectId) {
  //     dispatch(requestRemoveProjectById(projectId));
  //     dispatch(resetActiveModal());
  //     dispatch(resetNewProjectSelectedFile());
  //     navigate("/projects");
  //   }
  // };

  const handleOnClose = () => {
    setIsClosing(true);
    dispatch(resetNewProjectSelectedFile());
    dispatch(removeAllSelectedFiles());
    setTimeout(() => {
      onClose();
    }, 400);
  };

  useEffect(() => {
    if (projectId) {
      dispatch(
        setNewProjectDetails({
          name: curent?.name,
          description: curent?.description,
        })
      );
      dispatch(
        setNewProjectSelectedFiles(
          curent.datasets.map((item) => {
            return {
              name: item?.name,
              filePath: item?.filePath,
              fields: item?.fields,
            };
          }) as FileLibraryItem[]
        )
      );
    }
  }, [curent, dispatch, projectId]);

  const [populationsSelected, setPopulationsSelected] = useState<string>();
  const [measureSelected, setMeasureSelected] = useState<string>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const checkIfDisabled = (step: number) => {
    switch (step) {
      case 1:
        return setDisabled(!(
          newProjectDetails?.name?.trim()?.length && newProjectDetails.description.trim().length
        ))
      case 2:
        return setDisabled(!populationsSelected);

      case 3:
        return setDisabled(!measureSelected);

      case 4:
        return setDisabled(!measureSelected);

      default:
        return setDisabled(true);
    }
  };

  useEffect(() => {
    checkIfDisabled(activeStep)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, newProjectDetails, populationsSelected, measureSelected])

  return (
    <ModalOverlay
      $centred
      onClick={handleOnClose}
      $isClosing={activeStep !== 4 ? isClosing : false}
    >
      <ModalWrapper
        $centred
        onClick={(e) => e.stopPropagation()}
        $isClosing={activeStep !== 4 ? isClosing : false}
        $fileOverviewOpen
        style={{
          height: activeStep === 4 ? "100%" : "auto",
          maxWidth: activeStep === 4 ? "98%" : "min-content",
          width: activeStep === 4 ? "98%" : "min-content",
        }}
      >
        {activeStep !== 4 ? (
          <ModalHeadingWrapper>
            {projectId ? (
              <>
                <ModalHeadingRow>
                  <ModalHeading>Edit project</ModalHeading>
                </ModalHeadingRow>
                <ModalHeadingRow>
                  <CloseIcon onClick={handleOnClose} />
                </ModalHeadingRow>
              </>
            ) : (
              <>
                {activeStep === 1 && (
                  <ModalHeading>Create project</ModalHeading>
                )}
                {activeStep === 2 && (
                  <>
                    <ModalHeadingRow>
                      <ChevronLeft
                        style={{ cursor: "pointer", marginRight: "8px" }}
                        onClick={() => {
                          setSearch(undefined);
                          setActiveStep((prev) => prev - 1);
                        }}
                      />
                      <ModalHeading>Select Population</ModalHeading>
                    </ModalHeadingRow>
                    <ModalHeadingRow>
                      <Search width="xs" onChange={debounced} />
                      <CloseIcon onClick={handleOnClose} />
                    </ModalHeadingRow>
                  </>
                )}
                {activeStep === 3 && (
                  <>
                    <ModalHeadingRow>
                      <ChevronLeft
                        style={{ cursor: "pointer", marginRight: "8px" }}
                        onClick={() => {
                          setSearch(undefined);
                          setActiveStep((prev) => prev - 1);
                        }}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <ModalHeading>Select Measure</ModalHeading>
                        <ModalSubHeading>
                          Population <Separator /> {populationsSelected}
                        </ModalSubHeading>
                      </div>
                    </ModalHeadingRow>
                    <ModalHeadingRow>
                      <Search width="xs" onChange={debounced} />
                      <CloseIcon onClick={handleOnClose} />
                    </ModalHeadingRow>
                  </>
                )}
              </>
            )}
          </ModalHeadingWrapper>
        ) : null}
        {projectId ? (
          <OverviewContent>
            <ModalContent>
              <ProjectDetails
                name={newProjectDetails?.name || "-"}
                description={newProjectDetails?.description || "-"}
                setName={setName}
                setDescription={setDescription}
              />

              <AddDatasetsContainer
                name={curent.population || "-"}
                description={curent.measure || "-"}
                disabledButton={!!projectId}
              />
              <DatasetsItemsWrapper>
                {newProjectSelectedDatasets?.map((dataset, index) => (
                  <DatasetsItem
                    dataset={dataset}
                    key={index}
                    filePath={dataset.filePath}
                    hideRemove={!!projectId}
                  />
                ))}
              </DatasetsItemsWrapper>
            </ModalContent>
          </OverviewContent>
        ) : (
          <CreateProjectWrapper>
            {activeStep === 1 && (
              <FirstStep
                name={newProjectDetails.name}
                description={newProjectDetails.description}
                setName={setName}
                setDescription={setDescription}
              />
            )}
            {activeStep === 2 && (
              <SecondStep
                search={search}
                populationSelected={populationsSelected}
                setPopulationsSet={setPopulationsSelected}
              />
            )}
            {activeStep === 3 && (
              <ThirdStep
                search={search}
                populationSelected={populationsSelected!}
                measureSelected={measureSelected}
                setMeasuresSet={setMeasureSelected}
                goToPreview={goToPreview}
              ></ThirdStep>
            )}
            {activeStep === 4 && (
              <PreviewStep
                populationSelected={populationsSelected!}
                measureSelected={measureSelected}
                setMeasuresSet={setMeasureSelected}
                handleCreate={handleCreate}
                handleOnClose={handleOnClose}
                setActiveStep={setActiveStep}
              ></PreviewStep>
            )}
          </CreateProjectWrapper>
        )}
        {activeStep !== 4 ? (
          <ModalFooterWrapper>
            <Button
              name="Cancel"
              onClick={handleOnClose}
              variant="neutral"
              size="medium"
            />
            {!projectId && (
              <Steps>
                <StepDot active={activeStep === 1} finished={activeStep > 1} />
                <StepDot active={activeStep === 2} finished={activeStep > 2} />
                <StepDot active={activeStep === 3} finished={activeStep > 3} />
              </Steps>
            )}

            <ModalFooterButtons>
              {/* {projectId ? (
                <Button
                  name={"Remove"}
                  onClick={handleRemove}
                  type="reset"
                  variant={"danger"}
                  size="medium"
                  icon={<Trash fill="#992A4B" />}
                />
              ) : null} */}
              <Button
                name={projectId ? "Save" : activeStep > 2 ? "Create" : "Next"}
                onClick={handleCreate}
                type="submit"
                variant={disabled ? "disabled" : "secondary"}
                disabled={disabled}
                size="medium"
                icon={projectId ? <Check /> : <ArrowRight />}
              />
            </ModalFooterButtons>
          </ModalFooterWrapper>
        ) : null}
      </ModalWrapper>
    </ModalOverlay>
  );
};
