import styled from 'styled-components';
import { ReactComponent as radioButtonActive } from "../../assets/radioButtonActive.svg";
import { ReactComponent as radioButtonInactive } from "../../assets/radioButtonInactive.svg";
import { ReactComponent as radioButtonHovered } from "../../assets/hoveredRadioButton.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 136px);
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.neutralText};
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 1.5rem;
`;

export const HoveredRadioButton = styled(radioButtonHovered)`
  margin-top: 12px;
  margin-right: 12px;
  display: none;
`;

export const ActiveRadioButton = styled(radioButtonActive)`
  margin-top: 12px;
  margin-right: 12px;
`;

export const InactiveRadioButton = styled(radioButtonInactive)`
  margin-top: 12px;
  margin-right: 12px;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
`;

export const Option = styled.label<{ $selected: boolean }>`
  width: 216px;
  padding: 12px;
  border: 1px solid
    ${({ $selected, theme }) =>
      $selected ? theme.primaryBorder : theme.neutralBorder};
  border-radius: 5px;
  cursor: pointer;
  background: ${({ theme, $selected }) =>
    $selected ? theme.primaryBackground : ""};
  transition: box-shadow 0.2s ease-in-out;
  position: relative;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    transition: 0.2s ease-in-out;

    ${HoveredRadioButton} {
      display: ${({ $selected }) => !$selected && "block"};
    }
    ${ActiveRadioButton} {
      display: ${({ $selected }) => !$selected && "none"};
    }

    ${InactiveRadioButton} {
      display: ${({ $selected }) => !$selected && "none"};
    }
  }

  h3 {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
    color: ${({ theme }) => theme.neutralText};
  }

  p {
    font-size: 12px;
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;