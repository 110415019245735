import {
  IFormatting,
  IMarkersType,
  WidgetItem,
} from "../../../../models/Widgets";

export const PunchcardChartGroupedData = (
  currentWidgetData?: WidgetItem,
  punchcardChartSuggestion?: any
) => {
  const groupBy = currentWidgetData?.groupBy?.[0];

  const xAxe =
    currentWidgetData?.xAxe?.[0] ?? punchcardChartSuggestion.xAxe?.[0];

  const yAxe =
    currentWidgetData?.yAxe?.[0] ?? punchcardChartSuggestion.yAxe?.[0];

  if (groupBy) {
    return currentWidgetData?.data?.reduce((acc, obj) => {
      const group = obj[groupBy];
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push({
        x: obj[xAxe] ?? obj.year,
        y: obj[yAxe] ?? obj.value,
      });
      return acc;
    }, {});
  } else {
    return {
      default: currentWidgetData?.data?.map((l) => ({
        x: l[xAxe] ?? l.year,
        y: l[yAxe] ?? l.value,
      })),
    };
  }
};

export const PunchcardChartDefaultMarkers = (data: any): IMarkersType[] => {
  const groupBy = data?.groupBy?.[0];
  const uniqueValuesKeys =
    (data?.uniqueValues && Object.keys(data?.uniqueValues!)) || [];
  const groupByKey = groupBy ?? uniqueValuesKeys?.[0];
  const groupedData = data?.uniqueValues?.[groupByKey] ?? PunchcardChartGroupedData(data);

  const markers: IMarkersType[] = (data.uniqueValues ? groupedData : Object.keys(groupedData)).map(
    (item: string) => ({
      key: item,
      shape: "donut",
    })
  );

  return markers;
};

export const getPunchcardChartDefaultColors = (data: any): IFormatting[] => {
  const groupedData = data.uniqueValues || PunchcardChartGroupedData(data);

  const keys = data.uniqueValues ? groupedData : Object.keys(groupedData);
  const colors: IFormatting[] = keys.map((item: string, index: number) => ({
    key: item,
    color: String(index + 1),
  }));

  return colors;
};
