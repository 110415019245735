import { useRef } from "react";

import { SubMenuWrapper } from "./styles";
import { useOutsideClick } from "../../hooks/useOutsideClick";

import { Copy, Pencil, Trash, ClockHistory } from "react-bootstrap-icons";

type SubmenuProps = {
  projectMenu?: boolean;
  datasetItemMenu?: boolean;
  menuOpen: boolean;
  pageTabMenu?: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
  onRemove?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
  onDuplicate?: () => void;
};

export const Submenu = ({
  projectMenu,
  menuOpen,
  setMenuOpen,
  onRemove,
  datasetItemMenu,
  pageTabMenu,
  onEdit,
  onDuplicate,
}: SubmenuProps) => {
  const menuRef = useRef<any>(null);

  useOutsideClick(menuRef, () => {
    setMenuOpen(false);
  });

  return (
    <SubMenuWrapper
      $pageTab={pageTabMenu}
      $projectPage={projectMenu}
      $datasetItemMenu={datasetItemMenu}
      $open={menuOpen}
      ref={menuRef}
    >
      <li
        onClick={() => {
          onEdit && onEdit();
          setMenuOpen(false);
        }}
      >
        <Pencil />
        Edit
      </li>
      <li
        onClick={() => {
          onDuplicate && onDuplicate();
          setMenuOpen(false);
        }}
      >
        <Copy />
        Duplicate
      </li>
      {projectMenu && (
        <li
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <ClockHistory />
          Version history
        </li>
      )}
      <li
        onClick={() => {
          onRemove!();
          setMenuOpen(false);
        }}
      >
        <Trash />
        Remove
      </li>
    </SubMenuWrapper>
  );
};
