import { useState, useRef } from "react";
import { CheckCircle } from "react-bootstrap-icons";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";
import { Loader } from "../../Loader";

import { FormContent } from "./Form";
import { useDispatch, useSelector } from "react-redux";
import { updateDocumentName } from "../../../store/slices/pdfSlice";
import {
  requestCreatePage,
  requestUpdatePage,
} from "../../../store/slices/projectPages";
import { useNavigate, useParams } from "react-router-dom";
import { getReportActionType } from "../../../store/selectors/main";
import { getPageSettings } from "../../../store/selectors/projects";
import { toast } from "react-toastify";

const ReportSchema = Yup.object().shape({
  reportName: Yup.string().required("Report name is required"),
  cover: Yup.boolean(),
  tableOfContents: Yup.boolean(),
});

type ModalProps = {
  onClose: () => void;
  pageIdSelected?: string | null;
};

type FormValues = {
  reportName: string;
  cover: boolean;
  tableOfContent: boolean;
};

export const ReportConfiguration = ({
  onClose,
  pageIdSelected,
}: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const reportActionType = useSelector(getReportActionType);
  const currentPage = useSelector(getPageSettings);

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const formikRef = useRef<FormikProps<FormValues>>(null);

  const handleOnClose = () => {
    setIsClosing(true);
    onClose();
  };

  // Check if it's edit mode and get initial values
  const initialValues: FormValues = {
    reportName: reportActionType === "update" && currentPage ? currentPage.name : "",
    cover: reportActionType === "update" && currentPage ? !!currentPage?.showHeader : true,
    tableOfContent: reportActionType === "update" && currentPage ? !!currentPage?.tableOfContents : true,
  };

  const onSubmitForm = (value: FormValues) => {
    if (!id) return;

    dispatch(updateDocumentName(value.reportName));

    if (pageIdSelected && reportActionType === "create") {
      const newPage = {
        name: value.reportName,
        dashType: "report",
        projectId: id,
        templateId: "default",
        soursePageId: pageIdSelected,
        interactivity: ["tooltip"],
        styleId: "",
        showFilters: false,
        showSearch: false,
        showTooltip: false,
        showHeader: value.cover,
        tableOfContents: value.tableOfContent,
        header: {
          title: "",
          description: "",
          insights:
            "Here, you'll find additional information that may not be crucial but could still be interesting or helpful.",
          align: "left",
          image: "",
          backgroundColor: "linear-gradient(90deg, #FF930F 0%, #FFF95B 100%)",
          textColor: "#FFFFFF",
        },
        navigate,
      };
      dispatch(requestCreatePage(newPage));
      toast.success("The report has started creating.");
      onClose();
      return;
    }

    dispatch(
      requestUpdatePage({
        ...currentPage,
        name: value.reportName,
        showHeader: value.cover,
        tableOfContents: value.tableOfContent,
      })
    );

    onClose();
  };

  const handleSubmit = async () => {
    if (formikRef.current) {
      setIsLoading(true);
      await formikRef.current.submitForm();
      setIsLoading(false);
    }
  };

  return (
    <ModalOverlay
      $centred={true}
      $isClosing={isClosing}
      onClick={handleOnClose}
    >
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $centred={true}
      >
        <ModalHeadingWrapper $disableSeparator={true}>
          <ModalContainer>
            <ModalHeading>Report Configuration</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent $isLoading={isLoading}>
          <ModalContent>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={ReportSchema}
              onSubmit={(values) => {
                onSubmitForm(values);
                setIsLoading(false);
              }}
            >
              {({ handleChange, values, isValid, dirty }) => (
                <>
                  <FormContent values={values} handleChange={handleChange} />
                  {isLoading && <Loader />}

                  <ModalFooterWrapper>
                    <Button
                      name="Close"
                      onClick={handleOnClose}
                      variant="neutral"
                      size="medium"
                    />
                    <Actions>
                      <Button
                        name="Confirm"
                        onClick={handleSubmit}
                        variant="secondary"
                        size="medium"
                        icon={<CheckCircle />}
                        disabled={isLoading || !isValid || !dirty}
                      />
                    </Actions>
                  </ModalFooterWrapper>
                </>
              )}
            </Formik>
          </ModalContent>
        </OverviewContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};
