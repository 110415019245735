import React, { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import {
  AccentuatedLabel,
  Buttons,
  Container,
  DownIcon,
  DropDownMenuItem,
  DropDownMenuList,
  DropDownSelect,
  FlexBox,
  LowOpacityText,
  SelectContainer,
  SelectedValue,
  SquareColor,
} from "./style";
import { ISelect } from "./types";

type Props = {
  options: ISelect[];
  value: string;
};

export const ColorSelect: React.FC<Props> = ({ options, value }: Props) => {
  const [selectedValue, setSelectedValue] = useState<ISelect | undefined>(
    options?.find((item) => item.value === value)
  );
  const [activeDropDown, setActiveDropDown] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], () => setActiveDropDown(false));

  const handleSelectItem = (item: ISelect) => {
    setSelectedValue(item);
    setActiveDropDown(false);
  };

  return (
    <Container ref={ref}>
      <SelectContainer>
        <SelectedValue onClick={() => setActiveDropDown((prev) => !prev)}>
          <SquareColor $color={selectedValue?.value} />
          {selectedValue?.label.includes('Colour') ? (
            <span>{selectedValue?.label || "Select value"}</span>
          ) : (
            <FlexBox>
              <LowOpacityText>{selectedValue?.label?.split(' ')[0] || "Select value"}{' '}</LowOpacityText>
              <AccentuatedLabel>{selectedValue?.label?.split(' ').slice(1, selectedValue?.label?.split(' ').length).join(' ')}</AccentuatedLabel>
            </FlexBox>
          )}

        </SelectedValue>
        <Buttons>
          <DownIcon
            $close={activeDropDown}
            onClick={() => setActiveDropDown((prev) => !prev)}
          />
        </Buttons>
      </SelectContainer>

      {!!activeDropDown && (
        <DropDownSelect>
          <DropDownMenuList>
            {options.map((item) => (
              <DropDownMenuItem onClick={() => handleSelectItem(item)}>
                <SquareColor $color={item.value} />
                <span>{item.label}</span>
              </DropDownMenuItem>
            ))}
          </DropDownMenuList>
        </DropDownSelect>
      )}
    </Container>
  );
};