import L from "leaflet";

interface MapPopupI {
  layer: any;
  properties: { countyStateCode: string; members?: string; year?: string };
}

export const mapPopup = ({ properties, layer }: MapPopupI) => {
  let popupOptions = {
    closeButton: false,
    className: "custom-popup",
    offset: [0, -7],
    autoPan: false,
  };

  const value = (properties?.members && parseFloat(properties?.members)) || 0;
  const areZecimale = value % 1 !== 0;
  let valueOnPopup;

  if (areZecimale) {
    valueOnPopup = value?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    valueOnPopup = value?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const popupContent = `<div class="custom-popup-content">
    <p class="code" style="color: ${({ theme }: any) => theme.neutralText}">${
    properties?.countyStateCode || ""
  }</p>
    <div class="members">
    <p class="custom-popup-label" style="color: ${({ theme }: any) =>
      theme.neutralWeak}">Members</p>&nbsp;
      <p class="custom-popup-value" style="color: ${({ theme }: any) =>
        theme.neutralText}"> ${valueOnPopup}</p>
    </div>
    <div class="year">
    <p class="custom-popup-label" style="color: ${({ theme }: any) =>
      theme.neutralWeak}">Year</p>&nbsp;
      <p class="custom-popup-value" style="color: ${({ theme }: any) =>
        theme.neutralText}">${properties?.year || 2024}</p>
    </div>
    </div>`;

  const popup = L.popup(popupOptions).setContent(popupContent);

  layer.bindPopup(popup);

  layer.on("mouseover", function (e: any) {
    layer.openPopup(e.latlng)
  });

  layer.on("mousemove", function (e: any) {
    popup.setLatLng(e.latlng);
  });

  layer.on("mouseout", function () {
    layer.closePopup();
  });
};
