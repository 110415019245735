import { ThreeDots } from "react-bootstrap-icons";
import * as s from "./styles";
import { useState } from "react";
import { DropdownItem, OptionDropdown } from "../CommendHead/styles";
import { RepliesType } from "../../../../../../types/comments";
import { useClickOutside } from "../../../../../../hooks/useClickOutside";

interface MessageProps {
  author: string;
  avatarUrl?: string;
  time: string;
  message: string;
  isYou?: boolean;
  isResolved: boolean;
  handleResolve: () => void;
  replies: RepliesType[] | undefined;
  isRead: boolean;
  handleActions: (key: string) => void;
  onSelect: () => void;
}

export const Message: React.FC<MessageProps> = ({
  author,
  time,
  message,
  onSelect,
  isYou,
  isResolved,
  handleResolve,
  replies,
  isRead,
  handleActions,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [itemRef] = useClickOutside(setMenuOpen, false);

  const handleMenuActions = (key: string) => () => {
    handleActions(key);
    setMenuOpen(!isMenuOpen);
  };

  return (
    <s.CommentItem>
      <s.Avatar>D</s.Avatar>
      <s.MessageContent>
        <s.CommnetsHead>
          <s.Author>
            {isYou ? "You" : author} <s.Time>{time}</s.Time>
          </s.Author>
          <s.ActionsContainer ref={itemRef}>
            {!isYou && !isResolved && !isRead && <s.DotIcon />}
            {!isRead && !isResolved && (
              <s.OptionButton onClick={() => setMenuOpen(!isMenuOpen)}>
                <ThreeDots />
              </s.OptionButton>
            )}
            {isMenuOpen && (
              <OptionDropdown $right={"20px"} $top={"10px"}>
                <DropdownItem key="1" onClick={handleMenuActions("markAsRead")}>
                  Mark as read
                </DropdownItem>
              </OptionDropdown>
            )}
            {isResolved ? (
              <s.CheckCircleIcon onClick={handleResolve} />
            ) : (
              <s.CheckCircleFillIcon onClick={handleResolve} />
            )}
          </s.ActionsContainer>
        </s.CommnetsHead>
        <s.MessageText onClick={onSelect} role="textbox" aria-label="Message content">
          {message}
        </s.MessageText>
        {!!replies?.length && <s.Replies onClick={onSelect}>{replies.length} replies</s.Replies>}
      </s.MessageContent>
    </s.CommentItem>
  );
};
