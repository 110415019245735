import React, { useCallback, useEffect, useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { requestPageWidgets } from "../../store/slices/projectPages";
import {
  ValueDelimitator,
  FilterLabel,
  FilterLength,
  FilterValueBlock,
  FilterValues,
  FilterWrapper,
  FilterDelimitator,
  FilterValuesBlock,
  FilterValue,
  FilterOption,
  FilterItem,
  FilterItemDelimitator,
  FilterBlockItem,
} from "./styles";
import { useDebounce } from "../../hooks/useDebounce";
import { Loader } from "../Loader";
import {
  getCurrentPageWidgets,
  getPageSettings,
} from "../../store/selectors/projects";
import { getIsPublicMode } from "../../store/selectors/main";
import { requestComparisonWidgets } from "../../store/slices/comparison";

export interface OptionsInterface {
  label: string;
  value: string;
}

interface IDefaultItemRendererProps {
  checked: boolean;
  option: OptionsInterface;
  disabled?: boolean;
  onClick: () => void;
}

const YearFiltering = ({ years }: { years: OptionsInterface[] }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageWidgets = useSelector(getCurrentPageWidgets);
  const [selected, setSelected] = useState<OptionsInterface[]>([]);
  const [lastSelected, setLastSelected] = useState<OptionsInterface[]>([]);
  const [opened, setOpened] = useState<boolean>(false);
  const [isFromSelect, setIsFromSelect] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pageId } = useParams();
  const dispatch = useDispatch();
  const debouncedSelected = useDebounce(selected, 1000);
  const previousItemsRef = useRef<any[]>([]);
  const isPublicRoute = useSelector(getIsPublicMode);
  const pageSettings = useSelector(getPageSettings);

  const getWidgets = () => {
    if (isLoading || !pageId) return;
    setIsLoading(true);
    setIsFromSelect(true);

    setLastSelected(debouncedSelected);

    const selectedYears = debouncedSelected.map((yearObj) =>
      parseFloat(yearObj.value)
    );
    const filter = selectedYears.filter((year) => !isNaN(year));
    const query =
      filter.length !== years.length
        ? JSON.stringify({ "data.year": { $in: filter } })
        : "";

    setSearchParams({ query });

    const comparisonpPayload = {
      pageId,
      measure: pageSettings.comparisonMeasure?.split("=")[0],
      query,
    };

    const payload = {
      pageId,
      includeData: true,
      query,
      callbacks: {
        onSuccess: () => {
          setIsLoading(false);
          if (pageSettings.dashType === "comparison") {
            dispatch(requestComparisonWidgets(comparisonpPayload));
          }
        },
      },
    };

    return dispatch(
      isPublicRoute ? requestPageWidgets(payload) : requestPageWidgets(payload)
    );
  };

  // useEffect(() => {
  //   if (debouncedSelected.length === 0) {
  //     setSelected(years);
  //     setLastSelected(years);
  //   }
  // }, [debouncedSelected.length, years]);

  useEffect(() => {
    if (
      debouncedSelected.length > 0 &&
      debouncedSelected.length !== lastSelected.length &&
      !isLoading
    ) {
      getWidgets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSelected.length, lastSelected.length, isLoading]);

  const handleSelectionChange = (newSelected: OptionsInterface[]) => {
    if (newSelected.length < 2) {
      toast("At least two years should be selected", { type: "warning" });
      return;
    }
    setSelected(newSelected);
  };

  const valueRenderer = (
    selected: OptionsInterface[],
    options: OptionsInterface[]
  ) => {
    const isAll = selected?.length === options?.length;
    const selectedLabels = selected?.map((s) => s.label);
    selectedLabels?.sort();
    const firstLabel = selectedLabels?.at(0);
    const length = selectedLabels?.length - 1;
    return (
      <FilterValuesBlock>
        <FilterValueBlock>
          <FilterLabel>Year</FilterLabel>
          {isAll ? (
            <FilterValue>All</FilterValue>
          ) : (
            <FilterValues>
              <FilterValue>{firstLabel || ""}</FilterValue>
              <ValueDelimitator $menuOpen={opened} />
              {length ? <FilterLength>+{length}</FilterLength> : null}
            </FilterValues>
          )}
        </FilterValueBlock>
        <FilterDelimitator $menuOpen={opened} />
      </FilterValuesBlock>
    );
  };

  const itemRenderer = useCallback(
    ({ checked, option, onClick, disabled }: IDefaultItemRendererProps) => (
      <FilterBlockItem key={option.value || "all"}>
        <FilterItem
          $checked={checked}
          className={`item-renderer ${disabled ? "disabled" : ""}`}
        >
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
            tabIndex={-1}
            disabled={disabled}
            id={option.value || "all"}
            style={{ opacity: option.value ? 1 : 0 }}
          />
          <FilterOption $isAll={!option.value} htmlFor={option.value || "all"}>
            {option.label}
          </FilterOption>
        </FilterItem>
        {!option.value && <FilterItemDelimitator />}
      </FilterBlockItem>
    ),
    []
  );

  useEffect(() => {
    if (pageWidgets?.items?.length > 0) {
      const prevItems = previousItemsRef.current;
      const currentItems = pageWidgets.items;

      if (JSON.stringify(prevItems) !== JSON.stringify(currentItems)) {
        const item = currentItems[0];
        const data =
          item.chartType === "mapChart" ? item?.layers?.[0]?.data : item?.data;

        if (data) {
          const years = data.reduce((uniqueYears, entry) => {
            if (!uniqueYears.includes(entry.year)) {
              uniqueYears.push(entry.year);
            }
            return uniqueYears;
          }, []);

          const mappedYears = years
            .map((year: any) => parseInt(year, 10))
            .filter(
              (year: any) =>
                !Number.isNaN(year) && year !== Infinity && year !== -Infinity
            );
          if (mappedYears?.length) {
            const yearsOptions = mappedYears.map((year: any) => ({
              value: String(year),
              label: String(year),
            }));
            const queryYears =
              JSON.parse(searchParams.get("query") || "{}")?.["data.year"]?.[
                "$in"
              ] || [];
            if (queryYears.length && !isFromSelect) {
              const selectedYears = queryYears.map((el: number) => ({
                value: String(el),
                label: String(el),
              }));
              setSelected(selectedYears);
              if (yearsOptions.length === selectedYears.length && !isLoading) {
                setLastSelected(
                  selectedYears.map((el: number) => ({
                    value: String(el),
                    label: String(el),
                  }))
                );
              }
              setIsFromSelect(true);
            } else {
              setSelected(yearsOptions);
              setLastSelected(yearsOptions);
            }
          }
        }
      }
      previousItemsRef.current = currentItems;
    }
  }, [isFromSelect, isLoading, pageWidgets.items, searchParams]);

  return (
    <>
      {isLoading && <Loader fullScreen />}
      <FilterWrapper>
        <MultiSelect
          disableSearch
          ClearSelectedIcon={null}
          overrideStrings={{ selectAll: "All" }}
          options={years}
          value={selected}
          onChange={handleSelectionChange}
          labelledBy="Select"
          valueRenderer={valueRenderer}
          ItemRenderer={itemRenderer}
          onMenuToggle={setOpened}
          className="filter-multi-select"
        />
      </FilterWrapper>
    </>
  );
};
export default React.memo(YearFiltering);
