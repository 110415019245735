import React from 'react'
import { Container, UpArrow, Text } from './styles';

type Props = {
  value: string;
  disableMargin?: boolean;
}

export const Indicator = ({value, disableMargin} : Props ) => {
  return (
    <Container $disableMargin={disableMargin}>
      <UpArrow />
      <Text>
        {value}
      </Text>
    </Container>
  )
}