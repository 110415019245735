import { Color } from "react-color";
import { Indicator } from "../KPI/Indicator";
import {
  Dot,
  KPIDataContainer,
  KPIDropDown,
  KPIDropDownItem,
  KPIDropDownWrapper,
  KPILabel,
  KPILegendContainer,
  KPILegendDot,
  KPILegendText,
  KPIMainContainer,
  KPIPercentage,
  KPISubTitle,
  KPISubValues,
  KPIValue,
} from "./styles";
import { useEffect, useState } from "react";
import { Sizes } from "./sizes/Sizes.interface";
import { XLSize } from "./sizes/XLSize";
import { LGSize } from "./sizes/LGSize";
import { MDSize } from "./sizes/MDSize";
import { SMSize } from "./sizes/SMSize";
import { KPIDropDownArrow } from "./icons/DropDownArrowIcon";

export interface KPIProps {
  key: string;
  size: "xl" | "lg" | "md" | "sm";
  value: string;
  label?: {
    position: "left" | "right";
    text: string;
  };
  percentage?: number;
  indicator?: string;
  legend?: {
    color: Color;
    text: string;
  };
  subtitle?: string;
  settings?: {
    dropDownOptions: {
      value: string;
      label: string;
    }[];
    onDropDownOptionClick: (e: any) => void;
  };
}

export const KPIComponent = (props: KPIProps) => {
  const {
    subtitle,
    size,
    indicator,
    label,
    value,
    legend,
    percentage,
    settings,
  } = props;
  const [sizes, setSizes] = useState<Sizes>(XLSize);
  const [open, setOpen] = useState<boolean>(false);

  const getSizes = () => {
    if (size === "xl") {
      return XLSize;
    }
    if (size === "lg") {
      return LGSize;
    }
    if (size === "md") {
      return MDSize;
    }
    if (size === "sm") {
      return SMSize;
    }

    return XLSize;
  };

  useEffect(() => {
    const sizes = getSizes();
    setSizes(sizes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  return (
    <KPIMainContainer>
      <KPIDataContainer>
        {!!label && label?.position === "left" && (
          <KPISubValues $margin={sizes.margin}>
            <KPILabel $sizes={sizes.label}>{label.text}</KPILabel>
          </KPISubValues>
        )}
        <KPIValue $sizes={sizes.value}>{value}</KPIValue>
        <KPISubValues $margin={sizes.margin}>
          {!!label && label?.position === "right" && (
            <KPILabel $sizes={sizes.label}>{label.text}</KPILabel>
          )}
          {!!label && (!!percentage || !!indicator) && <Dot />}
          {!!percentage && (
            <KPIPercentage $sizes={sizes.subValues}>
              {percentage}%
            </KPIPercentage>
          )}
          {!!indicator && <Indicator disableMargin value={indicator} />}
        </KPISubValues>
      </KPIDataContainer>
      {!!subtitle && <KPISubTitle>{subtitle}</KPISubTitle>}
      {!!legend && (
        <KPILegendContainer>
          <KPILegendDot $color={legend.color} />
          <KPILegendText>{legend.text}</KPILegendText>
        </KPILegendContainer>
      )}
      {!!settings && (
        <KPIDropDown>
          <div
            onClick={() => {
              setOpen(!open);
            }}
          >
            <KPIDropDownArrow />
          </div>
          {open && (
            <KPIDropDownWrapper>
              {settings?.dropDownOptions?.map((el) => {
                return (
                  <KPIDropDownItem
                    value={el.value}
                    onClick={(e: any) => {
                      settings.onDropDownOptionClick(e);
                      setOpen(false);
                    }}
                  >
                    {el.label}
                  </KPIDropDownItem>
                );
              })}
            </KPIDropDownWrapper>
          )}
        </KPIDropDown>
      )}
    </KPIMainContainer>
  );
};
