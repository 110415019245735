import styled, { css } from "styled-components";

const optionStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  cursor: pointer;
  user-select:none;
`;

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px;
  border-radius: 5px;
  padding: 2px;
  align-items: center;
  background: ${({ theme }) => theme.neutralBackground};
`;

export const ToggleOption = styled.label<{ $selected: boolean }>`
  ${optionStyles}
  ${({ theme, $selected }) =>
    $selected
      ? css`
          background: ${theme.neutralBackgroundBase};
          border-radius: 5px;
          color: ${theme.neutralText};
        `
      : css`
          background: none;
          border-radius: 0;
          color: ${theme.neutralWeak};
        `}
    
    &:hover {
    color: ${({ theme, $selected }) => ($selected ? "" : theme.neutralText)};
  }
`;

export const ToggleInput = styled.input`
  display: none;
`;
