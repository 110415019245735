import React, { useState } from "react";

import { PasswordResetConfirmation } from "./Confirmation";
import { ForgotPasswordForm } from "./ForgotPass";
import { ResetPasswordForm } from "./ResetPass";

export const PasswordReset: React.FC = () => {
  const [step, setStep] = useState<'ForgotForm' | 'ConfirmPage' | 'ResetPage' >('ForgotForm');
  const [email, setEmail] = useState<string>('');
  return (
    <>
      {step === 'ForgotForm' ? <ForgotPasswordForm setStep={() => setStep('ConfirmPage')} setEmail={(value) => setEmail(value)} /> : ''}
      {step === 'ConfirmPage' ? <PasswordResetConfirmation setStep={() => setStep('ResetPage')} email={email} /> : ''}
      {step === 'ResetPage' ? <ResetPasswordForm /> : ''}
    </>
  );
};
