import styled from "styled-components";

export const SelectedContainer = styled.div<{
  $selected: boolean;
}>`
  margin-left: auto;
  margin-bottom: 6px;
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.primaryBackgroundStrong : theme.primaryBackground};
  color: ${({ theme, $selected }) =>
    $selected ? theme.primaryTextStrong : theme.primaryText};
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  padding: 2px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 18px;

  &:hover {
    padding-right: ${({ theme, $selected }) => !$selected ? '4px' : '6px'};
    background-color: ${({ theme, $selected }) =>
    !$selected ? theme.stratosBackground : ""};
    color: ${({ theme, $selected }) => (!$selected ? theme.primaryTextStrongHover : "")};
    div {
      display: block;
    }
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }
  div {
    display: none;
  }
`;

export const IconContainer = styled.div`
  height: 16px;
  display: flex;
  padding-left: 4px;
  margin-left: 6px;
  border-left: ${({ theme }) => `1px solid ${theme.primaryTextStrongHover}`};
  svg path {
    fill: ${({ theme }) => theme.primaryTextStrongHover};
  }
`;
