import { createPortal } from "react-dom";
import { useRef } from "react";

import {
  CheckboxLabel,
  CheckboxText,
  DropdownMenuWrapper,
  DropdownTitle,
} from "./styles";
import { useOnClickOutside } from "usehooks-ts";
import { CheckBox } from "../../../../../../Inputs/CustomCheckbox/CheckBox";

interface DropdownMenuProps {
  item: any;
  open: boolean;
  isOpen: boolean;
  top: number;
  left: number;
  setContentItem: (data: any) => void;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  item,
  isOpen,
  open,
  top,
  left,
  setContentItem,
}) => {
  const ref = useRef(null);
  const handleClickOutside = () => {
    // setIsOpen(!isOpen)
  };

  useOnClickOutside(ref, handleClickOutside);

  return open && isOpen ? (
    createPortal(
      <DropdownMenuWrapper
        ref={ref}
        isOpen={!!(!!open && !!isOpen)}
        top={top}
        left={left}
      >
        <DropdownTitle>Show</DropdownTitle>
        <CheckboxLabel $active={!!!item?.titleDisabled}>
          <CheckBox
            checked={!!!item?.titleDisabled}
            status={
              !!!item?.titleDisabled ? "checked" : "none"
            }
            onChange={() =>
              setContentItem({
                ...item,
                titleDisabled: !item?.titleDisabled,
              })
            }
          />
          <CheckboxText>
            <span>Title</span>
          </CheckboxText>
        </CheckboxLabel>
        <CheckboxLabel $active={!!!item?.textDisabled}>
          <CheckBox
            checked={!!!item?.textDisabled}
            status={
              !!!item?.textDisabled ? "checked" : "none"
            }
            onChange={(e) =>
              setContentItem({
                ...item,
                textDisabled: !item?.textDisabled,
              })
            }
          />
          <CheckboxText>
            <span>Text</span>
          </CheckboxText>
        </CheckboxLabel>
      </DropdownMenuWrapper>,
      document.body
    )
  ) : (
    <></>
  );
};
