import React, { useMemo } from "react";
import {
  Actions,
  ProgressBarWrapper,
  ProgressHeading,
  ProgressPercentages,
  UploadProgressWrapper,
  Progress,
  TextWrapper,
  LoadingBar,
  Procesing,
  CircleLoading,
  ProcesingText,
} from "./styles";
import { useSelector } from "react-redux";
import { getUploadFiles } from "../../store/selectors/files";

interface ProgressBarInterface {
  progress: number;
  index: number;
}
export const ProgressBar = ({ progress, index }: ProgressBarInterface) => {
  const files = useSelector(getUploadFiles);

  const fileName = useMemo(() => {
    const file = files?.length && files[index - 1];
    if (file) {
      return file.name || file.originalName;
    }
    return "Dataset #" + index;
  }, [files, index]);

  return (
    <UploadProgressWrapper>
      <Actions />
      <ProgressBarWrapper>
        <TextWrapper>
          <ProgressHeading>{fileName}</ProgressHeading>
          {progress !== 100 ? (
            <ProgressPercentages>{progress}%</ProgressPercentages>
          ) : (
            ""
          )}
        </TextWrapper>
        {progress !== 100 ? (
          <LoadingBar>
            <Progress $progress={progress} />
          </LoadingBar>
        ) : (
          <Procesing>
            <CircleLoading></CircleLoading>
            <ProcesingText>
              The uploaded data is being processed...
            </ProcesingText>
          </Procesing>
        )}
      </ProgressBarWrapper>
      {/* <CloseWrapper>
        <CloseButton onClick={() => {}}/>
      </CloseWrapper> */}
    </UploadProgressWrapper>
  );
};
