import { styled, keyframes } from "styled-components";

export const NavigationContainer = styled.div`
  display: none; // Hide by default

  @media (min-width: 1440px) {
    display: block; // Show only on screens 1440px or wider
    position: fixed;
    top: 130px;
    right: 20px;
    width: 216px;
    height: 86vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 6px;
    z-index: 100;
    padding: 8px;
    overscroll-behavior: none;
  }

  @media (min-width: 1900px) {
    right: 200px;
  }
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Thumbnail = styled.div<{ $isSelected: boolean }>`
  position: relative;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 6px;
  border: 1px solid transparent;
  transition: border-color 0.3s ease-in-out;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 4px solid
      ${({ $isSelected, theme }) =>
        $isSelected ? theme.primaryBackgroundMedium : "transparent"};
    border-radius: 6px;
    z-index: 1;
    pointer-events: none;
    transition: border-color 0.3s ease-in-out;
  }

  &:hover {
    border-color: ${({ theme }) => theme.primaryBorder};
  }
`;

export const ThumbnailImage = styled.img`
  width: 200px;
  height: 258px;
  object-fit: cover;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const pulse = keyframes`
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
`;

export const Skeleton = styled.div`
  width: 200px;
  height: 258px;
  margin-bottom: 8px;
  border-radius: 6px;
  background-color: #e0e0e0;
  animation: ${pulse} 1s ease-in-out infinite;
`;

export const PageNumber = styled.span`
  align-self: center;
  font-size: 12px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
