import { useRef } from 'react';

/**
 * Usage:
 * const [blockScroll, allowScroll] = useScrollBlock();
**/

const useScrollBlock = () => {
  const scrollBlocked = useRef<any>();
  const html = document?.documentElement;
  const body = document?.body;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current || !html || !html?.style) return;

    const scrollBarWidth = window.innerWidth - (html?.clientWidth || 0);
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

    html.style.position = 'relative';
    html.style.overflow = 'hidden';
    body.style.position = 'relative';
    body.style.overflow = 'hidden';
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current || !html || !html?.style) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;
  };

  return [blockScroll, allowScroll];
};

export default useScrollBlock