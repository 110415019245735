import { useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalOverlay,
  ModalSubHeading,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";

import { FormContent } from "./Form";
import { useDispatch, useSelector } from "react-redux";
import { ModalHeadingWrapper } from "./style";
import { getPopulations } from "./utils";
import { setSelectedMeasure } from "../../../store/slices/comparison";
import { setDraftPageSettings } from "../../../store/slices/projectPages";
import { setActiveModal } from "../../../store/slices/modals";
import { setReportActionType } from "../../../store/slices/appConfig";
import { getDraftPageSettings } from "../../../store/selectors/projects";
import { getPageIdSelected } from "../../../store/selectors/main";

type ModalProps = {
  onClose: () => void;
};

export const CreateComparison = ({ onClose }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [population, setPopulation] = useState<string | null>(
    () => getPopulations()[0].bucket
  );
  const [measure, setMeasure] = useState<string | null>(null);
  const pageDraftSettings = useSelector(getDraftPageSettings);
  const selectedPageId = useSelector(getPageIdSelected)

  const dispatch = useDispatch();

  const handleOnClose = () => {
    setIsClosing(true);
    onClose();
    setSelectedMeasure(null);
  };

  const handleSubmit = async () => {
    onClose();
    dispatch(
      setDraftPageSettings({
        ...pageDraftSettings,
        soursePageId: selectedPageId!,
        comparisonMeasure: `${measure}=${population}`!,
        dashType: "comparison",
      })
    );
    dispatch(
      setActiveModal({
        id: "projectSettingsModal",
        createOptions: true,
      })
    );
    dispatch(setReportActionType("unset"));
    return;
  };

  return (
    <ModalOverlay
      $centred={true}
      $isClosing={isClosing}
      onClick={handleOnClose}
    >
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $centred={true}
      >
        <ModalHeadingWrapper>
          <ModalContainer>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <ModalHeading>Select Additional Measure</ModalHeading>
              <ModalSubHeading>
                Select one additional measure you want to compare
                <br />
                with the main measure
              </ModalSubHeading>
            </div>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <FormContent
              selectedOption={measure}
              setSelectedOption={setMeasure}
              selectedChip={population}
              setSelectedChip={setPopulation}
            />
            <ModalFooterWrapper>
              <Button
                name="Cancel"
                onClick={handleOnClose}
                variant="neutral"
                size="medium"
              />
              <Actions>
                <Button
                  name="Confirm"
                  onClick={handleSubmit}
                  variant="secondary"
                  size="medium"
                  icon={<CheckCircle />}
                  disabled={!measure?.length}
                />
              </Actions>
            </ModalFooterWrapper>
          </ModalContent>
        </OverviewContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};
