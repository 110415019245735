import { StoreInterface } from "../../models";

export const getFiles = (store: StoreInterface) => store.files.items;

export const getUploadFiles = (store: StoreInterface) =>
  store.files.uploadFiles;

export const getFileUploadProgress = (store: StoreInterface) =>
  store.files.uploadProgress;

export const getUploadedFilesCount = (store: StoreInterface) =>
  store.files.uploadedFilesCount;
