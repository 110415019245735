import { css, styled } from "styled-components";

export const defaultStyles = css({
  position: 'relative',
  zIndex:'100',
  transition: '0.3s'
})


export const Circle = styled.circle`
  ${defaultStyles}
`

export const Rect = styled.rect`
  ${defaultStyles}
`

export const Polygon = styled.polygon`
  ${defaultStyles}
`