import React, { useEffect, useState } from "react";
import { Button } from "../../../../../Button";
import { CheckCircle, EmojiSmile } from "react-bootstrap-icons";
import * as s from "./styles";

interface Props {
  onSubmit: (text: string) => void;
  onCancel?: () => void;
  initValue: string;
}


export const InputComponent: React.FC<Props> = ({ onSubmit, onCancel, initValue = "" }) => {
  const [value, setValue] = useState("");
  const onHandleSubmit = () => {
    onSubmit(value)
    setValue("")
  }

  useEffect(() => {
    setValue(initValue);
  }, [initValue])

  const handleChange = (e: any) => {
    setValue(e.target.value)
  }

  return (
    <s.InputWrapper>
      <s.TextInput value={value} onChange={handleChange} rows={3} placeholder="Wade Warren" />
      <s.Control>
        <s.IconWrapper><EmojiSmile/></s.IconWrapper>
        <s.ButtonWrapper>
          <Button
            name="Cancel"
            onClick={() => onCancel!()}
            variant="neutral"
            size="small"
          />
          <Button
            name="Submit"
            onClick={onHandleSubmit}
            variant={"secondary"}
            size="small"
            icon={<CheckCircle />}
            disabled={!value.length}
          />
      </s.ButtonWrapper>
      </s.Control>
    </s.InputWrapper>
  );
};
