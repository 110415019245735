import { WidgetWrapper } from "./VerticalBarchart/styles";

interface ChartInterface {
  map?: boolean;
  selected?: boolean;
  storytelling?: boolean;
  recommended?: boolean;
  isFullTemplate?: boolean;
  onSelect?: () => void;
  children: React.ReactNode;
}

export const WidgetChartWrapper = ({
  onSelect,
  selected,
  map,
  storytelling,
  recommended = false,
  isFullTemplate = false,
  children,
}: ChartInterface) => {
  return (
    <WidgetWrapper
      $storytelling={storytelling}
      $selected={selected}
      $recommended={recommended}
      $isFullTemplate={isFullTemplate}
      $map={map}
      onClick={onSelect}
    >
      {children}
    </WidgetWrapper>
  );
};
