import { useState } from "react";
import {
  ActiveRadioButton,
  DimensionSettingsIconsItemWrapper,
  Label,
  HoveredRadioButton,
  InactiveRadioButton,
  RadioButtons,
  IconWrapper
} from "../styles";

export function RadioButtonComponent({ selectedCategory, type, handleCheckboxChange, label, icon,grayIcon }: { selectedCategory: string, type: string, handleCheckboxChange: (category: string) => void, label: string, icon: any, grayIcon: any }) {
  const [hover, setHover] = useState(false)

  return (
    <DimensionSettingsIconsItemWrapper
      $selected={selectedCategory === type}
      key={type}
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }}
      onClick={() => handleCheckboxChange(type)}
    >
      <RadioButtons>
        {selectedCategory === type ? (
          <ActiveRadioButton />
        ) : (
          <>
            <InactiveRadioButton />
            <HoveredRadioButton />
          </>
        )}
      </RadioButtons>
      <Label>{label}</Label> <IconWrapper>{(selectedCategory === type || hover) ? icon : grayIcon}</IconWrapper>
    </DimensionSettingsIconsItemWrapper>
  );
}