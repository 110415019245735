import styled from "styled-components";

export const KPIWrapperMainContainer = styled.div<{
  $selected?: boolean;
  $recommended?: boolean;
  $isRowView?: boolean;
}>`
  display: flex;
  gap: ${({$isRowView}) => $isRowView ? "30px" : "1px"};
  flex-direction: column;
  position: relative;
  padding: ${({ $recommended }) => ($recommended ? "12px 16px" : "0")};
  border-radius: 8px;
  background: ${({ theme, $selected, $recommended }) =>
    $selected && $recommended
      ? theme.primaryBackground
      : theme.neutralBackgroundBase};

  border: ${({ theme, $selected, $recommended }) =>
    !$selected && $recommended
      ? `1px solid ${theme.neutralBorder}`
      : `1px solid transparent`};
`;

export const KPIName = styled.div<{ $width: string }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => `${theme.neutralText}`};
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ $width }) => $width};
`;

export const KPIRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const KPISeparator = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 15px;
  border-left: 1px dashed #d3dbe3;
`;

export const HeadingNameAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const KPIComponentRow = styled.div`
  display: flex;
  flex-direction: row;
`;
