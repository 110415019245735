import React from "react";

import { Button } from "../Button";
import { Description, DetailsWrapper, NoProjectWrapper, Title } from "./styles";
import { ReactComponent as NoProjectIcon } from "../../assets/new-project.svg";
import { PlusLg } from "react-bootstrap-icons";

type EmptyProjectsType = {
  openNewProjectModal: () => void;
};

export const EmptyProjects = ({ openNewProjectModal }: EmptyProjectsType) => {
  return (
    <NoProjectWrapper>
      <NoProjectIcon />
      <DetailsWrapper>
        <Title>
          Looks like you haven't embarked on any projects just yet!{" "}
        </Title>
        <Description>
          Ready to kick off your first project? Click the button below, select a
          measure, and start exploring!
        </Description>
      </DetailsWrapper>
      <Button
        name="Create Project"
        onClick={() => openNewProjectModal()}
        icon={<PlusLg />}
        variant="secondary"
        size="medium"
      />
    </NoProjectWrapper>
  );
};
