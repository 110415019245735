import React, { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import {
  Buttons,
  CloseIcon,
  Container,
  ContentWrapper,
  DownIcon,
  DropDownMenuItem,
  DropDownMenuList,
  DropDownSelect,
  Label,
  SelectContainer,
  SelectedValue,
} from "./style";
import { ISelect } from "./types";

type Props = {
  options: ISelect[];
  value: string;
  withOutClose?: boolean;
  label?: string;
  onSelect?: (value: ISelect) => void;
  disabled?: boolean;
};

export const Select: React.FC<Props> = ({
  options,
  value,
  withOutClose,
  label,
  onSelect,
  disabled,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<ISelect | undefined>(
    options?.find((item) => item.value === value)
  );
  const [activeDropDown, setActiveDropDown] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], () => setActiveDropDown(false));

  const handleSelectItem = (item: ISelect) => {
    setSelectedValue(item);
    setActiveDropDown(false);
    onSelect?.(item);
  };

  const toggleDropDown = (e: React.MouseEvent) => {
    if (disabled) return;
    if ((e.target as HTMLElement).closest('button')) {
      return;
    }
    setActiveDropDown((prev) => !prev);
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    const emptyValue = { option: "", value: "" };
    setSelectedValue(emptyValue);
    onSelect?.(emptyValue);
  };

  return (
    <Container ref={ref}>
      <SelectContainer $hasLabel={!!label} $disabled={!!disabled} onClick={toggleDropDown}>
        <ContentWrapper>
          {label && <Label>{label}</Label>}
          <SelectedValue>
            {selectedValue?.option || "Select value"}
          </SelectedValue>
        </ContentWrapper>
        <Buttons>
          {!selectedValue && !withOutClose && (
            <CloseIcon onClick={handleClear} />
          )}
          <DownIcon $close={activeDropDown} />
        </Buttons>
      </SelectContainer>

      {!!activeDropDown && (
        <DropDownSelect>
          <DropDownMenuList>
            {options.map((item) => (
              <DropDownMenuItem
                key={item.value}
                onClick={() => handleSelectItem(item)}
              >
                {item.option}
              </DropDownMenuItem>
            ))}
          </DropDownMenuList>
        </DropDownSelect>
      )}
    </Container>
  );
};