import React, { createContext, useState } from 'react';

interface MapControlsContextType {
  activeMapId: string | null;
  setActiveMapId: (id: string) => void;
  period: string | undefined;
  setPeriod: (period: string) => void;
  leftSlider: number;
  rightSlider: number;
  setLeftSlider: (value: number) => void;
  setRightSlider: (value: number) => void;
}

const defaultContext: MapControlsContextType = {
  activeMapId: null,
  setActiveMapId: () => {},
  period: undefined,
  setPeriod: () => {},
  leftSlider: 0,
  rightSlider: 6,
  setLeftSlider: () => {},
  setRightSlider: () => {},
};

export const MapControlsContext = createContext<MapControlsContextType>(defaultContext);

export const MapControlsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeMapId, setActiveMapId] = useState<string | null>(null);
  const [period, setPeriod] = useState<string>();
  const [leftSlider, setLeftSlider] = useState(0);
  const [rightSlider, setRightSlider] = useState(6);

  return (
    <MapControlsContext.Provider
      value={{
        activeMapId,
        setActiveMapId,
        period,
        setPeriod,
        leftSlider,
        rightSlider,
        setLeftSlider,
        setRightSlider,
      }}
    >
      {children}
    </MapControlsContext.Provider>
  );
};