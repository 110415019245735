import styled from "styled-components";
import { ChevronDown } from "react-bootstrap-icons";


export const LayersWrapper = styled.div`
`

export const LayerWrapper = styled.div<{$isCard: boolean, $timeline: boolean}>`
  position: absolute;
  z-index: 1;
  bottom: ${({$isCard, $timeline}) => $isCard? 
    $timeline? "80px" : "21px" 
    : 
    "5px"
  };
  right: ${({$isCard}) => $isCard? "21px" : "5px"};
  width: 306px;
  
  background: white;
  border-radius: 8px;
  padding: 16px;
  max-width: calc(100% - 20px);
  overflow: hidden;
`;

export const LegendWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const LegendWrapperHeader = styled.p`
color: ${({ theme }) => theme.neutralText};
text-overflow: ellipsis;
white-space: nowrap;

font-size: 11px;
font-weight: 400;
line-height: 12px;
letter-spacing: 0.88px;
text-transform: uppercase;
`

export const HideButton = styled(ChevronDown)<{
  $show: boolean
}>`
  transform: ${({$show}) => `rotateZ( ${$show ? 0 : '180deg' })`};
  cursor: pointer;
  transition: 0.5s;
`

export const LayerHeading = styled.p`
  padding-bottom: 8px;
  color: ${({ theme }) => theme.neutralText};
  font-size: 12px;
  font-weight: 500;
`;

export const LegendDetailsWrapper = styled.div``;

export const LegendDetailsHeading = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

export const LegendAccentHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-right: 4px;
`;

export const LegendDetailsDescription = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 11px;
  font-weight: 400;
`;

export const MapLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`;

export const MapLegendColorsWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const ColorsWrapper = styled.div`
  position:relative;
  display: flex;

` 
export const RangeWrapper = styled.div`
  position:absolute;
  width: 100%;
  right:0;
  bottom:0;
` 


export const ColorLegendDotIndicator = styled.div`
  display: none;
  height: 8px;
  width: 8px;
  border: 1px solid white;
  border-radius: 10px;
  position: absolute;
  background-color: black;
`;

export const MapLegendColor = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 40px;
  height: 12px;
  position: relative;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    ${ColorLegendDotIndicator} {
      display: block;
      top: 50%;
      left: 8px;
      transform: translate(-50%, -50%);
    }
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    ${ColorLegendDotIndicator} {
      display: block;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }
`;

export const MapLegendDescriptionWrapper = styled.div`
  display: flex;
  padding-top: 6px;
`;

export const MapLegendDescription = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 9px;
  font-weight: 400;
  width: 40px;
  padding-right: 5px;
`;
