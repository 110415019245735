import { useMemo, useState } from "react";
import { LinePath } from "@visx/shape";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { curveMonotoneX } from "@visx/curve";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  HeadingNameAndButton,
  SettingsButtonWrapper,
  Title,
} from "../../VerticalBarchart/styles";
import { setActiveModal } from "../../../../store/slices/modals";
import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../store/selectors/main";
import { getPageSettings } from "../../../../store/selectors/projects";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import { ticksFormatter } from "../../../../helpers/ticksFormatter";
import { calculateNumTicks } from "../../widgetHelpers";
import { Tooltip, TooltipProps } from "../../Tooltip";
import { Loader } from "../../../Loader";
import { AiSuggestionsDto, WidgetItem } from "../../../../models/Widgets";
import { getAiSuggestions } from "../../../../store/selectors/widgets";
import { setCurrentWidget } from "../../../../store/slices/projectPages";
import { SelectBage } from "../../SelectBage";
import { replaceWords } from "../../../../helpers/replaceName";
import { LineChartMarker } from "../../utils/getMarker";
import {
  getCurrentColorV2,
  getCurrentMarker,
} from "../../utils/getCurrentMarker";
import { AVAILABLE_WIDGETS } from "../../../../constants/widgetRecomended";
import { createPortal } from "react-dom";

interface LineChartProps {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  currentWidget: WidgetItem;
}
export const LineChart = ({
  storytelling,
  currentWidget,
  recommended,
  showLegend = true,
  selected = false,
  hideName = false,
}: LineChartProps) => {
  const isEditMode = useSelector(getIsEditMode);
  const dispatch = useDispatch();
  const [ref, bounds] = useMeasure();
  const isPublicRoute = useSelector(getIsPublicMode);
  const margin = { top: 15, right: 5, bottom: 20, left: 40 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);

  const chartColor = getCurrentColorV2(currentWidget, "default", styleId);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  if (!currentWidget) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  const lineChartData =
    currentWidget && Object.keys(currentWidget).length
      ? currentWidget?.data
          ?.slice()
          ?.sort(
            (a: any, b: any) =>
              parseInt(a?.[currentWidget?.xAxe?.[0] || "year"]) -
              parseInt(b?.[currentWidget?.xAxe?.[0] || "year"])
          )
      : undefined;

  const lineChartSuggestion = aiSuggestions?.filter(
    (chart: AiSuggestionsDto) => chart.chartType === "lineChart"
  )[0];

  const xAxe = currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : lineChartSuggestion.xAxe?.at(0);

  const yAxe = currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : lineChartSuggestion.yAxe?.at(0);

  const uniqueCategories = Array.from(new Set(lineChartData?.map((d) => d[xAxe])));

  const xScale = scaleBand({
    domain: uniqueCategories,
    range: [margin.left, width - margin.right],
    padding: -1,
  });

  const yAxes = lineChartData?.map((d) => parseInt(d[yAxe])) || [];
  const yScale = scaleLinear({
    domain: [0, Math.max(...yAxes)],
    range: [height - margin.bottom, margin.top],
    nice: true,
  });

  const handleCircleMouseLeave = () => {
    setTooltip(null);
    setHoveredElement(null);
  };

  const numTicks = calculateNumTicks({ height: height });
  return (
    <>
      {!storytelling && (
        <HeaderWrapper>
          <HeadingNameAndButton>
            {!hideName && <Title>{name}</Title>}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={false}
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["lineChart"]?.length + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        </HeaderWrapper>
      )}
      <svg width="100%" height={"100%"} ref={ref}>
        {xScale.domain().map((value) => {
          return (
            <line
              key={`${value}`}
              x1={xScale(value)! + xScale.bandwidth() / 2}
              y1={margin.top}
              x2={xScale(value)! + xScale.bandwidth() / 2}
              y2={height - margin.bottom}
              stroke="#e0e0e0"
              strokeDasharray="1,2"
            />
          );
        })}
        {yScale.ticks(numTicks).map((value) => (
          <line
            key={value}
            x1={margin.left}
            y1={yScale(value)}
            x2={width - margin.right}
            y2={yScale(value)}
            stroke="#ccc"
            strokeDasharray="1 2"
          />
        ))}
        <line
          x1={width - margin.right}
          y1={margin.top}
          x2={width - margin.right}
          y2={height - margin.bottom}
          stroke="#e0e0e0"
          strokeDasharray="1, 2"
        />
        <AxisBottom
          top={height - margin.bottom}
          scale={xScale}
          hideTicks
          numTicks={uniqueCategories?.length}
          tickFormat={(tick) => tick.toString()}
          axisLineClassName="barchartAxisLine"
          tickLabelProps={(_, index, values) => {
            const isFirstTick = index === 0;
            const isLastTick = index === values.length - 1;
            const textAnchor =
              (isFirstTick && "start") || (isLastTick && "end") || "middle";
            return {
              fontSize: 11,
              fill: "#5F6877",
              textAnchor: textAnchor,
            };
          }}
        />
        <AxisLeft
          scale={yScale}
          left={margin.left}
          stroke="#ccc"
          strokeDasharray="1, 2"
          tickLineProps={{
            stroke: "#939BA7",
          }}
          numTicks={numTicks}
          tickLabelProps={(_, index, values) => ({
            fontSize: 11,
            fill: "#5F6877",
            textAnchor: "end",
            dx: -4,
            dy: index === 0 ? 0 : index < values.length - 1 ? 4 : 7,
          })}
          tickFormat={(value: any) => {
            return ticksFormatter(value);
          }}
        />
        <LinePath
          data={lineChartData?.map((d: any) => ({
            x: xScale(d[xAxe])! + xScale.bandwidth() / 2,
            y: yScale(d[yAxe])!,
          }))}
          x={(d: any) => d.x}
          y={(d: any) => d.y}
          curve={curveMonotoneX}
          opacity={hoveredElement ? 0.2 : 1}
          style={{ transition: "0.2s" }}
          stroke={chartColor ? hexToRGBA(chartColor, 0.8) : ""}
          strokeWidth={2}
          strokeLinecap="round"
        />
        {lineChartData?.map((d, i) => {
          const key = "Line" + i;

          const onMouseEnter = (event: any) => {
            if ((showTooltip || currentWidget.tooltip) && !recommended) {
              const { pageX, pageY, clientX, clientY } = event;
              const coords = { pageX, pageY, clientX, clientY };

              setHoveredElement(key);

              setTooltip({
                data: d,
                coords,
              });
            }
          };

          return LineChartMarker({
            opacity: hoveredElement ? (hoveredElement === key ? 1 : 0.2) : 1,
            i: i,
            markerType: getCurrentMarker(currentWidget, "default"),
            color: chartColor,
            xScale: xScale(d[xAxe])!,
            xScaleBand: xScale.bandwidth() / 2,
            yScale: yScale(d[yAxe]),
            r: hoveredElement === key ? 5 : 3,
            hovered: hoveredElement === key,
            onMouseEnterHandler: onMouseEnter,
            onMouseLeaveHandler: handleCircleMouseLeave,
          });
        })}
      </svg>
      {tooltip &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            coords={tooltip.coords}
          />,
          document.body
        )}
    </>
  );
};
