import { useDispatch, useSelector } from "react-redux";
import {
  TextAndImageHeadingContent,
  SectionWrapperBlock,
  SubsectionTextAndImageDescription,
  SubsectionTextAndImageTitle,
  UploadBlockWrapper,
  UploadBlock,
  UploadDescription,
  UploadLabelBlock,
  UploadTitle,
  UploadIcon,
  UploadIconBlock,
  SectionMenuClose,
} from "../../../../styles";
import { SectionWrapper } from "../../../../styles";
import { getIsEditMode, getIsPublicMode } from "../../../../../../store/selectors/main";
import { useEffect, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";
import {
  BlockLoader,
  BorderedSettingsBlock,
  Dot,
  IsDragging,
  IsHovered,
  SettingsWrapper,
} from "../../styles";
import { CenterIcon, LeftIcon, RightIcon } from "../../icons/TextPositionIcons";
import {
  requestDownloadFile,
  requestUploadSimpleFile,
} from "../../../../../../store/slices/files";
import { TextLoader } from "../../../../../LoaderText";
import { Image } from "react-bootstrap-icons";
import SwitchComponent from "../../../../../Inputs/CustomSwitch/Switch";
import StyledDropdownSection from "./TextsDropdown/TextsDropdown";
import { StorytellingImagePicker } from "./StorytellingImageMenu";

interface Props {
  contentItem: any;
  setContentItem: (item: any) => void;
  removeSection: (id: any) => void;
  draggable?: boolean;
}

const SectionTextImage: React.FC<Props> = ({
  setContentItem,
  contentItem,
  draggable,
  removeSection,
}) => {
  const isEditMode = useSelector(getIsEditMode);
  const isPublic = useSelector(getIsPublicMode);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<any>();
  const dispatch = useDispatch();
  const debounced = useDebounceCallback(setContentItem, 500);
  const debouncedFile = useDebounceCallback(setFile, 1000);

  useEffect(() => {
    if (file && typeof file !== "string") {
      try {
        dispatch(
          requestUploadSimpleFile({
            files: [file],
            callbacks: {
              onSuccess: (resp) => {
                const uploadedFile = resp?.at(0);
                if (uploadedFile) {
                  setContentItem({
                    ...contentItem,
                    image: uploadedFile.path,
                    imageDisabled: false,
                    backgroundColor: undefined,
                  });
                  setFile(null);
                }
              },
            },
          })
        );
      } catch (e) { }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRemoveSection = () => {
    removeSection && removeSection(contentItem)
  }

  useEffect(() => {
    if (contentItem.image) {
      dispatch(
        requestDownloadFile({
          path: contentItem.image,
          callbacks: {
            onSuccess: (resp) => {
              setFile(resp.url);
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItem.image]);

  return (
    <SectionWrapperBlock
      $isEditMode={!isPublic && isEditMode}
      $draggable={draggable}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      {!isPublic && isEditMode && open ? <IsHovered /> : null}
      {!isPublic &&draggable ? <IsDragging /> : null}
      <SectionWrapper
        $align={contentItem?.align}
        $backgroundColor={contentItem?.backgroundColor}
        $gradient={contentItem?.gradient}
        $rgbaColor={contentItem?.rgbaColor}
        spellCheck={false}
      >
        {!isPublic && isEditMode && (
          <>
            {
              open &&
              <SectionMenuClose
                onClick={handleRemoveSection}
              />
            }
            <SettingsWrapper $hover={open}>
              <>
                <BorderedSettingsBlock $active={!contentItem.imageDisabled} $background={contentItem.imageDisabled ? "neutralBackgroundBase" : "neutralBackgroundMedium"}>
                  <Image />
                  <SwitchComponent
                    checked={!contentItem.imageDisabled}
                    onChange={(e) => { setContentItem({ ...contentItem, imageDisabled: !e.target.checked }) }}
                  />
                </BorderedSettingsBlock>

                <Dot />
              </>
              <BorderedSettingsBlock>
                <LeftIcon
                  type={contentItem?.align}
                  onClick={() => {
                    setContentItem({ ...contentItem, align: "left" });
                  }}
                />
                {
                  (!contentItem.imageDisabled)
                    ? (
                      <RightIcon
                        type={contentItem?.align}
                        onClick={() => {
                          setContentItem({ ...contentItem, align: "right" });
                        }}
                      />
                    )
                    : (
                      <CenterIcon
                        type={contentItem?.align}
                        onClick={() => {
                          setContentItem({ ...contentItem, align: "center" });
                        }}
                      />
                    )
                }
              </BorderedSettingsBlock>
              <StyledDropdownSection open={open} item={contentItem} setContentItem={setContentItem} />
            </SettingsWrapper>
          </>
        )}
        {!(contentItem.titleDisabled && contentItem.textDisabled) && (
          <TextAndImageHeadingContent $alignment={contentItem?.align}>
            {!contentItem.titleDisabled && (
              contentItem?.title ? (
                <SubsectionTextAndImageTitle
                  defaultValue={contentItem?.title}
                  $color={contentItem?.textColor}
                  spellCheck={false}
                  contentEditable={!isPublic && isEditMode}
                  $alignment={contentItem?.align}
                  $isEditMode={!isPublic && isEditMode}
                  $isSection={true}
                  onChange={(e) => {
                    debounced({ ...contentItem, title: e.target.value });
                  }}
                  maxLength={59}
                />
              ) : (
                <div style={{ marginTop: "80px", width: "400px" }}>
                  <BlockLoader>
                    <TextLoader opacity={0.6} />
                    <TextLoader opacity={0.6} />
                    <TextLoader opacity={0.6} />
                  </BlockLoader>
                </div>
              )
            )}
            {!contentItem.textDisabled && (
              contentItem?.description ? (
                <SubsectionTextAndImageDescription
                  defaultValue={contentItem?.description}
                  $color={contentItem?.textColor}
                  spellCheck={false}
                  contentEditable={!isPublic && isEditMode}
                  $alignment={contentItem?.align}
                  $isEditMode={!isPublic && isEditMode}
                  $isSection={true}
                  onChange={(e) =>
                    debounced({ ...contentItem, description: e.target.value })
                  }
                  maxLength={240}
                />
              ) : (
                <div style={{ marginTop: "24px" }}>
                  <BlockLoader>
                    <TextLoader opacity={0.6} />
                    <TextLoader opacity={0.6} />
                    <TextLoader opacity={0.6} />
                  </BlockLoader>
                </div>
              )
            )}
            {!contentItem.textDisabled && (
              contentItem?.title && contentItem?.description ? (
                <SubsectionTextAndImageDescription
                  defaultValue={contentItem?.insights}
                  $color={contentItem?.textColor}
                  spellCheck={false}
                  contentEditable={!isPublic && isEditMode}
                  $alignment={contentItem?.align}
                  $isEditMode={!isPublic && isEditMode}
                  $isSection={true}
                  onChange={(e) =>
                    debounced({ ...contentItem, insights: e.target.value })
                  }
                  maxLength={240}
                />
              ) : (
                <div style={{ marginTop: "24px" }}>
                  <BlockLoader>
                    <TextLoader opacity={0.6} />
                    <TextLoader opacity={0.6} />
                    <TextLoader opacity={0.6} />
                  </BlockLoader>
                </div>
              )
            )}
          </TextAndImageHeadingContent>
        )}
        {/* {!contentItem.imageDisabled && ((file && typeof file === "string") || contentItem.image) && (
          <ImageContentWrapper>
            <ImageContent
              $imageUrl={typeof file === "string" ? file : undefined}
            />
          </ImageContentWrapper>
        )} */}
        {!contentItem.imageDisabled ? (
          <UploadBlockWrapper
            $align={contentItem?.align}
          >
            <UploadBlock
              $isCenter={contentItem?.align === "center"}
              $imageUrl={typeof file === "string" ? file : undefined}
            >
              {
                isEditMode && (
                  <StorytellingImagePicker
                    setFile={debouncedFile}
                    fileUrl={file}
                  />
                )
              }

              {typeof file === "string" ? null : (
                <UploadLabelBlock
                  $isEditMode={!isPublic && isEditMode}
                  $isCenter={contentItem?.align === "center"}
                >
                  <UploadIconBlock>
                    <UploadIcon />
                  </UploadIconBlock>
                  <UploadTitle>Upload an Image</UploadTitle>
                  <UploadDescription>
                    The image should reflect the presented information
                  </UploadDescription>
                </UploadLabelBlock>
              )}
            </UploadBlock>

          </UploadBlockWrapper>
        ) : null}
      </SectionWrapper>
    </SectionWrapperBlock>
  );
};

export default SectionTextImage;
