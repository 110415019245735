import { SequentialColors } from "../colors"

export const getSequentialColorsHex = (paletteId?: string): string[] => {
  
  const palette =  SequentialColors.find((item) => item.id === paletteId)

  if(!palette) {
    return SequentialColors[0].colors.map((item) => item.hex)
  }

  return palette.colors.map((item) => item.hex)
}