export const calculateNumTicks = ({
  height,
  width,
}: {
  height?: number;
  width?: number;
}) => {
  const heightThresholds = [
    { height: 80, numTicks: 2 },
    { height: 100, numTicks: 3 },
    { height: 130, numTicks: 4 },
    { height: 600, numTicks: 6 },
    { height: 800, numTicks: 8 },
  ];

  const widthThresholds = [
    { width: 300, numTicks: 3 },
    { width: 600, numTicks: 4 },
    { width: 800, numTicks: 5 },
  ];

  const heightThreshold = heightThresholds.find((t) => height! <= t.height);
  const widthThreshold = widthThresholds.find((t) => width! <= t.width);
  if (heightThreshold) return heightThreshold ? heightThreshold.numTicks : 10;
  else if (widthThreshold) return widthThreshold ? widthThreshold.numTicks : 8;
};
