export const defaultApparitionConfig = {
  textAlignment: "center",
  background: "",
  border: "",
  descriptionDisabled: false,
  insightsDisabled: false,
};

export const defaultNewWidget = {
  apparitionConfig: defaultApparitionConfig,
  description: "Add here description",
  insights: "Add here insights",
};