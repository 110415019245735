import React, { useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";

import { PageMenu } from "../../components/PageMenu";
import { HeadingMenuContainer } from "../../components/NavBar/styles";
import UserBg from "../../assets/userBg.jpg";
import {
  AccountAvatarText,
  AccountAvatarWithBg,
  ProfilePageWrapper,
  UserAvatar,
  UserProfileHeaderUserDescription,
  UserProfileHeaderUserDetails,
  UserProfileHeaderUserName,
  UserProfileHeaderUserRoleBadge,
  UserProfileHeaderWrapper,
} from "./styles";
import { ProfileSideBar } from "../../components/ProfileSideBar";
import { UserProfileProjects } from "../../components/UserProfileProjects";
import { AccountDto } from "../../models/Authentication";
import { useSelector } from "react-redux";
import { getUser } from "../../store/selectors/main";

export const ProfilePage = () => {
  const user = useSelector(getUser);
  const [account, setAccount] = useState<AccountDto | undefined>();

  useEffect(() => {
    if (user?.accessToken) {
      const account: AccountDto = jwtDecode(user?.accessToken);
      setAccount(account);
    }
  }, [user]);

  const initials = useMemo(() => {
    let initials = account?.email?.charAt(0);
    if (account?.firstName?.length || account?.lastName?.length) {
      initials =
        (account?.firstName?.charAt(0) || "") +
        (account?.lastName?.charAt(0) || "");
    }
    if (account?.name?.length) {
      initials = account?.name?.charAt(0);
    }
    return initials?.toUpperCase();
  }, [account?.email, account?.firstName, account?.lastName, account?.name]);

  const fullName = useMemo(() => {
    const name = [account?.firstName, account?.lastName]?.join(" ")?.trim();
    if (name?.length) {
      return name;
    }
    if (account?.name) {
      return account?.name;
    }
    return "Not set";
  }, [account?.firstName, account?.lastName, account?.name]);

  return (
    <>
      <HeadingMenuContainer>
        <UserProfileHeaderWrapper>
          <AccountAvatarWithBg>
            <UserAvatar src={UserBg} />
            <AccountAvatarText>{initials}</AccountAvatarText>
          </AccountAvatarWithBg>
          <UserProfileHeaderUserDetails>
            <UserProfileHeaderUserName>{fullName}</UserProfileHeaderUserName>
            <UserProfileHeaderUserDescription>
              Business analyst
              <UserProfileHeaderUserRoleBadge>
                Admin
              </UserProfileHeaderUserRoleBadge>
            </UserProfileHeaderUserDescription>
          </UserProfileHeaderUserDetails>
        </UserProfileHeaderWrapper>
        <PageMenu />
      </HeadingMenuContainer>
      <ProfilePageWrapper>
        <ProfileSideBar />
        <UserProfileProjects />
      </ProfilePageWrapper>
    </>
  );
};
