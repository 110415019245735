import React, { useEffect, useState } from "react";
import { THElement } from "../components/CreateProjectDataTable/components/THElement";
import {
  Container,
  TableBody,
  TableHeader,
} from "../components/CreateProjectDataTable/style";
import { TRElement } from "../components/CreateProjectDataTable/components/TRElement";
import { MEASURES, thirdStepTH } from "../../../../../constants/measure";
import { requestGetDatasetContents } from "../../../../../store/slices/datasets";
import { useDispatch, useSelector } from "react-redux";
import { getDatasetContents } from "../../../../../store/selectors/datasets";
import { Loader } from "../../../../Loader";
import { MeasureLoaderWrapper } from "./style";

type Props = {
  populationSelected: string;
  measureSelected?: string;
  setMeasuresSet: any;
  search?: string;
  goToPreview?: () => void;
};

const ThirdStep = ({
  populationSelected,
  measureSelected,
  setMeasuresSet,
  search,
  goToPreview,
}: Props) => {
  const dispatch = useDispatch();
  const datasetContents = useSelector(getDatasetContents);
  const [totalMeasures, setMeasures] = useState<any[]>([]);
  const [currentMeasures, setCurrentMeasures] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      requestGetDatasetContents({
        id: process.env.REACT_APP_DATA_SET_ID!,
        limit: 20,
        skip: 0,
        callbacks: {
          onSuccess: (data) => {
            setIsLoading(false);
          },
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (search) {
      const newValue = search?.toLowerCase();
      const newMeasures = totalMeasures?.filter((r) => {
        const measureDescriptionDesktop: string =
          r?.measureDescriptionDesktop?.toLowerCase();
        const id: string = r?.id?.toLowerCase();
        return (
          id?.includes(newValue) ||
          measureDescriptionDesktop?.includes(newValue)
        );
      });
      setCurrentMeasures(newMeasures);
    } else {
      if (totalMeasures?.length) {
        setCurrentMeasures(totalMeasures);
      } else {
        const measures = MEASURES.filter(
          (m) => m.bucket === populationSelected
        );
        setMeasures(measures);
        setCurrentMeasures(measures);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Container>
      <TableHeader>
        {thirdStepTH.map((item) => (
          <THElement text={item.text} key={item.text}></THElement>
        ))}
      </TableHeader>
      {isLoading ? (
        <MeasureLoaderWrapper>
          <Loader />
        </MeasureLoaderWrapper>
      ) : null}
      <TableBody>
        {currentMeasures.map((item: any) => {
          return (
            <TRElement
              key={item.id}
              id={item.id}
              header={item.id}
              subHeader={item.measureDescriptionDesktop}
              measures={0}
              selected={measureSelected === item.id}
              selectHandler={() => setMeasuresSet(item.id)}
              fields={datasetContents?.fields}
              goToPreview={goToPreview}
            />
          );
        })}
      </TableBody>
    </Container>
  );
};

export default ThirdStep;
