import { StoreInterface } from "../../models";

export const getActiveModal = (store: StoreInterface) =>
  store.modals.activeModal;

export const getModalCreateOptions = (store: StoreInterface) =>
  store.modals.createOptions;

export const getModalBackOptions = (store: StoreInterface) =>
  store.modals.backOptions;

export const getModalProjectId = (store: StoreInterface) =>
  store.modals.projectId;

export const hasBackOptions = (store: StoreInterface) =>
  store.modals.backOptions.length;

export const getModalFirstItem = (store: StoreInterface) =>
  store.modals.firstItem;
