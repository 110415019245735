import L from "leaflet";

export const mapInitialization = (
  mapRef: any,
  id: string,
  isFullScreen = false,
  terrain = false
) => {
  var container = L.DomUtil.get(id);
  if (container != null) {
    container._leaflet_id = null;
  }

  mapRef.current = L.map(id, {
    zoomControl: false,
    attributionControl: false,
  }).setView(
    isFullScreen ? [36.307339, -126.051611] : [37.5351425, -117.2663035],
    5.5
  );
  L.tileLayer(
    terrain?
    "https://tile.openstreetmap.org/{z}/{x}/{y}.png":
    "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
    {
      maxZoom: 20,
      minZoom: 5,
    }
  ).addTo(mapRef.current);

  const maxBounds = L.latLngBounds([
    [73, -173.0],
    [14.910669, -55.194935],
  ]);

  mapRef.current.setMaxBounds(maxBounds);
  mapRef.current.on("drag", function () {
    mapRef.current.panInsideBounds(maxBounds, { animate: true });
  });

  return () => {
    mapRef.current.remove();
  };
};
