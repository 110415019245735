import { styled } from "styled-components";



export const MapSettings = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  gap: 8px;
`

export const MapSettingsControls = styled.div`
  display: flex;
  justify-content: space-between;
  height: 24px;
  align-items: center;
`

export const MapSettingsSelectLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 20px 24px;
  gap: 8px;
`

export const MapSettingsSelectLayoutHeading = styled.p`
  color: ${({theme}) => theme.neutralText};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`

export const MapSettingsSelectLayoutOptions = styled.div`
  display: flex;
  justify-content: space-between;
`