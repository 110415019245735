import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (width: 1440px) {
    justify-content: start;
  }

`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 918px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  gap: 10px;
  margin-bottom: 10px;
  page-break-after: always;
`;

export const ProgressBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
  z-index: 1000;
`;

export const ProgressBar = styled.div<{ width: number }>`
  height: 100%;
  background-color: ${({ theme }) => theme.primaryBackgroundStrong};
  width: ${(props) => props.width}%;
  transition: width 0.3s ease-in-out;
`;

export const ThumbnailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;
