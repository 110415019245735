import styled from "styled-components";

export const BlockLabelAxe = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 3px 8px 3px;
`;

export const LabelAxe = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: ${({ theme }) => theme.neutralText};
`;

export const HeadingNameAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
