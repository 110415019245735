import { createSelector } from "reselect";
import { StoreInterface } from "../../models";

// Basic selectors
const selectAllProjects = (state: StoreInterface) => state.allProjects;
const selectCurrentProject = (state: StoreInterface) => state.currentProject;
const selectCurrentPage = createSelector(
  selectCurrentProject,
  (currentProject) => currentProject.pagesData?.currentPage
);

// Memoized selectors
export const getProjects = createSelector(
  selectAllProjects,
  (allProjects) => allProjects
);

export const getAllPages = createSelector(
  selectCurrentProject,
  (currentProject) => currentProject.pagesData
);

export const getLayout = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.settings.templateId
);

export const getInteractivityState = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.settings.interactivity
);

export const getPageSettings = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.settings
);

export const getPageBanner = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.settings.header
);

export const getPageId = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.settings.id
);

export const getDraftPageSettings = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.draftSettings
);

export const getSectionCountPage = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.sectionsCount
);

export const getCurrentProjectId = createSelector(
  selectCurrentProject,
  (currentProject) => currentProject.id
);

export const getCurrentProjectData = createSelector(
  selectCurrentProject,
  (currentProject) => currentProject.projectData
);

export const getCurrentPageWidgets = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.widgets
);

export const getCurrentPageKpi = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.kpis
);

export const getCurrentWidget = createSelector(
  selectCurrentProject,
  (currentProject) => currentProject.widget
);

export const getCurrentPageWidgetsItems = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage?.widgets?.items
);

export const getCurrentPageId = createSelector(
  selectCurrentPage,
  (currentPage) => currentPage
);

export const getProjectsSearch = createSelector(
  selectAllProjects,
  (allProjects) => allProjects.search
);

export const getCurrentProject = createSelector(
  selectCurrentProject,
  (currentProject) => currentProject
);
