import React from "react";
import { useDropzone } from "react-dropzone";
import { Upload } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";

import {
  Container,
  DetailsWrapper,
  Icon,
  UploadDescription,
  UploadText,
} from "./styles";
import { Button } from "../Button";
import { requestUploadFile, setUploadFile } from "../../store/slices/files";

export const FileImport = () => {
  const dispatch = useDispatch();

  async function onDrop(acceptedFiles: File[]) {
    dispatch(setUploadFile(acceptedFiles));
    dispatch(requestUploadFile([acceptedFiles]));
  }

  const { getRootProps, getInputProps, isDragReject, isDragActive } =
    useDropzone({
      onDrop,
      minSize: 0,
      // maxSize: 5242880,
      accept: {
        "text/csv": [],
      },
    });

  return (
    <Container $isDragActive={isDragActive} {...getRootProps()}>
      <Icon>
        <Upload />
      </Icon>
      <input {...getInputProps()} />
      {isDragActive && !isDragReject ? (
        <UploadText>Drop files here...</UploadText>
      ) : isDragReject ? (
        <>
          <UploadText>Please upload a CSV file</UploadText>
        </>
      ) : (
        <DetailsWrapper>
          <UploadText>Click or drag files to this area to upload</UploadText>
          <UploadDescription>CSV (max. 1mb)</UploadDescription>
        </DetailsWrapper>
      )}

      <Button
        onClick={() => {}}
        variant="primary"
        size="small"
        name="Browse files"
      />
    </Container>
  );
};
