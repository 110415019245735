import React, { ReactNode } from "react";

import {
  ButtonContainer,
  CustomButton,
  IconWrapper,
  VerticalLine,
} from "./styles";

type ButtonProps = {
  name?: string;
  onClick: (event?: any) => void;
  icon?: ReactNode;
  iconLeft?: ReactNode;
  variant:
  | "primary"
  | "secondary"
  | "neutral"
  | "neutral-color"
  | "disabled"
  | "danger";
  disabled?: boolean;
  size: "xxs" | "xs" | "small" | "medium" | "large" | "full";
  round?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  onMouseLeave?: () => void
  onMouseEnter?: () => void
};

export const Button = ({
  name,
  onClick,
  icon,
  iconLeft,
  variant,
  type,
  disabled,
  size,
  round,
  onMouseLeave,
  onMouseEnter
}: ButtonProps) => {
  return (
    <ButtonContainer  $full={size === "full"}>
      <CustomButton
        disabled={disabled}
        $disabled={disabled}
        $variant={variant}
        $size={size}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        onClick={disabled ? () => { } : onClick}
        type={type}
        $round={round}
      >
        <IconWrapper $small={size === "small"}>
          {iconLeft}
          {name ? (
            <>
              {iconLeft ? <VerticalLine $variant={variant} /> : null}
              {name}
              {icon ? <VerticalLine $variant={variant} /> : null}
            </>
          ) : null}
          {icon}
        </IconWrapper>
      </CustomButton>
    </ButtonContainer>
  );
};
