import { createPortal } from "react-dom";
import { useRef } from "react";

import { Button } from "../../../../../Button";
import { CheckBox } from "../../../../../Inputs/CustomCheckbox/CheckBox";
import {
  CheckboxLabel,
  CheckboxText,
  DropdownLine,
  DropdownMenuWrapper,
  DropdownTitle,
} from "./styles";
import {
  ApparitionConfig,
  UpdateSingleWidgetDTO,
} from "../../../../../../models/Widgets";
import {
  WidgetContentItem,
} from "../../../../../../store/slices/pageContentGenerator";
import { useOnClickOutside } from "usehooks-ts";

interface DropdownMenuProps {
  item: WidgetContentItem;
  open: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  top: number;
  left: number;
  apparitionConfig: ApparitionConfig;
  setContentItem: (data: UpdateSingleWidgetDTO) => void;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  item,
  isOpen,
  open,
  setIsOpen,
  top,
  left,
  apparitionConfig,
  setContentItem,
}) => {
  const ref = useRef(null);
  const handleClickOutside = () => {
    // setIsOpen(!isOpen)
  };

  useOnClickOutside(ref, handleClickOutside);

  return open && isOpen ? (
    createPortal(
      <DropdownMenuWrapper
        ref={ref}
        $isOpen={!!(!!open && !!isOpen)}
        top={top}
        left={left}
      >
        <DropdownTitle>Show</DropdownTitle>
        <CheckboxLabel $active={!!item?.apparitionConfig?.descriptionDisabled}>
          <CheckBox
            checked={!!item?.apparitionConfig?.descriptionDisabled}
            status={
              !!item?.apparitionConfig?.descriptionDisabled ? "checked" : "none"
            }
            onChange={() =>
              setContentItem({
                ...item,
                blockId: item.blockId!,
                apparitionConfig: {
                  ...apparitionConfig,
                  descriptionDisabled:
                    !item?.apparitionConfig?.descriptionDisabled,
                },
              })
            }
          />
          <CheckboxText>
            <span>Alternative text</span>
            <span>Data vizualization description</span>
          </CheckboxText>
        </CheckboxLabel>
        <CheckboxLabel $active={!!item?.apparitionConfig?.insightsDisabled}>
          <CheckBox
            checked={!!item?.apparitionConfig?.insightsDisabled}
            status={
              !!item?.apparitionConfig?.insightsDisabled ? "checked" : "none"
            }
            onChange={(e) =>
              setContentItem({
                ...item,
                blockId: item.blockId!,
                apparitionConfig: {
                  ...apparitionConfig,
                  insightsDisabled: !item?.apparitionConfig?.insightsDisabled,
                },
              })
            }
          />
          <CheckboxText>
            <span>Insight information</span>
            <span>Main highlights</span>
          </CheckboxText>
        </CheckboxLabel>
        <DropdownLine />
        <Button
          onClick={() => {
            setContentItem({
              ...item,
              blockId: item.blockId!,
              apparitionConfig: {
                ...apparitionConfig,
                descriptionDisabled: true,
                insightsDisabled: true,
              },
            });
          }}
          size="full"
          variant="neutral"
          name="Hide all"
        />
      </DropdownMenuWrapper>,
      document.body
    )
  ) : (
    <></>
  );
};
