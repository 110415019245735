import L from "leaflet";
import UsStates from "./GeoJson/us-states.json";
import {
  AiSuggestionsDto,
  CreateMapWidgetDto,
  MarkersVisualisationDto,
} from "../../../../models/Widgets";

interface GeoMarkerInterface {
  markersRef: any;
  mapRef: any;
  zoomLevel: number;
  mapType: "states" | "counties";
  aiSuggestions: AiSuggestionsDto;
}

export const geoMarker = ({
  markersRef,
  mapRef,
  zoomLevel,
  mapType,
  aiSuggestions,
}: GeoMarkerInterface) => {
  markersRef.current.forEach((marker: any) => {
    mapRef.current.removeLayer(marker);
  });

  markersRef.current = [];
  const labelLayer = L.layerGroup();

  if (zoomLevel >= 7 && mapType === "states") {
    L.geoJson(UsStates.features, {
      onEachFeature: function (feature: any, layer: any) {
        const stateCenter = layer.getBounds().getCenter();
        const label = L.marker(stateCenter, {
          icon: L.divIcon({
            className: "marker-label",
            html: feature.properties.NAME,
          }),
        });

        labelLayer.addLayer(label);
        markersRef.current.push(label);
      },
    });

    labelLayer.addTo(mapRef.current);
  }

  if (zoomLevel >= 7 && mapType === "counties") {
    if (aiSuggestions && Array.isArray(aiSuggestions)) {
      aiSuggestions.forEach((suggestion: CreateMapWidgetDto) => {
        if (suggestion.chartType === "mapChart" && suggestion.layers) {
          suggestion.layers.forEach((layer: MarkersVisualisationDto) => {
            const isGeospatialData =
              layer.geospatialData &&
              layer.geospatialData.length > 0 &&
              layer.geospatialData.every((element) => {
                return typeof element === "number";
              });

            if (isGeospatialData) {
              layer.geospatialData.forEach((value, _, __) => {
                const bubble = L.circleMarker(value, {
                  radius: 30,
                  stroke: true,
                  weight: 1,
                  color: layer.colour,
                  opacity: 1,
                  fill: true,
                  fillOpacity: 0.5,
                });
                labelLayer.addLayer(bubble);
                markersRef.current.push(bubble);
              });
            }
          });
        }
      });
    }

    labelLayer.addTo(mapRef.current);
  }
};
