import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";

import { REFRESH_TOKEN_URL, REGISTER_URL, SIGN_IN_URL } from "./constants";

export const registerUser = async (userData: {
  teamName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}) =>
  axios
    .post(REGISTER_URL, userData)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const signInUser = async (userData: {
  email: string;
  password: string;
}) =>
  axios
    .post(SIGN_IN_URL, userData)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const requestRefreshToken = async (token: string) =>
  axios
    .post(REFRESH_TOKEN_URL, { token })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
