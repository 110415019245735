import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trash, CheckCircle } from "react-bootstrap-icons";
import _ from "lodash";

import { Button } from "../../Button";
import { ModalHeadingNameWrapper } from "../DatasetsOverview/styles";

import {
  ModalFooterWrapper,
  ModalHeading,
  ModalOverlay,
  ModalWrapper,
  WrapperButtons,
} from "../styles";
import {
  CloseModal,
  ModalHeadingWrapperExt,
  ModalSubmenu,
  OverviewContentExt,
  SubmenuItem,
  SubmenuTitle,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getCurrentProjectId,
  getPageSettings,
} from "../../../store/selectors/projects";
import {
  requestCreateKpis,
  requestDeleteKpis,
  requestPageKpis,
  requestUpdatePage,
  setIsLoading,
  setPageWidgets,
} from "../../../store/slices/projectPages";
import { AiKpiDto, UpdateWidgetDTO } from "../../../models/Widgets";
import { KpiSettings } from "../../KpiSettings";
import {
  requestUpdateWidgets,
  requestWidgetsSuggestions,
} from "../../../store/slices/widgets";
import { LAYOUTS } from "../../ProjectPageLayouts/config";
import { KPI_TYPES } from "../../KPIComponentWrapper";
import { extractBlockIds, LayoutI } from "../../../helpers/extractBlockIds";
import { adjustArray } from "../../../helpers/ajustArray";
import { findFirstCommonElement } from "../../../helpers/firstCommonElement";

type ModalProps = {
  closeModal: () => void;
};

export const DataKpiSettings = ({ closeModal }: ModalProps) => {
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false);
  const currentPage = useSelector(getPageSettings);
  const id = useSelector(getCurrentProjectId);
  const widgets = useSelector(getCurrentPageWidgets);
  const aiKpi = useSelector(getCurrentPageKpi);
  const [recommendedKpis, setRecommendedKpis] = useState<AiKpiDto[]>([]);
  const [selectedKpis, setSelectedKpis] = useState<string[]>([]);
  const [nrAvailbleKpi, setNrAvailbleKpi] = useState<number>(4);
  const [isLoading, setLocalLoading] = useState(false);

  const getKpis = useCallback(() => {
    if (currentPage?.id) {
      dispatch(
        requestPageKpis({
          pageId: currentPage?.id,
          includeData: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage?.id]);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
      getKpis();
    }, 400);
  };

  const handleUpdateKpis = () => {
    if (!currentPage?.id) {
      return;
    }
    const addKpis = selectedKpis?.filter(
      (q) => !aiKpi.items?.some((k) => k.query === q)
    );
    const removeKpis = aiKpi.items?.filter(
      (k) => !selectedKpis?.some((q) => q === k.query)
    );

    for (let queryKpi of addKpis) {
      const kpi = recommendedKpis.find((r) => r.query === queryKpi);
      dispatch(requestCreateKpis({ ...kpi!, pageId: currentPage?.id }));
    }
    for (let kpi of removeKpis) {
      dispatch(requestDeleteKpis({ id: kpi.id }));
    }
    handleOnClose();
  };

  const setNumberOfAvailbleKpi = useCallback(() => {
    if (currentPage.dashType === "dashboard") {
      const newKpis = [...aiKpi.items, ...recommendedKpis]?.filter((k) =>
        selectedKpis?.includes(k.query)
      );

      let count = 4;
      // eslint-disable-next-line array-callback-return
      newKpis.map((item) => {
        count -=
          Number(
            [
              KPI_TYPES.HIGH_LOW?.toString(),
              KPI_TYPES.DISTINCTION?.toString(),
            ].includes(item.type)
          ) + 1;
      });

      setNrAvailbleKpi(count);
    }
  }, [aiKpi.items, currentPage.dashType, recommendedKpis, selectedKpis]);

  useEffect(() => {
    if (selectedKpis?.length) {
      setNumberOfAvailbleKpi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKpis?.length]);

  const removeKpi = () => {
    let barChartUpdateData: UpdateWidgetDTO[] = [];
    let lineChartUpdateData: UpdateWidgetDTO[] = [];
    let lollipopChartUpdateData: UpdateWidgetDTO[] = [];
    let sankeyChartUpdateData: UpdateWidgetDTO[] = [];
    let mapChartUpdateData: UpdateWidgetDTO[] = [];
    let areaChartUpdateData: UpdateWidgetDTO[] = [];
    let matrixChartUpdateData: UpdateWidgetDTO[] = [];
    let radarChartUpdateData: UpdateWidgetDTO[] = [];
    let scatterplotChartUpdateData: UpdateWidgetDTO[] = [];
    let punchcardChartUpdateData: UpdateWidgetDTO[] = [];
    let polarAreaChartUpdateData: UpdateWidgetDTO[] = [];
    let bubbleChartUpdateData: UpdateWidgetDTO[] = [];

    if (["storytelling", "comparison"].includes(currentPage?.dashType)) {
      for (let chart of widgets.items) {
        let blockId = chart.blockId ? parseInt(chart.blockId) : 0;
        blockId = blockId - 1;
        switch (chart?.chartType) {
          case "mapChart":
            mapChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "areaChart":
            areaChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "lineChart":
            lineChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "barChart":
            barChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "lollipopChart":
            lollipopChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "sankeyChart":
            sankeyChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "matrixChart":
            mapChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "radar":
            radarChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "scatterplot":
            scatterplotChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "punchcardChart":
            punchcardChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "polarAreaChart":
            polarAreaChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          case "bubbleChart":
            bubbleChartUpdateData.push({
              ...chart,
              blockId: blockId?.toString(),
            });
            break;
          default:
        }
      }
    }
    if (currentPage?.dashType === "dashboard") {
      const layout = LAYOUTS.find((l) => l.id === currentPage.templateId)!;
      const restWidgets = [...widgets?.items];
      const currentCharts = restWidgets?.map((w) => w.chartType);
      let complexWidgets = currentCharts?.filter((c) =>
        ["mapChart", "sankey", "sankeyChart"].includes(c)
      )?.length;
      complexWidgets = complexWidgets > 2 ? 2 : complexWidgets;
      let newLayout = LAYOUTS.find(
        (l) =>
          l.complexity === layout.complexity &&
          l.numWidgets === currentCharts?.length &&
          l.numComplexWidgets === complexWidgets
      );
      if (!newLayout) {
        newLayout = LAYOUTS.find(
          (l) =>
            l.numWidgets === 8 &&
            l.numComplexWidgets === complexWidgets &&
            l.variant === "c"
        );
      }
      let blocks: any = extractBlockIds(newLayout?.arranging as LayoutI);
      if (blocks.length > currentCharts?.length) {
        blocks = adjustArray(blocks, currentCharts?.length);
      }
      for (let i = 0; i < blocks?.length; i++) {
        const block = blocks[i];
        const chartType = findFirstCommonElement(currentCharts, block.widgets);
        const index = currentCharts.findIndex((c: string) => c === chartType);
        if (index !== -1) {
          currentCharts.splice(index, 1);
        }
        const blockId = block?.blockId;
        const chart = restWidgets?.find(
          (widget: any) => widget.chartType === chartType
        );
        const indexSuggestion = restWidgets?.findIndex(
          (widget: any) => widget.chartType === chartType
        );
        if (indexSuggestion !== -1) {
          restWidgets?.splice(indexSuggestion, 1);
        }
        switch (chart?.chartType) {
          case "mapChart":
            mapChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "areaChart":
            areaChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "lineChart":
            lineChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "barChart":
            barChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "lollipopChart":
            lollipopChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "sankey":
            sankeyChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "matrix":
            matrixChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "scatterplot":
            scatterplotChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "radar":
            radarChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "punchcardChart":
            punchcardChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "polarAreaChart":
            polarAreaChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "bubbleChart":
            bubbleChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          default:
        }
      }
      dispatch(
        requestUpdatePage({
          ...currentPage,
          templateId: newLayout?.id!,
        })
      );
    }

    const existCharts =
      barChartUpdateData?.length +
      lineChartUpdateData?.length +
      lollipopChartUpdateData?.length +
      sankeyChartUpdateData?.length +
      areaChartUpdateData?.length +
      matrixChartUpdateData?.length +
      mapChartUpdateData?.length +
      radarChartUpdateData?.length +
      scatterplotChartUpdateData?.length +
      punchcardChartUpdateData?.length +
      polarAreaChartUpdateData?.length +
      bubbleChartUpdateData?.length;
    if (currentPage?.id && existCharts > 0) {
      for (let kpi of aiKpi.items) {
        dispatch(requestDeleteKpis({ id: kpi.id }));
      }
      dispatch(setIsLoading(true));
      dispatch(setPageWidgets({ items: [], count: 0 }));
      if (existCharts > 0) {
        dispatch(
          requestUpdateWidgets({
            barChart: barChartUpdateData,
            lineChart: lineChartUpdateData,
            lollipopChart: lollipopChartUpdateData,
            sankeyChart: sankeyChartUpdateData,
            areaChart: areaChartUpdateData,
            matrixChart: matrixChartUpdateData,
            mapChart: mapChartUpdateData,
            scatterplotChart: scatterplotChartUpdateData,
            polarAreaChart: polarAreaChartUpdateData,
            punchcardChart: punchcardChartUpdateData,
            radarChart: radarChartUpdateData,
            bubbleChart: bubbleChartUpdateData,
            pageId: currentPage?.id,
          })
        );
      }
    }
    handleOnClose();
  };

  const firstKpi = useMemo(() => aiKpi.items?.at(0), [aiKpi.items]);

  useEffect(() => {
    if (aiKpi.items?.length) {
      setSelectedKpis(aiKpi.items?.map((k) => k.query) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiKpi.items]);

  const getSuggestions = useCallback(() => {
    if (id && currentPage?.id) {
      dispatch(
        requestWidgetsSuggestions({
          pageId: currentPage?.id,
          projectId: id,
          includeData: true,
          withKpi: true,
          callbacks: {
            onSuccess: (suggestions) => {
              setLocalLoading(false);
              setRecommendedKpis([...aiKpi.items, ...suggestions.kpis]);
            },
          },
        })
      );
    }
  }, [aiKpi.items, currentPage?.id, dispatch, id]);

  useEffect(() => {
    if (id && currentPage?.id) {
      setLocalLoading(true);
      getSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentPage?.id]);

  const isDisabled = useMemo(() => {
    const currentKpis = aiKpi.items?.map((k) => k.query) || [];
    return _.isEqual(currentKpis, selectedKpis);
  }, [aiKpi.items, selectedKpis]);

  return (
    <ModalOverlay
      $isClosing={isClosing}
      onClick={handleOnClose}
      $noBlur
      $centred
    >
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $recommendedWidgetsStyles
      >
        <ModalHeadingWrapperExt>
          <ModalHeadingNameWrapper>
            <ModalHeading>{firstKpi?.name}</ModalHeading>
            <CloseModal onClick={handleOnClose} />
          </ModalHeadingNameWrapper>
          <ModalSubmenu>
            <SubmenuItem $selected={true}>
              <SubmenuTitle>Configure Widget</SubmenuTitle>
            </SubmenuItem>
          </ModalSubmenu>
        </ModalHeadingWrapperExt>

        <OverviewContentExt>
          <KpiSettings
            isLoading={isLoading}
            selectedKpis={selectedKpis}
            recommendedKpis={[...recommendedKpis]}
            setRecommendedKpis={(newKpis) => {
              setRecommendedKpis(newKpis);
            }}
            setSelectedKpis={setSelectedKpis}
            nrAvailbleKpi={nrAvailbleKpi}
          />
        </OverviewContentExt>

        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <WrapperButtons>
            <Button
              name="Remove Widget"
              onClick={() => removeKpi()}
              variant="danger"
              size="medium"
              icon={<Trash />}
            />
            <Button
              name="Save"
              onClick={handleUpdateKpis}
              disabled={isDisabled}
              variant={isDisabled ? "neutral" : "primary"}
              size="medium"
              icon={<CheckCircle />}
            />
          </WrapperButtons>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
