import React from "react";
import {
  EmptyWidgetsDetailsDescription,
  EmptyWidgetsDetailsTitle,
  EmptyWidgetsDetailsWrapper,
  EmptyWidgetsIcon,
  EmptyWidgetsWrapper,
} from "./styles";

export const EmptyWidgets = () => {
  return (
    <EmptyWidgetsWrapper>
      <EmptyWidgetsIcon />
      <EmptyWidgetsDetailsWrapper>
        <EmptyWidgetsDetailsTitle>No widgets added</EmptyWidgetsDetailsTitle>
        <EmptyWidgetsDetailsDescription>
          Click “Recommended widgets” to find the most appropriate data
          visualizations.
        </EmptyWidgetsDetailsDescription>
      </EmptyWidgetsDetailsWrapper>
    </EmptyWidgetsWrapper>
  );
};
