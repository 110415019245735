export interface LayoutI {
  blockId?: number;
  rows?: RowI[];
  columns?: ColumnI[];
}

export interface RowI {
  columns: ColumnI[];
  height?: string;
}

export interface ColumnI {
  blockId?: number;
  rows?: RowI[];
  columns?: ColumnI[];
}

export const extractBlockIds = (obj: LayoutI | undefined): LayoutI[] => {
  const blocks: LayoutI[] = [];

  function recursiveExtract(item: any): void {
    if (item && typeof item === "object") {
      if ("blockId" in item) {
        blocks.push(item);
      }

      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key])) {
          item[key].forEach((subItem: any) => recursiveExtract(subItem));
        } else if (typeof item[key] === "object") {
          recursiveExtract(item[key]);
        }
      });
    }
  }

  recursiveExtract(obj);
  return blocks;
};
