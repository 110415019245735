import React, { useEffect, useState } from "react";
import {
  CountLegendItems,
  CountLegendModal,
  LegendItemColor,
  LegendItemName,
  LegendItemWrapper,
  LegendPalette,
  LegendPaletteColor,
  LegendPaletteWrapper,
  LegendWrapper,
  UnitLegend,
} from "./styles";
import { useSelector } from "react-redux";
import { getPageSettings } from "../../store/selectors/projects";
import { colorsPalettes } from "../../constants";
import { ColorRangeI } from "../../models/Pages";

export interface ChartLegendValue {
  label: string;
  color: string;
  key?: string;
}
interface ChartLegendInterface {
  legendType: "unit" | "palette";
  legendValues?: ChartLegendValue[];
  colorRanges?: ColorRangeI[];
  chartWidth?: number;
  isRight?: boolean;
  offset?: number;
}
export const ChartLegend = ({
  legendType,
  legendValues,
  colorRanges,
  chartWidth,
  isRight,
  offset,
}: ChartLegendInterface) => {
  const { styleId } = useSelector(getPageSettings);
  const colorPalette = colorsPalettes.find((palette) => palette.id === styleId);
  const unitColor2 = colorPalette?.colors[1].mainColor;
  const colorPaletteVariations = colorPalette?.colors.find(
    (color) => color.mainColor === unitColor2
  )?.variations;

  const calculateLabelWidth = (label: string): number => {
    return label.length * 7 + 17;
  };
  const priorityLabels = ["Other", "All"];

  const [data, setData] = useState<ChartLegendValue[]>([]);

  const sortByPriority = (a: ChartLegendValue, b: ChartLegendValue) => {

    const aPriority = priorityLabels.includes(a.label) ? 1 : 0;
    const bPriority = priorityLabels.includes(b.label) ? 1 : 0;

    if (aPriority === bPriority) {
      return 0;
    }

    return aPriority - bPriority;
  }

  useEffect(() => {
    const result: ChartLegendValue[] = [];
    let currentWidth = 25;
    let nonPriorityFullyAdded = true;
    
    legendValues?.forEach((item) => {
      const labelWidth = calculateLabelWidth(item.label);
  
      if (priorityLabels.includes(item.label)) {
        if (nonPriorityFullyAdded && currentWidth + labelWidth < chartWidth!) {
          result.push(item);
          currentWidth += labelWidth;
        }
      } else {
        if (currentWidth + labelWidth < chartWidth!) {
          result.push(item);
          currentWidth += labelWidth;
        } else {
          nonPriorityFullyAdded = false; 
        }
      }
    });
  
    setData(result.sort(sortByPriority));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legendValues, chartWidth]);

  return (
    <LegendWrapper $isRight={isRight} $offset={offset}>
      {legendType === "unit" && (
        <UnitLegend>
          {data?.map((legendValue, index) => {
            return (
              <LegendItemWrapper key={legendValue + String(index)}>
                <LegendItemColor $color={legendValue.color}></LegendItemColor>
                <LegendItemName>{legendValue.label}</LegendItemName>
              </LegendItemWrapper>
            );
          })}
          {legendValues?.length! > data?.length! && (
            <CountLegendItems>
              +{legendValues?.length! - data?.length!}
              <CountLegendModal>
                {legendValues && [...legendValues]?.sort(sortByPriority)?.map((legendValue, index) => {
                  if (!data?.includes(legendValue)) {
                    return (
                      <LegendItemWrapper key={legendValue + String(index)}>
                        <LegendItemColor
                          $color={legendValue.color}
                        ></LegendItemColor>
                        <LegendItemName>{legendValue.label}</LegendItemName>
                      </LegendItemWrapper>
                    );
                  }
                  return null;
                })}
              </CountLegendModal>
            </CountLegendItems>
          )}
        </UnitLegend>
      )}
      {legendType === "palette" && !colorRanges?.length ? (
        <LegendPaletteWrapper>
          Low
          <LegendPalette>
            {colorPaletteVariations?.map((color: string, index) => (
              <LegendPaletteColor
                $color={color}
                key={index}
              ></LegendPaletteColor>
            ))}
          </LegendPalette>
          High
        </LegendPaletteWrapper>
      ) : null}
      {legendType === "palette" && colorRanges?.length ? (
        <LegendPaletteWrapper>
          Low
          <LegendPalette>
            {colorRanges?.map((range: ColorRangeI, index) => (
              <LegendPaletteColor
                $color={range.color}
                key={index}
              ></LegendPaletteColor>
            ))}
          </LegendPalette>
          High
        </LegendPaletteWrapper>
      ) : null}
    </LegendWrapper>
  );
};
