import { FC } from "react";
import * as s from "./styles";
import { ReactComponent as Logo } from "../../../../assets/logo-32px.svg";

export const LastPage: FC = () => {
  return (
    <s.PageContainer id="last-page">
      <s.TextSection>
        <s.MainTitle>Contact Us</s.MainTitle>
        <s.Subtitle>
          Please submit questions about any new initiative on this email
          DHCS.ca.gov
        </s.Subtitle>
      </s.TextSection>
      <s.FooterSection>
        <s.FooterLogo>
          <Logo />
        </s.FooterLogo>
        <s.FooterText>Health Information Management Division</s.FooterText>
      </s.FooterSection>
    </s.PageContainer>
  );
};
