export const getSectionStyles = (type?: string) => {
  switch (type) {
    case 'center':
      return {
        flexOrientation: 'column',
        informativeFlexOrientation: 'row'
      };

    case 'left':
      return {
        flexOrientation: 'row',
        informativeFlexOrientation: 'column'
      };

    case 'right':
      return {
        flexOrientation: 'row-reverse',
        informativeFlexOrientation: 'column'
      };

    case 'chart':
      return {
        flexOrientation: 'row',
        informativeFlexOrientation: 'column'
      };

    default:
      return {
        flexOrientation: 'row',
        informativeFlexOrientation: 'column'
      };
  }
}