import React, { useMemo, useRef, useState } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  HeadingNameAndButton,
  SettingsButtonWrapper,
  Title,
} from "../VerticalBarchart/styles";
import { setActiveModal } from "../../../store/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import useMeasure from "react-use-measure";
import { hexToRGBA } from "../../../helpers/hexToRgba";
import { getPageSettings } from "../../../store/selectors/projects";
import { ticksFormatter } from "../../../helpers/ticksFormatter";
import { calculateNumTicks } from "../widgetHelpers";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getCurrentColorV2 } from "../utils/getCurrentMarker";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { LabelTooltip, TickLabel } from "../components/LabelTooltip";
import { ChartLegend, ChartLegendValue } from "../../ChartLegend";
import { getBarChartKeys } from "../GroupedBarChart/utils/getKeys";
import { createPortal } from "react-dom";

interface GroupedBarChartInterface {
  storytelling?: boolean;
  recommended?: boolean;
  currentWidget: WidgetItem;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  preview?: boolean;
}

export const HorizontalBarChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  preview = false,
}: GroupedBarChartInterface) => {
  const isEditMode = useSelector(getIsEditMode);
  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const divRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const isPublicRoute = useSelector(getIsPublicMode);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [labelTooltip, setLabelTooltip] = useState<{
    data: string;
    x: number;
    y: number;
  } | null>(null);
  const [hoveredElement, setHoveredElement] = useState<string | null>(null);

  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const barChartSuggestion = aiSuggestions
    ?.filter((chart: AiSuggestionsDto) => chart.chartType === "barChart")
    ?.at(0);

  const xAxe = currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : barChartSuggestion?.xAxe?.at(0);
  const yAxe = currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : barChartSuggestion?.yAxe?.at(0);

  const barchartData = currentWidget?.data;
  const hasLongValue = barchartData?.some((el) => el[xAxe].length > 6);
  const margin = {
    top: 10,
    right: 10,
    bottom: barchartData?.length! > 7 ? -4 : 21,
    left: hasLongValue ? 80 : 40,
  };

  const legendValues: ChartLegendValue[] = [];

  const groupBy = currentWidget?.groupBy?.at(0);

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const keys =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : getBarChartKeys(currentWidget);

  for (let i = 0; i < keys?.length; i++) {
    const dataKey = keys?.at(i);
    legendValues.push({
      label: dataKey!,
      color: getCurrentColorV2(currentWidget, dataKey!, styleId || "default"),
    });
  }

  const xScale = scaleLinear<any>({
    nice: true
  })
    .domain(
      barchartData ? [0, Math.max(...barchartData.map((d) => d[yAxe]))] : []
    )
    .range([margin.left, width - margin.right]);

  const yScale = scaleBand()
    .domain(barchartData ? barchartData.map((d) => d[xAxe]) : [])
    .range([margin.top, height - margin.bottom])
    .padding(0)
    .align(0.5);

  const numTicks = calculateNumTicks({ height: height });

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);
  const refHeight = useMemo(
    () => divRef?.current?.parentElement?.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef, divRef.current]
  );
  if (!barchartData || !barchartData.length) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }
  const h = yScale.bandwidth();
  const barHeight = Math.min(h < 16 ? 16 : h, 32);
  const h2 = barchartData.length * (barHeight + 3);
  const svgHeight = h2 < (refHeight || 0) ? refHeight : h2;

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <div />}
            {!isPublicRoute && !recommended && isEditMode && !storytelling ? (
              <SettingsButtonWrapper
                $modalOpen={false}
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["barChart"]?.length}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {currentWidget?.formatting?.length! > 1 && legendValues?.length > 1 && showLegend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="unit"
            legendValues={legendValues}
          />
        )}
      </HeaderWrapper>
      <div
        ref={divRef}
        style={
          barchartData.length > 7
            ? {
              height: refHeight ? refHeight - 16 * 2 : 160,
              overflowY: "auto",
            }
            : { height: "100%" }
        }
      >
        <svg
          width="100%"
          height={barchartData.length > 7 ? svgHeight : "100%"}
          ref={ref}
        >
          <Group>
            {/* Right Dashed Line */}
            <line
              x1={width - margin.right}
              x2={width - margin.right}
              y1={margin.top}
              y2={height - margin.bottom}
              stroke="#ccc"
              strokeDasharray="1 2"
            />
            {yScale.domain().map((d, idx) => (
              <line
                key={idx}
                x1={margin.left}
                x2={width - margin.right}
                y1={yScale(d)!}
                y2={yScale(d)!}
                stroke="#ccc"
                strokeDasharray="1 2"
              />
            ))}
            {xScale.ticks(numTicks).map((tick, idx) => (
              <line
                key={idx}
                x1={xScale(tick)}
                x2={xScale(tick)}
                y1={margin.top}
                y2={height - margin.bottom}
                stroke="#ccc"
                strokeDasharray="1 2"
              />
            ))}
          </Group>
          {!(barchartData.length > 7) && (
            <AxisBottom
              scale={xScale}
              top={height - margin.bottom}
              hideTicks
              numTicks={calculateNumTicks({ height })}
              tickFormat={(value: any) => {
                return ticksFormatter(value);
              }}
              tickLabelProps={(_, index, values) => {
                const isFirstTick = index === 0;
                const isLastTick = index === values.length - 1;
                const textAnchor =
                  (isFirstTick && "start") || (isLastTick && "end") || "middle";

                return {
                  fontSize: 10,
                  fill: "#5F6877",
                  textAnchor: textAnchor,
                  dx: 0,
                };
              }}
              axisLineClassName="barchartAxisLine"
            />
          )}
          <AxisLeft
            scale={yScale}
            top={0}
            hideAxisLine={true}
            left={margin.left}
            tickLineProps={{
              stroke: "#939BA7",
            }}
            numTicks={barchartData.length > 7 ? barchartData.length : numTicks}
            tickLabelProps={() => {
              return {
                fontSize: 11,
                fill: "#5F6877",
                textAnchor: "start",
                dominantBaseline: "middle",
                dx: hasLongValue ? -80 : -40,
              };
            }}
            tickComponent={(props) => (
              <TickLabel
                {...props}
                setTooltip={setLabelTooltip}
                length={hasLongValue ? 10 : 4}
                offsetX={-10}
              />
            )}
          />
          <Group>
            {barchartData?.map((d, idx) => {
              const barWidth = (xScale(d[yAxe]) as any) - margin.left;
              const legend = legendValues.find((r) => r.label === d[xAxe]) || {
                color: "#7BA4B7",
              };
              const color = getCurrentColorV2(
                currentWidget,
                d[groupBy!] ?? "default",
                "default"
              );

              const key = `Bar-${idx}`;
              return (
                <Bar
                  key={key}
                  x={margin.left}
                  style={{
                    transition: "0.2s",
                    opacity: hoveredElement
                      ? hoveredElement === key
                        ? 1
                        : 0.2
                      : 1,
                  }}
                  y={
                    yScale(d[xAxe])! + (yScale.bandwidth() - barHeight) / 2 + 2
                  }
                  width={barWidth > 0 ? barWidth : 0}
                  height={barHeight - 3}
                  fill={color ? hexToRGBA(color, 0.8) : legend.color || ""}
                  onMouseMove={(event: any) => {
                    if (
                      (showTooltip || currentWidget.tooltip) &&
                      !recommended
                    ) {
                      const { pageX, pageY, clientX, clientY } = event;
                      const coords = { pageX, pageY, clientX, clientY };
                      setHoveredElement(key);
                      setTooltip({
                        name: d[groupBy!] ?? undefined,
                        data: d,
                        coords,
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    setTooltip(null);
                    setHoveredElement(null);
                  }}
                />
              );
            })}
          </Group>
        </svg>
      </div>
      {barchartData.length > 7 && (
        <svg height={"25"} width={"100%"}>
          <AxisBottom
            scale={xScale}
            top={1}
            hideTicks
            numTicks={calculateNumTicks({ width })}
            tickFormat={(value: any) => {
              return ticksFormatter(value);
            }}
            tickLabelProps={(_, index, values) => {
              const isFirstTick = index === 0;
              const isLastTick = index === values.length - 1;
              const textAnchor =
                (isFirstTick && "start") || (isLastTick && "end") || "middle";

              return {
                fontSize: 10,
                fill: "#5F6877",
                textAnchor: textAnchor,
                dx: 0,
              };
            }}
            axisLineClassName="barchartAxisLine"
          />
        </svg>
      )}

      {tooltip &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
            coords={tooltip.coords}
          />,
          document.body
        )}
      {labelTooltip &&
        createPortal(
          <LabelTooltip
            x={labelTooltip?.x}
            y={labelTooltip?.y}
            data={labelTooltip?.data}
          />,
          document.body
        )}
    </>
  );
};
