import styled from "styled-components";
import { ChevronUp } from "react-bootstrap-icons";

export const Container = styled.div<{
  $disableMargin?: boolean;
}>`
  background: #e5f3ee;
  border-radius: 25px;
  border: 1px solid #cee8df;
  padding: 0 3px;
  display: flex;
  ${({ $disableMargin }) => ($disableMargin ? "" : "margin-bottom: 7px")};
  align-items: center;
`;

export const UpArrow = styled(ChevronUp)`
  width: 9px;
  height: 6px;
  margin: 0 1.5px;
  path {
    fill: #2b9e77;
    stroke: #2b9e77;
    stroke-width: 5;
  }
`;

export const Text = styled.p`
  padding: 0 3px;
  border-left: 1px solid #cee8df;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
`;
