import * as s from "./styles";

interface MessageProps {
  author: string;
  avatarUrl?: string;
  time: string;
  message: string;
  isYou: boolean;
  onEdit: () => void;
}

export const Message: React.FC<MessageProps> = ({
  author,
  time,
  message,
  isYou,
  onEdit,
}) => {
  return (
    <s.CommentItem>
      <s.MessageContent>
      <s.CommnetsHead>
        <s.Author>
          {isYou ? "You" : author} <s.Time>{time}</s.Time>
        </s.Author>
        <s.ActionsContainer>
          {isYou && <s.PencilSquareIcon onClick={onEdit} />}
        </s.ActionsContainer>
      </s.CommnetsHead>
        <s.MessageText role="textbox" aria-label="Message content">
          {message}
        </s.MessageText>
      </s.MessageContent>
    </s.CommentItem>
  );
};
