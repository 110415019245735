import styled from "styled-components";

export const FilterWrapper = styled.div`
  width: 130px;
  height: 41px;
`;

export const FilterValuesBlock = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const FilterValueBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 4px;
  width: 100%;
`;

export const ValueDelimitator = styled.div<{ $menuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: 1px;
  height: 16px;
  background-color: ${({ theme, $menuOpen }) =>
    $menuOpen ? theme.primaryText : "transparent"};
  margin-left: 1px;
`;

export const FilterDelimitator = styled.div<{ $menuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: 1px;
  height: ${({ $menuOpen }) => ($menuOpen ? "36px" : "20px")};
  opacity: ${({ $menuOpen }) => ($menuOpen ? "0.2" : "1")};
  background-color: ${({ theme, $menuOpen }) =>
    $menuOpen ? theme.primaryBorder : theme.neutralBorder};
  margin-left: 1px;
`;

export const FilterLabel = styled.div`
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.neutralWeak};
`;

export const FilterValues = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FilterValue = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralText};
`;

export const FilterLength = styled.span`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.neutralBackground};
  border-radius: 8px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  justify-content: center;
  align-items: center;
  padding: 1px 3px;
  color: ${({ theme }) => theme.neutralWeak};
  margin-left: 4px;
`;

export const FilterOption = styled.label<{ $isAll: boolean }>`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.neutralText};
  margin-left: ${({ $isAll }) => ($isAll ? "-14px" : "8px")};
`;

export const FilterBlockItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterItem = styled.div<{ $checked: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme, $checked }) =>
    $checked ? theme.neutralButton : "transparent"};
  padding: 4px 6px;
  border-radius: 4px;
  margin-bottom: 1px;
`;

export const FilterItemDelimitator = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.neutralBorder};
  margin: 6px 0;
  width: 100%;
  height: 1px;
`;
