import { styled } from "styled-components";

export const StyledWrapper = styled.div`
  position: relative;
`;

export const CommentsButton = styled.button`
  all: unset;
  width: 32px;
  height: 32px;
`;

export const CommentsWrapper = styled.div<{ 
  $isStorytelling?: boolean;
  $isComparison?: boolean;
  $isReports?: boolean;
}>`
  position: absolute;
  ${({ $isStorytelling, $isComparison, $isReports }) => {
    if ($isReports) return "top: -30px; left: -40px";
    if ($isComparison) return "right: 45%; top: 0px;";
    if ($isStorytelling) return "left: -15px; top: 0px;";
    return "right: 25%; top: 0px;";
  }};
  isolation: isolate;
  z-index: 10;
`;