import styled from "styled-components";
import { X, ChevronDown } from "react-bootstrap-icons";

//SelectContainer

export const Container = styled.div<{
  $width?: string;
}>`
  width: ${({ $width }) => $width || "100%"};
  position: relative;
`;

export const SelectContainer = styled.div<{ $hasLabel?: boolean; $disabled: boolean }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: ${({ $hasLabel }) => $hasLabel ? "2px 10px" : "10px 10px 10px 14px;"};
  border-radius: 6px;
  user-select: none;
  cursor: ${({ $disabled }) => $disabled ? "not-allowed" : "pointer"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ $disabled, theme }) => $disabled ? theme.neutralBackgroundMedium : 'inherit'};
  color: ${({ $disabled, theme }) => $disabled ? theme.neutralTextDisabled : theme.neutralText};
  span {
    color: ${({ $disabled, theme }) => $disabled ? theme.neutralTextDisabled : theme.neutralTextWeak};
  }
`;

export const SelectedValue = styled.p`
  font-size: 14px;
  width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const DownIcon = styled(ChevronDown)<{
  $close?: boolean;
}>`
  transition: 0.2s;
  transform: rotateX(${({ $close }) => ($close ? "180deg" : "0deg")});
  cursor: pointer;
`;

export const CloseIcon = styled(X)`
  margin-right: 7px;
  border-right: 1px solid ${({ theme }) => theme.neutralBorder};
  padding-right: 7px;
  width: 27px;
  height: 20px;
`;

//DropDownSelect

export const DropDownSelect = styled.div`
  position: absolute;
  margin-top: 10px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 10px;
  background: white;
  z-index: 999;
  max-height: 400px;
  overflow: auto;
`;
export const DropDownMenuList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
`;
export const DropDownMenuItem = styled.li`
  padding: 10px 14px;
  transition: 0.2s;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.neutralBackground};
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-bottom: 1px;
`;