interface trackZoomLevelI {
  map: any;
  setZoomLevel: (zoomLevel: number) => any;
}
export const trackZoomLevel = ({ map, setZoomLevel }: trackZoomLevelI) => {
  if (map) {
    map.on("zoomend", () => {
      setZoomLevel(map.getZoom());
    });
  }

  return () => {
    if (map) {
      map.off("zoomend");
    }
  };
};
