import React, { useEffect } from "react";
import {
  DotIcon,
  Dots,
  LoaderWrapper,
  LoadingSpinner,
  SpinnerContainer,
} from "./styles";
interface LoaderInterface {
  fullScreen?: boolean;
  blur?: boolean;
}
export const Loader = ({
  fullScreen = false,
  blur = true,
}: LoaderInterface) => {
  useEffect(() => {
    if (fullScreen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoaderWrapper $fullScreen={fullScreen} $blur={blur}>
      <SpinnerContainer>
        <LoadingSpinner />
        <Dots>
          <DotIcon $animation={1} />
          <DotIcon $animation={2} />
          <DotIcon $animation={3} />
        </Dots>
      </SpinnerContainer>
    </LoaderWrapper>
  );
};
