import React, { useEffect } from "react";
import { Legend, RangeInput } from "./style";

type Slides = {
  slideValue: number;
  slideSet: any;
};

type PropsT = {
  min: number;
  max: number;
  activeSlider: number;
  setCurrentValue: any;
  setActiveSlider: any;
  currentValue?: number;
  sliders: Slides[];
};

const MultiRangeInput = ({
  min,
  max,
  activeSlider,
  setCurrentValue,
  setActiveSlider,
  currentValue,
  sliders,
}: PropsT) => {
  useEffect(() => {
    if (!!!currentValue) {
      setActiveSlider(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const onMouseOverHandle = (index: number, value: number) => {
    setActiveSlider(index);
    setCurrentValue(value);
  };

  return (
    <Legend>
      {sliders.map((item, index) => {
        if (index === 0) {
          return (
            <RangeInput
              style={{ zIndex: activeSlider === index ? 5000 : 4000 }}
              onMouseOver={() => onMouseOverHandle(index, item.slideValue)}
              min={min}
              max={max}
              step={1}
              value={item.slideValue}
              $active={activeSlider === index}
              onChange={(e: any) => {
                if (
                  Number(e.target.value) >=
                  (sliders[index + 1]?.slideValue ?? 100)
                ) {
                } else {
                  setCurrentValue(e.target.value);
                  item.slideSet(Number(e.target.value));
                }
              }}
            />
          );
        }

        if (index < sliders.length - 1) {
          return (
            <RangeInput
              style={{ zIndex: activeSlider === index ? 5000 : 4000 }}
              onMouseOver={() => onMouseOverHandle(index, item.slideValue)}
              min={min}
              max={max}
              step={1}
              $active={activeSlider === index}
              value={item.slideValue}
              onChange={(e: any) => {
                if (
                  Number(e.target.value) >=
                    (sliders[index + 1]?.slideValue ?? 100) ||
                  Number(e.target.value) <=
                    (sliders[index - 1]?.slideValue ?? 100)
                ) {
                } else {
                  setCurrentValue(e.target.value);
                  item.slideSet(Number(e.target.value));
                }
              }}
            />
          );
        }

        if (index === sliders.length - 1) {
          return (
            <RangeInput
              style={{ zIndex: activeSlider === index ? 5000 : 4000 }}
              onMouseOver={() => onMouseOverHandle(index, item.slideValue)}
              min={min}
              max={max}
              $active={activeSlider === index}
              step={1}
              value={item.slideValue}
              onChange={(e: any) => {
                if (
                  Number(e.target.value) <=
                  (sliders[index - 1]?.slideValue ?? 100)
                ) {
                } else {
                  setCurrentValue(e.target.value);
                  item.slideSet(Number(e.target.value));
                }
              }}
            />
          );
        }

        return <></>;
      })}
    </Legend>
  );
};

export default MultiRangeInput;
