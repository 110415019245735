import React, { ChangeEvent } from "react";
import {
  EssentialsBlock,
  EssentialsWrapper,
  FullNameBlockWrapper,
  SwitchWrapper,
  SwitchText,
  GapFlexBlock
} from "../styles";
import { FormikErrors, FormikTouched } from "formik";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";

type PermissionsProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  values: { [key: string]: string[] };
  errors: {
    [key: string]:
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined;
  };
  touched: {
    [key: string]:
    | boolean
    | FormikTouched<any>
    | FormikTouched<any>[]
    | undefined;
  };
};

export const Permissions: React.FC<PermissionsProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  return (
    <EssentialsBlock>
      <EssentialsWrapper>
        <FullNameBlockWrapper>
          <GapFlexBlock>
            <SwitchWrapper>
              <SwitchText>Is this user an Administrator?</SwitchText>
              <SwitchComponent
                name={'isAdmin'}
                checked={!!values['isAdmin']?.length}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.isAdmin as string}
                touched={!!touched.isAdmin}
                labelSide="left"
                disableMargin
                label={!!values['isAdmin']?.length ? 'Yes' : 'No'}
              />
            </SwitchWrapper>
            <SwitchWrapper>
              <SwitchText>Can Add Projects?</SwitchText>
              <SwitchComponent
                name={'addProjects'}
                checked={!!values['addProjects']?.length}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.addProjects as string}
                touched={!!touched.addProjects}
                labelSide="left"
                disableMargin
                label={!!values['addProjects']?.length ? 'Yes' : 'No'}
              />
            </SwitchWrapper>
            <SwitchWrapper>
              <SwitchText>Give Automatic Access to AII Future Projects?</SwitchText>
              <SwitchComponent
                name={'autoAccess'}
                checked={!!values['autoAccess']?.length}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.autoAccess as string}
                touched={!!touched.autoAccess}
                labelSide="left"
                disableMargin
                label={!!values['autoAccess']?.length ? 'Yes' : 'No'}
              />
            </SwitchWrapper>
          </GapFlexBlock>
        </FullNameBlockWrapper>
      </EssentialsWrapper>
    </EssentialsBlock>
  );
};
