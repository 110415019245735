const TIME_INTERVALS = [
  { unit: 'year', seconds: 31536000, max: Infinity },
  { unit: 'month', seconds: 2592000, max: 12 },
  { unit: 'week', seconds: 604800, max: 4 },
  { unit: 'day', seconds: 86400, max: 7 },
  { unit: 'hour', seconds: 3600, max: 24 },
  { unit: 'minute', seconds: 60, max: 60 }
] as const;

export const calculateTimeElapsed = (timestamp: string): string => {
  const providedDate = new Date(timestamp);
  const currentDate = new Date();
  
  const localProvidedDate = new Date(
    providedDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
  );
  const localCurrentDate = new Date(
    currentDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
  );
  
  const diffMs = localCurrentDate.getTime() - localProvidedDate.getTime();
  const diffSeconds = Math.floor(diffMs / 1000);
  
  if (diffSeconds < 60) {
    return 'just now';
  }

  const minutes = Math.floor(diffSeconds / 60);
  const hours = Math.floor(minutes / 60);
  
  if (hours < 1 && minutes <= 60) {
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  }
  
  for (const interval of TIME_INTERVALS) {
    const value = Math.floor(diffSeconds / interval.seconds);
    if (value > 0 && value <= interval.max) {
      return `${value} ${interval.unit}${value === 1 ? '' : 's'} ago`;
    }
  }

  return 'a long time ago';
};