import { dimensionIcons, Dimensions, IconState } from "../assets/dimensionIcons";

interface ISuggestedTypeProps {
  type: string;
  subtype: string;
  state?: IconState;
}

export const getIconSrc = ({type, state = 'strong'}: ISuggestedTypeProps) => {
  const icons = dimensionIcons(state);
  const iconName = type as keyof Dimensions;

  return icons[iconName];
};
