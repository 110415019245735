import { styled } from "styled-components"
import { ArrowClockwise } from "react-bootstrap-icons"
export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const RefreshButton = styled(ArrowClockwise)<{
  $show: boolean
}>`
  position: absolute;
  width: 20px;
  height: 20px;
  z-index:10000;
  fill: #493DDD;
  right:0;
  top: 0;
  opacity:0;
  transition: 0.5s;

  ${({$show}) => $show &&`
    opacity: 1;
    cursor: pointer;
  `}
  
  &:hover {
    transform: rotateZ(360deg);
  }
`

export const RangeWrapper = styled.div`
  position:relative;
  border: 1px solid transparent;
  width: 100%;
  height: 30px;
  right:0;
  bottom: 13px;
` 

export const ChartAndLineWrapper = styled.div`
  position:relative;
  border: 1px solid transparent;
  width: 100%;
  right:0;
  bottom:0;
  overflow: visible;
  height: 100px;
` 
export const LineWrapper = styled.div`
  position:absolute;
  border: 1px solid transparent;
  width: 100%;
  right:0;
  bottom: 1px;
  overflow: visible;
  height: 100px;

  @-moz-document url-prefix() {
    bottom:-40px;
  }
` 

export const ColorsLine = styled.div<{
  $colors: string[]
  $ranges: number[]
}>`
  overflow: hidden;
  border-radius: 6px;
  width:100%;
  height: 12px;
  position: absolute;
  top:0;
  background-image: linear-gradient(
    to right, 
    ${({ $colors }) => $colors[0]} 0%,
    ${({ $colors, $ranges }) => `${$colors[0]} ${$ranges[0]}%`},
    ${({ $colors, $ranges }) => `${$colors[1]} ${$ranges[0]}%`},
    ${({ $colors, $ranges }) => `${$colors[1]} ${$ranges[1]}%`},
    ${({ $colors, $ranges }) => `${$colors[2]} ${$ranges[1]}%`},
    ${({ $colors, $ranges }) => `${$colors[2]} ${$ranges[2]}%`},
    ${({ $colors, $ranges }) => `${$colors[3]} ${$ranges[2]}%`},
    ${({ $colors, $ranges }) => `${$colors[3]} ${$ranges[3]}%`},
    ${({ $colors, $ranges }) => `${$colors[4]} ${$ranges[3]}%`},
    ${({ $colors, $ranges }) => `${$colors[4]} ${$ranges[4]}%`},
    ${({ $colors, $ranges }) => `${$colors[5]} ${$ranges[4]}%`},
    ${({ $colors, $ranges }) => `${$colors[5]} ${$ranges[5]}%`},
    ${({ $colors, $ranges }) => `${$colors[6]} ${$ranges[5]}%`},
    ${({ $colors, $ranges }) => `${$colors[6]} 100% `}
  );
`

// export const ColorsDiv = styled.div`
//   overflow: hidden;
//   border-radius: 6px;
//   width:100%;
//   height: 12px;
//   position: absolute;
//   top:0;
// `
// export const Color = styled.div<{
//   $color:string;
//   $width: number;
//   $zIndex: number;
// }>`
//   position: absolute;
//   top:0;
//   left:0;
//   background: ${({$color}) => $color };
//   width: ${({$width}) => $width + '%' };
//   height: 12px;
//   z-index: ${({$zIndex}) => $zIndex };
// `

export const Values = styled.div`
  margin-top: 2px;
  position: relative;
  left:0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1px;
`

export const InputValue = styled.input<{
  $width: number
}>`
  padding: 3px 4px;
  border-radius: 4px;
  border: 1px solid  ${({theme}) => theme.primaryBorder};
  width: ${({$width}) => `${$width}px`};
  outline: none;
  text-align: center;
  color: ${({theme}) => theme.neutralText};
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
`

export const Value =  styled.p`
  color: ${({theme}) => theme.neutralTextWeak};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`
export const InputWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
`