import { styled } from "styled-components";

export const Container = styled.div`
`

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .select-measure {
    width: 400px;
  }
`