import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

const ON_START_DELAY = 100;
const RESET_DELAY = 3000; // 3 seconds

async function preloadFonts() {
  const fontUrls = [
    'https://fonts.gstatic.com/s/inter/v18/UcC73FwrK3iLTeHuS_nVMrMxCp50SjIa1pL7W0Q5n-wU.woff2',
  ];

  await Promise.all(
    fontUrls.map(url => 
      new FontFace('Inter', `url(${url})`).load().then(font => {
        document.fonts.add(font);
      })
    )
  );
}

export async function downloadPDF(
  element: React.RefObject<HTMLDivElement>, 
  fileName: string,
  updateLoader: (progress: number) => void
) {
  try {
    updateLoader(5);
    await preloadFonts();
    updateLoader(10);

    const doc = new jsPDF({
      orientation: "p",
      unit: "px",
      format: "a4",
      hotfixes: ["px_scaling"],
      putOnlyUsedFonts: true,
      compress: true,
    });

    doc.setFontSize(8);
    let y = 0; // Start y position at top padding
    const x = 0; // Start x position at left padding
    const margin = 0;
    const padding = 0;
    const imageQuality = 1;
    const documentHeight = doc.internal.pageSize.getHeight();
    const documentWidth = doc.internal.pageSize.getWidth();
    const innerPageWidth = documentWidth - (padding * 2);
    const innerPageHeight = documentHeight - (padding * 2);

    const imageSettings = {
      quality: imageQuality,
      pixelRatio: 2,
      backgroundColor: "#FFFFFF",
      cacheBust: true,
      fontEmbedCSS: '@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");',
    };

    const makeSnapshot = async (element: HTMLElement): Promise<string | null> => {
      return await htmlToImage.toJpeg(element, imageSettings);
    };

    const addContent = async (content: HTMLElement, isFullPage: boolean = false) => {
      const canvas = await makeSnapshot(content);
      if (canvas) {
        const contentRatio = content.clientWidth / content.clientHeight;
        let contentHeight = innerPageWidth / contentRatio;

        if (isFullPage) {
          contentHeight = innerPageHeight;
        }

        if (y + contentHeight > innerPageHeight && !isFullPage) {
          doc.addPage();
          y = padding;
        }

        doc.addImage(
          canvas,
          'JPEG',
          x,
          y,
          innerPageWidth,
          contentHeight
        );

        y += contentHeight + (isFullPage ? 0 : margin);
      }
    };

    const generatePDF = async () => {
      const firstPage = element.current?.querySelector("#first-page");
      const tableOfContent = element.current?.querySelector("#table-of-content");
      const content = element.current?.querySelector("#content");
      const lastPage = element.current?.querySelector("#last-page");

      await new Promise((res) => setTimeout(res, ON_START_DELAY));

      if (firstPage) {
        await addContent(firstPage as HTMLElement, true);
        updateLoader(20);
      } else {
        updateLoader(20);
      }

      if (tableOfContent) {
        const tableOfContentSections = Array.from(tableOfContent.children || []);
        for (let i = 0; i < tableOfContentSections.length; i++) {
          await addContent(tableOfContentSections[i] as HTMLElement);
          updateLoader(20 + (i + 1) * (20 / tableOfContentSections.length));
        }
      } else {
        updateLoader(40);
      }

      if (content) {
        const contentSections = Array.from(content.children || []);
        for (let i = 0; i < contentSections.length; i++) {
          await addContent(contentSections[i] as HTMLElement);
          updateLoader(40 + (i + 1) * (50 / contentSections.length));
        }
      }

      if (lastPage) {
        if (y > padding) {
          doc.addPage();
          y = padding;
        }
        await addContent(lastPage as HTMLElement, true);
      }

      updateLoader(95);

      doc.save(fileName);
      updateLoader(100);

      // Set a timeout to reset loader to 0 after 3 seconds
      setTimeout(() => {
        updateLoader(0);
      }, RESET_DELAY);
    };

    await generatePDF();
  } catch (error) {
    console.error(`Error in PDF generation: ${error}`);
    updateLoader(0);
  }
}
