import { styled } from "styled-components";

export const Container = styled.div`
  gap: 16px;
  width: 918px;
  height: 1295px;
  border-radius: var(--layout-card-l-storytelling-radius, 8px);
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-size: 32px;
  color: ${({ theme }) => theme.neutralText};
  margin-bottom: 24px;
  margin-top: 24px;
  font-weight: 600;
`;

export const PageSection = styled.div`
  margin-bottom: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const PageTitle = styled.h3`
  font-size: 20px;
  color: ${({ theme }) => theme.neutralText};
  font-weight: 400;
  margin-bottom: 12px;
`;

export const ItemList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  border-left: 1px solid ${({ theme }) => theme.neutralBorder};
`;

export const ItemTitle = styled.span`
  color: ${({ theme }) => theme.neutralText};
  font-size: 16px;
  font-weight: 400;
`;

export const ItemNumber = styled.span`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 16px;
  font-weight: 400;
`;

export const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 16px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;