import styled from "styled-components";
import { ReactComponent as EmptyDatasetImg } from "../../assets/emptyDataset.svg";

export const NoDatasetWrapper = styled.div<{ $datasetLibrary: boolean }>`
  display: flex;
  margin: ${({ $datasetLibrary }) =>
    $datasetLibrary ? "50% auto" : "10% auto"};
  flex-direction: column;
  align-items: center;
`;

export const NoDatasetIcon = styled(EmptyDatasetImg)<{
  $datasetLibrary: boolean;
}>`
  ${({ $datasetLibrary }) =>
    $datasetLibrary ? "width: 96px; height: 96px" : ""}
`;

export const NoDatasetDetailsWrapper = styled.div<{ $datasetLibrary: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: ${({ $datasetLibrary }) => ($datasetLibrary ? "416px" : "433px")};
  margin: 24px 0;
`;

export const NoDatasetTitle = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 16px;
  font-weight: 600;
`;

export const NoDatasetDescription = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;
