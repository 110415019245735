import { useEffect, useRef, useState } from "react";

export const useSyncedState = <T extends unknown = any>(key: string, initialValue: T) => {
  // Initialize state
  const [state, setState] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) as T : initialValue;
    } catch {
      return initialValue;
    }
  });

  // Initialize BroadcastChannel
  const channelRef = useRef<BroadcastChannel | null>(null);

  useEffect(() => {
    // Create channel
    channelRef.current = new BroadcastChannel('comments-sync');

    // Listen for messages
    const handleMessage = (event: MessageEvent) => {
      if (event.data.key === key) {
        setState(event.data.value as T);
      }
    };

    channelRef.current.addEventListener('message', handleMessage);

    // Observe localStorage changes (fallback for older browsers)
    const handleStorage = (e: StorageEvent) => {
      if (e.key === key && e.newValue !== null) {
        setState(JSON.parse(e.newValue) as T);
      }
    };

    window.addEventListener('storage', handleStorage);

    return () => {
      channelRef.current?.close();
      window.removeEventListener('storage', handleStorage);
    };
  }, [key]);

  // Update function that syncs across all tabs/components
  const updateState = (newValue: T | ((prevValue: T) => T)) => {
    const valueToStore = newValue instanceof Function ? newValue(state) : newValue;
    
    // Update localStorage
    localStorage.setItem(key, JSON.stringify(valueToStore));
    
    // Broadcast the change
    channelRef.current?.postMessage({ key, value: valueToStore });
    
    // Update local state
    setState(valueToStore);
  };

  return [state, updateState] as const;
};