import React from "react";

export const handleColumnClick = (
  header: string,
  event: React.MouseEvent,
  key: string,
  errorsDetected: boolean,
  suggestedType?: string
) => {
  const rect = event.currentTarget.getBoundingClientRect();
  const top = rect.bottom;
  const left = rect.left;
  return { header, top, left, suggestedType, key, errorsDetected };
};
