export const colorsArray = [
  {
    id: "default",
    colors: [
      { key: 1, hex: "#473DD9" },
      { key: 2, hex: "#938CFF" },
      { key: 3, hex: "#36C4A5" },
      { key: 4, hex: "#ADF4E5" },
      { key: 5, hex: "#BDBCC8" },
      { key: 6, hex: "#56546D" },
      { key: 7, hex: "#FF8C47" },
      { key: 8, hex: "#3C33B5FF" },
      { key: 9, hex: "#938CFF" },
      { key: 10, hex: "#36C4A5" },
      { key: 11, hex: "#89E8D4" },
      { key: 12, hex: "#BDBCC8" },
      { key: 13, hex: "#56546D" },
      { key: 14, hex: "#FF8C47" },
      { key: 15, hex: "#473DD9" },
      { key: 16, hex: "#89A3F7FF" },
      { key: 17, hex: "#238F7D" },
      { key: 18, hex: "#ADF4E5" },
      { key: 19, hex: "#A09FA8" },
      { key: 20, hex: "#43425C" },
      { key: 21, hex: "#FFA36CFF" },
      { key: 22, hex: "#5448FFFF" },
      { key: 23, hex: "#88B9F6FF" },
      { key: 24, hex: "#2A8A70" },
      { key: 25, hex: "#94FFD3" },
      { key: 26, hex: "#91909A" },
      { key: 27, hex: "#36355E" },
      { key: 28, hex: "#FFBA91FF" },
      { key: 29, hex: "#7369FFFF" },
      { key: 30, hex: "#8CCBF1FF" },
      { key: 31, hex: "#49A588" },
      { key: 32, hex: "#A9FFDC" },
      { key: 33, hex: "#A6A5AB" },
      { key: 34, hex: "#48476E" },
      { key: 35, hex: "#FFD1B5FF" },
      { key: 36, hex: "#938CFFFF" },
      { key: 37, hex: "#98DAEBFF" },
      { key: 38, hex: "#6BC3A2" },
      { key: 39, hex: "#BEFFE4" },
      { key: 40, hex: "#BBB9C1" },
      { key: 41, hex: "#5B5980" },
      { key: 42, hex: "#FFE8DAFF" },
      { key: 43, hex: "#B5B0FFFF" },
      { key: 44, hex: "#A8E7E7FF" },
      { key: 45, hex: "#8FDDBF" },
      { key: 46, hex: "#D4FFED" },
      { key: 47, hex: "#D0CFD6" },
      { key: 48, hex: "#6F6D8A" },
      { key: 49, hex: "#D9D3FFFF" },
      { key: 50, hex: "#ADF4E5FF" },
      { key: 51, hex: "#B3E8D8" },
      { key: 52, hex: "#E9FFF6" },
      { key: 53, hex: "#E5E4EB" },
      { key: 54, hex: "#84829A" },
    ],
  },
  {
    id: "palette1",
    colors: [
      { key: 1, hex: "#213B34" },
      { key: 2, hex: "#2F584F" },
      { key: 3, hex: "#BFD3C5" },
      { key: 4, hex: "#C6ED62" },
      { key: 5, hex: "#B59869" },
      { key: 6, hex: "#D6CDB6" },
      { key: 7, hex: "#EAE3D1" },
      { key: 8, hex: "#659681" },
      { key: 9, hex: "#789F92" },
      { key: 10, hex: "#E5F1EC" },
      { key: 11, hex: "#F1FFB5" },
      { key: 12, hex: "#D9C3A1" },
      { key: 13, hex: "#F2EAD7" },
      { key: 14, hex: "#FFFFFF" },
      { key: 15, hex: "#4B7F6C" },
      { key: 16, hex: "#649684" },
      { key: 17, hex: "#D0E9E2" },
      { key: 18, hex: "#DEFF8F" },
      { key: 19, hex: "#C5B98E" },
      { key: 20, hex: "#DFE0C4" },
      { key: 21, hex: "#F6F6F6" },
      { key: 22, hex: "#347655" },
      { key: 23, hex: "#508775" },
      { key: 24, hex: "#BBE0D9" },
      { key: 25, hex: "#CBFF6A" },
      { key: 26, hex: "#B2AF7A" },
      { key: 27, hex: "#CCE0B2" },
      { key: 28, hex: "#ECECEC" },
      { key: 29, hex: "#1F6440" },
      { key: 30, hex: "#3D7666" },
      { key: 31, hex: "#A7D8D0" },
      { key: 32, hex: "#B8FF43" },
      { key: 33, hex: "#9FA667" },
      { key: 34, hex: "#B9DFA0" },
      { key: 35, hex: "#E1E1E1" },
      { key: 36, hex: "#095E2E" },
      { key: 37, hex: "#296657" },
      { key: 38, hex: "#92CFCA" },
      { key: 39, hex: "#A5FF1F" },
      { key: 40, hex: "#8C9D54" },
      { key: 41, hex: "#A7DF8E" },
      { key: 42, hex: "#D7D7D7" },
      { key: 43, hex: "#213B34" },
      { key: 44, hex: "#2F584F" },
      { key: 45, hex: "#BFD3C5" },
      { key: 46, hex: "#C6ED62" },
      { key: 47, hex: "#B59869" },
      { key: 48, hex: "#D6CDB6" },
      { key: 49, hex: "#EAE3D1" },
      { key: 50, hex: "#0A4D49" },
      { key: 51, hex: "#194F46" },
      { key: 52, hex: "#9FC8BD" },
      { key: 53, hex: "#A9FF29" },
      { key: 54, hex: "#948640" },
      { key: 55, hex: "#B8D094" },
      { key: 56, hex: "#D0CFC0" },
    ],
  },
  {
    id: "palette2",
    colors: [
      { key: 1, hex: "#F64844" },
      { key: 2, hex: "#012938" },
      { key: 3, hex: "#00465E" },
      { key: 4, hex: "#047474" },
      { key: 5, hex: "#01A085" },
      { key: 6, hex: "#41CC97" },
      { key: 7, hex: "#84EEA7" },
      { key: 8, hex: "#FFB5B3" },
      { key: 9, hex: "#3C5E55" },
      { key: 10, hex: "#3C6673" },
      { key: 11, hex: "#4B8C8C" },
      { key: 12, hex: "#4BC2B3" },
      { key: 13, hex: "#8EF6CF" },
      { key: 14, hex: "#D4FFDF" },
      { key: 15, hex: "#FF9A98" },
      { key: 16, hex: "#2A5148" },
      { key: 17, hex: "#2A5966" },
      { key: 18, hex: "#3A7E7E" },
      { key: 19, hex: "#3AAFA0" },
      { key: 20, hex: "#7DE7BE" },
      { key: 21, hex: "#C1FFCE" },
      { key: 22, hex: "#FF7F7C" },
      { key: 23, hex: "#194D5A" },
      { key: 24, hex: "#297171" },
      { key: 25, hex: "#297171" },
      { key: 26, hex: "#299A91" },
      { key: 27, hex: "#6CD8AD" },
      { key: 28, hex: "#AEFFBD" },
      { key: 29, hex: "#FF6460" },
      { key: 30, hex: "#07362D" },
      { key: 31, hex: "#073F4D" },
      { key: 32, hex: "#186464" },
      { key: 33, hex: "#178584" },
      { key: 34, hex: "#5BC99C" },
      { key: 35, hex: "#9DFFAC" },
      { key: 36, hex: "#FF4945" },
      { key: 37, hex: "#002920" },
      { key: 38, hex: "#004142" },
      { key: 39, hex: "#075757" },
      { key: 40, hex: "#067076" },
      { key: 41, hex: "#4BBB8C" },
      { key: 42, hex: "#8BFF9B" },
      { key: 43, hex: "#F64844" },
      { key: 44, hex: "#012938" },
      { key: 45, hex: "#00465E" },
      { key: 46, hex: "#047474" },
      { key: 47, hex: "#01A085" },
      { key: 48, hex: "#41CC97" },
      { key: 49, hex: "#84EEA7" },
      { key: 50, hex: "#FF2E2A" },
      { key: 51, hex: "#001C2B" },
      { key: 52, hex: "#00364F" },
      { key: 53, hex: "#035757" },
      { key: 54, hex: "#007E70" },
      { key: 55, hex: "#35B884" },
      { key: 56, hex: "#73DB96" },
    ]
  },
  {
    id: "palette3",
    colors: [
      { key: 1, hex: "#CFAF65" },
      { key: 2, hex: "#7B9579" },
      { key: 3, hex: "#2E509A" },
      { key: 4, hex: "#F0BEBA" },
      { key: 5, hex: "#E25828" },
      { key: 6, hex: "#322F36" },
      { key: 7, hex: "#B8D7D2" },
      { key: 8, hex: "#F7E6B8" },
      { key: 9, hex: "#AFC2A3" },
      { key: 10, hex: "#587CB3" },
      { key: 11, hex: "#FFEBE9" },
      { key: 12, hex: "#FF9260" },
      { key: 13, hex: "#5D5A62" },
      { key: 14, hex: "#E7F5F2" },
      { key: 15, hex: "#EFDFA7" },
      { key: 16, hex: "#9EB592" },
      { key: 17, hex: "#476E9F" },
      { key: 18, hex: "#FFDFDB" },
      { key: 19, hex: "#FF8050" },
      { key: 20, hex: "#4C4952" },
      { key: 21, hex: "#D4EBE6" },
      { key: 22, hex: "#E7D996" },
      { key: 23, hex: "#8CAE81" },
      { key: 24, hex: "#365F8A" },
      { key: 25, hex: "#FFD2CD" },
      { key: 26, hex: "#FF6F40" },
      { key: 27, hex: "#3B3843" },
      { key: 28, hex: "#C1E1DA" },
      { key: 29, hex: "#DFD285" },
      { key: 30, hex: "#7BA770" },
      { key: 31, hex: "#254F76" },
      { key: 32, hex: "#FFC5BF" },
      { key: 33, hex: "#FF5D30" },
      { key: 34, hex: "#2B282F" },
      { key: 35, hex: "#ADE7CE" },
      { key: 36, hex: "#D7CB75" },
      { key: 37, hex: "#6A9F60" },
      { key: 38, hex: "#133F61" },
      { key: 39, hex: "#FFB9B1" },
      { key: 40, hex: "#FF4C21" },
      { key: 41, hex: "#1A171F" },
      { key: 42, hex: "#9AEFC2" },
      { key: 43, hex: "#CFAF65" },
      { key: 44, hex: "#7B9579" },
      { key: 45, hex: "#2E509A" },
      { key: 46, hex: "#F0BEBA" },
      { key: 47, hex: "#E25828" },
      { key: 48, hex: "#322F36" },
      { key: 49, hex: "#B8D7D2" },
      { key: 50, hex: "#C79A54" },
      { key: 51, hex: "#68856B" },
      { key: 52, hex: "#1B456F" },
      { key: 53, hex: "#E5AFAA" },
      { key: 54, hex: "#C8451E" },
      { key: 55, hex: "#211E28" },
      { key: 56, hex: "#A3CDC1" },
    ]
  },
  {
    id: "palette4",
    colors: [
      { key: 1, hex: "#F56600" },
      { key: 2, hex: "#F69A02" },
      { key: 3, hex: "#1C0F49" },
      { key: 4, hex: "#222359" },
      { key: 5, hex: "#7BA3C9" },
      { key: 6, hex: "#01A2A6" },
      { key: 7, hex: "#E9E8E1" },
      { key: 8, hex: "#FFA645" },
      { key: 9, hex: "#FFD03F" },
      { key: 10, hex: "#4D3C72" },
      { key: 11, hex: "#4D4B73" },
      { key: 12, hex: "#A8C9E0" },
      { key: 13, hex: "#4BCED2" },
      { key: 14, hex: "#FFFFFF" },
      { key: 15, hex: "#FF9632" },
      { key: 16, hex: "#FFC02F" },
      { key: 17, hex: "#3B2F61" },
      { key: 18, hex: "#3C3A63" },
      { key: 19, hex: "#97B9D5" },
      { key: 20, hex: "#3ABCC1" },
      { key: 21, hex: "#F6F6F6" },
      { key: 22, hex: "#FF861F" },
      { key: 23, hex: "#FFB01E" },
      { key: 24, hex: "#2A2251" },
      { key: 25, hex: "#2B2953" },
      { key: 26, hex: "#86A8CA" },
      { key: 27, hex: "#29AAB0" },
      { key: 28, hex: "#ECECEC" },
      { key: 29, hex: "#FF750C" },
      { key: 30, hex: "#FF9F0E" },
      { key: 31, hex: "#191641" },
      { key: 32, hex: "#1A1843" },
      { key: 33, hex: "#7597BF" },
      { key: 34, hex: "#1898A0" },
      { key: 35, hex: "#E1E1E1" },
      { key: 36, hex: "#FF6500" },
      { key: 37, hex: "#FF8E00" },
      { key: 38, hex: "#080932" },
      { key: 39, hex: "#090733" },
      { key: 40, hex: "#6486B4" },
      { key: 41, hex: "#078690" },
      { key: 42, hex: "#D7D7D7" },
      { key: 43, hex: "#F56600" },
      { key: 44, hex: "#F69A02" },
      { key: 45, hex: "#1C0F49" },
      { key: 46, hex: "#222359" },
      { key: 47, hex: "#7BA3C9" },
      { key: 48, hex: "#01A2A6" },
      { key: 49, hex: "#E9E8E1" },
      { key: 50, hex: "#E35400" },
      { key: 51, hex: "#E78900" },
      { key: 52, hex: "#0E0840" },
      { key: 53, hex: "#13104C" },
      { key: 54, hex: "#688FAF" },
      { key: 55, hex: "#007C80" },
      { key: 56, hex: "#D0CFC0" },
    ]
  }
]
export const Qualitative2Colors1 = {
  id: 'Qualitative2Colors1',
  colors: [
    {
      key: '1',
      hex: '#7BA4B7'
    },
    {
      key: '2',
      hex: '#E47225'
    }
  ]
}

export const Qualitative2Colors2 = {
  id: 'Qualitative2Colors2',
  colors: [
    {
      key: '1',
      hex: '#7C93F2'
    },
    {
      key: '2',
      hex: '#656380'
    }
  ]
}

export const Qualitative4Colors = {
  id: 'Qualitative4Colors',
  colors: [
  {
    key: '1',
    hex: '#2D6E8D'
  },
  {
    key: '2',
    hex: '#EEA676'
  },
  {
    key: '3',
    hex: '#2F54EB'
  },
  {
    key: '4',
    hex: '#9E9DAF'
  }
]}

export const Qualitative12Colors = {
  id: 'Qualitative12Colors',
  colors: [
    {
      key: '1',
      hex: '#2D6E8D'
    },
    {
      key: '2',
      hex: '#EEA676'
    },
    {
      key: '3',
      hex: '#2F54EB'
    },
    {
      key: '4',
      hex: '#9E9DAF'
    },
    {
      key: '5',
      hex: '#43A047'
    },
    {
      key: '6',
      hex: '#DA7973'
    },
    {
      key: '7',
      hex: '#7BA4B7'
    },
    {
      key: '8',
      hex: '#E47225'
    },
    {
      key: '9',
      hex: '#7C93F2'
    },
    {
      key: '10',
      hex: '#656380'
    },
    {
      key: '11',
      hex: '#89C38B'
    },
    {
      key: '12',
      hex: '#C52B20'
    },
  ]
}

export const SequentialColors= [
  {
    id: 'SequentialColors1',
    colors: [
      {
        key: '1',
        hex: '#CDD6FA'
      },
      {
        key: '2',
        hex: '#A6B5F6'
      },
      {
        key: '3',
        hex: '#7C93F2'
      },
      {
        key: '4',
        hex: '#5473EF'
      },
      {
        key: '5',
        hex: '#2F54EB'
      },
      {
        key: '6',
        hex: '#2847C8'
      },
      {
        key: '7',
        hex: '#213CA7'
      }
    ]
  },
  {
    id: 'SequentialColors2',
    colors: [
      {
        key: '1',
        hex: '#CDDCE4'
      },
      {
        key: '2',
        hex: '#A5C1CE'
      },
      {
        key: '3',
        hex: '#7BA4B7'
      },
      {
        key: '4',
        hex: '#5388A2'
      },
      {
        key: '5',
        hex: '#2D6E8D'
      },
      {
        key: '6',
        hex: '#265E78'
      },
      {
        key: '7',
        hex: '#204E64'
      }
    ]
  },
  {
    id: 'SequentialColors3',
    colors: [
      {
        key: '1',
        hex: '#F9DDCB'
      },
      {
        key: '2',
        hex: '#F3C2A1'
      },
      {
        key: '3',
        hex: '#EEA676'
      },
      {
        key: '4',
        hex: '#E98B4C'
      },
      {
        key: '5',
        hex: '#E47225'
      },
      {
        key: '6',
        hex: '#C2611F'
      },
      {
        key: '7',
        hex: '#A2511A'
      }
    ]
  },
  {
    id: 'SequentialColors4',
    colors: [
      {
        key: '1',
        hex: '#F9DDCB'
      },
      {
        key: '2',
        hex: '#D0B883'
      },
      {
        key: '3',
        hex: '#A5AE75'
      },
      {
        key: '4',
        hex: '#79A175'
      },
      {
        key: '5',
        hex: '#4F9079'
      },
      {
        key: '6',
        hex: '#2F797B'
      },
      {
        key: '7',
        hex: '#204E64'
      }
    ]
  },
]

export const getQualitativePalette = (number?: number) => {
  if(!number) {
    return {id: '', colors: []};
  }

  if(number < 3 ) {
    return [
      Qualitative2Colors1,
      Qualitative2Colors2
    ]
  }

  if( number > 2 && number < 5 ) {
    return Qualitative4Colors;
  }

  if (number > 4) {
    return Qualitative12Colors;
  }

  return {id: '', colors: []};
}