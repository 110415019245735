import styled from "styled-components";
import { LayersHalf } from "react-bootstrap-icons";

export const KpiSettingsWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const KpiSettingsLeftSide = styled.div`
  width: 100%;
`;

export const KpiSettingsRightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid lightgray;
  padding: 20px;
  position: relative;
`;

export const KpiSettingsDatasetsWrapper = styled.div`
  border-bottom: 1px solid lightgray;
  padding: 8px 20px 16px 20px;
`;

export const KpiSettingsDatasetsHeading = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const SwitchDataset = styled(LayersHalf)`
  fill: #493ddd;
`;

export const KpiSettingsDatasets = styled.div``;

export const KpiSettingsLayersWrapper = styled.div``;

export const KpiSettingsLayerHeader = styled.div`
  margin: 8px 20px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const KpiSettingLayerContent = styled.div`
  margin: 0px 20px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const KpiSettingTitle = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const KpiSettingDescription = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const KpiSettingList = styled.div`
  display: flex;
  flex-direction: column;
`;
