import { RowI } from "./extractBlockIds";

export const getMaxHeight = (rows: RowI[]): string => {
  let maxHeight = 0;

  const parseHeight = (height?: string): number => {
    if (!height) return 0;
    return parseFloat(height);
  };

  rows.forEach((row) => {
    // Verificăm valoarea de height dacă există
    const rowHeight = parseHeight(row.height);
    if (rowHeight > maxHeight) {
      maxHeight = rowHeight;
    }

    // Recursiv pentru coloanele din rând
    if (row.columns) {
      row.columns.forEach((column) => {
        if (column.rows) {
          const nestedMaxHeight = parseHeight(getMaxHeight(column.rows));
          if (nestedMaxHeight > maxHeight) {
            maxHeight = nestedMaxHeight;
          }
        }
      });
    }
  });

  return maxHeight + "%";
};
