import React from "react";
import { VerticalBarchart } from "../VerticalBarchart";
import { HorizontalBarChart } from "../HorizontalBarChart";
import { GroupedBarChart } from "../GroupedBarChart";

import { WidgetItem } from "../../../models/Widgets";
import { HorizontalGroupedBarChart } from "../HorizontalGroupedBarChart";

interface BarChartInterface {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
  isRight?: boolean;
}

export const Barchart = ({
  storytelling,
  recommended,
  showLegend = true,
  selected = false,
  currentWidget,
  hideName = false,
  isRight,
}: BarChartInterface) => {
  const groupBy = currentWidget?.groupBy !== null ? currentWidget?.groupBy : [];

  return (
    <>
      {currentWidget?.orientation === "vertical" ? (
        <>
          {groupBy && groupBy?.length > 0 ? (
            <GroupedBarChart
              storytelling={storytelling}
              currentWidget={currentWidget}
              showLegend={showLegend}
              recommended={recommended}
              selected={selected}
              hideName={hideName}
              isRight={isRight}
            />
          ) : (
            <VerticalBarchart
              recommended={recommended}
              storytelling={storytelling}
              currentWidget={currentWidget}
              showLegend={showLegend}
              selected={selected}
              hideName={hideName}
            />
          )}
        </>
      ) : (
        <>
          {groupBy && groupBy?.length > 0 ? (
            <HorizontalGroupedBarChart
              storytelling={storytelling}
              currentWidget={currentWidget}
              showLegend={showLegend}
              recommended={recommended}
              selected={selected}
              hideName={hideName}
              isRight={isRight}
            />
          ) : (
            <HorizontalBarChart
              storytelling={storytelling}
              currentWidget={currentWidget}
              showLegend={showLegend}
              recommended={recommended}
              selected={selected}
              hideName={hideName}
            />
          )}
        </>
      )}
    </>
  );
};
