import styled, { css } from "styled-components";
import { Layers } from "react-bootstrap-icons";

export const SVGStyled = styled.svg`
  .bubble-label {
    pointer-events: none;
  }

  .tick-label {
    cursor: pointer;
  }
`;

const MapSettingButtonStyles = css`
  z-index: 1;
  position: absolute;
  top: 14px;
  right: 15px;
`;

export const HeaderWrapper = styled.div<{
  $isRow?: boolean;
}>`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  flex-direction: ${({ $isRow }) => ($isRow ? "row" : "column")};
  align-items: flex-start;
  gap: 8px;
`;

export const HeadingNameAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.neutralText};
  line-height: 22px;
`;

export const DatavizRecommendedCount = styled.div`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.primaryAction};
  border-radius: 2px;
`;

export const DatavizSettingsIcon = styled(Layers)`
  fill: ${({ theme }) => theme.neutralTextWeak};
  transition: 0.3s;
`;

export const SettingsButtonWrapper = styled.div<{
  $map?: boolean;
  $modalOpen?: boolean;
}>`
  transition: 0.3s;
  display: ${({ $modalOpen }) => ($modalOpen ? "none" : "flex")};
  gap: 4px;
  align-items: center;
  padding: 2px 4px 2px 2px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
  cursor: pointer;
  background: ${({ theme }) => theme.neutralBackgroundActive};
  ${({ $map }) => $map && MapSettingButtonStyles}

  &:hover {
    background: ${({ theme }) => theme.primaryBackgroundMedium};
    ${DatavizSettingsIcon} {
      fill: ${({ theme }) => theme.neutralText};
    }
  }

  &:active {
    background: ${({ theme }) => theme.primaryBackgroundActive};
    ${DatavizSettingsIcon} {
      fill: ${({ theme }) => theme.primaryText};
    }
  }
`;
