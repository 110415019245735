import styled from 'styled-components';

import { ReactComponent as radioButtonActive } from "../../assets/radioButtonActive.svg";
import { ReactComponent as radioButtonInactive } from "../../assets/radioButtonInactive.svg";
import { ReactComponent as radioButtonHovered } from "../../assets/hoveredRadioButton.svg";
import { ReactComponent as pressRadioButton } from "../../assets/pressRadioButton.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 136px);
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.neutralTextWeak};;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  max-width: 400px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

export const HoveredRadioButton = styled(radioButtonHovered)`
  margin-top: 12px;
  margin-right: 12px;
  display: none;
`;

export const ActiveRadioButton = styled(radioButtonActive)`
  margin-top: 12px;
  margin-right: 12px;
`;

export const PressRadioButton = styled(pressRadioButton)`
  margin-top: 12px;
  margin-right: 12px;
`;

export const InactiveRadioButton = styled(radioButtonInactive)`
  margin-top: 12px;
  margin-right: 12px;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 2px;
`;

export const Option = styled.label<{ $selected: boolean; $pressed: boolean }>`
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid
    ${({ $selected, theme }) =>
      $selected ? theme.primaryBorder : theme.neutralBorder};
  border-radius: 5px;
  cursor: pointer;
  background: ${({ theme, $selected, $pressed }) =>
    $selected || $pressed ? theme.primaryBackgroundActive : ""};
  transition: box-shadow 0.2s ease-in-out;
  position: relative;
  width: 400px;

  svg {
    path {
      fill: ${({ theme }) => theme.neutralText};
    }
  }

  &:hover {
    box-shadow: ${({ $selected, $pressed }) =>
    !$selected && !$pressed ? "rgba(99, 99, 99, 0.2) 0 2px 8px 0" : ""};
    transition: 0.2s ease-in-out;

    ${HoveredRadioButton} {
      display: ${({ $selected }) => !$selected && "block"};
    }
    ${ActiveRadioButton} {
      display: ${({ $selected }) => !$selected && "none"};
    }

    ${InactiveRadioButton} {
      display: ${({ $selected }) => !$selected && "none"};
    }
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.neutralText};
  }
`;
