import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { Search } from "../../SearchBar";

import {
  Header,
  HeadingIcon,
  Icon,
  SettingsIcon,
  Table,
  TableColumn,
  TableHeading,
  TableHeadingWrapper,
  TableRow,
} from "../DatasetsOverview/styles";
import {
  Close,
  ContentWrapper,
  FileOverview,
  HeaderItemsWrapper,
  HeaderWrapper,
  Heading,
} from "../UploadedFilePreview/styles";
import {
  removeSelectedFile,
  requestDatasetRemove,
  requestGetDatasetContents,
  setDatasetPreview,
} from "../../../store/slices/datasets";
import { Button } from "../../Button";
import { DimensionSettings } from "../../DimensionSettings";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { handleColumnClick } from "../../../helpers/handleColumnClick";
import {
  getDatasetContents,
  getSelectedDataset,
} from "../../../store/selectors/datasets";
import { getIconSrc } from "../../../helpers/getIconType";
import { DimensionSettingsInterface } from "../../../models/Files";
import { Trash } from "react-bootstrap-icons";

type DatasetFileOverviewInterface = {
  setLibraryDatasetOverview: Dispatch<SetStateAction<boolean>>;
  dimensionsSelected: any;
};

export const LibraryDatasetOverview = ({
  setLibraryDatasetOverview,
  dimensionsSelected,
}: DatasetFileOverviewInterface) => {
  const [searchInput, setSearchInput] = useState<string>("");

  const [showDimensionSettings, setShowDimensionSettings] =
    useState<DimensionSettingsInterface | null>(null);

  const ref = useRef(null);
  const dispatch = useDispatch();
  const selectedDatasetContents = useSelector(getDatasetContents);
  const selectedDataset = useSelector(getSelectedDataset);

  const datasetItems = selectedDataset?.fields?.map((item) => item);

  const previewItems = selectedDatasetContents?.items.map(
    (previewItem: any) => previewItem
  );

  const filteredItems = datasetItems?.filter((item) =>
    item.label.toLowerCase().includes(searchInput.toLowerCase())
  );

  useOutsideClick(ref, () => {
    setShowDimensionSettings(null);
  });

  useEffect(() => {
    dispatch(
      requestGetDatasetContents({
        id: selectedDataset?.id!,
        limit: 20,
        skip: 0,
      })
    );
  }, [dispatch, selectedDataset]);

  return (
    <ContentWrapper ref={ref}>
      <HeaderWrapper>
        <Heading>
          {selectedDataset?.name.charAt(0).toUpperCase() +
            selectedDataset!.name.slice(1, -4)}
        </Heading>
        <HeaderItemsWrapper>
          <Search
            width="small"
            onChange={(value: string) => setSearchInput(value)}
          />
          <Button
            onClick={() => {
              setLibraryDatasetOverview(false);
              dispatch(requestDatasetRemove(selectedDataset?.id || ""));
              dispatch(removeSelectedFile(selectedDataset?.filePath || ""));
            }}
            variant="danger"
            size="medium"
            name="Remove"
            icon={<Trash fill="#992a4b" />}
          />
        </HeaderItemsWrapper>
        <Close
          onClick={() => {
            setLibraryDatasetOverview(false);
            dispatch(setDatasetPreview({}));
          }}
        />
      </HeaderWrapper>
      <FileOverview>
        <Table>
          {filteredItems?.map((datasetItem) => {
            return (
              <TableColumn
                key={datasetItem.key}
                $errorDetected={false}
                $selected={dimensionsSelected.includes(datasetItem.label)}
              >
                <TableHeadingWrapper>
                  <TableHeading>
                    <HeadingIcon>
                      <Icon
                        src={getIconSrc({
                          type: datasetItem.type,
                          subtype: datasetItem.subtype,
                        })}
                      />
                    </HeadingIcon>
                    <Header>{datasetItem.label}</Header>
                  </TableHeading>
                  <SettingsIcon
                    onClick={(e) => {
                      setShowDimensionSettings(
                        handleColumnClick(
                          datasetItem.label,
                          e,
                          datasetItem.key,
                          false,
                          datasetItem.type
                        )
                      );
                    }}
                  />
                </TableHeadingWrapper>
                {previewItems?.map((item: any, idx: number) => {
                  return (
                    <TableRow key={idx}>
                      {item[datasetItem.key]
                        ? item[datasetItem.key]
                        : "Loading..."}
                    </TableRow>
                  );
                })}
              </TableColumn>
            );
          })}
        </Table>
      </FileOverview>
      {showDimensionSettings && (
        <div ref={ref}>
          <DimensionSettings
            filePath={selectedDataset!.filePath || ""}
            setShowDimensionSettings={setShowDimensionSettings}
            showDimensionSettings={showDimensionSettings}
          />
        </div>
      )}
    </ContentWrapper>
  );
};
