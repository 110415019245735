export const AVAILABLE_WIDGETS: { [key: string]: string[] } = {
  lollipopChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
  ],
  lineChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
    "radar",
  ],
  areaChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
  ],
  barChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
    "polarAreaChart",
    "radar",
  ],
  sankey: ["sankey", "matrix"],
  matrix: ["matrix", "sankey", "punchcardChart"],
  mapChart: ["map", "sankey_map", "matrix_map"],
  bubbleChart: [],
  scatterplotChart: [],
  punchcardChart: ['matrix'],
  polarAreaChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "radar",
  ],
  radar: [
    "polarAreaChart",
    "lineChart",
    "barChart_vertical",
    "barChart_horizontal",
  ],
};
