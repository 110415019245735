import { useMemo, useRef, useState } from "react";
import { BarGroupHorizontal } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand, scaleOrdinal } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  HeadingNameAndButton,
  SettingsButtonWrapper,
  Title,
} from "../VerticalBarchart/styles";
import { setActiveModal } from "../../../store/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import useMeasure from "react-use-measure";
import { getPageSettings } from "../../../store/selectors/projects";
import { ticksFormatter } from "../../../helpers/ticksFormatter";
import { calculateNumTicks } from "../widgetHelpers";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getCurrentColorV2 } from "../utils/getCurrentMarker";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { LabelTooltip, TickLabel } from "../components/LabelTooltip";
import { ChartLegend, ChartLegendValue } from "../../ChartLegend";
import { getBarChartKeys } from "../GroupedBarChart/utils/getKeys";
import { createPortal } from "react-dom";
import { Rect } from "./styles";
import { getBarPosition } from "../GroupedBarChart/utils/getBarPosition";

interface GroupedBarChartProps {
  storytelling?: boolean;
  recommended?: boolean;
  currentWidget: WidgetItem;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  isRight?: boolean;
}

type DataItem = {
  year: string;
  [key: string]: string | number;
};

export const HorizontalGroupedBarChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  isRight,
}: GroupedBarChartProps) => {
  const isEditMode = useSelector(getIsEditMode);
  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const divRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const isPublicRoute = useSelector(getIsPublicMode);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [labelTooltip, setLabelTooltip] = useState<{
    data: string;
    x: number;
    y: number;
  } | null>(null);

  const [hoveredElement, setHoveredElement] = useState<null | string>(null);

  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const barChartSuggestion = aiSuggestions
    ?.filter((chart: AiSuggestionsDto) => chart.chartType === "barChart")
    ?.at(0);

  const xAxe = currentWidget?.xAxe?.[0] || barChartSuggestion?.xAxe?.[0];
  const yAxe = currentWidget?.yAxe?.[0] || barChartSuggestion?.yAxe?.[0];
  const barchartData = currentWidget?.data;

  const groupBy = currentWidget?.groupBy?.at(0);

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const keys =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : getBarChartKeys(currentWidget);

  const legendValues: ChartLegendValue[] = keys.map((key) => ({
    label: key,
    color: getCurrentColorV2(currentWidget, key, styleId || "default"),
  }));

  const categoriesSet = new Set(barchartData?.map((item) => item[xAxe])).size;
  const hasLongBarValue = barchartData?.some((el) => el[xAxe].length > 6);
  const margin = {
    top: 0,
    right: 1,
    bottom: barchartData?.length! > 7 ? -4 : 40,
    left: hasLongBarValue ? 80 : 40,
  };
  const getCategory = (d: DataItem) => d[xAxe];
  const getValue = (d: DataItem) => Number(d[yAxe]);

  const data: any = currentWidget?.data;
  const maxXAxe = data && data?.length ? Math.max(...data?.map(getValue)) : 0;
  const roundedMaxXAxe = useMemo(
    () =>
      Math.ceil(
        maxXAxe / Math.pow(10, Number(Math.floor(Math.log10(maxXAxe))))
      ) * Math.pow(10, Number(Math.floor(Math.log10(maxXAxe)))),
    [maxXAxe]
  );

  const numTicks = calculateNumTicks({ height: height });

  const name = useMemo(
    () =>
      recommended ? replaceWords(currentWidget?.name) : currentWidget?.name,
    [currentWidget?.name, recommended]
  );

  const refHeight = useMemo(
    () => divRef?.current?.parentElement?.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef, divRef.current]
  );

  if (!barchartData || !barchartData.length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  const numBarsInGroup = keys.length;
  const fixedBarHeight = 16;
  const fixedBarSpacing = 1;
  const totalSpaceRequiredPerGroup =
    numBarsInGroup * fixedBarHeight + (numBarsInGroup - 1) * fixedBarSpacing;
  const yMax = totalSpaceRequiredPerGroup * categoriesSet + 50 * categoriesSet - 1;

  const formattedData = data?.reduce((acc: any[], item: any) => {
    const itemIndex = acc.findIndex((obj) => obj[xAxe] === item[xAxe]);
    if (itemIndex !== -1) {
      acc[itemIndex] = {
        ...acc[itemIndex],
        [item[groupBy!]]: item[yAxe],
      };
      return acc;
    }
    acc.push({
      [xAxe]: item[xAxe],
      [item[groupBy!]]: item.value,
    });
    return acc;
  }, []);

  const categoryScale = scaleBand<string>({
    domain: data?.map(getCategory),
    align: 0,
    range: [0, yMax],
  });

  const keysScale = scaleBand<string>({
    domain: keys,
    range: [0, totalSpaceRequiredPerGroup],
    paddingInner: 0.2, // Increase this value to add space between bars
    paddingOuter: 0.1,
  });

  const valueScale = scaleLinear<number>({
    domain: [0, roundedMaxXAxe],
    range: [margin.left, width - margin.right],
    round: true,
    nice: true,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: legendValues?.map((r) => r.color),
  });

  return (
    <>
      <HeaderWrapper ref={refWidget} style={{ marginBottom: "10px" }}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <div />}
            {!isPublicRoute && !recommended && isEditMode && !storytelling ? (
              <SettingsButtonWrapper
                $modalOpen={false}
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["barChart"]?.length}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {legendValues?.length > 1 && showLegend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="unit"
            legendValues={legendValues}
            isRight={isRight}
          />
        )}
      </HeaderWrapper>
      <div
        ref={divRef}
        style={
          barchartData.length > 7
            ? {
              height: refHeight
                ? refHeight - 50 * 2 < 170
                  ? 170
                  : refHeight - 50 * 2
                : 170,
              overflowY: "auto",
            }
            : { height: `calc(100% - 10px)` }
        }
      >
        <svg width="100%" height={yMax} ref={ref}>
          <Group>
            {categoryScale.domain().map((d, idx) => (
              <line
                key={idx}
                x1={margin.left}
                x2={width - margin.right}
                y1={categoryScale(d)!}
                y2={categoryScale(d)!}
                stroke="#ccc"
                strokeDasharray="1 2"
              />
            ))}
            {valueScale.ticks(numTicks).map((tick, idx) => (
              <line
                key={idx}
                x1={valueScale(tick)}
                x2={valueScale(tick)}
                y1={0}
                y2={height - margin.bottom}
                stroke="#ccc"
                strokeDasharray="1 2"
              />
            ))}
          </Group>
          {!(barchartData.length > 7) && (
            <AxisBottom
              scale={valueScale}
              top={yMax}
              left={margin.left}
              tickFormat={(value: any) => ticksFormatter(value)}
              numTicks={numTicks}
              tickLabelProps={(value, index, ticks) => {
                const isFirstTick = index === 0;
                const isLastTick = index === ticks.length - 1;
                const textAnchor =
                  (isFirstTick && "start") || (isLastTick && "end") || "middle";

                return {
                  fontSize: 10,
                  fill: "#5F6877",
                  textAnchor,
                  dx: isFirstTick ? 10 : isLastTick ? -10 : 0,
                  dy: 5,
                };
              }}
              axisLineClassName="barchartAxisLine"
            />
          )}
          <AxisLeft
            scale={categoryScale}
            top={0}
            hideTicks
            hideAxisLine
            left={margin.left}
            tickLineProps={{ stroke: "#939BA7" }}
            numTicks={barchartData.length > 7 ? barchartData.length : numTicks}
            tickLabelProps={() => ({
              fontSize: 11,
              fill: "#5F6877",
              textAnchor: "start",
              dominantBaseline: "middle",
              dx: hasLongBarValue ? -80 : -40,
            })}
            tickComponent={(props) => (
              <TickLabel
                {...props}
                setTooltip={setLabelTooltip}
                length={hasLongBarValue ? 10 : 4}
                offsetX={-10}
              />
            )}
          />
          <Group left={margin.left}>
            <BarGroupHorizontal
              data={formattedData}
              keys={keys}
              height={yMax}
              y0={(d) => d[xAxe]}
              y0Scale={categoryScale}
              y1Scale={keysScale}
              xScale={valueScale}
              color={colorScale}
              width={0}
            >
              {(barGroups) => {
                const groupSpace = barGroups.find((item) => item.y0)?.y0 ?? 0;
                return barGroups.map((barGroup) => (
                  <Group
                    key={`bar-group-${barGroup.index}-${barGroup.y0}`}
                    top={barGroup.y0}
                  >
                    {barGroup.bars.map((bar) => {
                      const legend = legendValues.find(
                        (r) => r.label === bar.key
                      );
                      const key = `bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`;
                      const y =
                        groupSpace / 2 -
                        8 +
                        getBarPosition(16, bar.index, keys.length);

                      return (
                        <Rect
                          key={key}
                          x={bar.x}
                          y={y}
                          width={bar.width - valueScale(0)}
                          height={16}
                          fill={legend?.color}
                          opacity={
                            hoveredElement
                              ? hoveredElement === key
                                ? 1
                                : 0.3
                              : 1
                          }
                          onMouseMove={(event: any) => {
                            if (
                              (showTooltip || currentWidget.tooltip) &&
                              !recommended
                            ) {
                              const { pageX, pageY, clientX, clientY } = event;
                              const coords = { pageX, pageY, clientX, clientY };
                              const hoveredBarGroup =
                                formattedData[barGroup.index];

                              setHoveredElement(key);
                              setTooltip({
                                name: bar.key,
                                data: {
                                  [xAxe]: hoveredBarGroup[xAxe],
                                  [groupBy!]: keys[bar.index],
                                  [yAxe]: bar?.value?.toString(),
                                },
                                coords,
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            setTooltip(null);
                            setHoveredElement(null);
                          }}
                        />
                      );
                    })}
                  </Group>
                ));
              }}
            </BarGroupHorizontal>
          </Group>
        </svg>
      </div>
      {barchartData.length > 7 && (
        <svg height={40} width={"100%"}>
          <AxisBottom
            scale={valueScale}
            top={1}
            hideTicks
            numTicks={calculateNumTicks({ width })}
            tickFormat={(value: any) => ticksFormatter(value)}
            tickLabelProps={(_, index, values) => {
              const isFirstTick = index === 0;
              const isLastTick = index === values.length - 1;
              const textAnchor =
                (isFirstTick && "start") || (isLastTick && "end") || "middle";
              return {
                fontSize: 10,
                fill: "#5F6877",
                textAnchor,
                dx: 0,
                dy: -1,
              };
            }}
            axisLineClassName="barchartAxisLine"
          />
        </svg>
      )}
      {tooltip &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
            coords={tooltip.coords}
          />,
          document.body
        )}
      {labelTooltip &&
        createPortal(
          <LabelTooltip
            x={labelTooltip?.x}
            y={labelTooltip?.y}
            data={labelTooltip?.data}
          />,
          document.body
        )}
    </>
  );
};
