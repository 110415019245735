export const secondStepTH = [
  { text: "Population name", size: "367px" },
  { text: "Measures", size: "91px" },
];

export const thirdStepTH = [{ text: "Measure name" }];

export const MEASURES = [
  {
    id: "M14_HCBS_ALL",
    measureMobile: "MCal using HCBS (MCal HCBS)",
    measureDesktop: "HCBS participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who utilized one or more HCBS, including all waiver programs and State Plan services: IHSS, CCT, CBAS, HH services, and 1915(c) Waivers (ALW, SDP, HCBS-DD, HCBA, MCWP, and MSSP)",
    program: "Utilization",
    bucket: "CalAIM Priority",
  },
  {
    id: "M14_HCBS_ALL",
    measureMobile: "MCal using HCBS (MCal HCBS)",
    measureDesktop: "HCBS participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who utilized one or more HCBS, including all waiver programs and State Plan services: IHSS, CCT, CBAS, HH services, and 1915(c) Waivers (ALW, SDP, HCBS-DD, HCBA, MCWP, and MSSP)",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M14_HCBS_ALL",
    measureMobile: "MCal using HCBS (MCal HCBS)",
    measureDesktop: "HCBS participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who utilized one or more HCBS, including all waiver programs and State Plan services: IHSS, CCT, CBAS, HH services, and 1915(c) Waivers (ALW, SDP, HCBS-DD, HCBA, MCWP, and MSSP)",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M18_HCBS_SELECT",
    measureMobile: "MCal using Select HCBS",
    measureDesktop:
      "Select HCBS participants (IHSS, CBAS, HCBA, ALW, MSSP, CCT)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who utilized one or more select HCBS services (MCWP, IHSS, CBAS, HCBA, ALW, CCT, or MSSP). These programs were selected for inclusion based upon the nature of overlapping providers and population for these services",
    program: "Utilization",
    bucket: "CalAIM Priority",
  },
  {
    id: "M18_HCBS_SELECT",
    measureMobile: "MCal using Select HCBS",
    measureDesktop:
      "Select HCBS participants (IHSS, CBAS, HCBA, ALW, MSSP, CCT)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who utilized one or more select HCBS services (MCWP, IHSS, CBAS, HCBA, ALW, CCT, or MSSP). These programs were selected for inclusion based upon the nature of overlapping providers and population for these services",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M18_HCBS_SELECT",
    measureMobile: "MCal using Select HCBS",
    measureDesktop:
      "Select HCBS participants (IHSS, CBAS, HCBA, ALW, MSSP, CCT)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who utilized one or more select HCBS services (MCWP, IHSS, CBAS, HCBA, ALW, CCT, or MSSP). These programs were selected for inclusion based upon the nature of overlapping providers and population for these services",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M18_HCBS_SELECT",
    measureMobile: "MCal using Select HCBS",
    measureDesktop:
      "Select HCBS participants (IHSS, CBAS, HCBA, ALW, MSSP, CCT)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who utilized one or more select HCBS services (MCWP, IHSS, CBAS, HCBA, ALW, CCT, or MSSP). These programs were selected for inclusion based upon the nature of overlapping providers and population for these services",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M18_HCBS_SELECT",
    measureMobile: "MCal using Select HCBS",
    measureDesktop:
      "Select HCBS participants (IHSS, CBAS, HCBA, ALW, MSSP, CCT)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who utilized one or more select HCBS services (MCWP, IHSS, CBAS, HCBA, ALW, CCT, or MSSP). These programs were selected for inclusion based upon the nature of overlapping providers and population for these services",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M1a_MEDI_CAL_ALL",
    measureMobile: "Medi-Cal members (MCal)",
    measureDesktop: "Medi-Cal members (MCal)",
    measureDescriptionDesktop: "Number of certified eligible Medi-Cal members",
    program: "Utilization",
    bucket: "Medi-Cal",
  },
  {
    id: "M1b_MEDI_CAL_GE65",
    measureMobile: "MCal age 65+",
    measureDesktop: "Medi-Cal members age 65 and older",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who are age 65 and older",
    program: "Utilization",
    bucket: "Medi-Cal",
  },
  {
    id: "M1c_MEDI_CAL_GE65_OR_DISABILITY",
    measureMobile: "MCal age 65+ or w/disability",
    measureDesktop: "Medi-Cal members age 65 and older or with a disability",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members that are either age 65 and older or are in Medi-Cal aid codes based on disability",
    program: "Utilization",
    bucket: "Medi-Cal",
  },
  {
    id: "M24_1915c_MCWP",
    measureMobile: "MCal enrolled in 1915c MCWP",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c MCWP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the MCWP",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M24_1915c_MCWP",
    measureMobile: "MCal enrolled in 1915c MCWP",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c MCWP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the MCWP",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M24_1915c_MCWP",
    measureMobile: "MCal enrolled in 1915c MCWP",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c MCWP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the MCWP",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M24_1915c_MCWP",
    measureMobile: "MCal enrolled in 1915c MCWP",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c MCWP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the MCWP",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M24_1915c_MCWP",
    measureMobile: "MCal enrolled in 1915c MCWP",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c MCWP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the MCWP",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M25_1915c_ALW",
    measureMobile: "MCal enrolled in 1915c ALW",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the ALW",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M25_1915c_ALW",
    measureMobile: "MCal enrolled in 1915c ALW",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the ALW",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M25_1915c_ALW",
    measureMobile: "MCal enrolled in 1915c ALW",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the ALW",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M25_1915c_ALW",
    measureMobile: "MCal enrolled in 1915c ALW",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the ALW",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M25_1915c_ALW",
    measureMobile: "MCal enrolled in 1915c ALW",
    measureDesktop: "Number of Medi-Cal members enrolled in the 1915c ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the ALW",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M26a_CBAS",
    measureMobile: "MCal enrolled in CBAS",
    measureDesktop: "CBAS enrollees",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in CBAS",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M26a_CBAS",
    measureMobile: "MCal enrolled in CBAS",
    measureDesktop: "CBAS enrollees",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in CBAS",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M26a_CBAS",
    measureMobile: "MCal enrolled in CBAS",
    measureDesktop: "CBAS enrollees",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in CBAS",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M26a_CBAS",
    measureMobile: "MCal enrolled in CBAS",
    measureDesktop: "CBAS enrollees",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in CBAS",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M27_CCT",
    measureMobile: "MCal enrolled in CCT",
    measureDesktop: "Number of Medi-Cal members enrolled in CCT",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the CCT Project",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M27_CCT",
    measureMobile: "MCal enrolled in CCT",
    measureDesktop: "Number of Medi-Cal members enrolled in CCT",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the CCT Project",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M27_CCT",
    measureMobile: "MCal enrolled in CCT",
    measureDesktop: "Number of Medi-Cal members enrolled in CCT",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the CCT Project",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M27_CCT",
    measureMobile: "MCal enrolled in CCT",
    measureDesktop: "Number of Medi-Cal members enrolled in CCT",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the CCT Project",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M2_LTSS_ALL",
    measureMobile: "MCal using LTSS (Mcal LTSS)",
    measureDesktop: "Medi-Cal members using LTSS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received any LTSS benefit, including LTC and any HCBS",
    program: "Utilization",
    bucket: "CalAIM Priority",
  },
  {
    id: "M2_LTSS_ALL",
    measureMobile: "MCal using LTSS (Mcal LTSS)",
    measureDesktop: "Medi-Cal members using LTSS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received any LTSS benefit, including LTC and any HCBS",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M31_1915c_HCBA",
    measureMobile: "MCal enrolled in 1915c HCBA",
    measureDesktop: "1915c HCBA participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the HCBA waiver",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M31_1915c_HCBA",
    measureMobile: "MCal enrolled in 1915c HCBA",
    measureDesktop: "1915c HCBA participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the HCBA waiver",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M31_1915c_HCBA",
    measureMobile: "MCal enrolled in 1915c HCBA",
    measureDesktop: "1915c HCBA participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the HCBA waiver",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M31_1915c_HCBA",
    measureMobile: "MCal enrolled in 1915c HCBA",
    measureDesktop: "1915c HCBA participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the HCBA waiver",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M31_1915c_HCBA",
    measureMobile: "MCal enrolled in 1915c HCBA",
    measureDesktop: "1915c HCBA participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members enrolled in the HCBA waiver",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M32_HCBA_SNF",
    measureMobile: "1915c HCBAp with a SNF stay",
    measureDesktop: "1915c HCBA participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who were enrolled in the HCBA waiver and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M32_HCBA_SNF",
    measureMobile: "1915c HCBAp with a SNF stay",
    measureDesktop: "1915c HCBA participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who were enrolled in the HCBA waiver and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M32_HCBA_SNF",
    measureMobile: "1915c HCBAp with a SNF stay",
    measureDesktop: "1915c HCBA participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who were enrolled in the HCBA waiver and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M32_HCBA_SNF",
    measureMobile: "1915c HCBAp with a SNF stay",
    measureDesktop: "1915c HCBA participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who were enrolled in the HCBA waiver and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "Long Term Care",
  },
  {
    id: "M32_HCBA_SNF",
    measureMobile: "1915c HCBAp with a SNF stay",
    measureDesktop: "1915c HCBA participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who were enrolled in the HCBA waiver and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M32_HCBA_SNF",
    measureMobile: "1915c HCBAp with a SNF stay",
    measureDesktop: "1915c HCBA participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who were enrolled in the HCBA waiver and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M33_HH",
    measureMobile: "MCal using Home Health",
    measureDesktop: "Medi-Cal members receiving Home Health",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received HH services",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M33_HH",
    measureMobile: "MCal using Home Health",
    measureDesktop: "Medi-Cal members receiving Home Health",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received HH services",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M33_HH",
    measureMobile: "MCal using Home Health",
    measureDesktop: "Medi-Cal members receiving Home Health",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received HH services",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M33_HH",
    measureMobile: "MCal using Home Health",
    measureDesktop: "Medi-Cal members receiving Home Health",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received HH services",
    program: "Utilization",
    bucket: "Other",
  },
  {
    id: "M34_HH_SNF",
    measureMobile: "MCal using Home Health in SNF",
    measureDesktop: "Medi-Cal members receiving Home Health with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received HH services and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M34_HH_SNF",
    measureMobile: "MCal using Home Health in SNF",
    measureDesktop: "Medi-Cal members receiving Home Health with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received HH services and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "Long Term Care",
  },
  {
    id: "M34_HH_SNF",
    measureMobile: "MCal using Home Health in SNF",
    measureDesktop: "Medi-Cal members receiving Home Health with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received HH services and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M34_HH_SNF",
    measureMobile: "MCal using Home Health in SNF",
    measureDesktop: "Medi-Cal members receiving Home Health with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received HH services and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M34_HH_SNF",
    measureMobile: "MCal using Home Health in SNF",
    measureDesktop: "Medi-Cal members receiving Home Health with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received HH services and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "Other",
  },
  {
    id: "M35a_IHSS",
    measureMobile: "MCal using IHSS",
    measureDesktop: "IHSS participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M35a_IHSS",
    measureMobile: "MCal using IHSS",
    measureDesktop: "IHSS participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M35a_IHSS",
    measureMobile: "MCal using IHSS",
    measureDesktop: "IHSS participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS",
    program: "Utilization",
    bucket: "IHSS",
  },
  {
    id: "M35a_IHSS",
    measureMobile: "MCal using IHSS",
    measureDesktop: "IHSS participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M35a_IHSS",
    measureMobile: "MCal using IHSS",
    measureDesktop: "IHSS participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M36_IHSS_ALW",
    measureMobile: "MCal using IHSS and 1915c ALW",
    measureDesktop: "IHSS participants in ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in ALW in the same year",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M36_IHSS_ALW",
    measureMobile: "MCal using IHSS and 1915c ALW",
    measureDesktop: "IHSS participants in ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in ALW in the same year",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M36_IHSS_ALW",
    measureMobile: "MCal using IHSS and 1915c ALW",
    measureDesktop: "IHSS participants in ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in ALW in the same year",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M36_IHSS_ALW",
    measureMobile: "MCal using IHSS and 1915c ALW",
    measureDesktop: "IHSS participants in ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in ALW in the same year",
    program: "Utilization",
    bucket: "IHSS",
  },
  {
    id: "M36_IHSS_ALW",
    measureMobile: "MCal using IHSS and 1915c ALW",
    measureDesktop: "IHSS participants in ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in ALW in the same year",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M36_IHSS_ALW",
    measureMobile: "MCal using IHSS and 1915c ALW",
    measureDesktop: "IHSS participants in ALW",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in ALW in the same year",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M37_IHSS_CBAS",
    measureMobile: "MCal using IHSS and CBAS",
    measureDesktop: "IHSS participants in CBAS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in CBAS in the same year",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M37_IHSS_CBAS",
    measureMobile: "MCal using IHSS and CBAS",
    measureDesktop: "IHSS participants in CBAS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in CBAS in the same year",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M37_IHSS_CBAS",
    measureMobile: "MCal using IHSS and CBAS",
    measureDesktop: "IHSS participants in CBAS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in CBAS in the same year",
    program: "Utilization",
    bucket: "IHSS",
  },
  {
    id: "M37_IHSS_CBAS",
    measureMobile: "MCal using IHSS and CBAS",
    measureDesktop: "IHSS participants in CBAS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in CBAS in the same year",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M37_IHSS_CBAS",
    measureMobile: "MCal using IHSS and CBAS",
    measureDesktop: "IHSS participants in CBAS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in CBAS in the same year",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M39_IHSS_HCBA",
    measureMobile: "MCal using IHSS and HCBA",
    measureDesktop: "IHSS participants in HCBA",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the HCBA waiver in the same year",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M39_IHSS_HCBA",
    measureMobile: "MCal using IHSS and HCBA",
    measureDesktop: "IHSS participants in HCBA",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the HCBA waiver in the same year",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M39_IHSS_HCBA",
    measureMobile: "MCal using IHSS and HCBA",
    measureDesktop: "IHSS participants in HCBA",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the HCBA waiver in the same year",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M39_IHSS_HCBA",
    measureMobile: "MCal using IHSS and HCBA",
    measureDesktop: "IHSS participants in HCBA",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the HCBA waiver in the same year",
    program: "Utilization",
    bucket: "IHSS",
  },
  {
    id: "M39_IHSS_HCBA",
    measureMobile: "MCal using IHSS and HCBA",
    measureDesktop: "IHSS participants in HCBA",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the HCBA waiver in the same year",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M39_IHSS_HCBA",
    measureMobile: "MCal using IHSS and HCBA",
    measureDesktop: "IHSS participants in HCBA",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the HCBA waiver in the same year",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M40_IHSS_SNF",
    measureMobile: "MCal using IHSS w/SNF stay",
    measureDesktop: "IHSS participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M40_IHSS_SNF",
    measureMobile: "MCal using IHSS w/SNF stay",
    measureDesktop: "IHSS participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M40_IHSS_SNF",
    measureMobile: "MCal using IHSS w/SNF stay",
    measureDesktop: "IHSS participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "IHSS",
  },
  {
    id: "M40_IHSS_SNF",
    measureMobile: "MCal using IHSS w/SNF stay",
    measureDesktop: "IHSS participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "Long Term Care",
  },
  {
    id: "M40_IHSS_SNF",
    measureMobile: "MCal using IHSS w/SNF stay",
    measureDesktop: "IHSS participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M40_IHSS_SNF",
    measureMobile: "MCal using IHSS w/SNF stay",
    measureDesktop: "IHSS participants with SNF stay",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and had one or more SNF stays in the same year",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M41_IHSS_MSSP",
    measureMobile: "MCal using IHSS and 1915c MSSP",
    measureDesktop: "IHSS participants in MSSP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the MSSP waiver in the same year",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M41_IHSS_MSSP",
    measureMobile: "MCal using IHSS and 1915c MSSP",
    measureDesktop: "IHSS participants in MSSP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the MSSP waiver in the same year",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M41_IHSS_MSSP",
    measureMobile: "MCal using IHSS and 1915c MSSP",
    measureDesktop: "IHSS participants in MSSP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the MSSP waiver in the same year",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M41_IHSS_MSSP",
    measureMobile: "MCal using IHSS and 1915c MSSP",
    measureDesktop: "IHSS participants in MSSP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the MSSP waiver in the same year",
    program: "Utilization",
    bucket: "IHSS",
  },
  {
    id: "M41_IHSS_MSSP",
    measureMobile: "MCal using IHSS and 1915c MSSP",
    measureDesktop: "IHSS participants in MSSP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the MSSP waiver in the same year",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M41_IHSS_MSSP",
    measureMobile: "MCal using IHSS and 1915c MSSP",
    measureDesktop: "IHSS participants in MSSP",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received IHSS and were enrolled in the MSSP waiver in the same year",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M42_1915c_MSSP",
    measureMobile: "MCal enrolled in 1915c MSSP",
    measureDesktop: "1915c MSSP participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received MSSP waiver services",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M42_1915c_MSSP",
    measureMobile: "MCal enrolled in 1915c MSSP",
    measureDesktop: "1915c MSSP participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received MSSP waiver services",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M42_1915c_MSSP",
    measureMobile: "MCal enrolled in 1915c MSSP",
    measureDesktop: "1915c MSSP participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received MSSP waiver services",
    program: "Utilization",
    bucket: "HCBS Select",
  },
  {
    id: "M42_1915c_MSSP",
    measureMobile: "MCal enrolled in 1915c MSSP",
    measureDesktop: "1915c MSSP participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received MSSP waiver services",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M42_1915c_MSSP",
    measureMobile: "MCal enrolled in 1915c MSSP",
    measureDesktop: "1915c MSSP participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received MSSP waiver services",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M43_PACE",
    measureMobile: "MCal enrolled in PACE",
    measureDesktop: "PACE participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who enrolled in program of All-Inclusive Care for the Elderly (PACE)",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M44_1915c_WAIVERS",
    measureMobile: "MCal enrolled in 1915c",
    measureDesktop: "All 1915c waiver participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received services for one of the six 1915(c) waivers (ALW, SDP, HCBA, HCBS-DD, MCWP, and MSSP)",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M44_1915c_WAIVERS",
    measureMobile: "MCal enrolled in 1915c",
    measureDesktop: "All 1915c waiver participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received services for one of the six 1915(c) waivers (ALW, SDP, HCBA, HCBS-DD, MCWP, and MSSP)",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M44_1915c_WAIVERS",
    measureMobile: "MCal enrolled in 1915c",
    measureDesktop: "All 1915c waiver participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received services for one of the six 1915(c) waivers (ALW, SDP, HCBA, HCBS-DD, MCWP, and MSSP)",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M45_1915c_HCBS_DD",
    measureMobile: "MCal enrolled in 1915c HCBS-DD",
    measureDesktop: "1915c HCBS-DD waiver participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received the HCBS-DD",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M45_1915c_HCBS_DD",
    measureMobile: "MCal enrolled in 1915c HCBS-DD",
    measureDesktop: "1915c HCBS-DD waiver participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received the HCBS-DD",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M45_1915c_HCBS_DD",
    measureMobile: "MCal enrolled in 1915c HCBS-DD",
    measureDesktop: "1915c HCBS-DD waiver participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received the HCBS-DD",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M46_1915c_SDP",
    measureMobile: "MCal enrolled in 1915c SDP",
    measureDesktop: "1915c SDP waiver participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received the California SDP",
    program: "Utilization",
    bucket: "1915C",
  },
  {
    id: "M46_1915c_SDP",
    measureMobile: "MCal enrolled in 1915c SDP",
    measureDesktop: "1915c SDP waiver participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received the California SDP",
    program: "Utilization",
    bucket: "HCBS All",
  },
  {
    id: "M46_1915c_SDP",
    measureMobile: "MCal enrolled in 1915c SDP",
    measureDesktop: "1915c SDP waiver participants",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received the California SDP",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M5_LTSS_SELECT_HCBS",
    measureMobile: "MCal Select LTSS (Select MCal LTSS)",
    measureDesktop: "Medi-Cal members using Select LTSS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received LTSS benefits for LTC stays and/or select HCBS programs.  For this measure, “select HCBS” includes MCWP, IHSS, CBAS, HCBA, ALW, CCT, and MSSP.",
    program: "Utilization",
    bucket: "CalAIM Priority",
  },
  {
    id: "M5_LTSS_SELECT_HCBS",
    measureMobile: "MCal Select LTSS (Select MCal LTSS)",
    measureDesktop: "Medi-Cal members using Select LTSS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received LTSS benefits for LTC stays and/or select HCBS programs.  For this measure, “select HCBS” includes MCWP, IHSS, CBAS, HCBA, ALW, CCT, and MSSP.",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M5_LTSS_SELECT_HCBS",
    measureMobile: "MCal Select LTSS (Select MCal LTSS)",
    measureDesktop: "Medi-Cal members using Select LTSS",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members who received LTSS benefits for LTC stays and/or select HCBS programs.  For this measure, “select HCBS” includes MCWP, IHSS, CBAS, HCBA, ALW, CCT, and MSSP.",
    program: "Utilization",
    bucket: "LTSS Select",
  },
  {
    id: "M8_1b_SNF",
    measureMobile: "MCal w/ SNF",
    measureDesktop: "SNF stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more SNF stays",
    program: "Utilization",
    bucket: "CalAIM Priority",
  },
  {
    id: "M8_1b_SNF",
    measureMobile: "MCal w/ SNF",
    measureDesktop: "SNF stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more SNF stays",
    program: "Utilization",
    bucket: "Long Term Care",
  },
  {
    id: "M8_1b_SNF",
    measureMobile: "MCal w/ SNF",
    measureDesktop: "SNF stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more SNF stays",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M8_1c_SNF_ALZ_DEM",
    measureMobile: "MCal SNF w/ Alz/Dem",
    measureDesktop: "SNF stay(s) for Medi-Cal members w/ Alzheimer's/Dementia",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with Alzheimer’s disease and/or related dementia with one or more SNF stays",
    program: "Utilization",
    bucket: "CalAIM Priority",
  },
  {
    id: "M8_1c_SNF_ALZ_DEM",
    measureMobile: "MCal SNF w/ Alz/Dem",
    measureDesktop: "SNF stay(s) for Medi-Cal members w/ Alzheimer's/Dementia",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with Alzheimer’s disease and/or related dementia with one or more SNF stays",
    program: "Utilization",
    bucket: "Long Term Care",
  },
  {
    id: "M8_1c_SNF_ALZ_DEM",
    measureMobile: "MCal SNF w/ Alz/Dem",
    measureDesktop: "SNF stay(s) for Medi-Cal members w/ Alzheimer's/Dementia",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with Alzheimer’s disease and/or related dementia with one or more SNF stays",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M8_1e_SUBACUTE",
    measureMobile: "MCal w/ subacute facility stay",
    measureDesktop: "Subacute facility stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more subacute care stays",
    program: "Utilization",
    bucket: "Long Term Care",
  },
  {
    id: "M8_1e_SUBACUTE",
    measureMobile: "MCal w/ subacute facility stay",
    measureDesktop: "Subacute facility stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more subacute care stays",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M8_1f_ICF",
    measureMobile: "MCal w/ ICF facility stay",
    measureDesktop:
      "Regional Center consumers enrolled in MC residing in an ICF",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more ICF stays",
    program: "Utilization",
    bucket: "Long Term Care",
  },
  {
    id: "M8_1f_ICF",
    measureMobile: "MCal w/ ICF facility stay",
    measureDesktop:
      "Regional Center consumers enrolled in MC residing in an ICF",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more ICF stays",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M8_1g_SNF_CUSTODIAL",
    measureMobile: "MCal w/ custodial care stay",
    measureDesktop: "Custodial care stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more custodial care stays",
    program: "Utilization",
    bucket: "Long Term Care",
  },
  {
    id: "M8_1g_SNF_CUSTODIAL",
    measureMobile: "MCal w/ custodial care stay",
    measureDesktop: "Custodial care stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more custodial care stays",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M8a_LTC",
    measureMobile: "MCal w/ LTC facility stay",
    measureDesktop: "LTC facilitiy stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more LTC stays",
    program: "Utilization",
    bucket: "CalAIM Priority",
  },
  {
    id: "M8a_LTC",
    measureMobile: "MCal w/ LTC facility stay",
    measureDesktop: "LTC facilitiy stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more LTC stays",
    program: "Utilization",
    bucket: "Long Term Care",
  },
  {
    id: "M8a_LTC",
    measureMobile: "MCal w/ LTC facility stay",
    measureDesktop: "LTC facilitiy stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more LTC stays",
    program: "Utilization",
    bucket: "LTSS ALL",
  },
  {
    id: "M8a_LTC",
    measureMobile: "MCal w/ LTC facility stay",
    measureDesktop: "LTC facilitiy stay(s)",
    measureDescriptionDesktop:
      "Number of certified eligible Medi-Cal members with one or more LTC stays",
    program: "Utilization",
    bucket: "LTSS Select",
  },
];
