import React, { useRef, useState } from "react";
import {
  ContentWrapper,
  OptionsMenuItem,
  OptionsMenuItemsWrapper,
  OptionsMenuWrapper,
  OptionsWrapper,
} from "./styles";
import { ColorPicker } from "../ColorPicker";
import {
  SectionMenuArrowButton,
  SectionMenuClose,
} from "../Storytelling/styles";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { ImagePicker } from "../ImagePicker";

interface StorytellingOptionsMenuInterface {
  item: any;
  fileUrl?: string;
  isSection?: boolean;
  setContentItem: any;
  setFile: any;
  handleRemoveSection?: () => void;
  setIsUploadOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const StorytellingOptionsMenu = ({
  item,
  fileUrl,
  setContentItem,
  isSection,
  setFile,
  handleRemoveSection,
}: StorytellingOptionsMenuInterface) => {
  const [activeTab, setActiveTab] = useState<"colorPicker" | "image" | "video">(
    isSection ? "image" : "colorPicker"
  );
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setOptionsMenuOpen(false);
  });

  return (
    <>
      <div ref={ref}>
        <SectionMenuArrowButton
          $active={optionsMenuOpen}
          $isSection={isSection}
          onClick={() => {
            setOptionsMenuOpen(!optionsMenuOpen);
          }}
        />
        {isSection && (
          <SectionMenuClose
            onClick={handleRemoveSection}
          />
        )}
        {optionsMenuOpen && (
          <OptionsWrapper $isSection={isSection} $isColorpicker={activeTab === "colorPicker"}>
            {!isSection && (
              <OptionsMenuWrapper>
                <OptionsMenuItemsWrapper>
                  <OptionsMenuItem
                    $activeItem={activeTab === "colorPicker"}
                    $width={81}
                    onClick={() => setActiveTab("colorPicker")}
                  >
                    Color Picker
                  </OptionsMenuItem>
                  <OptionsMenuItem
                    onClick={() => setActiveTab("image")}
                    $activeItem={activeTab === "image"}
                    $width={45}
                  >
                    Image
                  </OptionsMenuItem>
                </OptionsMenuItemsWrapper>
              </OptionsMenuWrapper>
            )}
            <ContentWrapper $isSection={isSection}>
              {activeTab === "colorPicker" && (
                <ColorPicker
                  item={item}
                  callback={(color) => {
                    setContentItem({
                      ...item,
                      backgroundColor: color,
                    });
                  }}
                  callbackText={(color) => {
                    setContentItem({
                      ...item,
                      backgroundColor: fileUrl ? "" : item?.backgroundColor,
                      textColor: color,
                    });
                  }}
                />
              )}
              {activeTab === "image" && (
                <ImagePicker
                  image={fileUrl!}
                  isSection={isSection}
                  callback={(newImage) => {
                    setFile(newImage);
                  }}
                />
              )}
            </ContentWrapper>
          </OptionsWrapper>
        )}
      </div>
    </>
  );
};
