import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Upload } from "react-bootstrap-icons";

import { setActiveModal } from "../../store/slices/modals";
import { Button } from "../Button";
import {
  NoDatasetDescription,
  NoDatasetDetailsWrapper,
  NoDatasetIcon,
  NoDatasetTitle,
  NoDatasetWrapper,
} from "./styles";
import { getActiveModal } from "../../store/selectors/modals";

export const EmptyDatasetsPage = () => {
  const dispatch = useDispatch();
  const modals = useSelector(getActiveModal);
  const datasetLibraryModal = modals.includes("datasetLibraryModal");

  return (
    <NoDatasetWrapper $datasetLibrary={datasetLibraryModal}>
      <NoDatasetIcon $datasetLibrary={datasetLibraryModal} />
      <NoDatasetDetailsWrapper $datasetLibrary={datasetLibraryModal}>
        <NoDatasetTitle>No datasets were discovered</NoDatasetTitle>
        <NoDatasetDescription>
          Feel free to add data by uploading CSV or XLS files. Just click the
          "Upload Dataset" button below!
        </NoDatasetDescription>
      </NoDatasetDetailsWrapper>
      {!datasetLibraryModal && (
        <Button
          name="Upload Dataset"
          onClick={() => {
            dispatch(setActiveModal({ id: "uploadDatasetModal" }));
          }}
          icon={<Upload />}
          variant="primary"
          size="medium"
        />
      )}
    </NoDatasetWrapper>
  );
};
