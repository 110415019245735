import React, { SetStateAction, useState } from "react";

import { Button } from "../Button";

import { ReactComponent as MetricValueIcon } from "../../assets/dimensionIcons/metric-value.svg";
import { ReactComponent as MetricValueGrayIcon } from "../../assets/dimensionIcons/metric-valueGray.svg";
import { ReactComponent as CategoryIcon } from "../../assets/dimensionIcons/category.svg";
import { ReactComponent as CategoryGrayIcon } from "../../assets/dimensionIcons/categoryGray.svg";
import { ReactComponent as DateTimeIcon } from "../../assets/dimensionIcons/date-time.svg";
import { ReactComponent as DateTimeGrayIcon } from "../../assets/dimensionIcons/date-timeGray.svg";
import { ReactComponent as LocationIcon } from "../../assets/dimensionIcons/location.svg";
import { ReactComponent as LocationGrayIcon } from "../../assets/dimensionIcons/locationGray.svg";
import { ReactComponent as TextGrayIcon } from "../../assets/dimensionIcons/textGray.svg";
import { ReactComponent as TextIcon } from "../../assets/dimensionIcons/text.svg";
import { ReactComponent as MetaIcon } from "../../assets/dimensionIcons/meta.svg";
import { ReactComponent as MetaGrayIcon } from "../../assets/dimensionIcons/metaGray.svg";

import {
  DimensionSettingsFooterWrapper,
  DimensionSettingsHeading,
  DimensionSettingsIconsItemsWrapper,
  DimensionSettingsInput,
  DimensionSettingsInputLabel,
  DimensionSettingsInputWrapper,
  DimensionSettingsTypeLabel,
  DimensionSettingsTypeWrapper,
  DimensionSettingsWrapper,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  requestUpdateDatasetById,
  updateDatasetData,
  updateDatasetHeader,
} from "../../store/slices/datasets";
import { updateFileData } from "../../store/slices/files";
import { getActiveModal } from "../../store/selectors/modals";
import {
  getSelectedDataset,
  getSelectedFileView,
} from "../../store/selectors/datasets";
import { toast } from "react-toastify";
import { RadioButtonComponent } from "./components/RadioButtonComponent";

interface IDimensionSettings {
  filePath: string;
  setShowDimensionSettings: SetStateAction<any>;
  showDimensionSettings: {
    header: string;
    top: number;
    left: number;
    suggestedType?: string;
    key: string;
    errorsDetected: boolean;
  };
}

export const DimensionSettings = ({
  setShowDimensionSettings,
  showDimensionSettings: {
    header,
    top,
    left,
    suggestedType,
    key,
    errorsDetected,
  },
  filePath,
}: IDimensionSettings) => {
  const dispatch = useDispatch();
  const activeModal = useSelector(getActiveModal);
  const [columnName, setColumnName] = useState<string>(header);
  const [selectedCategory, setSelectedCategory] = useState<string>(
    suggestedType || ""
  );
  const fileSelected = useSelector(getSelectedFileView);
  const selectedDataset = useSelector(getSelectedDataset);
  const handleCheckboxChange = (category: string) => {
    setSelectedCategory(category);
  };

  const settingsItemsData = [
    { type: "categorical", label: "Category", icon: <CategoryIcon />, grayIcon: <CategoryGrayIcon /> },
    { type: "location", label: "Location", icon: <LocationIcon />, grayIcon: <LocationGrayIcon /> },
    { type: "datetime", label: "Date / Time", icon: <DateTimeIcon />, grayIcon: <DateTimeGrayIcon /> },
    { type: "metric", label: "Metric / Value", icon: <MetricValueIcon />, grayIcon: <MetricValueGrayIcon /> },
    { type: "text", label: "Text", icon: <TextIcon />, grayIcon: <TextGrayIcon /> },
    { type: "meta", label: "Meta", icon: <MetaIcon />, grayIcon: <MetaGrayIcon /> },
  ];

  const handleSave = () => {
    if (activeModal.includes("datasetLibraryModal")) {
      if (selectedDataset !== null && selectedDataset.id) {
        const updatedFields = selectedDataset?.fields?.map((field) => {
          if (field.key === key) {
            return {
              ...field,
              label: columnName,
              type: selectedCategory,
              subtype: selectedCategory,
              icon: selectedCategory,
            };
          }
          return field;
        });

        dispatch(
          updateDatasetData({
            filePath,
            key,
            label: columnName,
            category: selectedCategory,
          })
        );

        dispatch(
          requestUpdateDatasetById({
            id: selectedDataset.id,
            name: selectedDataset.name,
            fields: updatedFields!,
          })
        );
      }
    } else {
      if (
        fileSelected.parsedSuggestions
          .map((suggestion) => suggestion.label)
          ?.filter((label: string) => label !== header)
          .includes(columnName)
      ) {
        toast.error("Column name already exists!");
      } else {
        dispatch(
          updateDatasetHeader({
            label: columnName,
            selectedType: selectedCategory,
            selectedSubType: selectedCategory,
            objectKey: key,
          })
        );
        dispatch(
          updateFileData({
            key,
            newHeader: columnName,
            filePath: filePath,
            type: selectedCategory,
          })
        );
      }
    }

    setShowDimensionSettings(null);
  };

  return (
    <DimensionSettingsWrapper $top={top} $left={left}>
      <DimensionSettingsHeading>Dimension settings:</DimensionSettingsHeading>
      <DimensionSettingsInputWrapper>
        <DimensionSettingsInputLabel>Name</DimensionSettingsInputLabel>
        <DimensionSettingsInput
          defaultValue={columnName}
          onChange={(e) => setColumnName(e.target.value)}
        />
      </DimensionSettingsInputWrapper>

      <DimensionSettingsTypeWrapper>
        <DimensionSettingsTypeLabel>Type</DimensionSettingsTypeLabel>
        <DimensionSettingsIconsItemsWrapper>
          {settingsItemsData.map(({ type, label, icon, grayIcon }) => (
            <RadioButtonComponent {...{ selectedCategory, type, handleCheckboxChange, label, icon, grayIcon }} />
          ))}
        </DimensionSettingsIconsItemsWrapper>
      </DimensionSettingsTypeWrapper>

      <DimensionSettingsFooterWrapper>
        <Button
          onClick={() => {
            setShowDimensionSettings(null);
          }}
          variant="neutral"
          size="small"
          name="Close"
        />
        <Button
          onClick={handleSave}
          variant={
            !errorsDetected &&
              header === columnName &&
              suggestedType === selectedCategory
              ? "disabled"
              : "secondary"
          }
          size="small"
          name="Save"
          disabled={
            !errorsDetected &&
            header === columnName &&
            suggestedType === selectedCategory
          }
        />
      </DimensionSettingsFooterWrapper>
    </DimensionSettingsWrapper>
  );
};


