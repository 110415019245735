import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  AvailableWidgetsWrapper,
  AvailableWidgetsCount,
  AvailableWidgetsHeading,
  AvailableWidgetsHeadingWrapper,
  WidgetItem,
  WidgetsItemsWrapper,
} from "./styles";
import { VerticalBarchart } from "../Widgets/VerticalBarchart";
import { SankeyPlot } from "../Widgets/Sankey";
import { GroupedBarChart } from "../Widgets/GroupedBarChart";
import { LineChart } from "../Widgets/LineChart/SingleLineChart";
import { AreaChart } from "../Widgets/AreaChart";
import { getCurrentWidget } from "../../store/selectors/projects";
import { GroupedLineChart } from "../Widgets/LineChart/GroupedLineChart";
import { HorizontalBarChart } from "../Widgets/HorizontalBarChart";
import { HorizontalLollipopChart } from "../Widgets/Lollipop/Horizontal";
import { VerticalLollipopChart } from "../Widgets/Lollipop/Vertical";
import { WidgetChartWrapper } from "../Widgets";
import { MatrixChart } from "../Widgets/MatrixChart";
import { WIDGETS } from "../Widgets/widgets";
import { AVAILABLE_WIDGETS } from "../../constants/widgetRecomended";
import { DataItem } from "../../models/Widgets";
import { HorizontalGroupedBarChart } from "../Widgets/HorizontalGroupedBarChart";
import { LeafletMap } from "../Widgets/LeafletMap";
import { PolarAreaChart } from "../Widgets/PolarAreaChart";
import { PunchcardChart } from "../Widgets/PunchcardChart";
import { RadarChart } from "../Widgets/RadarChart";

export const AvailableWidgets = ({
  selectChart,
}: {
  selectChart: (chart?: string) => void;
}) => {
  const [recommendedWidgets, setRecommendedWidgets] = useState<string[]>([]);
  const currentWidget = useSelector(getCurrentWidget);
  const [selectedChart, setSelectedChart] = useState<string | undefined>(
    currentWidget?.id
  );

  useEffect(() => {
    if (currentWidget) {
      let widgets = AVAILABLE_WIDGETS[currentWidget.chartType] as string[];
      const orientation = currentWidget.orientation;
      const chartType = orientation
        ? `${currentWidget.chartType}_${orientation}`
        : currentWidget.chartType;
      widgets = widgets?.filter((r) => r !== chartType);
      if (["matrix", "sankey"].includes(currentWidget.chartType)) {
        const ifRecomendedToMap = currentWidget?.data?.some(
          (d) => d?.county && d?.state
        );
        if (ifRecomendedToMap) {
          ["matrix", "sankey"].forEach((t) => {
            const index = widgets.indexOf(t);
            if (index !== -1) {
              widgets.splice(index, 1, `${t}_map`);
            }
          });

          widgets = [...widgets, "map_matrix"];
        }
      }
      setRecommendedWidgets(widgets || []);
    } else {
      setRecommendedWidgets([]);
    }
  }, [currentWidget]);

  const onSelectChart = (value?: string) => {
    const chart = value !== selectedChart ? value : undefined;
    setSelectedChart(chart);
    selectChart(chart);
  };

  const renderRecommendedWidgets = () => {
    return recommendedWidgets?.map((widget: string, index: number) => {
      switch (widget) {
        case "lollipopChart_horizontal":
          return (
            <WidgetItem key={index} $selected={true}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "lollipopChart_horizontal"}
                onSelect={() => onSelectChart("lollipopChart_horizontal")}
              >
                <HorizontalLollipopChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "lollipopChart_horizontal"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "lollipopChart_vertical":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "lollipopChart_vertical"}
                onSelect={() => onSelectChart("lollipopChart_vertical")}
              >
                <VerticalLollipopChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "lollipopChart_vertical"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "barChart_vertical":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "barChart_vertical"}
                onSelect={() => onSelectChart("barChart_vertical")}
              >
                {currentWidget?.groupBy === null ? (
                  <VerticalBarchart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_vertical"}
                  />
                ) : (
                  <GroupedBarChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_vertical"}
                  />
                )}
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "barChart_horizontal":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "barChart_horizontal"}
                onSelect={() => onSelectChart("barChart_horizontal")}
              >
                {currentWidget?.groupBy === null ? (
                  <HorizontalBarChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_horizontal"}
                  />
                ) : (
                  <HorizontalGroupedBarChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "barChart_horizontal"}
                  />
                )}
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "lineChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "lineChart"}
                onSelect={() => onSelectChart("lineChart")}
              >
                {currentWidget?.groupBy ? (
                  <GroupedLineChart
                    currentWidget={currentWidget}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "lineChart"}
                  />
                ) : (
                  <LineChart
                    currentWidget={currentWidget!}
                    recommended={true}
                    showLegend={false}
                    hideName={true}
                    selected={selectedChart === "lineChart"}
                  />
                )}
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "areaChart":

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "areaChart"}
                onSelect={() => onSelectChart("areaChart")}
              >
                <AreaChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "areaChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "punchcardChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "punchcardChart"}
                onSelect={() => onSelectChart("punchcardChart")}
              >
                <PunchcardChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "punchcardChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "polarAreaChart":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "polarAreaChart"}
                onSelect={() => onSelectChart("polarAreaChart")}
              >
                <PolarAreaChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "polarAreaChart"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "radar":
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "radar"}
                onSelect={() => onSelectChart("radar")}
              >
                <RadarChart
                  currentWidget={currentWidget!}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "radar"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "sankey":
          const groupBy = currentWidget?.groupBy?.at(0)!;
          const xAxe = currentWidget?.xAxe?.at(0)!;
          const yAxe = currentWidget?.yAxe?.at(0)!;
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "sankey"}
                onSelect={() => onSelectChart("sankey")}
              >
                <SankeyPlot
                  currentWidget={{
                    ...currentWidget!,
                    data: currentWidget?.data,
                    display: [yAxe],
                    arrangeBy: [groupBy, xAxe],
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "sankey"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "matrix":
          const arrangeBy = currentWidget?.uniqueValues
            ? Object.keys(currentWidget?.uniqueValues!)?.at(1)!
            : currentWidget?.arrangeBy?.at(0)!;
          const display = currentWidget?.display?.at(0)!;
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "matrix"}
                onSelect={() => onSelectChart("matrix")}
              >
                <MatrixChart
                  currentWidget={{
                    ...currentWidget!,
                    chartType: "matrixChart",
                    arrangeBy: [],
                    xAxe: [arrangeBy],
                    yAxe: [display],
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "matrix"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );

        case "sankey_map":
          const sankeyMapChartData =
            currentWidget?.layers?.at(0)?.data || currentWidget?.data || [];

          const isMap2Sankey = currentWidget?.chartType === "mapChart";

          const displaySankey: string[] = isMap2Sankey
            ? currentWidget?.layers.at(0)?.arrangeByMetric ?? ["value"]
            : currentWidget?.yAxe ?? ["value"];

          const arrangeBySankey: string = isMap2Sankey
            ? currentWidget?.layers?.at(0)?.timePeriod?.field ?? "year"
            : currentWidget?.xAxe?.at(0) ?? "year";

          const sankeyQuery = isMap2Sankey
            ? currentWidget?.layers?.at(0)?.query ?? ""
            : currentWidget?.query ?? "";

          const data = sankeyMapChartData?.reduce(
            (t: DataItem[], r: DataItem) => {
              const exist = t?.some(
                (n: DataItem) => n[arrangeBySankey] === r[arrangeBySankey]
              );
              if (exist) {
                return t?.map((d: DataItem) => {
                  if (d[arrangeBySankey] === r[arrangeBySankey]) {
                    return {
                      ...d,
                      [displaySankey[0]]: (
                        Number(d[displaySankey[0]]) +
                        Number(r[displaySankey[0]])
                      ).toString(),
                    };
                  }
                  return d;
                });
              }
              return [...t, r];
            },
            []
          );

          const uniqueValuesSankey = data?.reduce(
            (t: string[], r: DataItem) => {
              if (!t?.includes(r.county)) {
                return [...t, r[arrangeBySankey]];
              }
              return t;
            },
            []
          );

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "sankey_map"}
                onSelect={() => onSelectChart("sankey_map")}
              >
                <SankeyPlot
                  currentWidget={{
                    ...currentWidget!,
                    data: sankeyMapChartData,
                    dataFormat: currentWidget?.dataFormat || {},
                    uniqueValues: {
                      [arrangeBySankey]: uniqueValuesSankey,
                    },
                    palette: currentWidget?.palette || {},
                    arrangeBy: ["state", arrangeBySankey],
                    display: displaySankey,
                    query: sankeyQuery,
                  }}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  selected={selectedChart === "sankey_map"}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "matrix_map":
          const matrixMapChartData =
            currentWidget?.layers?.at(0)?.data || currentWidget?.data || [];

          const isMap = currentWidget?.chartType === "mapChart";

          const getXAxe = isMap
            ? [currentWidget?.layers?.at(0)?.timePeriod?.field ?? "year"]
            : [currentWidget?.arrangeBy?.at(1) ?? "year"];

          const getYAxe = (isMap
            ? currentWidget?.layers?.at(0)?.arrangeByMetric
            : currentWidget?.display) ?? ["value"];

          const dataMatrix = matrixMapChartData?.reduce(
            (t: DataItem[], r: DataItem) => {
              if (r.state === "California") {
                const exist = t?.some(
                  (n: DataItem) =>
                    n[getXAxe[0]] === r[getXAxe[0]] && n.county === r.county
                );
                if (exist) {
                  return t?.map((d: DataItem) => {
                    if (
                      d[getXAxe[0]] === r[getXAxe[0]] &&
                      d.county === r.county
                    ) {
                      return {
                        ...d,
                        [getYAxe[0]]: (
                          Number(d[getYAxe[0]]) + Number(r[getYAxe[0]])
                        ).toString(),
                      };
                    }
                    return d;
                  });
                }
                return [
                  ...t,
                  {
                    [getXAxe[0]]: r[getXAxe[0]],
                    [getYAxe[0]]: r[getYAxe[0]],
                    county: r.county,
                  },
                ];
              }
              return t;
            },
            []
          );

          const uniqueCountyValues = dataMatrix?.reduce(
            (t: string[], r: DataItem) => {
              if (!t?.includes(r.county)) {
                return [...t, r.county];
              }
              return t;
            },
            []
          );

          const queryMatrixMap = isMap
            ? currentWidget?.layers?.at(0)?.query
            : currentWidget?.query;

          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                recommended={true}
                selected={selectedChart === "matrix_map"}
                onSelect={() => onSelectChart("matrix_map")}
              >
                <MatrixChart
                  selected={selectedChart === "matrix_map"}
                  recommended={true}
                  showLegend={false}
                  hideName={true}
                  storytelling={false}
                  currentWidget={{
                    ...currentWidget!,
                    data: dataMatrix,
                    layers: [],
                    chartType: "matrixChart",
                    xAxe: getXAxe,
                    yAxe: getYAxe,
                    groupBy: [],
                    arrangeBy: ["county"],
                    uniqueValues: uniqueCountyValues
                      ? {
                        county: uniqueCountyValues,
                        [getXAxe[0]]:
                          currentWidget?.layers?.at(0)?.timePeriod?.values!,
                      }
                      : {
                        [getXAxe[0]]:
                          currentWidget?.layers?.at(0)?.timePeriod?.values!,
                      },
                    legend: currentWidget?.legend ?? false,
                    tooltip: currentWidget?.tooltip ?? false,
                    blockId: currentWidget?.blockId?.toString(),
                    dataFormat: currentWidget?.dataFormat || {},
                    palette: (currentWidget?.palette as any) || {},
                    query: queryMatrixMap,
                  }}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );
        case "map_matrix":
          const isMatrix = currentWidget?.chartType === "matrix";

          const field = isMatrix
            ? currentWidget?.xAxe?.at(0) ?? "year"
            : currentWidget?.arrangeBy?.at(1) ?? "year";

          const arrangeByMetricMap = isMatrix
            ? currentWidget.yAxe ?? ["value"]
            : currentWidget?.display ?? ["value"];
          return (
            <WidgetItem key={index}>
              <WidgetChartWrapper
                map={true}
                recommended={true}
                selected={selectedChart === "map_matrix"}
                onSelect={() => onSelectChart("map_matrix")}
              >
                <LeafletMap
                  showLegend={false}
                  recommended={true}
                  selected={selectedChart === "map_matrix"}
                  currentWidget={{
                    ...currentWidget!,
                    layers: [
                      {
                        data: currentWidget?.data as DataItem[],
                        name: "Members by Year",
                        query: currentWidget?.query!,
                        colour: "green",
                        format: "geojson",
                        tooltip: true,
                        analytics: "average",
                        datasetId: currentWidget?.datasetId!,
                        geospatialData: ["state", "county"],
                        arrangeByMetric: arrangeByMetricMap,
                        visualisationType: "markers",
                        geoTarget: [],
                        description: null,
                        timePeriod: {
                          type: "",
                          field:
                            currentWidget?.xAxe?.at(0) ||
                            currentWidget?.arrangeBy?.at(1) ||
                            "year",
                          values:
                            field && currentWidget?.uniqueValues
                              ? currentWidget?.uniqueValues[field]
                              : [],
                        },
                      },
                    ],
                    dataFormat: currentWidget?.dataFormat || {},
                    chartType: "mapChart",
                    layout: "card",
                    legend: false,
                    background: "white",
                    search: false,
                  }}
                />
              </WidgetChartWrapper>
            </WidgetItem>
          );

        default:
          return null;
      }
    });
  };

  const renderCurrentWidget = () => {
    if (!currentWidget) {
      return null;
    }
    const ChartComponent: any =
      currentWidget &&
      currentWidget.chartType &&
      WIDGETS[currentWidget.chartType];

    return (
      <WidgetItem key={currentWidget.id}>
        <WidgetChartWrapper
          recommended={true}
          storytelling={false}
          map={currentWidget.chartType === "mapChart"}
          selected={selectedChart === currentWidget.id}
          onSelect={() => onSelectChart(currentWidget.id)}
        >
          <ChartComponent
            recommended={true}
            showLegend={false}
            storytelling={false}
            selected={selectedChart === currentWidget.id}
            currentWidget={currentWidget}
            hideName={true}
          />
        </WidgetChartWrapper>
      </WidgetItem>
    );
  };

  return (
    <AvailableWidgetsWrapper>
      <AvailableWidgetsHeadingWrapper>
        <AvailableWidgetsHeading>Variants</AvailableWidgetsHeading>
        <AvailableWidgetsCount>
          {recommendedWidgets?.length ? recommendedWidgets?.length + 1 : 0}
        </AvailableWidgetsCount>
      </AvailableWidgetsHeadingWrapper>

      <WidgetsItemsWrapper>
        {renderCurrentWidget()}
        {renderRecommendedWidgets()}
      </WidgetsItemsWrapper>
    </AvailableWidgetsWrapper>
  );
};
