import styled from "styled-components";
import { ReactComponent as Users } from "../../assets/usersWithAccess.svg";
import { ChevronExpand } from "react-bootstrap-icons";

export const ProjectsTableWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 8px;
`;

export const UploadedOnIcon = styled(ChevronExpand)`
  margin-left: 4px;
  cursor: pointer;
`;

export const ProjectsTableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const ProjectsTableHead = styled.thead`
  text-align: left;
  border-bottom: 1px solid lightgray;
  border-radius: 10px;
  height: 36px;
`;

export const ProjectsTableHeaderRow = styled.tr`
  border-radius: 10px 10px 0 0;

  th {
    font-size: 11px;
    font-weight: 400;
    color: ${({ theme }) => theme.neutralTextWeak};
    background-color: ${({ theme }) => theme.neutralBackgroundMedium};
  }
`;

export const ProjectsTableBody = styled.tbody`
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
`;

export const ProjectsTableBodyRow = styled.tr`
  vertical-align: top;
  &:hover {
    background-color: ${({ theme }) => theme.primaryBackground};
  }
`;

export const ProjectNameHeading = styled.th`
  word-wrap: break-word;
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const ProjectName = styled.td`
  padding: 8px 8px;
  word-wrap: break-word;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
  max-width: 285px;
`;
export const ProjectDescription = styled.td`
  padding: 8px 8px 8px 0;
  word-wrap: break-word;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProjectCount = styled.td`
  padding-top: 8px;
`;

export const ProjectsCountLabel = styled.div`
  width: 16px;
  font-size: 11px;
  font-weight: 400;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.neutralBackground};
  color: ${({ theme }) => theme.neutralTextWeak};
  padding: 3px 4px;
`;

export const ProjectRole = styled.td`
  padding-top: 8px;
`;

export const ProjectRoleLabel = styled.div<{ $role: string }>`
  height: 20px;
  width: fit-content;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 8px;
  margin-right: 8px;
  background-color: ${({ theme, $role }) =>
    $role === "owner"
      ? theme.successBackground
      : $role === "editor"
      ? theme.primaryBackground
      : $role === "viewer"
      ? theme.neutralBackground
      : ""};

  color: ${({ theme, $role }) =>
    $role === "owner"
      ? theme.successText
      : $role === "editor"
      ? theme.primaryText
      : $role === "viewer"
      ? theme.neutralTextWeak
      : ""};
`;

export const UsersIcons = styled.td`
  padding-top: 8px;
`;

export const UsersWithAccessIcon = styled(Users)``;

export const UploadedOn = styled.td`
  padding: 8px 8px 0;
  font-size: 12px;
  font-weight: 400;
  text-align: end;
  color: ${({ theme }) => theme.neutralText};
`;

export const UploadedTime = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
