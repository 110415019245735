import { styled } from "styled-components";

export const Container = styled.div<{ $active?: boolean, $finished?: boolean }>`
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  position: relative;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  transition: 0.3s;

  ${({ $active, theme }) => $active && `
    border-color: ${theme.primaryBackgroundStrong};
    background: ${theme.primaryBackgroundActive};

    &:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      height: 8px;
      width: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: ${theme.primaryBackgroundStrong};
    }
  `};

${({ $finished, theme }) => $finished && `
    border-color: ${theme.primaryAction};
    background: ${theme.primaryAction};
  `};
`;