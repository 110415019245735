import React from "react";
import { Container } from "./style";

type Props = {
  active?: boolean;
  finished?: boolean;
};

const StepDot = ({ active, finished }: Props) => {
  return <Container $active={active} $finished={finished}></Container>;
};

export default StepDot;
