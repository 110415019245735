import * as s from "./styles";

interface Props {
  onClose?: () => void;
  onResolveAll?: () => void;
  hasComments: boolean;
}

export const CommentHead: React.FC<Props> = ({
  hasComments,
  onClose,
  onResolveAll,
}) => {
  if (!hasComments) return null;
  return (
    <s.CommentHeadWrapper>
      <s.ActionSection>
          <s.ActionItem onClick={onResolveAll}>
            Resolve All
          </s.ActionItem>
        <s.CloseButton onClick={onClose}>
          <s.Close />
        </s.CloseButton>
      </s.ActionSection>
    </s.CommentHeadWrapper>
  );
};
