import { Paragraph, TableHeaderElement } from "./style";
import { ThreeDots } from "react-bootstrap-icons";

type THProps = {
  text: string;
  size?: string;
};

export const THElement = ({ text, size }: THProps) => {
  return (
    <TableHeaderElement $size={size}>
      <Paragraph>{text}</Paragraph>
      <ThreeDots style={{ fill: "#5F6877" }} />
    </TableHeaderElement>
  );
};
