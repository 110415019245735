import styled, { css } from "styled-components";

import {
  CaretDownFill,
  TextCenter,
  TextLeft,
  TextRight,
  XLg,
} from "react-bootstrap-icons";

const alignmentItemsStyle = css<{ $active: boolean }>`
  background-color: ${({ theme, $active }) =>
    $active && theme.primaryBackgroundActive};
  border-radius: 2px;
  cursor: pointer;
`;

export const PageWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

export const AlignmentOptionsWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: absolute;
  padding: 2px;
  left: 50%;
  top: 18px;
  width: 60px;
  height: 20px;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
`;

export const AlignLeftIcon = styled(TextLeft)<{ $active: boolean }>`
  ${alignmentItemsStyle}
`;
export const AlignCenterIcon = styled(TextCenter)<{ $active: boolean }>`
  ${alignmentItemsStyle}
`;
export const AlignRightIcon = styled(TextRight)<{ $active: boolean }>`
  ${alignmentItemsStyle}
`;

export const SectionMenuArrowButton = styled(CaretDownFill)<{
  $active: boolean;
}>`
  display: ${({ $active }) => ($active ? "block" : "none")};
  position: absolute;
  top: 16px;
  right: ${({ $active }) => ($active ? "40px" : " 16px")};
  cursor: pointer;
  padding: 2px 4px;
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.neutralTextWeak};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
`;

export const SectionMenuClose = styled(XLg)`
  display: block;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  padding: 2px 4px;
  height: 20px;
  width: 20px;
  color: ${({ theme }) => theme.neutralTextWeak};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 4px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
`;

export const SectionWrapper = styled.div<{
  $applyBackground?: boolean;
  $backgroundColor?: string;
  $gradient?: string;
  $rgbaColor?: string;
  $imageUrl?: string;
  $isEditMode?: boolean;
}>`
  width: auto;
  border: 1px solid transparent;
  border-radius: 8px;
  position: relative;
  background-image: ${({ $imageUrl }) =>
    $imageUrl ? `url(${$imageUrl})` : ""};
  background: ${({ theme, $applyBackground, $backgroundColor, $imageUrl }) =>
    $imageUrl
      ? ""
      : $backgroundColor
      ? $backgroundColor
      : $applyBackground && theme.neutralBackgroundMedium};
  background-size: cover;

  display: flex;
  &:hover {
    border: 1px solid
      ${({ theme, $isEditMode }) =>
        $isEditMode ? theme.primaryBorder : "transparent"};

    ${AlignmentOptionsWrapper} {
      display: flex;
    }
    ${SectionMenuArrowButton} {
      display: block;
    }
  }
`;

export const ChartHeader = styled.div`
  padding: 24px 0;
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderTitle = styled.textarea`
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
  margin-left: 32px;
  border: none;
  resize: none;
  max-width: 340px;
  overflow: hidden;
  background-color: transparent;
  text &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus-visible {
    background-color: ${({ theme }) => theme.primaryBackground};
    outline: none;
    border-radius: 5px;
  }
`;

export const ItemWrapper = styled.div<{ $reverse?: boolean }>`
  height: 392px;
  display: flex;
  padding: 0 32px;
  gap: 32px;
  flex-direction: ${({ $reverse }) => $reverse && "row-reverse"};
`;

export const ChartWrapper = styled.div`
  width: 799px;
  height: 368px;
`;

export const HighlightsText = styled.textarea`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
  height: 110px;
  width: 329px;
  flex: none;
  border: none;
  resize: none;
  max-width: 340px;
  overflow: hidden;
  background-color: transparent;

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus-visible {
    background-color: ${({ theme }) => theme.primaryBackground};
    outline: none;
    border-radius: 5px;
  }
`;

export const MapWrapper = styled.div<{
  $draggable?: boolean;
}>`
  height: 479px;
  position: relative;
  border: ${({ $draggable, theme }) =>
    `1px ${
      $draggable ? `dashed ${theme.primaryBorder}` : "solid transparent"
    }`};
`;

const commonContentStyles = css<{ $alignment?: string; $isEditMode?: boolean }>`
  color: #fff;
  border: none;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  text-align: ${({ $alignment }) => $alignment};
  border-radius: 8px;
  cursor: ${({ $isEditMode }) => ($isEditMode ? "text" : "default")};
  &:hover {
    background-color: ${({ theme, $isEditMode }) =>
      $isEditMode ? theme.neutralBackgroundHover : "none"};
  }

  &:focus-visible {
    outline: none;
    border-radius: 5px;
  }
`;

export const TextAndImageHeadingContent = styled.div<{ $alignment?: string }>`
  text-align: ${({ $alignment }) => $alignment};
`;

export const TextAndImageHeading = styled.textarea.attrs(() => ({
  role: "textbox",
  suppressContentEditableWarning: true,
}))<{ $alignment?: string; $isEditMode: boolean; $color?: string }>`
  width: 800px;
  height: 112px;
  /* margin-top: 80px; */
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.05em;
  line-height: 56px;
  pointer-events: ${({ $isEditMode }) => ($isEditMode ? "auto" : "none")};
  color: ${({ $color, theme }) =>
    $color ? $color : theme.neutralText} !important;
  ${commonContentStyles}
`;

export const TextAndImageHighlights = styled.textarea.attrs(() => ({
  role: "textbox",
  suppressContentEditableWarning: true,
}))<{ $alignment?: string; $isEditMode: boolean; $color?: string }>`
  width: 800px;
  height: 228px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.02em;
  margin-top: 24px;
  pointer-events: ${({ $isEditMode }) => ($isEditMode ? "auto" : "none")};
  color: ${({ $color, theme }) =>
    $color ? $color : theme.neutralText} !important;
  ${commonContentStyles}
`;

export const SectionWrapperBlock = styled.div<{
  $draggable?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  /* gap: 24px 32px; */
  border: ${({ $draggable, theme }) =>
    `1px ${
      $draggable ? `dashed ${theme.primaryBorder}` : "solid transparent"
    }`};
  border-radius: 8px;
  &:hover {
    border: ${({ theme }) => `1px solid  ${theme.primaryBorder}`};
  }
`;

export const TextAndImageDescription = styled.textarea.attrs(() => ({
  role: "textbox",
  suppressContentEditableWarning: true,
}))<{ $alignment?: string; $isEditMode: boolean; $color?: string }>`
  width: 800px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  pointer-events: ${({ $isEditMode }) => ($isEditMode ? "auto" : "none")};
  margin-top: 24px;
  color: ${({ $color, theme }) =>
    $color ? $color : theme.neutralText} !important;
  ${commonContentStyles}
`;

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 918px;
  height: 1298px;
  border-radius: var(--layout-card-l-storytelling-radius, 8px);
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 24px 32px;
`;

export const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 16px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PageNumber = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
