import { capitalizeName } from "./capitalizeName";

const words = ["lollipop", "bar", "sankey", "line", "area", "matrix", "map"];

export const replaceWords = (text: string) => {
  if (!text) {
    return;
  }
  for (const word of words) {
    const regex = new RegExp(`\\b${word}\\b`, "gi");
    text = text.replace(regex, "");
  }
  text = text.replace(/\s\s+/g, " ").trim();

  text = capitalizeName(text);
  return text;
};
