import { useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "../Button";
import {
  AddSectionButton,
  AddSectionSeparator,
  AddSectionWrapper,
  Close,
  Plus,
  SectionType,
  SectionTypeIcon,
  SectionTypeMenuWrapper,
  SectionTypeName,
} from "./styles";

import { ReactComponent as TextAndImageType } from "../../assets/sectionTypes/textAndImage.svg";
import { ReactComponent as WidgetType } from "../../assets/sectionTypes/widget.svg";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { getAiSuggestions } from "../../store/selectors/widgets";

export const defaultHeader = {
  title: "",
  description: "",
  insights:
    "Here, you'll find additional information that may not be crucial but could still be interesting or helpful.",
  align: "left",
  image: "",
  backgroundColor: "linear-gradient(90deg, #FF930F 0%, #FFF95B 100%)",
  textColor: "#FFFFFF",
};

export const defaultSection = {
  title: "Add a title to this section",
  description:
    "Please add a subtitle here that provides a brief overview of the content presented on this page below.",
  insights:
    "Here, you'll find additional information that may not be crucial but could still be interesting or helpful.",
  align: "left",
  image: "",
  backgroundColor: "#ffffff",
  textColor: "#000005",
};

export const AddStorytellingSection = ({
  addSection,
  addWidget,
}: {
  addSection: () => void;
  addWidget: () => void;
}) => {
  const [showSectionMenu, setShowSectionMenu] = useState(false);
  const [hovered, setHovered] = useState(false);
  const suggestions = useSelector(getAiSuggestions);

  const ref = useRef(null);

  const toggleMenu = () => {
    setShowSectionMenu(!showSectionMenu);
  };

  useOutsideClick(ref, () => {
    setShowSectionMenu(false);
  });

  return (
    <div ref={ref}>
      <AddSectionWrapper $menuOpen={showSectionMenu}>
        <AddSectionSeparator
          $hover={!showSectionMenu && hovered}
          $menuOpen={showSectionMenu}
        />
        <AddSectionButton $menuOpen={showSectionMenu}>
          <Button
            onClick={toggleMenu}
            onMouseLeave={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
            variant={showSectionMenu ? "neutral" : "primary"}
            size="small"
            name={showSectionMenu ? "Cancel" : "Add section"}
            icon={!showSectionMenu ? <Plus /> : <Close />}
          />
        </AddSectionButton>
        <AddSectionSeparator
          $hover={!showSectionMenu && hovered}
          $menuOpen={showSectionMenu}
        />
      </AddSectionWrapper>
      {showSectionMenu && (
        <SectionTypeMenuWrapper $menuOpen={showSectionMenu}>
          <SectionType
            onClick={() => {
              addSection();
              setShowSectionMenu(false);
            }}
          >
            <SectionTypeIcon>
              <TextAndImageType />
            </SectionTypeIcon>
            <SectionTypeName>Text and image</SectionTypeName>
          </SectionType>
          <SectionType
            onClick={() => {
              suggestions?.length > 0 && addWidget();
            }}
            $disabled={!suggestions?.length}
          >
            <SectionTypeIcon>
              <WidgetType />
            </SectionTypeIcon>
            <SectionTypeName>Widget</SectionTypeName>
          </SectionType>
        </SectionTypeMenuWrapper>
      )}
    </div>
  );
};
