import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AreaClosed, LinePath } from "@visx/shape";
import { curveNatural } from "d3";
import React, { useEffect, useState } from "react";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import useMeasure from "react-use-measure";

type Props = {
  values: any;
  maxValue: number;
  rangeStep: number;
};

const DataRangeFilterChart = ({ values, maxValue, rangeStep }: Props) => {
  const [groupedData, setGroupedData] = useState<{ x: number; y: number }[]>(
    []
  );
  const [maxHeighValue, setMaxHeighValue] = useState(0);
  const [ref, bounds] = useMeasure();

  useEffect(() => {
    const lastValuesIndex = values.length - 1;

    const dataDensity = [{ x: 0, y: 0 }];
    let maxData = 0;
    let curentValuesIndex = 0;

    for (let i = 1; i <= 7; i++) {
      let dataInCurentRange = 0;
      const stepValue = i === 7 ? maxValue : rangeStep * i;

      for (
        curentValuesIndex;
        curentValuesIndex < lastValuesIndex;
        curentValuesIndex++
      ) {
        if (values[curentValuesIndex] <= stepValue) {
          dataInCurentRange++;
        } else {
          break;
        }
      }

      dataDensity.push({
        x: stepValue,
        y: dataInCurentRange,
      });
      if (maxData < dataInCurentRange) {
        maxData = dataInCurentRange;
      }
    }

    setGroupedData(dataDensity);
    setMaxHeighValue(maxData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const xScale = scaleBand({
    range: [0, bounds.width],
    domain: groupedData.map((item) => item.x),
    padding: -1,
  });

  const yScale = scaleLinear({
    domain: [0, maxHeighValue],
    nice: true,
    range: [bounds.height - 5, 0],
  });

  return (
    <>
      <div ref={ref}>
        <svg width="100%" height={"80px"}>
          <Group>
            {groupedData &&
              Object.keys(groupedData)?.map((key) => {
                const area = groupedData;
                area?.sort(function (a, b) {
                  if (a.x < b.x) return -1;
                  if (b.x > a.x) return 1;

                  return 0;
                });

                return (
                  <AreaClosed
                    style={{ transition: "0.3s" }}
                    key={key}
                    data={area?.map((d: { x: number; y: number }) => {
                      return {
                        x: xScale(d.x)! + xScale.bandwidth() / 2,
                        y0: yScale(0)!,
                        y1: yScale(d.y)!,
                      };
                    })}
                    x={(d: any) => d.x}
                    y0={(d: any) => d.y0}
                    y1={(d: any) => d.y1}
                    yScale={yScale}
                    curve={curveNatural}
                    fill={hexToRGBA("#F3F3F4", 1)}
                  />
                );
              })}
          </Group>

          <Group>
            {groupedData &&
              Object.keys(groupedData)?.map((key) => {
                const area = groupedData;

                area?.sort(function (a, b) {
                  if (a.x < b.x) return -1;
                  if (b.x > a.x) return 1;
                  return 0;
                });

                return (
                  <LinePath
                    key={key}
                    style={{ transition: "0.3s" }}
                    data={area?.map((d: { x: number; y: number }) => ({
                      x: xScale(d.x)! + xScale.bandwidth() / 2,
                      y: yScale(d.y)!,
                    }))}
                    x={(d: any) => d.x}
                    y={(d: any) => d.y}
                    stroke={"#D3DBE3"}
                    strokeWidth={1}
                    strokeDasharray="0"
                    curve={curveNatural}
                    opacity={1}
                  />
                );
              })}
          </Group>
        </svg>
      </div>
    </>
  );
};

export default DataRangeFilterChart;
