import { FormikErrors, FormikTouched } from "formik";
import { ChangeEvent } from "react";

export function getSettings(values: { [key: string]: string[]; }, errors: { [key: string]: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined; }, touched: { [key: string]: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined; }, handleChange: (event: ChangeEvent<HTMLInputElement>) => void, handleBlur: React.ChangeEventHandler<Element>) {
  const notificationSettings = [
    {
      name: 'desktopAlerts',
      checked: Boolean(values['desktopAlerts']?.length),
      errors: errors.desktopNotifications as string,
      touched: touched.desktopNotifications as boolean,
      label: 'Enable desktop alerts for important notifications?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'receiveDashboard',
      checked: Boolean(values['receiveDashboard']?.length),
      errors: errors.receiveDashboard as string,
      touched: touched.receiveDashboard as boolean,
      label: 'Receive dashboard/report published notifications?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'receiveViewerEditor',
      checked: Boolean(values['receiveViewerEditor']?.length),
      errors: errors.receiveViewerEditor as string,
      touched: touched.receiveViewerEditor as boolean,
      label: 'Receive “Added as viewer/editor” notifications?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'playSoundAlert',
      checked: Boolean(values['playSoundAlert']?.length),
      errors: errors.playSoundAlert as string,
      touched: touched.playSoundAlert as boolean,
      label: 'Play a sound when I receive a reminder or alert?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'receiveStatus',
      checked: Boolean(values['receiveStatus']?.length),
      errors: errors.receiveStatus as string,
      touched: touched.receiveStatus as boolean,
      label: 'Receive status update notifications?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
  ];

  const emailNotificationSettings = [
    {
      name: 'receiveViewerEditorMail',
      checked: Boolean(values['receiveViewerEditorMail']?.length),
      errors: errors.receiveViewerEditorMail as string,
      touched: touched.receiveViewerEditorMail as boolean,
      label: 'Receive “Added as viewer/editor” emails?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'receiveDashboardEmail',
      checked: Boolean(values['receiveDashboardEmail']?.length),
      errors: errors.receiveDashboardEmail as string,
      touched: touched.receiveDashboardEmail as boolean,
      label: 'Receive dashboard/report completed emails?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'receiveStatusMail',
      checked: Boolean(values['receiveStatusMail']?.length),
      errors: errors.receiveStatusMail as string,
      touched: touched.receiveStatusMail as boolean,
      label: 'Receive profile status update emails?',
      onChange: handleChange,
      onBlur: handleBlur,
    },
  ];
  return { notificationSettings, emailNotificationSettings };
}
