import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export const SkeletonText = styled.div<{ $width: string; $height?: string; $opacity?: number }>`
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 1000px 100%;
  border-radius: 4px;
  opacity: ${({$opacity}) => $opacity ?? 1 };
  width: ${({ $width }) => $width || "100%"};
  height: ${({ $height }) => $height || "16px"};
  animation: ${shimmer} 2s infinite;
  margin: 0 10px 10px 0;
`;
