import styled, { css } from "styled-components";
import { animateMenuOpen } from "../../styles";

const projectPageStyle = css`
  top: 25px;
  right: -80px;
`;

const datasetMenuItemStyle = css`
  position: absolute;
  top: 39px;
  left: unset;
  right: -1px;
`;

const pageTabStyle = css`
  position: absolute;
  top: 40px;
  left: 0;
`;

export const SubMenuWrapper = styled.ul<{
  $open: boolean;
  $projectPage?: boolean;
  $datasetItemMenu?: boolean;
  $pageTab?: boolean;
}>`
  position: absolute;
  top: 118px;
  left: auto;

  background: ${({ theme }) => theme.neutralBackgroundBase};
  padding: 0 12px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  width: 200px;
  border-radius: 10px;
  z-index: 3;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0 5px 0;
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  animation: 0.3s ${({ $open }) => $open && animateMenuOpen} ease forwards;

  ${({ $projectPage }) => $projectPage && projectPageStyle}
  ${({ $datasetItemMenu }) => $datasetItemMenu && datasetMenuItemStyle}
  ${({ $pageTab }) => $pageTab && pageTabStyle}
  
  
  li {
    list-style: none;
    cursor: pointer;
    padding-top: 13px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.neutralTextWeak};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.1s ease-in-out;
    gap: 8px;

    &:hover {
      color: ${({ theme }) => theme.neutralText};

      svg {
        filter: brightness(0) saturate(100%) invert(5%) sepia(16%)
          saturate(5773%) hue-rotate(203deg) brightness(92%) contrast(89%);
      }
    }

    svg {
      height: 16px;
      width: 16px;
    }

    &:last-child {
      border-top: 1px solid ${({ theme }) => theme.neutralBorder};
      padding-bottom: 13px;
    }

    &:nth-last-child(2) {
      padding-bottom: 13px;
    }
  }
`;
