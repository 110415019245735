import { MEASURES } from "../../../constants/measure";

export type MeasuresType = {
  id: string;
  measureMobile: string;
  measureDesktop: string;
  measureDescriptionDesktop: string;
  program: string;
  bucket: string;
  count: number;
};

export const getPopulations = (): MeasuresType[] => {
  const populationMap = new Map();

  for (const measure of MEASURES) {
    const { bucket } = measure;
    if (populationMap.has(bucket)) {
      populationMap.get(bucket).count += 1;
    } else {
      populationMap.set(bucket, { ...measure, count: 1 });
    }
  }

  return Array.from(populationMap.values());
};

export const getMeasure = (populationSelected: string | null) =>
  MEASURES.filter((m) => m.bucket === populationSelected);
