import React from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ProjectCount,
  ProjectDescription,
  ProjectName,
  ProjectNameHeading,
  ProjectRole,
  ProjectRoleLabel,
  ProjectsCountLabel,
  ProjectsTableBody,
  ProjectsTableBodyRow,
  ProjectsTableContainer,
  ProjectsTableHead,
  ProjectsTableHeaderRow,
  ProjectsTableWrapper,
  UploadedOn,
  UploadedOnIcon,
  UploadedTime,
  UsersIcons,
  UsersWithAccessIcon,
} from "./styles";

import { getProjects } from "../../store/selectors/projects";
import { useSortByCreatedDate } from "../../hooks/useSortByDate";
import { EmptyProjects } from "../EmptyProjects";
import { setActiveModal } from "../../store/slices/modals";
import { ProjectItemI } from "../../models/Projects";

export const ProjectsTable = () => {
  const { items: projects } = useSelector(getProjects);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { handleSortByCreatedDate, sortItemsByCreatedDate } =
    useSortByCreatedDate("desc");
  const sortedItems = sortItemsByCreatedDate(projects ? projects : []);

  const openCreateProjectModal = () =>
  dispatch(setActiveModal({ id: "createProjectModal" }));

  return (
    <ProjectsTableWrapper>
      {!sortedItems.length?
        <EmptyProjects openNewProjectModal={openCreateProjectModal}></EmptyProjects>
        :
        <ProjectsTableContainer>
          <ProjectsTableHead>
            <ProjectsTableHeaderRow>
              <ProjectNameHeading
                style={{ minWidth: "268px", borderTopLeftRadius: "7px" }}
              >
                Name
              </ProjectNameHeading>
              <th>Description</th>
              <th style={{ minWidth: "50px" }}>Pages</th>
              <th style={{ minWidth: "50px" }}>Role</th>
              <th>Users with access</th>
              <th
                style={{
                  borderTopRightRadius: "7px",
                  textAlign: "end",
                  paddingRight: "8px",
                }}
              >
                Uploaded on <UploadedOnIcon onClick={handleSortByCreatedDate} />
              </th>
            </ProjectsTableHeaderRow>
          </ProjectsTableHead>
          {sortedItems.map((project: ProjectItemI) => (
            <ProjectsTableBody
              key={project.id}
              onClick={() => navigate(`/projects/${project.id}`)}
            >
              <ProjectsTableBodyRow>
                <ProjectName>{project.name}</ProjectName>
                <ProjectDescription>{project.description}</ProjectDescription>
                <ProjectCount>
                  <ProjectsCountLabel>4</ProjectsCountLabel>
                </ProjectCount>
                <ProjectRole>
                  <ProjectRoleLabel $role={"owner"}>Owner</ProjectRoleLabel>
                </ProjectRole>
                <UsersIcons>
                  <UsersWithAccessIcon />
                </UsersIcons>
                <UploadedOn>
                  {format(new Date(project.createdAt), "MM.dd.yyyy")}
                  <UploadedTime>
                    {format(new Date(project.createdAt), " hh:mm a")}
                  </UploadedTime>
                </UploadedOn>
              </ProjectsTableBodyRow>
            </ProjectsTableBody>
          ))}
        </ProjectsTableContainer>
      }
    </ProjectsTableWrapper>

  );
};
