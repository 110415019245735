import React from "react";

import { Heading } from "../../styles";
import { HeadingMenuContainer } from "../../components/NavBar/styles";

export const Community = () => {
  return (
    <>
      <HeadingMenuContainer>
        <Heading>Community</Heading>
      </HeadingMenuContainer>
    </>
  );
};
