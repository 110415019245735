import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DatasetDTO,
  DatasetsResponseListDto,
  HeaderUpdatePayload,
  NewDraftProjectDTO,
  SelectedDataset,
  SelectedFileView,
  SourcesResponseListDto,
  UpdateDatasetDTO,
} from "../../models/Datasets";
import { DatasetQuery, DatasetsQuery } from "../../api/datasets";
import { FileLibraryItem } from "../../models/Files";

export interface DataSetDataState {
  dataSets: DatasetsResponseListDto;
  selectedFileView: SelectedFileView;
  newDataset: FileLibraryItem | null;
  selectedLibraryDatasets: SelectedDataset[];
  selectedDatasetPreview: FileLibraryItem;
  datasetContents: any;
  newDraftProject: NewDraftProjectDTO;
}

type setOverviewFileDataPayload = {
  payload: any;
};

const initialState: DataSetDataState = {
  dataSets: { items: [], count: 0, isLoading: false },
  newDataset: null,
  selectedFileView: {} as SelectedFileView,
  selectedDatasetPreview: {} as FileLibraryItem,
  selectedLibraryDatasets: [],
  datasetContents: null,
  newDraftProject: {
    selectedDatasets: [],
    name: "",
    description: "",
  },
};

const datasets = createSlice({
  name: "dataSetData",
  initialState,
  reducers: {
    setOverviewFileData: (state: DataSetDataState, { payload }) => {
      return {
        ...state,
        selectedFileView: {
          ...payload,
        },
      };
    },

    setNewDataset: (
      state: DataSetDataState,
      { payload }: PayloadAction<FileLibraryItem>
    ) => {
      return {
        ...state,
        newDataset: payload,
        dataSets: {
          items: [...state.dataSets.items, payload],
          count: state.dataSets.count + 1,
        },
      };
    },

    updateSelectedFiles: (
      state: DataSetDataState,
      { payload }: PayloadAction<SelectedDataset>
    ) => {
      return {
        ...state,
        selectedLibraryDatasets: payload.fields.length
          ? state.selectedLibraryDatasets
              .map((file) => file.id)
              .includes(payload.id)
            ? state.selectedLibraryDatasets.map((file) =>
                file.id !== payload.id ? file : payload
              )
            : [...state.selectedLibraryDatasets, payload]
          : state.selectedLibraryDatasets.filter(
              (file) => file.id !== payload.id
            ),
      };
    },

    updateDatasetHeader: (
      state: DataSetDataState,
      { payload }: PayloadAction<HeaderUpdatePayload>
    ) => {
      const { label, selectedType, selectedSubType, objectKey } = payload;

      const updatedSuggestions = state.selectedFileView.parsedSuggestions.map(
        (item) => {
          if (item.objectKey === objectKey) {
            return {
              ...item,
              label,
              types: [{ type: selectedType!, subtype: selectedSubType! }],
            };
          }
          return item;
        }
      );

      return {
        ...state,
        selectedFileView: {
          ...state.selectedFileView,
          parsedSuggestions: updatedSuggestions,
        },
      };
    },

    updateDatasetData: (
      state: DataSetDataState,
      {
        payload,
      }: PayloadAction<{
        filePath: string;
        key: string;
        label: string;
        category: string;
      }>
    ) => {
      const updatedItems = state.dataSets.items.map((item) => {
        if (item.filePath === payload.filePath) {
          return {
            ...item,
            fields: item.fields?.map((field: any) => {
              if (field.key === payload.key)
                return {
                  label: payload.label,
                  type: payload.category,
                  subtype: payload.category,
                  key: payload.key,
                  icon: payload.category,
                };
              return field;
            }),
          };
        }
        return item;
      });

      const updatedDatasetPreview = state.selectedDatasetPreview?.fields?.map(
        (field) => {
          if (field.key === payload.key) {
            return {
              label: payload.label,
              type: payload.category,
              subtype: payload.category,
              key: payload.key,
              icon: payload.category,
            };
          }
          return field;
        }
      );

      return {
        ...state,
        dataSets: {
          ...state.dataSets,
          items: updatedItems,
        },
        selectedDatasetPreview: {
          ...state.selectedDatasetPreview,
          fields: updatedDatasetPreview,
        },
      };
    },

    setNewProjectSelectedFiles: (
      state: DataSetDataState,
      action: PayloadAction<FileLibraryItem[]>
    ): DataSetDataState => {
      return {
        ...state,
        newDraftProject: {
          ...state.newDraftProject,
          selectedDatasets: action.payload,
        },
      };
    },

    removeNewProjectSelectedDataset: (
      state: DataSetDataState,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        newDraftProject: {
          ...state.newDraftProject,
          selectedDatasets: state.newDraftProject.selectedDatasets.filter(
            (dataset) => dataset.filePath !== action.payload
          ),
        },
      };
    },

    resetNewProjectSelectedFile: (state: DataSetDataState) => {
      return {
        ...state,
        newDraftProject: {
          selectedDatasets: [],
          name: "",
          description: "",
        },
      };
    },

    setNewProjectDetails: (
      state: DataSetDataState,
      action: PayloadAction<{ name?: string; description?: string }>
    ) => {
      return {
        ...state,
        newDraftProject: {
          ...state.newDraftProject,
          name: action.payload.name ? action.payload.name : "",
          description: action.payload.description
            ? action.payload.description
            : "",
        },
      };
    },

    removeSelectedFile: (
      state: DataSetDataState,
      { payload }: PayloadAction<string>
    ) => {
      return {
        ...state,
        selectedLibraryDatasets: [...state.selectedLibraryDatasets].filter(
          (file) => file.id !== payload
        ),
      };
    },

    removeAllSelectedFiles: (state: DataSetDataState) => {
      return {
        ...state,
        selectedLibraryDatasets: [],
      };
    },

    setAllDatasets: (state: DataSetDataState, { payload }) => {
      return {
        ...state,
        dataSets: {
          items: payload.items ? payload.items : [],
          count: payload.count,
          isLoading: false,
        },
      };
    },

    setDatasetPreview: (
      state: DataSetDataState,
      { payload }: setOverviewFileDataPayload
    ) => {
      return {
        ...state,
        selectedDatasetPreview: payload,
      };
    },

    setDatasetContents: (
      state: DataSetDataState,
      action: PayloadAction<SourcesResponseListDto>
    ) => {
      return {
        ...state,
        datasetContents: action.payload,
      };
    },

    resetDatasetContents: (state: DataSetDataState) => {
      return {
        ...state,
        datasetContents: null,
      };
    },

    handleRemoveDataset: (state: DataSetDataState, action) => {
      return {
        ...state,
        dataSets: {
          count: state.dataSets.count - 1,
          items: [
            ...state.dataSets.items.filter(
              (dataset) => dataset.id !== action.payload
            ),
          ],
        },
      };
    },

    requestUpdateDatasetById: (
      state: DataSetDataState,
      action: PayloadAction<UpdateDatasetDTO>
    ) => {},

    requestCreateDataset: (
      state: DataSetDataState,
      action: PayloadAction<DatasetDTO>
    ) => {},

    requestDatasetRemove: (
      state: DataSetDataState,
      action: PayloadAction<string>
    ) => {},

    requestGetAllDatasets: (
      state: DataSetDataState,
      action: PayloadAction<Partial<DatasetsQuery>>
    ) => {},

    requestGetDatasetContents: (
      state: DataSetDataState,
      action: PayloadAction<DatasetQuery>
    ) => {},
    setIsLoading: (state: DataSetDataState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        dataSets: {
          ...state.dataSets,
          isLoading: action.payload,
        },
      };
    },
  },
});

export const {
  setOverviewFileData,
  requestCreateDataset,
  setNewDataset,
  requestGetAllDatasets,
  updateSelectedFiles,
  setAllDatasets,
  setDatasetPreview,
  requestGetDatasetContents,
  resetDatasetContents,
  setDatasetContents,
  requestDatasetRemove,
  handleRemoveDataset,
  removeSelectedFile,
  setIsLoading,
  removeAllSelectedFiles,
  setNewProjectSelectedFiles,
  setNewProjectDetails,
  resetNewProjectSelectedFile,
  removeNewProjectSelectedDataset,
  updateDatasetHeader,
  updateDatasetData,
  requestUpdateDatasetById,
} = datasets.actions;
export default datasets.reducer;
