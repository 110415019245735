import React, { useEffect, useState } from "react";
import { MEASURES, secondStepTH } from "../../../../../constants/measure";
import { THElement } from "../components/CreateProjectDataTable/components/THElement";
import {
  Container,
  TableBody,
  TableHeader,
} from "../components/CreateProjectDataTable/style";
import { TRElement } from "../components/CreateProjectDataTable/components/TRElement";

type Props = {
  populationSelected?: string;
  search?: string;
  setPopulationsSet: any;
};

const SecondStep: React.FC<Props> = ({
  populationSelected,
  setPopulationsSet,
  search,
}: Props) => {
  const [totalPopulations, setPopulations] = useState<any[]>([]);
  const [currentPopulations, setCurrentPopulations] = useState<any[]>([]);

  useEffect(() => {
    const newValue = search?.trim()?.toLowerCase();
    if (newValue) {
      const populations = totalPopulations?.filter((r) => {
        const bucket: string = r?.bucket?.toLowerCase();
        const program: string = r?.program?.toLowerCase();
        return program?.includes(newValue) || bucket?.includes(newValue);
      });
      setCurrentPopulations(populations);
    } else {
      if (totalPopulations?.length) {
        setCurrentPopulations(totalPopulations);
      } else {
        const populations = MEASURES.reduce((t: any[], p) => {
          if (!t.some((r) => r.bucket === p.bucket)) {
            return [...t, p];
          }
          return t;
        }, []);
        setCurrentPopulations(populations);
        setPopulations(populations);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Container key={"second"}>
      <TableHeader>
        {secondStepTH.map((item) => (
          <THElement
            text={item.text}
            key={item.text}
            size={item?.size}
          ></THElement>
        ))}
      </TableHeader>
      {currentPopulations?.length ? (
        <TableBody>
          {currentPopulations?.map((item: any) => {
            const measures = MEASURES?.filter((m) => m.bucket === item.bucket);
            return (
              <TRElement
                key={item.bucket}
                id={item.bucket}
                header={item.bucket}
                subHeader={item.program}
                measures={measures?.length ?? 0}
                selected={populationSelected === item.bucket}
                selectHandler={() => {
                  setPopulationsSet(item.bucket);
                }}
                fields={[]}
              />
            );
          })}
        </TableBody>
      ) : null}
    </Container>
  );
};

export default SecondStep;
