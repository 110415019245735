import styled from "styled-components";
import { ReactComponent as CardMap } from "../../assets/maplayouts/card-map.svg";
import { ReactComponent as CardMapNoP } from "../../assets/maplayouts/card-no-padding.svg";
import { ReactComponent as FullScreen } from "../../assets/maplayouts/full-screen.svg";
import { LayersHalf } from "react-bootstrap-icons";

import { IMarkers } from "./types/types";

export const MapSettingsWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const MapSettingsLeftSide = styled.div`
  width: 100%;
`;

export const MapSettingsRightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid lightgray;
`;

export const MapSettingsDatasetsWrapper = styled.div`
  border-bottom: 1px solid lightgray;
  padding: 8px 20px 16px 20px;
`;

export const MapSettingsDatasetsHeading = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const SwitchDataset = styled(LayersHalf)`
  fill: #493ddd;
`;

export const MapSettingsDatasets = styled.div``;

export const MapSettingsLayersWrapper = styled.div``;

export const MapSettingsLayerHeader = styled.div`
  margin: 8px 20px 5px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const MapSettingLayerContent = styled.div`
  margin: 0px 20px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MapSettingsLayerDirection = styled.div`
  display: flex;
  gap: 17px;
`;

export const MapSettingsLayoutWrapper = styled.div`
  padding: 16px 20px 0 20px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const MapSettingsLayouts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 8px;
`;

export const MapSettingsLayoutCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
`;

export const MapSettingsLayoutIconWrapper = styled.div<{
  $selectedItem: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ theme, $selectedItem }) =>
      $selectedItem ? theme.primaryBorder : theme.neutralBorder};
  border-radius: 6px;
  padding: 8px;
  align-self: center;
  width: 96px;
  height: 72px;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  }
`;

export const MapSettingsLayoutCardName = styled.div<{ $selectedItem: boolean }>`
  font-size: 12px;
  font-weight: ${({ $selectedItem }) => ($selectedItem ? 500 : 400)};
  color: ${({ theme, $selectedItem }) =>
    $selectedItem ? theme.neutralText : theme.neutralTextWeak};
`;

export const MapSettingsLayoutMapCard = styled(CardMap)``;
export const MapSettingsLayoutMapCardNoP = styled(CardMapNoP)``;
export const MapSettingsLayoutMapFullScreen = styled(FullScreen)``;

export const MapSettingsControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  align-items: center;
`;

export const MapSettingsStyleFormating = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  gap: 12px;
  flex-direction: column;
`;

export const Marker = styled.div<{
  $type: IMarkers;
  $color: string;
}>`
  background: ${({ $color }) => $color};

  ${({ $type, $color }) =>
    !!($type === "donut") &&
    `
    border: 3px solid ${$color};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: transparent;
  `}

  ${({ $type }) =>
    !!($type === "circle") &&
    `
    width: 12px;
    height: 12px;
    border-radius: 50%;
  `}

  ${({ $type }) =>
    !!($type === "square") &&
    `
    width: 12px;
    height: 12px;
  `}

  ${({ $type }) =>
    !!($type === "rhombus") &&
    `
    width: 12px;
    height: 12px;
    transform: rotateZ(45deg);
  `}

  ${({ $type, $color }) =>
    !!($type === "triangle") &&
    `
    background: transparent;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid ${$color};
  `}

  ${({ $type, $color }) =>
    !!($type === "disabled") &&
    `
    background: transparent;
    width: 12px;
    height: 12px;
    position: relative;
    border: 1px solid ${$color};
    border-radius: 50%;
    &::before {
      transform: rotate(45deg);
      content: '';
      position: absolute;
      width: 1px;
      height: 10px;
      background: ${$color};
      left: 50%;
  `}

  ${({ $type, $color }) =>
    !!($type === "cross") &&
    `
    width: 12px;
    height: 3px;
    position: relative;
    background: ${$color};
    transform: rotate(45deg);
    &::before {
      content: '';
      transform: rotate(-90deg);
      position: absolute;
      width: 12px;
      height: 3px;
      background: ${$color};
  `}
`;

export const MapSettingsRangeControlWrapper = styled.div`
  padding: 30px 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MapSettingsRangeControlHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
`

export const MapSettingsRangeControlName = styled.p`
  color: ${({theme}) => theme.neutralText};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`

export const MapSettingsStyleFormatingParamSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MapSettingsStyleFormatingParamSelectText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  padding-left: 15px;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid ${({ theme }) => theme.neutralBorderMedium};
    left: 0;
    top: 55%;
    transform: translateY(-55%);
  }
`;

export const MapSettingsStyleFormatingColors = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;

export const ColorsVariations = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 3px;
  left: -4px;
  border-radius: 25px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  position: absolute;
  top: 20px;
  z-index: 1;

  div {
    margin-bottom: -7px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ColorsWrapper = styled.div`
  position: relative;
`;

export const Colors = styled.div<{
  $color: string;
  $active?: boolean;
  $left?: string;
  $sequential?: boolean;
}>`
  background: ${({ $color }) => $color};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  /* border: 2px solid transparent; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;

  ${({$sequential}) => !$sequential && `
    &:hover {
      border-color: black;
      cursor: pointer;
      z-index: 1;
    }
  `}

  

  ${({ $active }) =>
    $active
      ? `
      z-index: 5;
       
      &:before{
        position: absolute;
        z-index: 5;
        content: '';
        width: 16px;
        height: 16px;
        border: 2px solid white; 
        background: transparent;
        border-radius: 50%;
      }
  `
      : ``}

  ${({ $left }) =>
    $left
      ? `
    margin-left: ${$left};
  `
      : ``}

  ${({$sequential}) => $sequential && `
    margin-left: -5px;
    &:first-child {
      margin-left: 0;
    }
  `}
`;

export const ColorList = styled.div<{
  $gap: boolean;
}>`
  display: flex;
  gap: ${({ $gap }) => ($gap ? "3px" : "0px")};
  align-items: center;
`;

export const MarkerSelect = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.neutralBackground};
  ${({ $selected, theme }) =>
    $selected ? `background: ${theme.primaryBackgroundActive};` : ``}

  border-radius: 3px;
  cursor: pointer;
`;

export const MapSettingsStyleFormatingParamSelectContent = styled.div`
  display: flex;
  gap: 4px;
`;

export const MapSettingsStyleFormatingElementsList = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

export const MapSettingsStyleFormatingElement = styled.div<{
  $selected: boolean;
}>`
  padding: 3px 6px;
  background: ${({ theme }) => theme.neutralBackground};
  border-radius: 2px;
  align-items: center;
  font-size: 12px;
  display: flex;
  gap: 6px;
  cursor: pointer;
  ${({ $selected, theme }) =>
    $selected ? `background: ${theme.primaryBackgroundActive};` : ``}
  user-select: none;
`;

export const MapSettingsStyleFormatingName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const MapSettingsControlName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;
export const MapSettingsControl = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`;
