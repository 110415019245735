import React from "react";
import { Legend, RangeLine } from "./style";

type Props = {
  min: number;
  max: number;
  lines: number[];
};

const MultiRangeLines = ({ min, max, lines }: Props) => {
  return (
    <Legend>
      {lines.map((item) => (
        <RangeLine min={min} max={max} step={1} value={item} />
      ))}
    </Legend>
  );
};

export default MultiRangeLines;
