import React, { useEffect, useState } from "react";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
} from "./styles";
import { Envelope } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { getUser } from "../../store/selectors/main";
import { AccountDto } from "../../models/Authentication";
import { jwtDecode } from "jwt-decode";

export const ContactsSection = () => {
  const user = useSelector(getUser);
  const [account, setAccount] = useState<AccountDto | undefined>();

  useEffect(() => {
    if (user?.accessToken) {
      const account: AccountDto = jwtDecode(user?.accessToken);
      setAccount(account);
    }
  }, [user]);

  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>Contacts</ProfileSideBarHeading>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Envelope />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>Email</ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription>
            {account?.email}
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
