import React, { useEffect, useRef, useState } from "react";
import { InputComponent } from "./components/ReplyInput/ReplyInput";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedWidgetId } from "../../../../store/selectors/comments";
import { Message } from "./components/Message/Message";
import { CommentHead } from "./components/CommendHead/CommendHead";
import * as s from "./styles";
import {
  requestAddComment,
  requestEditComment,
  requestResolveComments,
} from "../../../../store/slices/commnets";
import { getPageId } from "../../../../store/selectors/projects";
import { calculateTimeElapsed } from "../../../../helpers/calculateTimeElapsed";
import { GroupedComment, Comment } from "../../../../types/comments";
import { useClickOutside } from "../../../../hooks/useClickOutside";

interface Props {
  onClose: () => void;
  commentsPerWidget: GroupedComment | undefined;
  isStorytelling?: boolean;
}

export const DiscussionThread: React.FC<Props> = ({
  commentsPerWidget,
  onClose,
  isStorytelling,
}) => {
  const [isReply, setReply] = useState(false);
  const [editCommnet, setEditComment] = useState({
    content: "",
    id: ""
  });
  const [shouldScroll, setShouldScroll] = useState(true);
  const commentsRef = useRef<HTMLDivElement>(null);
  const prevCommentsLength = useRef(0);
  const [itemRef] = useClickOutside(onClose, false);

  const selectedWidgetId = useSelector(getSelectedWidgetId);
  const pageId = useSelector(getPageId);
  const dispatch = useDispatch();

  const { widgetId, comments } = commentsPerWidget || {};

  const sortedComments =
    comments &&
    comments.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

  useEffect(() => {
    if (!commentsRef.current || !comments?.length) {
      setReply(true);
      return;
    }

    const hasNewComments = comments?.length > prevCommentsLength.current;
    prevCommentsLength.current = comments.length;

    if (hasNewComments && shouldScroll) {
      setTimeout(() => {
        if (commentsRef.current) {
          commentsRef.current.scrollTo({
            top: commentsRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 100);
    }
  }, [comments, shouldScroll]);

  // Handle scroll events to determine if auto-scroll should continue
  const handleScroll = () => {
    if (!commentsRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = commentsRef.current;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;

    setShouldScroll(isNearBottom);
  };

  const handleSubmit = (text: string) => {
    if (!pageId || (!widgetId && !selectedWidgetId)) return;

    setShouldScroll(true);
    if (editCommnet.id) {
      dispatch(
        requestEditComment({
          id: editCommnet.id,
          content: text,
        })
      );
    } else {
      dispatch(
        requestAddComment({
          pageId,
          content: text,
          widgetId: selectedWidgetId ?? widgetId,
          ...(sortedComments?.[0]?.id && { parentId: sortedComments[0].id }),
        })
      );
    }
    setReply(true);
    setEditComment({
      content: "",
      id: ""
    });
  };

  const handleCancel = () => {
    if (sortedComments?.length) {
      setReply(false);
      setEditComment({
        content: "",
        id: ""
      });
    } else {
      onClose();
    }
  };

  const handleResolveAll = () => {
    if (!sortedComments?.[0]?.id) return;
    setTimeout(() => {
      dispatch(
        requestResolveComments({ ids: sortedComments.map(({ id }) => id), isResolved: true })
      );
    }, 1000)
  };

  const handleEdit = (comment: Comment) => () => {
    setEditComment({
      id: comment.id,
      content: comment.content
    });
  }

  return (
    <s.CommentWrapper $isStorytelling={!!isStorytelling} ref={itemRef}>
      <CommentHead
        onResolveAll={handleResolveAll}
        hasComments={!!sortedComments?.length}
        onClose={(onClose)}
      />
      <s.Comments ref={commentsRef} onScroll={handleScroll}>
        <s.MessagesContainer>
          {sortedComments?.map((comment) => (
            <Message
              key={comment.id}
              author={comment?.author?.name}
              avatarUrl={comment.image}
              time={calculateTimeElapsed(comment.createdAt)}
              message={comment.content}
              isYou
              onEdit={handleEdit(comment)}
            />
          ))}
        </s.MessagesContainer>
      </s.Comments>
      <s.ReplyBox>
        {editCommnet.id || isReply ? (
          <InputComponent initValue={editCommnet.content} onSubmit={handleSubmit} onCancel={handleCancel} />
        ) : (
          <s.ReplyButton onClick={() => setReply(true)}>Reply</s.ReplyButton>
        )}
      </s.ReplyBox>
    </s.CommentWrapper>
  );
};
