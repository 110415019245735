import React, { useState } from "react";
import { Legend, RangeInput } from "./style";

type Props = {
  leftSlider: number;
  rightSlider: number;
  setLeftSlider: any;
  setRightSlider: any;
};

const RangeInputCustom = ({
  leftSlider,
  rightSlider,
  setLeftSlider = () => {},
  setRightSlider = () => {},
}: Props) => {
  const [activeSlider, setactiveSlider] = useState<"l" | "r">("l");
  return (
    <Legend>
      <RangeInput
        type="range"
        style={{ zIndex: activeSlider === "l" ? 5000 : 4000 }}
        onMouseOver={() => setactiveSlider("l")}
        min={0}
        max={6}
        step={1}
        value={leftSlider}
        onChange={(e: any) => {
          if (Number(e.target.value) >= rightSlider) {
          } else {
            setLeftSlider(Number(e.target.value));
          }
        }}
      />

      <RangeInput
        type="range"
        style={{ zIndex: activeSlider === "r" ? 5000 : 4000 }}
        onMouseOver={() => setactiveSlider("r")}
        min={0}
        max={6}
        step={1}
        value={rightSlider}
        onChange={(e: any) => {
          if (leftSlider >= Number(e.target.value)) {
          } else {
            setRightSlider(Number(e.target.value));
          }
        }}
      />
    </Legend>
  );
};

export default RangeInputCustom;
