import { useDispatch, useSelector } from "react-redux";
import { StorytellingOptionsMenu } from "../../../../../StorytellingOptionsMenu";
import {
  TextAndImageHeadingContent,
  TextAndImageHeading,
  TextAndImageHighlights,
  TextAndImageDescription,
  SectionWrapperBlock,
} from "../../../ReportContent/styles";
import { SectionWrapper } from "../../../ReportContent/styles";
import { getIsEditMode } from "../../../../../../store/selectors/main";
import { useEffect, useState, useRef } from "react";
import { useDebounceCallback } from "usehooks-ts";
import {
  BlockLoader,
  BorderedSettingsBlock,
  IsDragging,
  IsHovered,
  SettingsWrapper,
} from "../../styles";
import { CenterIcon, LeftIcon, RightIcon } from "../../icons/TextPositionIcons";
import {
  requestDownloadFile,
  requestUploadSimpleFile,
} from "../../../../../../store/slices/files";
import { TextLoader } from "../../../../../LoaderText";

interface Props {
  contentItem: any;
  setContentItem: (item: any) => void;
  draggable?: boolean;
}

const SectionTextImage: React.FC<Props> = ({
  setContentItem,
  contentItem,
  draggable,
}) => {
  const isEditMode = useSelector(getIsEditMode);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const debounced = useDebounceCallback(setContentItem, 500);
  const debouncedFile = useDebounceCallback(setFile, 1000);

  const prevContentItemRef = useRef(contentItem);

  useEffect(() => {
    const prevContentItem = prevContentItemRef.current;
    const contentItemJson = JSON.stringify(contentItem);

    if (JSON.stringify(prevContentItem) !== contentItemJson) {
      setContentItem({
        ...contentItem,
        image: contentItem?.backgroundColor ? undefined : contentItem?.image,
      });

      if (contentItem?.backgroundColor) {
        setFile(undefined);
      }

      prevContentItemRef.current = contentItem;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItem]);

  useEffect(() => {
    if (file && typeof file !== "string") {
      try {
        dispatch(
          requestUploadSimpleFile({
            files: [file],
            callbacks: {
              onSuccess: (resp) => {
                const uploadedFile = resp?.at(0);
                if (uploadedFile) {
                  setContentItem({
                    ...contentItem,
                    image: uploadedFile.path,
                    backgroundColor: undefined,
                  });
                }
              },
            },
          })
        );
      } catch (e) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (contentItem.image) {
      dispatch(
        requestDownloadFile({
          path: contentItem.image,
          callbacks: {
            onSuccess: (resp) => {
              setFile(resp.url);
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItem.image]);

  return (
    <SectionWrapperBlock
    $draggable={draggable}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      {open ? <IsHovered /> : null}
      {draggable ? <IsDragging /> : null}
      <SectionWrapper
        $backgroundColor={contentItem?.backgroundColor}
        $imageUrl={typeof file === "string" ? file : undefined}
        $gradient={contentItem?.gradient}
        $rgbaColor={contentItem?.rgbaColor}
        spellCheck={false}
      >
        {isEditMode && (
          <>
            <StorytellingOptionsMenu
              item={contentItem}
              setContentItem={debounced}
              setFile={debouncedFile}
              fileUrl={file}
            />
            <SettingsWrapper $hover={open}>
              <BorderedSettingsBlock>
                <LeftIcon
                  type={contentItem?.align}
                  onClick={() => {
                    debounced({ ...contentItem, align: "left" });
                  }}
                />
                <CenterIcon
                  type={contentItem?.align}
                  onClick={() => {
                    debounced({ ...contentItem, align: "center" });
                  }}
                />
                <RightIcon
                  type={contentItem?.align}
                  onClick={() => {
                    debounced({ ...contentItem, align: "right" });
                  }}
                />
              </BorderedSettingsBlock>
            </SettingsWrapper>
          </>
        )}

        <TextAndImageHeadingContent $alignment={contentItem?.align}>
          {contentItem?.title ? (
            <TextAndImageHeading
              defaultValue={contentItem?.title}
              $color={contentItem?.textColor}
              spellCheck={false}
              contentEditable={isEditMode}
              $alignment={contentItem?.align}
              $isEditMode={isEditMode}
              onChange={(e) => {
                debounced({ ...contentItem, title: e.target.value });
              }}
              maxLength={59}
            />
          ) : (
            <div style={{ marginTop: "80px", width: "400px" }}>
              <BlockLoader>
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
              </BlockLoader>
            </div>
          )}
          {contentItem?.description ? (
            <TextAndImageHighlights
              defaultValue={contentItem?.description}
              $color={contentItem?.textColor}
              spellCheck={false}
              contentEditable={isEditMode}
              $alignment={contentItem?.align}
              $isEditMode={isEditMode}
              onChange={(e) =>
                debounced({ ...contentItem, description: e.target.value })
              }
              maxLength={240}
            />
          ) : (
            <div style={{ marginTop: "24px" }}>
              <BlockLoader>
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
              </BlockLoader>
            </div>
          )}
          <br />
          {contentItem?.title && contentItem?.description ? (
            <TextAndImageDescription
              defaultValue={contentItem?.insights}
              $color={contentItem?.textColor}
              spellCheck={false}
              contentEditable={isEditMode}
              $alignment={contentItem?.align}
              $isEditMode={isEditMode}
              onChange={(e) =>
                debounced({ ...contentItem, insights: e.target.value })
              }
              maxLength={240}
            />
          ) : (
            <div style={{ marginTop: "24px" }}>
              <BlockLoader>
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
              </BlockLoader>
            </div>
          )}
        </TextAndImageHeadingContent>
      </SectionWrapper>
    </SectionWrapperBlock>
  );
};

export default SectionTextImage;
