import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import {
  CheckBoxWrapper,
  DatasetDimensionsCount,
  Dimensions,
  FileName,
  More,
  RowWrapper,
  UploadedOn,
  UploadedOnTime,
} from "./styles";
import { setActiveModal } from "../../store/slices/modals";
import {
  requestDatasetRemove,
  setDatasetPreview,
} from "../../store/slices/datasets";
import { NewDatasetDTO } from "../../models/Datasets";
import { Submenu } from "../Submenu";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";
import Badge from "../Badge";
import { getIconSrc } from "../../helpers/getIconType";

interface NewDataSetDtoCustom extends NewDatasetDTO {
  selectAll?: boolean;
}

export const DatasetRow = (dataset: NewDataSetDtoCustom) => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const { name, createdAt, fields, id, selectAll } = dataset;
  const [showAllDimensions, setShowAllDimensions] = useState<boolean>(false);
  const openModal = (dataset: NewDatasetDTO) => {
    dispatch(setDatasetPreview(dataset));
    dispatch(setActiveModal({ id: "datasetOverviewModal" }));
  };

  const dimensionsToShow = () => {
    if (showAllDimensions) return fields;
    if (!showAllDimensions) return fields.slice(0, 6);
  };

  const [checked, setChecked] = useState<boolean | undefined>(false);

  useEffect(() => {
    setChecked(selectAll);
  }, [selectAll]);

  return (
    <RowWrapper onMouseLeave={() => setShowAllDimensions(false)}>
      <CheckBoxWrapper>
        <CheckBox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </CheckBoxWrapper>
      <FileName onClick={() => openModal(dataset)}>{name}</FileName>
      <Dimensions>
        {dimensionsToShow()!.map((dimension, idx) => {
          return (
            <Badge
              name={dimension.label}
              onClick={() => {}}
              icon={getIconSrc({
                type: dimension.type,
                subtype: dimension.subtype,
              })}
              key={idx}
              selectedItems={[]}
            />
          );
        })}
        {Math.sign(fields.length - 6) &&
        fields.length - 6 > 0 &&
        !showAllDimensions ? (
          <DatasetDimensionsCount onClick={() => setShowAllDimensions(true)}>
            +{fields.length - 6}
          </DatasetDimensionsCount>
        ) : (
          ""
        )}
      </Dimensions>
      <UploadedOn>
        {format(new Date(createdAt!), "MM.dd.yyyy")}
        <UploadedOnTime>
          {format(new Date(createdAt!), "HH:mm a")}
        </UploadedOnTime>
      </UploadedOn>
      <More onClick={() => setMenuOpen((prevState) => !prevState)} />
      {menuOpen && (
        <Submenu
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          datasetItemMenu
          onRemove={() => dispatch(requestDatasetRemove(id))}
        />
      )}
    </RowWrapper>
  );
};
