import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Search } from "../../SearchBar";

import {
  ErrorIcon,
  Header,
  HeadingIcon,
  Icon,
  SettingsIcon,
  Table,
  TableColumn,
  TableHeading,
  TableHeadingWrapper,
  TableRow,
} from "../DatasetsOverview/styles";
import {
  Close,
  ContentWrapper,
  FileOverview,
  HeaderItemsWrapper,
  HeaderWrapper,
  Heading,
} from "./styles";
import {
  removeSelectedFile,
  setOverviewFileData,
} from "../../../store/slices/datasets";
import { Trash } from "react-bootstrap-icons";
import { Button } from "../../Button";
import { removeFileData } from "../../../store/slices/files";
import { handleColumnClick } from "../../../helpers/handleColumnClick";
import { DimensionSettings } from "../../DimensionSettings";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { getSelectedFileView } from "../../../store/selectors/datasets";
import { getIconSrc } from "../../../helpers/getIconType";
import { DimensionSettingsInterface } from "../../../models/Files";

type DatasetFileOverviewInterface = {
  setFileOverviewOpen: Dispatch<SetStateAction<boolean>>;
  dimensionsSelected: any;
};

export const UploadedFilePreview = ({
  setFileOverviewOpen,
  dimensionsSelected,
}: DatasetFileOverviewInterface) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [showDimensionSettings, setShowDimensionSettings] =
    useState<DimensionSettingsInterface | null>(null);

  const ref = useRef(null);
  const dispatch = useDispatch();
  const fileSelected = useSelector(getSelectedFileView);
  const suggestedData = fileSelected.parsedSuggestions;
  const previewItems = fileSelected?.previewItems;
  const filteredItems = suggestedData?.filter((item) =>
    item.label.toLowerCase().includes(searchInput.toLowerCase())
  );

  useOutsideClick(ref, () => {
    setShowDimensionSettings(null);
  });
  return (
    <ContentWrapper ref={ref}>
      <HeaderWrapper>
        <Heading>{`${fileSelected.originalName
          ?.charAt(0)
          .toUpperCase()}${fileSelected.originalName?.slice(1, -4)}`}</Heading>
        <HeaderItemsWrapper>
          <Search
            width="small"
            onChange={(value: string) => setSearchInput(value)}
          />
          <Button
            onClick={() => {
              dispatch(removeFileData(fileSelected.path));
              dispatch(removeSelectedFile(fileSelected.path));
              dispatch(setOverviewFileData({}));
              setFileOverviewOpen(false);
            }}
            variant="danger"
            size="medium"
            name="Remove"
            icon={<Trash fill="#992A4B"/>}
          />
        </HeaderItemsWrapper>
        <Close
          onClick={() => {
            setFileOverviewOpen(false);
            dispatch(setOverviewFileData({}));
          }}
        />
      </HeaderWrapper>
      <FileOverview>
        <Table>
          {filteredItems?.map((suggestion) => {
            const iconSrc = getIconSrc(suggestion.types[0]);
            const errorDetected = suggestion.types.length > 1;
            return (
              <TableColumn
                key={suggestion.objectKey}
                $errorDetected={errorDetected}
                $selected={dimensionsSelected.includes(suggestion.label)}
              >
                <TableHeadingWrapper>
                  <TableHeading>
                    <HeadingIcon>
                      <Icon src={iconSrc} />
                    </HeadingIcon>
                    <Header>{suggestion.label}</Header>
                  </TableHeading>
                  {errorDetected && <ErrorIcon />}
                  <SettingsIcon
                    onClick={(e) => {
                      setShowDimensionSettings(
                        handleColumnClick(
                          suggestion.label,
                          e,
                          suggestion.objectKey,
                          errorDetected,
                          suggestion.types[0].type
                        )
                      );
                    }}
                  />
                </TableHeadingWrapper>
                {previewItems.map((item: any, idx: number) => {
                  return (
                    <TableRow key={idx}>
                      {item[suggestion.objectKey]
                        ? item[suggestion.objectKey]
                        : "N/A"}
                    </TableRow>
                  );
                })}
              </TableColumn>
            );
          })}
        </Table>
      </FileOverview>
      {showDimensionSettings && (
        <div ref={ref}>
          <DimensionSettings
            filePath={fileSelected.path}
            setShowDimensionSettings={setShowDimensionSettings}
            showDimensionSettings={showDimensionSettings}
          />
        </div>
      )}
    </ContentWrapper>
  );
};
