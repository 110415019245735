import styled from "styled-components";

export const MeasureLoaderWrapper = styled.div`
  position: fixed;
  z-index: 1010;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
