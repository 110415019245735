import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { requestGetAllDatasets } from "../../store/slices/datasets";

import { MapSettingsWrapper } from "./styles";
import { getCurrentProjectData } from "../../store/selectors/projects";
import { IFormatting, IMarkersType, WidgetItem } from "../../models/Widgets";
import {
  getQualitativePalette,
  SequentialColors,
} from "../../constants/colors";
import WidgetsConfig from "./components/WidgetsConfig/WidgetsConfig";
import MapConfig from "./components/MapConfig/MapConfig";

type Props = {
  widgetData: WidgetItem;
  setWidgetData: React.Dispatch<React.SetStateAction<WidgetItem>>;
  fullScreenLayout: boolean;
};

export const MapSettings: React.FC<Props> = ({
  widgetData,
  setWidgetData,
  fullScreenLayout,
}: Props) => {
  const dispatch = useDispatch();

  const projectData = useSelector(getCurrentProjectData);

  const [selectedKey, setSelectedKey] = useState<string | undefined>(() => {
    if (widgetData?.groupBy) {
      return undefined;
    }
    return "default";
  });

  const [paletteIndex, setPaletteIndex] = useState<number>(0);
  const [colorRanges, setColorRanges] = useState<number[]>([]);

  const [selectedFormatting, setSelectedFormatting] = useState<
    IFormatting | undefined
  >(undefined);

  const [selectedMarker, setSelectedMarker] = useState<
    IMarkersType | undefined
  >(undefined);

  const selectedDimensions = [
    ...(widgetData.arrangeBy ?? []),
    ...(widgetData.groupBy ?? []),
    ...(widgetData.display ?? []),
    ...(widgetData.xAxe ?? []),
    ...(widgetData.yAxe ?? []),
  ];

  const selectedColors =
    widgetData?.formatting?.map((item) => item.color) ?? [];

  const currentChartPalette = () => {
    if (["matrix", "sankey", "mapChart"].includes(widgetData.chartType)) {
      return SequentialColors;
    }

    return getQualitativePalette(widgetData?.formatting?.length);
  };

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  useEffect(() => {
    const palette = currentChartPalette();

    if (Array.isArray(palette)) {
      // eslint-disable-next-line array-callback-return
      palette.find((item, index) => {
        if (item.id === widgetData.palette?.paletteId) {
          setPaletteIndex(index);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWidgetData({
      ...widgetData,
      palette: {
        ...widgetData.palette,
        range: colorRanges,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorRanges]);

  return (
    <MapSettingsWrapper>
      {widgetData.chartType === "mapChart" ? (
        <MapConfig
          widgetData={widgetData}
          setWidgetData={setWidgetData}
          projectData={projectData}
          selectedDimensions={selectedDimensions}
          fullScreenLayout={fullScreenLayout}
        />
      ) : (
        <WidgetsConfig
          widgetData={widgetData}
          setWidgetData={setWidgetData}
          projectData={projectData}
          selectedDimensions={selectedDimensions}
          setSelectedMarker={setSelectedMarker}
          setSelectedKey={setSelectedKey}
          selectedColors={selectedColors}
          setSelectedFormatting={setSelectedFormatting}
          colorRanges={colorRanges}
          setColorRanges={setColorRanges}
          paletteIndex={paletteIndex}
          setPaletteIndex={setPaletteIndex}
          currentChartPalette={currentChartPalette}
          selectedMarker={selectedMarker}
          selectedKey={selectedKey}
          selectedFormatting={selectedFormatting}
        />
      )}
    </MapSettingsWrapper>
  );
};
