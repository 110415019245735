import React, { useState, useEffect, useCallback, useId } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { SelectorIcon } from '../../icons/SelectorIcon';
import { DropdownContainer, DropdownButton } from './styles';
import { DropdownMenu } from './DropdownMenu';
import { ApparitionConfig, UpdateSingleWidgetDTO } from '../../../../../../models/Widgets';
import { WidgetContentItem } from '../../../../../../store/slices/pageContentGenerator';

const defaultApparitionConfig = {
  textAlignment: "center",
  background: "",
  border: "",
  descriptionDisabled: false,
  insightsDisabled: false,
};

interface Props {
  open: boolean;
  item: WidgetContentItem;
  apparitionConfig?: ApparitionConfig;
  setContentItem: (data: UpdateSingleWidgetDTO) => void;
}

const StyledDropdown: React.FC<Props> = ({ open, item, apparitionConfig, setContentItem }) => {
  const id = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const { width, height } = useWindowSize();

  const calculatePosition = useCallback((rect: DOMRect) => {
    setDropdownPosition({
      top: rect.bottom + window.scrollY + 2,
      left: rect.left + window.scrollX,
    });
  }, []);

  useEffect(() => {
    if (!open && isOpen) {
      setIsOpen(false)
    }
  }, [open, isOpen])

  useEffect(() => {
    if (isOpen) {
      const buttonRect = document.getElementById(id)!.getBoundingClientRect();
      calculatePosition(buttonRect);
    }
  }, [isOpen, width, height, id, calculatePosition]);

  const toggleDropdown = (event: React.MouseEvent) => {
    const rect = event.currentTarget.getBoundingClientRect();
    calculatePosition(rect);
    setIsOpen((prev) => !prev);
  };

  return (
    <DropdownContainer>
      <DropdownButton id={id} $isOpen={isOpen} onClick={toggleDropdown}>
        Aa
        <SelectorIcon rotate={isOpen} />
      </DropdownButton>
      <DropdownMenu
        item={item}
        apparitionConfig={apparitionConfig || defaultApparitionConfig}
        setContentItem={setContentItem}
        open={open}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        top={dropdownPosition.top}
        left={dropdownPosition.left}
      />
    </DropdownContainer>
  );
};

export default StyledDropdown;
