import styled from "styled-components";

export const NoProjectWrapper = styled.div`
  display: flex;
  margin: 10% auto;
  flex-direction: column;
  align-items: center;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 450px;
  margin: 24px 0;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 16px;
  font-weight: 600;
`;
export const Description = styled.span`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;
