import styled from "styled-components";
import { Eye } from "react-bootstrap-icons";

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
`;

export const EyeIcon = styled(Eye)``;

export const SliderIcon = styled.div<{ $active: boolean }>`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
  background-color: ${({ theme, $active }) =>
    $active ? theme.primaryBorder : "transparent"};
  color: ${({ theme, $active }) =>
    $active ? theme.primaryTextStrong : theme.neutralTextDisabled};
  z-index: 10;
  transform: background-color 0.4s ease;

  &:hover {
    background-color: ${({ theme, $active }) =>
      $active ? theme.primaryBackgroundStrongHover : "transparent"};
  }
  &:active {
    background-color: ${({ theme, $active }) =>
      $active ? theme.primaryBackgroundStrongActive : "transparent"};
  }
`;

export const SwitchInput = styled.input<{
  $error?: boolean;
}>`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Switch = styled.label<{ $active: boolean }>`
  position: relative;
  display: flex;
  width: 76px;
  gap: 4px;
  height: 40px;
  padding: 4px 0;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.neutralBackground};
  transform: background-color 0.4s ease;
  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }
  &:active {
    background-color: ${({ theme }) => theme.neutralBackgroundActive};
  }
`;
