import React, { ChangeEvent } from "react";
import {
  EssentialsBlock,
  EssentialsWrapper,
  FullNameBlockWrapper,
  SwitchWrapper,
  SwitchText,
  GapFlexRowBlock,
  GapColumnBlock,
  SubTitleText,
  FlexBlock
} from "../styles";
import { FormikErrors, FormikTouched } from "formik";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";
import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import { getSettings } from "./getSettings";

type NotificationsProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  values: { [key: string]: string[] };
  errors: {
    [key: string]:
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined;
  };
  touched: {
    [key: string]:
    | boolean
    | FormikTouched<any>
    | FormikTouched<any>[]
    | undefined;
  };
};

export const Notifications: React.FC<NotificationsProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  const { notificationSettings, emailNotificationSettings } = getSettings(values, errors, touched, handleChange, handleBlur);

  return (
    <EssentialsBlock>
      <EssentialsWrapper>
        <FullNameBlockWrapper>
          <GapFlexRowBlock>
            <GapColumnBlock>
              <SwitchWrapper>
                <SwitchText>Desktop Notifications</SwitchText>
                <SwitchComponent
                  name={'desktopNotifications'}
                  checked={!!values.desktopNotifications?.length}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.desktopNotifications as string}
                  touched={!!touched.desktopNotifications}
                  label={!!values.desktopNotifications?.length ? 'Enabled' : 'Disabled'}
                  disableMargin
                />
              </SwitchWrapper>
              {
                !!values.desktopNotifications?.length &&
                <>
                  {notificationSettings.map((setting) => (
                    <FlexBlock key={setting.name}>
                      <CheckBox
                        onChange={setting.onChange}
                        onBlur={setting.onBlur}
                        errors={setting.errors}
                        touched={setting.touched}
                        name={setting.name}
                        checked={setting.checked}
                      />
                      <SubTitleText>
                        {setting.label}
                      </SubTitleText>
                    </FlexBlock>
                  ))}
                </>
              }
            </GapColumnBlock>
            <GapColumnBlock>
              <SwitchWrapper>
                <SwitchText>Emails</SwitchText>
                <SwitchComponent
                  name={'haveEmails'}
                  checked={!!values?.haveEmails?.length}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.haveEmails as string}
                  touched={!!touched.haveEmails}
                  label={!!values.haveEmails?.length ? 'Enabled' : 'Disabled'}
                  disableMargin
                />
              </SwitchWrapper>
              {
                !!values['haveEmails']?.length &&
                <>
                  {emailNotificationSettings.map((setting) => (
                    <FlexBlock key={setting.name}>
                      <CheckBox
                        onChange={setting.onChange}
                        onBlur={setting.onBlur}
                        errors={setting.errors}
                        touched={setting.touched}
                        name={setting.name}
                        checked={setting.checked}
                      />
                      <SubTitleText>
                        {setting.label}
                      </SubTitleText>
                    </FlexBlock>
                  ))}
                </>
              }
            </GapColumnBlock>
          </GapFlexRowBlock>
        </FullNameBlockWrapper>
      </EssentialsWrapper>
    </EssentialsBlock>
  );
};


