import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableHeader = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.neutralBorder};
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  background-color: ${({ theme }) => theme.neutralBackgroundMedium};
  padding: 0 12px;
`;

export const TableBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 20px;
  max-height: 368px;
  overflow-y: auto;
  scrollbar-width: thin;
`;
