import styled from "styled-components";

export const Container = styled.div`
  display: block;
  position: relative;
  padding-left: 17px;
  width: 17px;
  height: 17px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  height: 17px;
  width: 17px;
`;

export const Checkmark = styled.span`
  position: absolute;
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 4px;
  background-color: #f3f3f4;
  border: 1px solid #d3dbe3;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

export const Checkbox = styled.div<{
  $error?: boolean;
  $indeterminate?: boolean;
}>`
  /* width: 17px; */
  height: 17px;
  display: flex;
  align-items: center;
  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.neutralTextWeak};
    margin-left: 8px;
  }

  ${({ $error }) =>
    $error
      ? `
      ${Input} ~ ${Checkmark} {
        background: #C536601F;
        border: none;
      }
    `
      : ""}

  ${Input}:disabled ~ ${Checkmark} {
    border: none;
  }

  ${Input}:checked ~ ${Checkmark} {
    background-color: #00143f;
    border: none;
    &::after {
      display: block;
    }
  }

  ${Input}:checked:hover ~ ${Checkmark} {
    background-color: #000631;
    border: none;
    &::after {
      border-color: #81A8BB;
    }
  }

  ${Input}:checked:active ~ ${Checkmark} {
    &::after {
      border-color: #578BA4;
    }
  }

  ${Input}:not(:checked):not(:disabled):active ~ ${Checkmark}:after {
    border-color: #6562ff;
  }

  ${Checkmark}:after {
    ${({ $indeterminate }) =>
      $indeterminate
        ? `
      content: "";
      right: 26%;
      top: 45%;
      width: 7px;
      border-radius: 25px;
      border: solid white;
      border-width: 0 1px 2px 0;
    `
        : `
      content: "";
      right: 6px;
      top: 3px;
      width: 4px;
      height: 7px;
      border: solid white;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(38deg);
      -ms-transform: rotate(38deg);
      transform: rotate(38deg);
    `}
  }
`;
