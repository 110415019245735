import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";

import { GET_COMPARISON } from "./constants";
import { generateQueryString } from "../helpers/queryGenerator";

export interface QueryGetComparison {
  pageId: string;
  query?: string;
  measure?: string;
}

export const getComparison = async (query: QueryGetComparison) => {
  const queryParams = generateQueryString<QueryGetComparison>({
    ...query,
  });

  return axios
    .get(`${GET_COMPARISON}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};