import styled from "styled-components";

export const ProjectCardUsersWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 7px;
  height: 40px;
  width: 160px;
  display: flex;
  z-index: 1;
`;

export const UserAvatar = styled.img`
  width: 32px;
  height: 32px;
  margin-left: -16px;
  border-radius: 5px;
  border: 2px solid white;
  position: relative;
  transition: 0.3s;
  &:first-child {
    margin-left: 0;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.primaryBorder};
  }
`

export const UserIconWrapper = styled.div<{ $user?: string, $neutral?: boolean }>`
  position: relative;
  transition: 0.3s;
  height: 32px;
  width: 32px;
  margin-left: -16px;
  background: ${({theme, $neutral}) => $neutral? theme.neutralBackgroundMedium : "rgb(154, 148, 248)"};
  border-radius: 5px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;



  &:first-child {
    margin-left: 0;
  }
  &:hover {
    border: 2px solid ${({ theme }) => theme.primaryBorder};
  }
`;

export const UserIcon = styled.div`
  /* background: ${({ theme }) => theme.primaryBackgroundActive}; */
  border-radius: 5px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-size: 12px;
  font-weight: 400;
`;
