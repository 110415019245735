import { FC, useState } from "react";
import * as s from "./styles";
import { Button } from "../Button";
import { CheckCircle } from "react-bootstrap-icons";
import { ReactComponent as EmptyWidgetsIcon } from "../../assets/EmptyWidgets.svg";
import { ReactComponent as BarchartStepIcon } from "../../assets/bar-chart-steps.svg";
import { PagesData } from "../../models/Pages";
import { useDispatch } from "react-redux";
import { setPageIdSelected } from "../../store/slices/appConfig";

// Types for the options
export type ReportType = string;

interface Props {
  pages: PagesData;
}

const WidgetSelector: FC<Props> = ({ pages }) => {
  const [selectedOption, setSelectedOption] = useState<ReportType | null>(null);
  const [pressedOption, setPressedOption] = useState<ReportType | null>(null);

  const dispatch = useDispatch();

  const filteredPages = (pages?.items || []).filter(
    (page) => page.dashType !== "report"
  );

  const handleConfirm = () => {
    dispatch(setPageIdSelected(selectedOption));
  };

  return (
    <s.Container>
      <EmptyWidgetsIcon />
      <s.Title>No widgets added</s.Title>
      <s.Description>
        Add widgets from an existing dashboard or comparison by selecting "add
        to report" from the widget menu. Alternatively, use an existing page as
        template.
      </s.Description>

      <s.OptionsContainer>
        {filteredPages.map((page) => (
          <s.Option
            key={page.id}
            $selected={selectedOption === page.id}
            $pressed={pressedOption === page.id}
            onClick={() => setSelectedOption(page.id || "1")}
            onMouseDown={() => setPressedOption(page.id || "1")}
            onMouseUp={() => setPressedOption(null)}
            onMouseLeave={() => setPressedOption(null)}
          >
            <BarchartStepIcon />
            <h3>{page.name}</h3>
            <s.RadioButtonWrapper>
              {selectedOption === page.id ? (
                <s.ActiveRadioButton />
              ) : pressedOption === page.id ? (
                <s.PressRadioButton />
              ) : (
                <>
                  <s.HoveredRadioButton />
                  <s.InactiveRadioButton />
                </>
              )}
            </s.RadioButtonWrapper>
          </s.Option>
        ))}
      </s.OptionsContainer>

      <Button
        onClick={handleConfirm}
        icon={<CheckCircle />}
        variant={selectedOption ? "secondary" : "disabled"}
        size="medium"
        name="Confirm"
        disabled={!selectedOption}
      />
    </s.Container>
  );
};

export default WidgetSelector;
