import styled from "styled-components";

export const ProjectsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ProjectsListHeading = styled.div`
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
`;
