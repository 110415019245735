export const checkFullProperty = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }
  if (obj.hasOwnProperty('full')) {
    return true;
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (checkFullProperty(obj[key])) {
        return true;
      }
    }
  }
  return false;
};
