import { useState, useEffect, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const Header = styled.div<{ $height?: number, $offset?: number }>`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: ${({ $height }) => $height || '72px'};
  width: 100%;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
  z-index: 999;
  ${({ $offset }) => css`
    transform: translateY(${$offset}px);
  `}
`;
interface Props {
  height?: number
}
export const ScrollWrapper: React.FC<PropsWithChildren<Props>> = ({ children, height }) => {
  const [offset, setOffset] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const deltaY = currentScrollY - lastScrollY;

    if (deltaY > 0) {
      setOffset((prevOffset) => Math.max(prevOffset - deltaY, -(height || 64)));
    } else {
      setOffset((prevOffset) => Math.min(prevOffset - deltaY, 0));
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);

  return (
    <>
      <Header $offset={offset}>
        {children}
      </Header>
    </>
  );
};