import { DimensionsCount } from "../DatasetModalListItem/style";
import React from "react";
import { ParsedSuggestions } from "../../models/Files";
import { DatasetFiledDTO } from "../../models/Datasets";

interface DimensionsCountsInterface {
  data: ParsedSuggestions[] | DatasetFiledDTO[] | undefined;
  showAllDatasets: boolean;
  setShowAllDatasets: (a: boolean) => void;
}
export const DimensionsCounts = ({
  data,
  showAllDatasets,
  setShowAllDatasets,
}: DimensionsCountsInterface) => {
  if (data && data.length > 5 && !showAllDatasets) {
    const count = data.length - 5;
    return (
      <DimensionsCount onClick={() => setShowAllDatasets(true)}>
        +{count}
      </DimensionsCount>
    );
  }
  return null;
};
