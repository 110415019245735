export const colors = [
  [
    "#FEFFFE",
    "#00374A",
    "#004D65",
    "#016E8F",
    "#008CB4",
    "#00A1D8",
    "#01C7FC",
    "#52D6FC",
    "#93E3FC",
    "#CBF0FF",
  ],
  [
    "#EBEBEB",
    "#011D57",
    "#012F7B",
    "#0042A9",
    "#0056D6",
    "#0061FD",
    "#3A87FD",
    "#74A7FF",
    "#A7C6FF",
    "#D2E2FE",
  ],
  [
    "#D6D6D6",
    "#11053B",
    "#1A0A52",
    "#2C0977",
    "#371A94",
    "#4D22B2",
    "#5E30EB",
    "#864FFD",
    "#B18CFE",
    "#D8C9FE",
  ],
  [
    "#C2C2C2",
    "#2E063D",
    "#450D59",
    "#61187C",
    "#7A219E",
    "#982ABC",
    "#BE38F3",
    "#D357FE",
    "#E292FE",
    "#EFCAFE",
  ],
  [
    "#ADADAD",
    "#3C071B",
    "#551029",
    "#791A3D",
    "#99244F",
    "#B92D5D",
    "#E63B7A",
    "#EE719E",
    "#F4A4C0",
    "#F9D3E0",
  ],
  [
    "#999999",
    "#5C0701",
    "#831100",
    "#B51A00",
    "#E22400",
    "#FF4015",
    "#FE6250",
    "#FF8C82",
    "#FFB5AF",
    "#FFDAD8",
  ],
  [
    "#858585",
    "#5A1C00",
    "#7B2900",
    "#AD3E00",
    "#DA5100",
    "#FF6A00",
    "#FE8648",
    "#FEA57D",
    "#FFC5AB",
    "#FFE2D6",
  ],
  [
    "#707070",
    "#583300",
    "#7A4A00",
    "#A96800",
    "#D38301",
    "#FFAB01",
    "#FEB43F",
    "#FEC777",
    "#FED9A8",
    "#FEECD4",
  ],
  [
    "#5C5C5C",
    "#563D00",
    "#785800",
    "#A67B01",
    "#D19D01",
    "#FCC700",
    "#FECB3E",
    "#FED977",
    "#FDE4A8",
    "#FEF1D5",
  ],
  [
    "#474747",
    "#666100",
    "#8D8602",
    "#C4BC00",
    "#F5EC00",
    "#FEFB41",
    "#FFF76B",
    "#FFF994",
    "#FFFBB9",
    "#FDFBDD",
  ],
  [
    "#333333",
    "#4F5504",
    "#6F760A",
    "#9BA50E",
    "#C3D117",
    "#D9EC37",
    "#E4EF65",
    "#EAF28F",
    "#F1F7B7",
    "#F6FADB",
  ],
  [
    "#000000",
    "#263E0F",
    "#38571A",
    "#4E7A27",
    "#669D34",
    "#76BB40",
    "#96D35F",
    "#B1DD8B",
    "#CDE8B5",
    "#DEEED4",
  ],
];

export const compactColors = [
  "#FEFFFE",
  "#EBEBEB",
  "#D6D6D6",
  "#C2C2C2",
  "#ADADAD",
  "#999999",
  "#858585",
  "#707070",
  "#5C5C5C",
  "#474747",
  "#333333",
  "#000000",
  "#00374A",
  "#011D57",
  "#11053B",
  "#2E063D",
  "#3C071B",
  "#5C0701",
  "#5A1C00",
  "#583300",
  "#563D00",
  "#666100",
  "#4F5504",
  "#263E0F",
  "#004D65",
  "#012F7B",
  "#1A0A52",
  "#450D59",
  "#551029",
  "#831100",
  "#7B2900",
  "#7A4A00",
  "#785800",
  "#8D8602",
  "#6F760A",
  "#38571A",
  "#016E8F",
  "#0042A9",
  "#2C0977",
  "#61187C",
  "#791A3D",
  "#B51A00",
  "#AD3E00",
  "#A96800",
  "#A67B01",
  "#C4BC00",
  "#9BA50E",
  "#4E7A27",
  "#008CB4",
  "#0056D6",
  "#371A94",
  "#7A219E",
  "#99244F",
  "#E22400",
  "#DA5100",
  "#D38301",
  "#D19D01",
  "#F5EC00",
  "#C3D117",
  "#669D34",
  "#00A1D8",
  "#0061FD",
  "#4D22B2",
  "#982ABC",
  "#B92D5D",
  "#FF4015",
  "#FF6A00",
  "#FFAB01",
  "#FCC700",
  "#FEFB41",
  "#D9EC37",
  "#76BB40",
  "#01C7FC",
  "#3A87FD",
  "#5E30EB",
  "#BE38F3",
  "#E63B7A",
  "#FE6250",
  "#FE8648",
  "#FEB43F",
  "#FECB3E",
  "#FFF76B",
  "#E4EF65",
  "#96D35F",
  "#52D6FC",
  "#74A7FF",
  "#864FFD",
  "#D357FE",
  "#EE719E",
  "#FF8C82",
  "#FEA57D",
  "#FEC777",
  "#FED977",
  "#FFF994",
  "#EAF28F",
  "#B1DD8B",
  "#93E3FC",
  "#A7C6FF",
  "#B18CFE",
  "#E292FE",
  "#F4A4C0",
  "#FFB5AF",
  "#FFC5AB",
  "#FED9A8",
  "#FDE4A8",
  "#FFFBB9",
  "#F1F7B7",
  "#CDE8B5",
  "#CBF0FF",
  "#D2E2FE",
  "#D8C9FE",
  "#EFCAFE",
  "#F9D3E0",
  "#FFDAD8",
  "#FFE2D6",
  "#FEECD4",
  "#FEF1D5",
  "#FDFBDD",
  "#F6FADB",
  "#DEEED4",
];

export const colorBlocks = [
  {
    gradient: "linear-gradient(90deg, #0052D4 0%, #4364F7 50%, #6FB1FC 100%)",
    id: 1,
  },
  {
    gradient: "linear-gradient(134deg, #0061FF 0%, #60EFFF 100%)",
    id: 2,
  },
  {
    gradient: "linear-gradient(109deg, #9BF8F4 0%, #6F7BF7 100%)",
    id: 3,
  },
  {
    gradient: "linear-gradient(90deg, #FF930F 0%, #FFF95B 100%)",
    id: 4,
  },
  {
    gradient: "linear-gradient(90deg, #FF0F7B 0%, #F89B29 100%)",
    id: 5,
  },
  {
    gradient: "linear-gradient(90deg, #FF5858 0%, #FA9372 100%)",
    id: 6,
  },
  {
    gradient: "linear-gradient(90deg, #9796F0 0%, #FBC7D4 100%)",
    id: 7,
  },
  {
    gradient: "linear-gradient(90deg, #FFAFBD 0%, #FFC3A0 100%)",
    id: 8,
  },
  {
    gradient: "linear-gradient(90deg, #ED4264 0%, #FFEDBC 100%)",
    id: 9,
  },
  {
    gradient: "linear-gradient(90deg, #4776E6 0%, #8E54E9 100%)",
    id: 10,
  },
];

export const colorTexts = [
  {
    color: "#000005",
    id: 1,
  },
  {
    color: "#FFFFFF",
    id: 2,
  },
];
