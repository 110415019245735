import styled from "styled-components";
import { ReactComponent as radioButtonActive } from "../../../../assets/radioButtonActive.svg";
import { ReactComponent as radioButtonInactive } from "../../../../assets/radioButtonInactive.svg";
import { ReactComponent as radioButtonHovered } from "../../../../assets/hoveredRadioButton.svg";
import { ReactComponent as pressRadioButton } from "../../../../assets/pressRadioButton.svg";

export const FormWrapper = styled.div`
  padding: 0 20px 16px 20px;
  height: 384px;
`;

export const FormGroup = styled.div`
  margin-bottom: 12px;
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  color: ${({ theme }) => theme.neutralText};
  text-overflow: ellipsis;
  p {
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 16px;
`;

export const Chip = styled.button<{ $selected: boolean }>`
  all: unset;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.primaryBackgroundActive : theme.neutralBackground};
  color: ${({ $selected, theme }) =>
    $selected ? theme.primaryText : theme.neutralTextWeak};
  padding: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  transition: 0.2s ease-in-out;

  span {
    font-size: 12px;
    font-weight: 500;
    color: ${({ $selected, theme }) =>
      $selected ? theme.primaryText : theme.neutralText};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.neutralBackgroundActive} !important;
  }

  &:hover {
    background-color: ${({ $selected, theme }) =>
      $selected ? theme.primaryBackgroundActive : theme.neutralBackgroundHover};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.neutralBackground};
    color: ${({ theme }) => theme.neutralTextDisabled};
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  overflow: scroll;
  max-height: 256px;
`;

export const HoveredRadioButton = styled(radioButtonHovered)`
  margin-right: 8px;
  display: none;
`;

export const ActiveRadioButton = styled(radioButtonActive)`
  margin-right: 8px;
`;

export const PressRadioButton = styled(pressRadioButton)`
  margin-right: 8px;
`;

export const InactiveRadioButton = styled(radioButtonInactive)`
  margin-right: 8px;
`;

export const RadioButtonWrapper = styled.div`
  align-self: flex-start;
`;

export const Option = styled.label<{ $selected: boolean }>`
  padding: 8px 0 8px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 400px;
  transition: 0.2s ease-in-out;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 25px;
    background: ${({ theme }) => theme.neutralBorder};
  }

  &:last-child {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      background: transparent;
    }
  }

  svg {
    path {
      fill: ${({ theme }) => theme.neutralText};
    }
  }

  &:hover {
    ${HoveredRadioButton} {
      display: ${({ $selected }) => !$selected && "block"};
    }

    ${ActiveRadioButton}, ${InactiveRadioButton} {
      display: ${({ $selected }) => !$selected && "none"};
    }
  }
`;
