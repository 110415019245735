export const darkTheme = {
  // Neutral
  neutralBackgroundBase: "#020D20",
  neutralBackgroundMedium: "#162032",
  neutralBackgroundExtraDark: "#141C2C",
  neutralBackground: "#9FA4AD1F",
  neutralBackgroundHover: "#9FA4AD29",
  neutralBackgroundActive: "#9FA4AD33",

  neutralText: "#FFFFFF",
  neutralTextWeak: "#9FA4AD",
  neutralTextDisabled: "#5F6877",

  neutralBorder: "#2E3849",
  neutralBorderMedium: "#2E3849",

  neutralScrim: "#FFFFFF1A",
  
  // Primary
  primaryAction: "#FFFFFF1A",
  primaryActionHover: "#D1D6DE",

  primaryBackground: "#5C91AB29",
  primaryBackgroundMedium: "#5C91AB33",
  primaryBackgroundActive: "#5C91AB47",
  primaryBackgroundStrong: "#5C91AB",
  primaryBackgroundStrongHover: "#5C91AB",
  primaryBackgroundStrongActive: "#85ADC0",

  primaryText: "#709FB6",
  primaryTextHover: "#85ADC0",
  primaryTextStrong: "#020D20",
  primaryTextStrongHover: "#276A8B",
  primaryTextStrongActive: "#337696",
  secondaryTextUser: "#0b101a",

  primaryBorder: "#5C91AB",

  // Alert
  alertBackground: "#D878941F",
  alertBackgroundMedium: "#D8789433",
  alertBackgroundActive: "#D878943D",
  alertBackgroundStrong: "#D87894",
  alertBackgroundStrongHover: "#DC86A0",
  alertBackgroundStrongActive: "#E29AAF",

  alertText: "#DC86A0",
  alertTextHover: "#E29AAF",

  alertBorder: "#D87894",
  
  // Warning
  warningBackground: "#D7860D1F",
  warningBackgroundMedium: "#D7860D33",
  warningBackgroundActive: "#D7860D3D",
  warningBackgroundStrong: "#D7860D",
  warningBackgroundStrongHover: "#DD9933",
  warningBackgroundStrongActive: "#E6B366",
  
  warningText: "#DD9933",
  warningTextHover: "#E6B366",
  
  warningBorder: "#D7860D",

  // Success
  successBackground: "#D7860D",
  successBackgroundMedium: "#40A88533",
  successBackgroundActive: "#40A8853D",
  successBackgroundStrong: "#40A885",
  successBackgroundStrongHover: "#51AF8F",
  successBackgroundStrongActive: "#51AF8F",

  successText: "#51AF8F",
  successTextHover: "#60B699",
  
  successBorder: "#40A885",

  //PREVENT ERRORS TEMPORARY VALUES
  primaryBackgroundPublish: "rgba(84, 72, 255, 0.06)",
  primaryBackgroundHover: "rgba(84, 72, 255, 0.16)",
  secondaryBackground: "rgba(0, 20, 63, 1)",
  stratosBackground: "rgba(0, 6, 49, 1)",
  mediumBorder: "rgba(147, 155, 167, 1)",
  colorGray500: "rgb(223, 225, 228)",
  primaryButton: "#2D6E8D1F",
  secondaryButton: "#00143F",
  secondaryButtonHover: "#000631",
  secondaryButtonActive: "#000631",
  neutralButton: "rgba(95, 104, 119, 0.08)",
  neutralButtonColored: "rgba(230, 233, 238)",
  neutralButtonColoredHover: "rgba(226, 230, 234)",
  secondaryText: "rgba(255, 255, 255, 1)",
  disabledText: "rgba(147, 155, 167, 1)",
  neutralWeak: "rgba(95, 104, 119, 1)",
  primaryTextHoverStrong: "rgba(55, 46, 165, 1)",
  malibuText: "rgba(131, 145, 255, 1)",
  boxShadowColor: "rgba(99, 99, 99, 0.2) 0 0 3px 0",
  primaryBorderColor: "rgba(211, 219, 227, 1)",
};