import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";
import {
  ServerResponse,
  GetCommentsResponse,
  GetGroupedCommentsResponse,
  CreateCommentResponse,
  UpdateActionResponse,
  CommentQueryParams,
  CreateCommentRequest,
  UpdateCommentsRequest,
  EditCommentRequest,
  EditCommentResponse,
} from "../types/comments";

import {
  COMMENTS_URL,
  COMMENTS_READ_URL,
  COMMENTS_HIDE_URL,
  COMMENTS_GROUPED_URL,
  COMMENTS_RESOLVE_URL,
  COMMENTS_EDIT_URL,
} from "./constants";

export const fetchComments = async (
  params: CommentQueryParams
): Promise<ServerResponse<GetCommentsResponse>> => {
  try {
    const response: AxiosResponse<GetCommentsResponse> = await axios.get(
      COMMENTS_URL, 
      { params }
    );
    return { response };
  } catch (error) {
    return { error: error as AxiosError<{ message: string }> };
  }
};

export const addComment = async (
  commentData: CreateCommentRequest
): Promise<ServerResponse<CreateCommentResponse>> => {
  try {
    const response: AxiosResponse<CreateCommentResponse> = await axios.post(
      COMMENTS_URL,
      commentData
    );
    return { response };
  } catch (error) {
    return { error: error as AxiosError<{ message: string }> };
  }
};

export const editComment = async (
  data: EditCommentRequest
): Promise<ServerResponse<EditCommentResponse>> => {
  try {
    const url = COMMENTS_EDIT_URL.replace('{id}', data.id);
    const response: AxiosResponse<EditCommentResponse> = await axios.patch(
      url,
      {
        content: data.content,
      }
    );
    return { response };
  } catch (error) {
    return { error: error as AxiosError<{ message: string }> };
  }
};

export const fetchGroupedComments = async (
  params: CommentQueryParams
): Promise<ServerResponse<GetGroupedCommentsResponse>> => {
  try {
    const response: AxiosResponse<GetGroupedCommentsResponse> = await axios.get(
      COMMENTS_GROUPED_URL,
      { params }
    );
    return { response };
  } catch (error) {
    return { error: error as AxiosError<{ message: string }> };
  }
};

export const readComments = async (
  data: UpdateCommentsRequest
): Promise<ServerResponse<UpdateActionResponse>> => {
  try {
    const response: AxiosResponse<UpdateActionResponse> = await axios.post(
      COMMENTS_READ_URL,
      data
    );
    return { response };
  } catch (error) {
    return { error: error as AxiosError<{ message: string }> };
  }
};

export const hideComments = async (
  data: UpdateCommentsRequest
): Promise<ServerResponse<UpdateActionResponse>> => {
  try {
    const response: AxiosResponse<UpdateActionResponse> = await axios.post(
      COMMENTS_HIDE_URL,
      data
    );
    return { response };
  } catch (error) {
    return { error: error as AxiosError<{ message: string }> };
  }
};

export const resolveComments = async (
  data: UpdateCommentsRequest
): Promise<ServerResponse<UpdateActionResponse>> => {
  try {
    const response: AxiosResponse<UpdateActionResponse> = await axios.post(
      COMMENTS_RESOLVE_URL,
      data
    );
    return { response };
  } catch (error) {
    return { error: error as AxiosError<{ message: string }> };
  }
};