import { createSelector } from 'reselect';
import { StoreInterface } from "../../models";

const selectPdfSlice = (state: StoreInterface) => state.pdfSlice;

export const documentName = createSelector(
  selectPdfSlice,
  (pdfSlice) => pdfSlice?.documentName
);

export const pdfGenerationTrigger = createSelector(
  selectPdfSlice,
  (pdfSlice) => pdfSlice?.pdfGenerationTrigger
);

export const pdfGenerationProgress = createSelector(
  selectPdfSlice,
  (pdfSlice) => pdfSlice?.pdfGenerationProgress
);
