import React from "react";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
  ViewToggleOn,
} from "./styles";
import { Laptop, EnvelopeExclamation } from "react-bootstrap-icons";

export const NotificationsSection = () => {
  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>Notifications</ProfileSideBarHeading>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <EnvelopeExclamation />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>
            Email notifications
          </ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription>
            <ViewToggleOn /> Send Notifications by email
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>

      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Laptop />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>
            Desktop notifications
          </ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription>
            <ViewToggleOn /> Send Notifications to my computer
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
