import { styled } from "styled-components";
import { ChevronDown } from "react-bootstrap-icons";

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`

export const LastCreated = styled.div`
  align-items: center;
  user-select: none;
  line-height: 16px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  gap: 4px;
`

export const Arrow = styled(ChevronDown)`
  fill: "#5F6877";
  height: 16px;
  width: 16px;
`