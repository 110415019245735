import { Pen } from "react-bootstrap-icons";
import {
  EyeIcon,
  SliderIcon,
  Switch,
  SwitchInput,
  SwitchLabel,
  SwitchWrapper,
} from "./style";

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.ChangeEventHandler;
  values?: any;
  checked?: boolean;
  name?: string;
  disabled?: boolean;
  errors?: string;
  touched?: boolean;
  label?: string;
};

export const EditSwitchComponent: React.FC<Props> = (props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);
  };

  return (
    <SwitchWrapper>
      {!!props.label && <SwitchLabel>{props.label}</SwitchLabel>}
      <Switch $active={!!props.checked}>
        <SwitchInput
          $error={!!(props.errors && props.touched)}
          disabled={props.disabled}
          onChange={handleChange}
          onBlur={props.onBlur}
          type="checkbox"
          name={props.name}
          checked={props.checked}
        ></SwitchInput>
        <SliderIcon $active={!props.checked}>
          <EyeIcon />
        </SliderIcon>
        <SliderIcon $active={!!props.checked}>
          <Pen />
        </SliderIcon>
      </Switch>
    </SwitchWrapper>
  );
};
