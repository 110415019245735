export const ticksFormatter = (value: number) => {
  const abbreviations = ["B", "M", "K"];

  for (let i = 0; i < abbreviations.length; i++) {
    const unit = Math.pow(1000, abbreviations.length - i);
    if (Math.abs(value) >= unit) {
      const formattedValue = (value / unit).toFixed(1);
      if (formattedValue.endsWith(".0")) {
        return formattedValue.slice(0, -2) + abbreviations[i];
      } else {
        return formattedValue + abbreviations[i];
      }
    }
  }

  return value.toFixed(0);
};
