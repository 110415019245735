import React from "react";
import {
  TooltipBlock,
  TooltipColumn,
  TooltipContainer,
  TooltipHeading,
} from "./styles";

export const TooltipLabel = ({
  x,
  y,
  children,
  name,
}: {
  name?: string;
  x: number;
  y: number;
  children?: any;
}) => {
  return (
    <TooltipContainer style={{ top: y, left: x }}>
      <TooltipBlock>
        <TooltipColumn>
          <TooltipHeading>{name}</TooltipHeading>
        </TooltipColumn>
        {children}
      </TooltipBlock>
    </TooltipContainer>
  );
};
