import styled from "styled-components";
// import { StoryTellingSectionProps } from "../types/StoryTellingSectionProps.interface"

interface Props {
  type?: string;
  onClick: () => void;
}

export const BackgroundLinesPath = styled.path`
  fill: transparent;
`;

export const LinesPath = styled.path<{ $active?: boolean }>`
  fill: ${({ $active }) => ($active ? "#155676 !important" : "#5F6877")};
`;

export const SVG = styled.svg<{ $active?: boolean }>`
  cursor: pointer;
  background-color: ${({ $active }) => ($active ? "#2D6E8D3D !important" : "transparent")};
  border-radius: 2px;
  &:hover {
    ${LinesPath} {
      fill: #000005;
    }

    ${BackgroundLinesPath} {
      fill: #5f68771f;
    }
  }

  path {
    transition: fill 300ms, fill-opacity 300ms;
  }
`;

export const LeftIcon = (props: Props) => {
  const active = props.type === "left";
  return (
    <SVG
      onClick={props.onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      $active={active}
      xmlns="http://www.w3.org/2000/svg"
    >
      <BackgroundLinesPath
        d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
        fillOpacity="0.16"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12.5C2 12.2239 2.22386 12 2.5 12H9.5C9.77614 12 10 12.2239 10 12.5C10 12.7761 9.77614 13 9.5 13H2.5C2.22386 13 2 12.7761 2 12.5Z"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.5C2 9.22386 2.22386 9 2.5 9H13.5C13.7761 9 14 9.22386 14 9.5C14 9.77614 13.7761 10 13.5 10H2.5C2.22386 10 2 9.77614 2 9.5Z"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6.5C2 6.22386 2.22386 6 2.5 6H9.5C9.77614 6 10 6.22386 10 6.5C10 6.77614 9.77614 7 9.5 7H2.5C2.22386 7 2 6.77614 2 6.5Z"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3.5C2 3.22386 2.22386 3 2.5 3H13.5C13.7761 3 14 3.22386 14 3.5C14 3.77614 13.7761 4 13.5 4H2.5C2.22386 4 2 3.77614 2 3.5Z"
      />
    </SVG>
  );
};

export const CenterIcon = (props: Props) => {
  const active = props.type === "center";
  return (
    <SVG
      onClick={props.onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      $active={active}
      xmlns="http://www.w3.org/2000/svg"
    >
      <BackgroundLinesPath
        d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
        fillOpacity="0.16"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12.5C4 12.2239 4.22386 12 4.5 12H11.5C11.7761 12 12 12.2239 12 12.5C12 12.7761 11.7761 13 11.5 13H4.5C4.22386 13 4 12.7761 4 12.5Z"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.5C2 9.22386 2.22386 9 2.5 9H13.5C13.7761 9 14 9.22386 14 9.5C14 9.77614 13.7761 10 13.5 10H2.5C2.22386 10 2 9.77614 2 9.5Z"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6.5C4 6.22386 4.22386 6 4.5 6H11.5C11.7761 6 12 6.22386 12 6.5C12 6.77614 11.7761 7 11.5 7H4.5C4.22386 7 4 6.77614 4 6.5Z"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3.5C2 3.22386 2.22386 3 2.5 3H13.5C13.7761 3 14 3.22386 14 3.5C14 3.77614 13.7761 4 13.5 4H2.5C2.22386 4 2 3.77614 2 3.5Z"
      />
    </SVG>
  );
};

export const RightIcon = (props: Props) => {
  const active = props.type === "right";
  return (
    <SVG
      onClick={props.onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      $active={active}
      xmlns="http://www.w3.org/2000/svg"
    >
      <BackgroundLinesPath
        d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
        fillOpacity="0.16"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12.5C6 12.2239 6.22386 12 6.5 12H13.5C13.7761 12 14 12.2239 14 12.5C14 12.7761 13.7761 13 13.5 13H6.5C6.22386 13 6 12.7761 6 12.5Z"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.5C2 9.22386 2.22386 9 2.5 9H13.5C13.7761 9 14 9.22386 14 9.5C14 9.77614 13.7761 10 13.5 10H2.5C2.22386 10 2 9.77614 2 9.5Z"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6.5C6 6.22386 6.22386 6 6.5 6H13.5C13.7761 6 14 6.22386 14 6.5C14 6.77614 13.7761 7 13.5 7H6.5C6.22386 7 6 6.77614 6 6.5Z"
      />
      <LinesPath
        $active={active}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3.5C2 3.22386 2.22386 3 2.5 3H13.5C13.7761 3 14 3.22386 14 3.5C14 3.77614 13.7761 4 13.5 4H2.5C2.22386 4 2 3.77614 2 3.5Z"
      />
    </SVG>
  );
};
