import { IAcceptedTypes } from "../../Inputs/CustomSelectDimensions/types";
import { IGroupTypesV2 } from "../types/types";

export type IMetrics = {
  type: IGroupTypesV2;
  lable: string;
  subLable: string;
  icon: "axe" | "add" | "display";
  axe?: "X" | "Y";
  acceptTypes: IAcceptedTypes[];
};

export const getMetrics = (
  widgetName: string,
  direction?: "horizontal" | "vertical"
): IMetrics[] => {
  switch (widgetName) {
    case "lineChart":
    case "areaChart":
      return [
        {
          type: "xAxe",
          lable: "X Axis",
          subLable: "Category",
          icon: "axe",
          axe: "X",
          acceptTypes: ["location", "categorical", "datetime"],
        },
        {
          type: "yAxe",
          lable: "Y Axe",
          subLable: "Value or Metric",
          icon: "axe",
          axe: "Y",
          acceptTypes: ["metric"],
        },
        {
          type: "groupBy",
          lable: "Group by",
          subLable: "Category",
          icon: "add",
          acceptTypes: ["location", "categorical", "datetime"],
        },
      ];
    case "barChart":
    case "lollipopChart":
      if (direction === "vertical") {
        return [
          {
            type: "xAxe",
            lable: "X Axis",
            subLable: "Category",
            icon: "axe",
            axe: "X",
            acceptTypes: ["location", "categorical", "datetime"],
          },
          {
            type: "yAxe",
            lable: "Y Axe",
            subLable: "Value or Metric",
            icon: "axe",
            axe: "Y",
            acceptTypes: ["metric"],
          },
          {
            type: "groupBy",
            lable: "Group by",
            subLable: "Category",
            icon: "add",
            acceptTypes: ["location", "categorical", "datetime"],
          },
        ];
      } else {
        return [
          {
            type: "yAxe",
            lable: "X Axis",
            subLable: "Value or Metric",
            icon: "axe",
            axe: "X",
            acceptTypes: ["metric"],
          },
          {
            type: "xAxe",
            lable: "Y Axe",
            subLable: "Category",
            icon: "axe",
            axe: "Y",
            acceptTypes: ["location", "categorical", "datetime"],
          },
          {
            type: "groupBy",
            lable: "Group by",
            subLable: "Category",
            icon: "add",
            acceptTypes: ["location", "categorical", "datetime"],
          },
        ];
      }
    case "matrix":
      return [
        {
          type: "xAxe",
          lable: "X Axis",
          subLable: "Category",
          icon: "axe",
          axe: "X",
          acceptTypes: ["location", "categorical", "datetime"],
        },
        {
          type: "yAxe",
          lable: "Y Axe",
          subLable: "Category",
          icon: "axe",
          axe: "Y",
          acceptTypes: ["location", "categorical", "datetime"],
        },
        {
          type: "arrangeBy",
          lable: "Arrange by",
          subLable: "Value or Metric",
          icon: "add",
          acceptTypes: ["location", "categorical"],
        },
      ];
    case "sankey":
      return [
        {
          type: "display",
          lable: "Display",
          subLable: "Value",
          icon: "display",
          acceptTypes: ["metric", "categorical"],
        },
        {
          type: "arrangeBy",
          lable: "Arrange by",
          subLable: "Category",
          icon: "add",
          acceptTypes: ["location", "categorical", "datetime"],
        },
      ];
    default:
      return [];
  }
};
