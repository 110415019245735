import { styled } from "styled-components";
import { CommentItem, MessageContent } from "./components/Message/styles";

export const CommentsWrapper = styled.div`
  position: absolute;
  top: 56px;
  right: 0px;
  width: 360px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 8px;
  padding: 12px;
  background-color: #fff;
  z-index: 1000;
`;

export const ReplyBox = styled.div`
  padding-top: 8px;
`;

export const ReplyButton = styled.button`
  flex: 1;
  padding: 8px;
  font-size: 14px;
  outline: none;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  background: ${({ theme }) => theme.neutralBackground};
  &:hover {
    background: ${({ theme }) => theme.neutralBackgroundHover};
  }
`;

export const Comments = styled.div`
  max-height: 312px;
  overflow: auto;
  scroll-behavior: smooth;
`;

export const MessagesContainer = styled.div`
  > ${CommentItem}:not(:last-child) ${MessageContent} {
    border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  }
`;
